import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Verbiage,
  loaderStyle,
  userPreferencesKey,
  ContractDisplayOptions,
} from '../../constants';
import { debounce } from '../../helpers/debounce';
import httpService from '../../services/http-service';
import AgGridComponent from '../../sharedcomponents/ag-grid/AgGrid';
import {
  getFilterColumns,
  getSortColumns,
} from '../../sharedcomponents/ag-grid/ag-grid-helper';
import MultiSelectDropdown from '../../sharedcomponents/multi-select-dropdown/multi-select-dropdown';
import { ReactComponent as DeleteIcon } from './../../assets/icons/common/delete.svg';
import { ReactComponent as UserGroupIcon } from './../../assets/icons/common/user-group.svg';
import Loader from './../../components/Loader';
import { Endpoints, UrlPath } from './../../constants';
import { getCSAContractsData } from './../../helpers/component-helper-methods';
import { addContract } from './../../redux/actions';
import { persistDataStore } from './../../redux/persistStore';
import store from './../../redux/store';
import { Notify } from './../../sharedcomponents/Alert/Notify';
import AddUserGroup from './add-user-group/add-user-group';
import './user-group.scss';
import copy_icon from '../../assets/icons/entitlements/mailing.svg';
import CopyLinkCell from '../../sharedcomponents/copy-link-cell/CopyLinkCell';
import AddUserRegistration from '../../pages/manage-email-domains/add-user-registration';

const UserGroup = () => {
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [showGroup, setShowGroup] = useState(false);
  const [dropDownLoader, setDropDownLoader] = useState(true);
  const [breadCrumbValue, setBreadCrumbValue] = useState();
  const [contractsResult, setContractsResult] = useState();
  const [editData, setEditData] = useState();
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [clickedRowData, setClickedRowData] = useState([]);
  const [contractDisplayOption, setContractDisplayOption] = useState();
  const [isViewerUser, setIsViewerUser] = useState(false);
  const dispatch = useDispatch();
  const showGroupModal = (bln) => setShowGroup(bln);

  const location = useLocation();
  const gridRef = useRef();

  useEffect(async () => {
    try {
      let storeData = store.getState();
      let storeUserInfo = storeData?.userInfo;

      if (storeUserInfo && storeUserInfo.userInfo) {
        setIsViewerUser(
          storeUserInfo.userInfo.SPGGroups?.includes('GRP_SFS_VIEWER'),
        );
        await getContractDisplayOption(storeUserInfo.userInfo.userPreferences);
        getContractsDataSet(storeUserInfo.userInfo.email);
      }
    } catch (err) {}
    // eslint-disable-next-line
  }, [useSelector((x) => x.userInfo)]);

  // set selected contracts
  useEffect(() => {
    const reduceCallback = setTimeout(() => {
      contractChangeHandler();
    }, 1000);
    return () => clearTimeout(reduceCallback);
    // eslint-disable-next-line
  }, [contractsResult]);

  const getContractLabelKey = () => {
    if (contractDisplayOption) {
      if (
        contractDisplayOption.preferenceValue ===
        ContractDisplayOptions[1].contractDisplayOption
      ) {
        return 'contractDescription';
      }
      if (
        contractDisplayOption.preferenceValue ===
        ContractDisplayOptions[2].contractDisplayOption
      ) {
        return 'label';
      }
    }

    return 'contractNumber';
  };

  const getContractDisplayOption = async (userPreferences) => {
    try {
      if (
        userPreferences?.find(
          (x) => x.preferenceKey === userPreferencesKey['displayContract'],
        )
      ) {
        setContractDisplayOption(
          userPreferences.find(
            (x) => x.preferenceKey === userPreferencesKey['displayContract'],
          ),
        );
      } else {
        setContractDisplayOption({
          preferenceValue: ContractDisplayOptions[0].contractDisplayOption,
        });
      }
    } catch (err) {}
  };

  const getContractDisplayColumns = (contractDisplayType) => {
    return !(
      contractDisplayType === contractDisplayOption?.preferenceValue ||
      contractDisplayOption?.preferenceValue ===
        ContractDisplayOptions[2].contractDisplayOption
    );
  };

  const isFirstColumn = (params) => {
    let displayedColumns = params.api.getAllDisplayedColumns();
    let thisIsFirstColumn = displayedColumns[0] === params.column;
    if (thisIsFirstColumn) {
      return thisIsFirstColumn;
    }
  };

  const isSameOrFirstColumn = (params) => {
    // Show checkbox on each row of first column and on grouped, show it only on grouped columns
    let displayedColumns = params?.api?.getAllDisplayedColumns();
    let rowGroupCheckBox = !params?.node?.group;
    let showCheckBox =
      // rowGroupCheckBox &&
      displayedColumns[params?.node?.level] === params?.column;
    return showCheckBox;
  };

  const defaultConfig = {
    editable: true,
    isExportCSV: false,
    suppressDragLeaveHidesColumns: true,
    isExportExcel: false,
    pivotPanelShow: '',
    pagination: false,
    isAutoSizeColums: true,
    enableCharts: false,
    rowGroupPanelShow: false,
    sideBar: true,
    overlayNoRowsTemplate:
      contractsResult && contractsResult.some((x) => x.checked)
        ? Verbiage.userGroups
        : Verbiage.noData,
  };

  const getContractsDataSet = async (userEmail) => {
    let csaActionData =
      persistDataStore.getState()?.userActionData?.csaUserActionInfo;
    userEmail = userEmail.toLowerCase();
    let queryParamsContract = {};

    let selectedUserContracts = [];
    if (localStorage.hasOwnProperty('CSASelectedUserContracts')) {
      // eslint-disable-next-line
      selectedUserContracts = localStorage
        .getItem('CSASelectedUserContracts')
        .split(',');
      queryParamsContract = {
        PageSize: 5000,
        Field:
          'contractNumber,contractType,contractDescription,bulkEntitlementEnable',
      };
    } else {
      queryParamsContract = {
        PageSize: 5000,
        Field:
          'contractNumber,contractType,contractDescription,bulkEntitlementEnable',
        Filter: `email:"${userEmail}"`,
      };
    }
    try {
      let storeData = store.getState();
      let storeContracts = storeData.contract;
      if (
        storeContracts &&
        storeContracts.contracts &&
        storeContracts.contracts.length > 0
      ) {
        let storedContract = [...storeContracts.contracts];
        setContractsResult(storedContract);
      } else {
        if (csaActionData?.type && csaActionData?.value) {
          let csaContracts = await getCSAContractsData();
          setDropDownLoader(false);
          setContractsResult(csaContracts);
          dispatch(addContract(csaContracts));
        } else {
          const contractResponse = await httpService.get(
            Endpoints.contractApi,
            queryParamsContract,
          );
          let tempState = contractResponse.data?.results?.map((el, i) => {
            return {
              label:
                el.contractNumber +
                (el.contractDescription ? ' - ' + el.contractDescription : ''),
              id: el.contractNumber,
              checked: true,
              bulkEntitlementEnable: el.bulkEntitlementEnable,
              ...el,
            };
          });
          setContractsResult(tempState);
          dispatch(addContract(tempState));
        }
      }
    } catch (err) {
    } finally {
      setDropDownLoader(false);
      setLoading(false);
    }
  };

  const columnFilterParams = {
    filter: 'agTextColumnFilter',
    enableRowGroup: false,
    enablePivot: false,
    menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
  };

  const defaultColdef = {
    ...columnFilterParams,
    sortable: true,
    resizable: true,
    checkboxSelection: !isViewerUser && isSameOrFirstColumn,
    headerCheckboxSelection: !isViewerUser && isFirstColumn,
  };

  const closeEmailModal = () => {
    setShowEmailModal(false);
  };

  const columns = [
    {
      headerName: '',
      field: 'contractNumber',
      resizable: true,
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: [
          'inRange',
          {
            displayKey: 'inRange',
            displayName: 'Range Between',
            predicate: ([filterValue], cellValue) => {
              return cellValue == null || cellValue < filterValue;
            },
          },
        ],
      },
      suppressHeaderMenuButton: true,
      sortable: false,
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      headerTooltip: 'groupName',
      field: 'groupName',
      initialFlex: 3,
      lockPosition: true,
      cellRenderer: (params) => {
        if (params.value != null) {
          return (
            <div
              className="email-label"
              title={params.value}
              onClick={() => {
                setEditData(params.data);
                showGroupModal(true);
              }}
            >
              {params.value}
            </div>
          );
        } else return '';
      },
    },
    {
      headerName: 'Contract ID',
      headerTooltip: 'Contract ID',
      field: 'contractNumber',
      tooltipField: 'contractNumber',
      initialFlex: 3,
      ...columnFilterParams,
      hide: contractDisplayOption?.preferenceValue
        ? getContractDisplayColumns(
            ContractDisplayOptions[0].contractDisplayOption,
          )
        : true,
      suppressColumnsToolPanel: contractDisplayOption?.preferenceValue
        ? getContractDisplayColumns(
            ContractDisplayOptions[0].contractDisplayOption,
          )
        : true,
    },
    {
      headerName: 'Contract Description',
      headerTooltip: 'Contract Description',
      field: 'contractDescription',
      tooltipField: 'contractDescription',
      initialFlex: 3,
      ...columnFilterParams,
      hide: getContractDisplayColumns(
        ContractDisplayOptions[1].contractDisplayOption,
      ),
      suppressColumnsToolPanel: getContractDisplayColumns(
        ContractDisplayOptions[1].contractDisplayOption,
      ),
    },
    {
      headerName: 'Modified Date',
      headerTooltip: 'Modified Date',
      field: 'modifiedDate',
      tooltipField: 'modifiedDate',
      menuTabs: ['generalMenuTab', 'columnsMenuTab'],
      initialFlex: 3,
      initialHide: true,
    },
    {
      headerName: 'Invite Users',
      headerTooltip: 'Invite Users',
      field: 'uniqueLink',
      tooltipField: '',
      filter: false,
      sortable: false,
      initialFlex: 2,
      cellRenderer: (params) => {
        return (
          <>
            <div className="link-block">
              <CopyLinkCell value={params.value} />
              <span
                onClick={() => {
                  setShowEmailModal(true);
                  setClickedRowData(params.data);
                }}
              >
                <img
                  className="cursor-pointer"
                  src={copy_icon}
                  width={18}
                  height={18}
                  title="Click here to send registration invite to end users"
                />
              </span>
            </div>
          </>
        );
      },
      hide: isViewerUser,
      suppressColumnsToolPanel: isViewerUser,
    },
  ];

  const [buttonIcons, setButtonIcons] = useState({
    DeleteIcon: false,
    ExportIcon: false,
  });

  const onFilterTextBoxChanged = useCallback(({ target }) => {
    let searchText = target.value;
    if (!searchText || searchText.length > 2) {
      gridRef.current.api.onFilterChanged();
    }
  }, []);

  const onFilterTextBoxChangedDebounce = debounce(onFilterTextBoxChanged, 1000);

  const getUserGroup = () => {
    gridRef.current.api.onFilterChanged();
    gridRef.current.api.ensureIndexVisible(0, 'top');
  };

  const openEditGroupPopUp = (rowData) => {
    // Fnc to find user group & open it's edit modal
    let userGroupId = location.state?.userGroupAction?.groupId;
    if (userGroupId) {
      // Find the groupId to open popup
      for (let i = 0; i < rowData.length; i++) {
        if (rowData[i].groupId === userGroupId) {
          setEditData(rowData[i]);
          showGroupModal(true);
          break;
        }
      }

      // clear location state
      location.state = null;
    }
  };

  const onGridReadyHandler = (params) => {
    params.api.showLoadingOverlay();
    gridRef.current = params;

    gridRef.current.api.setGridOption(
      'serverSideDatasource',
      getServerSideDatasource(),
    );
  };

  async function hashText(text) {
    const encoder = new TextEncoder();
    const data = await encoder.encode(text);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = await Array.from(new Uint8Array(hashBuffer));
    let hashHex = await hashArray
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('');
    return hashHex;
  }

  const getServerSideDatasource = () => {
    return {
      getRows: (params) => {
        try {
          let AgParams = params?.request;
          let contractFilterModel =
            AgParams?.filterModel['contractNumber']?.filter;
          if (
            contractFilterModel?.length > 0 &&
            contractFilterModel !== 'NoContractSelected'
          ) {
            let FilterColumns =
              'groupName,description' +
              (contractDisplayOption?.preferenceValue ===
                ContractDisplayOptions[2].contractDisplayOption ||
              contractDisplayOption?.preferenceValue ===
                ContractDisplayOptions[0].contractDisplayOption
                ? ',contractNumber'
                : '') +
              (contractDisplayOption?.preferenceValue ===
                ContractDisplayOptions[2].contractDisplayOption ||
              contractDisplayOption?.preferenceValue ===
                ContractDisplayOptions[1].contractDisplayOption
                ? ',contractDescription'
                : '');
            let searchText = document.getElementById(
              'user-group-search-filter-input',
            ).value;
            let queryParams = {
              Filter: getFilterColumns(AgParams),
              FilteredColumns: JSON.stringify(
                gridRef.current.api.getFilterModel(),
              ),
              Field:
                'groupId,groupName,contractNumber,contractDescription,description,modifiedDate',
              Sort: getSortColumns(AgParams, 'modifiedDate:desc'),
              PageSize: AgParams.endRow - AgParams.startRow,
              Page:
                AgParams.startRow / (AgParams.endRow - AgParams.startRow) + 1,
              querySearch: searchText,
              searchColumns: searchText ? FilterColumns : '',
            };
            gridRef.current.api.showLoadingOverlay();
            httpService
              .get(Endpoints.userGroupApi, queryParams)
              .then(async ({ data }) => {
                let rowData = data?.results ?? data?.aggResultValue;
                rowData = await Promise.all(
                  rowData?.map(async (el) => {
                    let contractHash = await hashText(el.contractNumber);
                    let groupHash = await hashText(el.groupId);
                    const encodedContract = btoa(
                      el.contractNumber + '||' + contractHash,
                    );
                    const encodedGroup = btoa(el.groupId + '||' + groupHash);

                    let uniqueLink = (
                      window.location.origin +
                      UrlPath.endUserRegistration +
                      '&' +
                      encodedGroup
                    ).replace(':id', encodedContract);

                    let modifiedDate = el.modifiedDate
                      ?.replace('T', ' ')
                      .split('.')[0];
                    return { ...el, modifiedDate, uniqueLink };
                  }),
                );

                params.success({
                  rowData: rowData,
                  rowCount: data?.metadata.count,
                });

                if (rowData?.length === 0)
                  gridRef.current.api.showNoRowsOverlay();
                else {
                  gridRef.current.api.hideOverlay();
                  openEditGroupPopUp(rowData);
                }
              })
              .catch(() => {
                params.fail();
                gridRef.current.api.hideOverlay();
              });
          } else {
            params.success({
              rowData: [],
              rowCount: 0,
            });

            if (contractFilterModel === 'NoContractSelected') {
              gridRef.current.api.showNoRowsOverlay();
            }
          }
        } catch (err) {
          params.fail();
        }
      },
    };
  };

  const contractChangeHandler = () => {
    if (gridRef.current && contractsResult && contractsResult.length > 0) {
      let filteredContracts = contractsResult.filter((x) => x.checked);

      let filterValue = {
        filter:
          filteredContracts.length !== 0
            ? filteredContracts
                .map((t) =>
                  t.contractNumber ? '"' + t.contractNumber + '"' : '',
                )
                .join(',')
            : 'NoContractSelected',
        filterType: 'text',
        type: 'inRange',
      };
      gridRef.current.api.setFilterModel(
        getAllFilterModel('contractNumber', filterValue),
      );
      gridRef.current.api.onFilterChanged();
    } else if (contractsResult?.length) {
      gridRef.current.api.showNoRowsOverlay();
    }
  };

  const getAllFilterModel = (colId, newModel) => {
    let currentModel = gridRef.current.api.getFilterModel();
    currentModel[colId] = newModel;
    return { ...currentModel };
  };

  const onSelectionChanged = (elements) => {
    let rowLength = elements.api.getSelectedRows().length;
    setButtonIcons({
      ...buttonIcons,

      DeleteIcon: rowLength > 0,
      ExportIcon: rowLength > 0,
      UserGroupIcon: rowLength > 1,
    });
  };

  const onSelectionChangedDebounce = debounce(onSelectionChanged, 100);

  // set header breadcrumb to CSA selected value
  useEffect(() => {
    let csaActionData =
      persistDataStore.getState()?.userActionData?.csaUserActionInfo;

    let breadCrumbData =
      csaActionData?.type === 'account'
        ? csaActionData?.data?.account
        : csaActionData?.type === 'hccompany'
        ? csaActionData?.data?.homogenizedCompany
        : csaActionData?.value;
    setBreadCrumbValue(breadCrumbData);
  }, []);

  const getDate = () => {
    let currentDate = new Date();
    return (
      currentDate.getDate() +
      '-' +
      (currentDate.getMonth() + 1) +
      '-' +
      currentDate.getFullYear()
    );
  };
  // eslint-disable-next-line
  const exportUserCSV = () => {
    if (buttonIcons.ExportIcon) {
      gridRef.current.api.exportDataAsCsv({
        onlySelected: true,
        fileName: 'User_Group_' + getDate(),
      });
    }
  };

  const selectedCount = contractsResult?.reduce(
    (counter, obj) => (obj.checked === true ? (counter += 1) : counter),
    0,
  );

  const openDeletedSuccess = (title) => {
    Notify({
      alert: true,
      type: 'success',
      title: title,
    });
  };

  const deleteUserGroups = () => {
    let deleteUserGroupIds = [];
    let selectedUserGroups = gridRef.current.api.getSelectedRows();
    if (selectedUserGroups && selectedUserGroups.length > 0) {
      selectedUserGroups.map((user) => deleteUserGroupIds.push(user.groupId));
      let deleteSuccessTitle =
        deleteUserGroupIds.length > 1
          ? 'User Groups Deleted'
          : 'User Group Deleted';

      setActionLoading(true);
      httpService
        .delete(Endpoints.userGroupApi, deleteUserGroupIds)
        .then(({ data }) => {
          if (data) {
            openDeletedSuccess(deleteSuccessTitle);
            gridRef.current.api
              .getSelectedNodes()
              .forEach((node) => node.setSelected(false));
            gridRef.current.api.onFilterChanged();
          }
        })
        .catch((err) => {
          setActionLoading(false);
        })
        .finally(() => setActionLoading(false));
    }
  };

  const openStatusModal = (title) => {
    Notify({
      alert: true,
      type: 'warning',
      title: title,
      confirmBtnText: 'YES DELETE',
      cancelBtnText: 'NO, KEEP IT',
      onConfirm: () => deleteUserGroups(),
    });
  };
  const getDeletedTitle = (selectedUserGroups) => {
    if (selectedUserGroups.length === 0) {
      return '';
    } else if (selectedUserGroups.length === 1) {
      return 'Are you sure you want to permanently delete the user group?';
    } else {
      return 'Are you sure you want to permanently delete the user groups?';
    }
  };
  const userGroupDeleteClickHandler = () => {
    let selectedUserGroups = gridRef.current.api.getSelectedRows();
    if (selectedUserGroups && selectedUserGroups.length > 0) {
      openStatusModal(getDeletedTitle(selectedUserGroups));
    }
  };

  const getRowId = (params) => {
    if (params?.data && params.data.groupId && params.data.contractNumber) {
      return 'user-' + params.data.groupId + '-' + params.data.contractNumber;
    }
  };

  return (
    <>
      {showEmailModal && (
        <div>
          <AddUserRegistration
            handleClose={closeEmailModal}
            clickedRowData={clickedRowData}
          ></AddUserRegistration>
        </div>
      )}
      {loading ? (
        <Loader type="scaleLoader" />
      ) : (
        <>
          {/*  Modal START */}
          {showGroup && (
            <AddUserGroup
              show={showGroup}
              contracts={contractsResult}
              editData={editData}
              setEditData={setEditData}
              showGroupModal={(bln) => showGroupModal(bln)}
              getUserGroup={getUserGroup}
              isViewerUser={isViewerUser}
            />
          )}
          {/*  Modal END */}

          <div className="row mb-1">
            <div className="col-lg-8 col-md-7">
              <span
                id="user-location-header"
                className="page-header breadcrumb-nav-wrapper"
              >
                <span>My Groups</span>
                <span className="breadcrumb-nav" title={breadCrumbValue}>
                  {breadCrumbValue ? ` / ${breadCrumbValue}` : ''}
                </span>
              </span>
            </div>
            <div className="col-lg-4 col-md-5">
              <MultiSelectDropdown
                enableSelectAll={true}
                defaultSelectAll={true}
                labelKeyName={getContractLabelKey()}
                ddOptionsKey="id"
                dropDownLoader={dropDownLoader}
                ddOptions={contractsResult}
                setddOptions={setContractsResult}
                ddName={'contracts'}
                ddPlaceHolder={
                  selectedCount === contractsResult?.length
                    ? 'Contracts: All Contracts'
                    : `Contracts: (${selectedCount})`
                }
              />
            </div>
          </div>
          <div className="position-relative">
            {actionLoading && (
              <Loader type="scaleLoader" cssClass={loaderStyle} />
            )}

            <div className="row mt-2">
              <div className="col">
                <input
                  type="text"
                  id="user-group-search-filter-input"
                  className="form-control search-box"
                  defaultValue={''}
                  placeholder={
                    'Search by group name' +
                    (contractDisplayOption?.preferenceValue ===
                    ContractDisplayOptions[2].contractDisplayOption
                      ? ', contract number or description'
                      : contractDisplayOption?.preferenceValue ===
                        ContractDisplayOptions[1].contractDisplayOption
                      ? ' or contract description'
                      : ' or contract number')
                  }
                  onInput={onFilterTextBoxChangedDebounce}
                ></input>
              </div>
              <div className="col my-1 action-item-bar">
                {!isViewerUser && (
                  <>
                    <span className="user-grid-icons icon-divider">
                      <UserGroupIcon
                        className="icon-active"
                        alt="User Group"
                        width="18"
                        title="Create group"
                        onClick={showGroupModal}
                      ></UserGroupIcon>
                    </span>
                    <span className="user-grid-icons icon-divider">
                      <DeleteIcon
                        className={
                          buttonIcons.DeleteIcon
                            ? 'icon-active'
                            : 'cursor-default'
                        }
                        alt="Delete Groups"
                        width="18"
                        title="Please select at least one group to delete from the user group list"
                        onClick={userGroupDeleteClickHandler}
                      ></DeleteIcon>
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="ag-grid-group-table mbx-12">
              <AgGridComponent
                config={defaultConfig}
                defaultColumnDef={defaultColdef}
                getRowId={getRowId}
                columns={columns}
                onGridReady={onGridReadyHandler}
                onSelectionChanged={onSelectionChangedDebounce}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UserGroup;
