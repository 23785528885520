import React, { useState, useCallback, useRef, useEffect } from 'react';
import httpService from '../../services/http-service';
import AgGridComponent from '../../sharedcomponents/ag-grid/AgGrid';
import { debounce } from '../../helpers/debounce';
import {
  Endpoints,
  Verbiage,
  UrlPath,
  UserId,
  GenericError,
} from './../../constants';
import { Notify } from './../../sharedcomponents/Alert/Notify';
import Loader from '../../components/Loader';
import EmailDomainsAddEdit from './email-domains-add-edit';
import './manage-email-domains.scss';
import { ReactComponent as ExportIcon } from './../../assets/icons/common/export.svg';
import copy_icon from '../../assets/icons/entitlements/mailing.svg';
import CopyLinkCell from '../../sharedcomponents/copy-link-cell/CopyLinkCell';
import AddUserRegistration from '../../pages/manage-email-domains/add-user-registration';
import { hashText } from '../../helpers/hash';
import { useSelector } from 'react-redux';
import store from './../../redux/store';

const ManageEmailDomains = () => {
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [isViewerUser, setIsViewerUser] = useState(false);
  const gridRef = useRef();
  const [emailRowData, setEmailRowData] = useState([]);
  const [clickedRowData, setClickedRowData] = useState([]);
  const [editedEmailDomains, setEditedEmailDomains] = useState([]);

  const defaultConfig = {
    rowModelType: 'clientSide',
    sortable: true,
    resizable: true,
    isExportCSV: false,
    isExportExcel: false,
    sideBar: false,
    rowGroupPanelShow: false,
    pagination: false,
    overlayNoRowsTemplate: Verbiage.noData,
  };
  const defaultColdef = {
    resizable: true,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };

  const columnFilterParams = {
    filter: 'agSetColumnFilter',
    sortable: true,
    enableRowGroup: false,
    enablePivot: false,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };

  const manageEmailDomainsColumns = [
    {
      headerName: 'Contract ID',
      field: 'contractNumber',
      tooltipField: 'contractNumber',
      headerTooltip: 'Contract ID',
      cellRenderer: (params) => {
        if (params.value != null) {
          return (
            <>
              {isViewerUser ? (
                <div>{params.value}</div>
              ) : (
                <div
                  className="email-label"
                  title={params.value}
                  onClick={() => {
                    setEditedEmailDomains(params.data);
                    setShowModal(true);
                  }}
                >
                  {params.value}
                </div>
              )}
            </>
          );
        } else return '';
      },
      lockPosition: true,
      initialFlex: 2,
    },
    {
      headerName: 'Accounts',
      field: 'accountName',
      tooltipField: 'accountName',
      headerTooltip: 'Accounts',
      initialFlex: 4,
    },
    {
      headerName: 'Allowed Email Domains',
      field: 'emailDomain',
      tooltipField: 'emailDomain',
      headerTooltip: 'Allowed Email Domains',
      initialFlex: 3,
    },
    {
      headerName: 'Invite Users',
      headerTooltip: 'Invite Users',
      field: 'onboardingLink',
      tooltipField: '',
      initialFlex: 2,
      filter: false,
      sortable: false,
      hide: isViewerUser,
      cellRenderer: (params) => {
        return (
          <>
            <div className="link-block">
              <CopyLinkCell value={params.value} />
              <span
                onClick={() => {
                  setShowEmailModal(true);
                  setClickedRowData(params.data);
                }}
              >
                <img
                  className="cursor-pointer"
                  src={copy_icon}
                  width={18}
                  height={18}
                  title="Click here to send registration invite to end users"
                />
              </span>
            </div>
          </>
        );
      },
    },
    {
      headerName: 'Approval Required',
      headerTooltip: 'Approval Required',
      field: 'isRestricted',
      initialFlex: 2,
      hide: isViewerUser,
      ...columnFilterParams,
      menuTabs: ['generalMenuTab'],
      cellRenderer: (params) => {
        if (params?.data) {
          let switchElement = document.getElementById(
            params.data.contractNumber,
          );
          if (switchElement)
            switchElement.checked =
              params.data && params.data.isRestricted === 1;
          return (
            <>
              <div className="form-check form-switch form-switch-custom">
                <input
                  type="checkbox"
                  role="switch"
                  name="isRestricted"
                  id={params.data.contractNumber}
                  className="form-check-input form-check-input-custom"
                  onChange={saveApprovalStatusDebounce}
                  defaultChecked={params.data && params.data.isRestricted === 1}
                  prevchecked={(
                    params.data && params.data.isRestricted === 1
                  ).toString()}
                />
              </div>
            </>
          );
        } else {
          return '';
        }
      },
    },
  ];

  const saveApprovalStatus = (e) => {
    const { checked, id: contractNumber, prevChecked } = e.target;

    // To avoid extra API calls for same status
    if (prevChecked !== checked) {
      // set new value for previous checked state
      let switchElement = document.getElementById(contractNumber);

      switchElement.disabled = true;

      const postData = {
        contractNumber: contractNumber,
        userId: UserId,
        isRestricted: checked ? 1 : 0,
      };

      httpService
        .post(Endpoints.contractApi, postData)
        .then((resp) => {
          if (resp?.data !== 0) throw 'error';
          else switchElement.prevChecked = checked;
        })
        .catch((err) => {
          switchElement.checked = prevChecked;
          Notify({
            alert: true,
            type: 'error',
            title: GenericError.somethingWentWrong,
          });
        })
        .finally(() => (switchElement.disabled = false));
    }
  };

  const saveApprovalStatusDebounce = debounce(saveApprovalStatus, 300);

  const transformAccountResponse = async (responseData) => {
    let transformData = [];
    if (responseData?.length > 0) {
      for (const obj of responseData) {
        const key = obj.contractNumber;
        const existingGroup = transformData.find(
          (group) => group.contractNumber === key,
        );
        if (existingGroup) {
          if (obj.accountId) existingGroup.accountId += ',' + obj.accountId;
          if (obj.accountName)
            existingGroup.accountName += ', ' + obj.accountName;
        } else {
          let contractHash = await hashText(obj.contractNumber);
          const encodedContract = btoa(
            obj.contractNumber + '||' + contractHash,
          );

          // Sort email domain
          obj.emailDomain = obj.emailDomain
            ?.split(',')
            .sort((a, b) =>
              a.localeCompare(b, undefined, { sensitivity: 'base' }),
            )
            .join(',');
          transformData.push({
            ...obj,
            onboardingLink:
              window.location.origin +
              UrlPath.endUserRegistration.replace(':id', encodedContract),
          });
        }
      }
    }
    return transformData;
  };

  const getEmailDomains = async () => {
    try {
      setEmailRowData([]);
      let queryParams = {
        PageSize: 50000,
        id: Date.now(),
        Field: 'accountName,accountId,emailDomain,contractNumber,isRestricted',
      };
      setTimeout(() => {
        gridRef?.current?.api?.showLoadingOverlay();
      }, 50);
      const response = await httpService.get(Endpoints.accountApi, queryParams);
      if (response?.data?.results?.length === 0)
        gridRef?.current?.api?.showNoRowsOverlay();

      let transformedData = await transformAccountResponse(
        response?.data?.results,
      );
      setEmailRowData(transformedData);
    } catch (err) {
      gridRef?.current?.api?.showNoRowsOverlay();
      setEmailRowData([]);
    } finally {
      gridRef?.current?.api?.hideOverlay();
    }
  };
  useEffect(async () => {
    let storeData = store.getState();
    let storeUserInfo = storeData.userInfo;
    if (storeUserInfo && storeUserInfo.userInfo) {
      setIsViewerUser(
        storeUserInfo.userInfo.SPGGroups?.includes('GRP_SFS_VIEWER'),
      );
    }
  }, [useSelector((x) => x.userInfo)]);

  useEffect(() => {
    setLoading(false);
  }, []);

  const onFilterTextBoxChanged = useCallback(({ target }) => {
    let searchText = target.value;
    if (!searchText || searchText.length > 2) {
      gridRef?.current?.api?.setQuickFilter(
        document.getElementById('search-filter-input').value,
      );
      if (gridRef?.current?.api?.getDisplayedRowCount() < 1)
        gridRef?.current?.api?.showNoRowsOverlay();
      else gridRef?.current?.api?.hideOverlay();
    }
  }, []);

  const onFilterTextBoxChangedDebounce = debounce(onFilterTextBoxChanged, 1000);

  const onGridReadyHandler = (params) => {
    gridRef.current = params;
    getEmailDomains();
  };

  const closeEmailDomainsModal = () => {
    setShowModal(false);
    setShowEmailModal(false);
  };

  const getDate = () => {
    let currentDate = new Date();
    return (
      currentDate.getDate() +
      '-' +
      (currentDate.getMonth() + 1) +
      '-' +
      currentDate.getFullYear()
    );
  };
  const exportEmailDomainsCSV = () => {
    gridRef.current.api.exportDataAsCsv({
      fileName: 'EmailDomains_' + getDate(),
    });
  };

  return (
    <>
      {showEmailModal && (
        <div>
          <AddUserRegistration
            handleClose={closeEmailDomainsModal}
            clickedRowData={clickedRowData}
          ></AddUserRegistration>
        </div>
      )}
      {loading ? (
        <Loader type="scaleLoader" />
      ) : (
        <div>
          <div className="row mb-1">
            <div className="col-12">
              <span className="page-header">Manage Email Domains</span>
            </div>
          </div>
          {showModal && !showEmailModal && (
            <EmailDomainsAddEdit
              show={showModal}
              handleClose={closeEmailDomainsModal}
              getEmailDomains={getEmailDomains}
              editedEmailDomains={editedEmailDomains}
            ></EmailDomainsAddEdit>
          )}
          <div className="row mt-2">
            <div className="col">
              <input
                type="text"
                id="search-filter-input"
                className="form-control search-box"
                placeholder="Search by contract id, accounts or email domains"
                onInput={onFilterTextBoxChangedDebounce}
              />
            </div>
            <div className="col action-item-bar">
              <ExportIcon
                className="icon-active cursor-pointer"
                alt="Email Domain Export"
                width="21"
                title="Export to CSV"
                onClick={exportEmailDomainsCSV}
              ></ExportIcon>
            </div>
          </div>
          <div className="ag-grid-table email-domain">
            <AgGridComponent
              config={defaultConfig}
              defaultColumnDef={defaultColdef}
              data={emailRowData}
              columns={manageEmailDomainsColumns}
              onGridReady={onGridReadyHandler}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ManageEmailDomains;
