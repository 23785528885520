import React, { useEffect, useMemo, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import {
  Active,
  Cancelled,
  DistributorUserIDRequiredList,
  Endpoints,
  GenericError,
  PendingActive,
  PendingApproval,
  PendingInactive,
  Blocked,
  ProductDeliveryPlatforms,
  Rejected,
  UrlPath,
  Verbiage,
  maxPageSize,
  negativeStatuses,
  AutoSelectPkgDist,
} from '../../../constants';
import httpService from '../../../services/http-service';
import DistributorsDropdown from '../../Entitlements/DistributorsDropdown';
import PackagesDropdown from '../../Entitlements/PackagesDropdown';
import Loader from './../../../components/Loader';
import { addContract } from './../../../redux/actions';
import store from './../../../redux/store';
import { Notify } from './../../../sharedcomponents/Alert/Notify';
import DistributorUserIdModal from './../../Entitlements/DistributorUserIdModal';
import MySubscriptionDetails from './MySubscriptionDetails';
import MySubscriptionEmailPreferenceDetails from './MySubscriptionEmailPreferenceDetails';

const MySubscription = () => {
  const entitlementsTabName = 'entitlements';
  const emailPreferencesTabName = 'email-preferences';
  const [loadingTab, setLoadingTab] = useState(false);
  const [loadingProductTab, setLoadingProductTab] = useState(false);
  const [emailPreferencesEnabled, setEmailPreferencesEnabled] = useState(false);
  const [activeTab, setActiveTab] = useState(entitlementsTabName);
  const [userDetails, setUserDetails] = useState();
  const [userEntitlements, setUserEntitlements] = useState();
  const [userEntitlementsData, setUserEntitlementsData] = useState([]);
  const [initialEntitlements, setInitialEntitlements] = useState();
  const [packageDataSet, setPackageDataSet] = useState();
  const [distributorDataSet, setDistributorDataSet] = useState();
  const [contractDataSet, setContractDataSet] = useState([]);
  const [filteredPackages, setFilteredPackages] = useState();
  const [filteredDistributors, setFilteredDistributors] = useState();
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedDistributors, setSelectedDistributors] = useState([]);
  const [selectedDistributorsId, setSelectedDistributorsId] = useState([]);
  const [packageDistributorData, setPackageDistributorData] = useState([]);
  const [updatedPackageDistributors, setUpdatedPackageDistributors] = useState(
    [],
  );
  const [selectedParentCascade, setSelectedParentCascade] = useState();
  const [contracts, setContracts] = useState();
  const [contractAdminEmail, setContractAdminEmail] = useState();
  const [isRestrictedMap, setIsRestrictedMap] = useState(new Map());
  const [showDistributorUserIdModal, setShowDistributorUserIdModal] = useState(
    [],
  );
  const [finalRowNodeData, setFinalRowNodeData] = useState([]);
  const [finalEntitlementSaveData, setFinalEntitlementSaveData] = useState([]);
  const [keyValue, setKeyValue] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const reduceCallback = setTimeout(async () => {
      setPackageDistributorData([]);
      setFilteredPackages();
      setSelectedPackages([]);
      setSelectedDistributors([]);
      setSelectedDistributorsId([]);
      getAllUserEntitlements();
      let contractrestrictions = await getIsRestricted();
      setIsRestrictedMap(contractrestrictions);
    }, 1000);
    return () => clearTimeout(reduceCallback);
  }, [contracts]);

  const getAllUserEntitlements = () => {
    try {
      if (contracts?.length > 0 && contracts.some((el) => el.checked)) {
        // Fetch all packages and distributor data
        let queryParams = {
          pageSize: maxPageSize,
          id: new Date().getTime(),
          filter: `contractNumber in (${contracts
            .filter((x) => x.checked)
            .map((t) => (t.id ? '"' + t.id + '"' : ''))
            .join(',')})`,
          Field:
            'packageId,packageName,distributor,distributorId,distributorPlatform,distributorDisplayName,distributorPlatformDisplayName,deliveryType,productComponentId,contractNumber,platformCategories',
        };
        httpService
          .get(Endpoints.contractPackagesApi, queryParams)
          .then((res) => {
            if (res) {
              let metadata = res?.data?.metadata;
              if (contracts && contracts.some((x) => x.checked)) {
                let contractNumbers = [];
                contracts.filter((x) => {
                  if (x.checked) {
                    contractNumbers.push(x.id);
                  }
                });
                let filteredContractPackages = res?.data?.results;
                setUserEntitlementsData(filteredContractPackages);
                let packageData = filteredContractPackages.map(
                  ({
                    distributorId,
                    packageId,
                    packageName,
                    productComponentId,
                    contractNumber,
                  }) => ({
                    distributorId,
                    packageId,
                    packageName,
                    productComponentId,
                    contractNumber,
                  }),
                );
                packageData = [
                  ...new Map(
                    packageData.map((item) => [item['packageId'], item]),
                  ).values(),
                ];
                metadata.count = packageData.length;
                let finalPackageData = [];
                finalPackageData.metadata = metadata;
                finalPackageData.results = packageData;
                setPackageDataSet(finalPackageData);

                // Set distributor data
                let distributorData = [];
                distributorData.metadata = filteredContractPackages.length;
                distributorData.results = filteredContractPackages;
                setDistributorDataSet(distributorData);
              } else {
                setUserEntitlementsData([]);
                setPackageDataSet([]);
              }
            }
          })
          .catch((err) => {});
      } else {
        setUserEntitlementsData([]);
        setPackageDataSet([]);
      }
    } catch (err) {}
  };

  const getIsRestricted = async () => {
    try {
      if (contracts?.length > 0 && contracts.some((el) => el.checked)) {
        // Fetch restricted status of the end user's contract
        let queryParams = {
          pageSize: maxPageSize,
          id: new Date().getTime(),
          filter: `contractNumber in (${contracts
            .filter((x) => x.checked)
            .map((t) => (t.id ? '"' + t.id + '"' : ''))
            .join(',')})`,
        };

        let contractRestrictions = await httpService.get(
          Endpoints.contractApi,
          queryParams,
        );
        let contractResult = contractRestrictions?.data?.results;
        setContractDataSet(contractResult);
        if (contractRestrictions && contractResult?.length > 0) {
          // set contract admin for email
          let contractAdminEmail = contractResult[0].email;
          setContractAdminEmail(contractAdminEmail);

          let newIsRestrictedMap = {};

          contractResult.forEach((result) => {
            const { contractNumber, isRestricted } = result;
            newIsRestrictedMap[contractNumber] = isRestricted;
          });
          dispatch(addContract(contractResult));

          return newIsRestrictedMap;
        }
      }
    } catch (err) {}
    return [];
  };

  useEffect(() => {
    // Set initial state
    if (packageDataSet) setFilteredPackages(packageDataSet);
    if (distributorDataSet) setFilteredDistributors(distributorDataSet);
  }, [packageDataSet, distributorDataSet]);

  useEffect(() => {
    // Controller to abort previous API calls if any ongoing flight
    const controller = new AbortController();
    const signal = controller.signal;
    const reduceCallback = setTimeout(() => {
      if (selectedPackages.length > 0 && isAnyAutoSelectPkg()) {
        getContractPackages(
          signal,
          false,
          AutoSelectPkgDist.Market_Basics.distId,
        );
        return;
      }

      if (selectedPackages.length > 0 && selectedDistributors.length > 0) {
        getContractPackages(signal, false);
      } else if (
        selectedPackages.length === 0 ||
        selectedDistributors.length === 0
      ) {
        setPackageDistributorData([]);
        setUpdatedPackageDistributors([]);
      }
    }, 1000);
    return () => {
      clearTimeout(reduceCallback);
      controller?.abort();
    };
  }, [selectedPackages, selectedDistributors]);

  useEffect(() => {
    if (selectedParentCascade === 'PackageDropdown' && userEntitlementsData) {
      // Filter distributor based on package selection
      let pkgId = [];
      selectedPackages.forEach((el) => {
        pkgId.push(el.packageName);
      });
      let filterData = userEntitlementsData.filter((el) =>
        pkgId.includes(el.packageName),
      );
      setFilteredDistributors({ results: filterData });
    }
  }, [selectedPackages]);

  useEffect(() => {
    if (
      selectedParentCascade === 'DistributorDropdown' &&
      userEntitlementsData
    ) {
      // Filter packages based on distributor selection
      let filterData = userEntitlementsData.filter((el) => {
        return selectedDistributorsId.includes(el.distributorId);
      });
      setFilteredPackages({ results: filterData });
    }
  }, [selectedDistributorsId]);

  const isAnyAutoSelectPkg = () => {
    try {
      let isAutoSelect = false;
      const marketBasicsSelected = selectedPackages.some((pkg) =>
        pkg.packageName.includes(AutoSelectPkgDist.Market_Basics.pkgName),
      );

      // Check if Market Basics is selected
      if (marketBasicsSelected) {
        const plattsConnectSelected = selectedDistributors.find(
          (dst) => dst.distributorId == AutoSelectPkgDist.Market_Basics.distId,
        );
        // Platts Connect is not selected
        if (!plattsConnectSelected) {
          isAutoSelect = true;
        }
      }
      return isAutoSelect;
    } catch (error) {}
  };

  const cascadingPackageDistributor = () => {
    // On empty selection, set to default data
    if (
      selectedPackages.length === 0 &&
      selectedParentCascade === 'PackageDropdown'
    ) {
      setFilteredDistributors(distributorDataSet);
    } else if (
      selectedDistributorsId.length === 0 &&
      selectedParentCascade === 'DistributorDropdown'
    ) {
      setFilteredPackages(packageDataSet);
    }

    // Cascading functionality to set which dropdown is parent
    if (selectedPackages.length === 0 && selectedDistributors.length === 0) {
      setSelectedParentCascade();
    } else if (!selectedParentCascade) {
      if (selectedPackages.length > 0) {
        setSelectedParentCascade('PackageDropdown');
      }
      if (selectedDistributors.length > 0) {
        setSelectedParentCascade('DistributorDropdown');
      }
    }
  };

  // Memoized selection to stop loop from dropdown callback
  const cascadingPackageDistributorMemo = useMemo(cascadingPackageDistributor, [
    selectedPackages,
    selectedDistributors,
  ]);

  const getContractPackages = async (
    signal,
    isUserChanged = false,
    autoSelectedDistributor = '',
  ) => {
    try {
      let packageDistributorResponse;
      if (!isUserChanged) {
        const packageIdCollection = selectedPackages
          .map((el) => String(el.packageId))
          .join(',');

        let distributorIdCollection = selectedDistributors
          .map((el) => String(el.distributorId))
          .join(',');

        if (autoSelectedDistributor) {
          distributorIdCollection = distributorIdCollection
            ? `${distributorIdCollection},${autoSelectedDistributor}`
            : autoSelectedDistributor;
        }

        if (packageIdCollection && distributorIdCollection) {
          isTabLoading(true);
          let queryParamsUser = {
            PageSize: maxPageSize,
            Filter: `packageId in(${packageIdCollection}) and distributorId in(${distributorIdCollection}) and contractNumber in(${contracts
              .filter((x) => x.checked)
              .map((t) => (t.id ? '"' + t.id + '"' : ''))
              .join(',')})`,
          };

          packageDistributorResponse = await httpService.get(
            Endpoints.contractPackagesApi,
            queryParamsUser,
            { signal },
          );
          isTabLoading(false);
          if (
            packageDistributorResponse.data?.results.length > 0 &&
            selectedPackages.some((pkg) =>
              pkg.packageName.includes(AutoSelectPkgDist.Market_Basics.pkgName),
            )
          ) {
            packageDistributorResponse.data.results =
              packageDistributorResponse.data.results.filter(
                (el) =>
                  el.packageName.includes(
                    AutoSelectPkgDist.Market_Basics.pkgName,
                  ) ||
                  selectedDistributorsId.some((id) => id == el.distributorId),
              );
          }
          setUpdatedPackageDistributors(packageDistributorResponse);
        }
      } else {
        packageDistributorResponse = updatedPackageDistributors;
      }
      if (
        selectedPackages.length !== 0 &&
        (selectedDistributors.length !== 0 || autoSelectedDistributor)
      ) {
        let preparePackageDistributorData = [];
        packageDistributorResponse?.data?.results?.forEach(
          (packagePlatform) => {
            if (
              packagePlatform.contractNumber ===
              userDetails.results[0].contractNumber
            ) {
              packagePlatform.distributorData =
                packagePlatform.distributor +
                ' : ' +
                packagePlatform.distributorPlatform +
                ' (' +
                packagePlatform.deliveryType +
                ')';
              packagePlatform.contactData = userDetails.results[0].contactName;
              packagePlatform.contactId = userDetails.results[0].contactId;
              packagePlatform.contractNumber = packagePlatform.contractNumber;
              preparePackageDistributorData.push({ ...packagePlatform });
            }
          },
        );

        setPackageDistributorData(preparePackageDistributorData);
      } else {
        setPackageDistributorData([]);
      }
    } catch (err) {
      isTabLoading(false);
    }
  };

  const callBackPackages = (props) => setSelectedPackages(props);
  const callBackDistributors = (params) => {
    let dstsId = [];
    params.forEach((dst) => {
      dstsId.push(dst.distributorId);
    });
    setSelectedDistributors(params);
    setSelectedDistributorsId(dstsId);
  };
  const onTabSelect = (key) => {
    if (!emailPreferencesEnabled) setEmailPreferencesEnabled(true);
    setActiveTab(key);
  };
  useEffect(() => {
    let storeData = store.getState();
    let storeUserInfo = storeData?.userInfo;

    if (storeUserInfo && storeUserInfo.userInfo) {
      getEntitlements(storeUserInfo.userInfo.email);
      getUserDetails(storeUserInfo.userInfo.email);
    }
  }, [useSelector((x) => x.userInfo)]);

  const getUserDetails = (userEmail) => {
    let queryParams = {
      Filter: `email:"${userEmail}"`,
      Field:
        'contactName,contactId,groupid,groupName,contractNumber,email,phone,department,jobFunction,designation,isEmailOptOut,account,accountId,officeLocation,officeLocationId,entitled,modifiedDate',
      PageSize: 50,
    };

    httpService
      .get(Endpoints.userApi, queryParams)
      .then(({ data }) => {
        if (data?.results?.length > 0) {
          setUserDetails(data);
          let contract = data.results.map((res) => {
            return {
              checked: true,
              contractNumber: res.contractNumber,
              id: res.contractNumber,
              label: res.contractNumber,
            };
          });
          setContracts(contract);
        }
      })
      .catch(() => setUserDetails({ results: [] }));
  };

  const getEntitlements = (userEmail) => {
    try {
      let queryParams = {
        pageSize: maxPageSize,
        Filter: `emailId:"${userEmail}"`,
        Sort: 'modifiedDate:desc',
      };
      httpService
        .get(Endpoints.userentitlementsApi, queryParams)
        .then((res) => {
          const response = res?.data?.results?.map((c, index) => {
            c.id = index;
            return c;
          });
          setUserEntitlements(response);
          let initialEntitlements = response.filter(
            (ue) => !ProductDeliveryPlatforms.includes(ue.distributor),
          );
          setInitialEntitlements(initialEntitlements);
        })
        .catch((err) => {
          setUserEntitlements([]);
        })
        .finally(() => isTabLoading(false));
    } catch (error) {
      isTabLoading(false);
    }
  };

  const sendEmailToCCA = () => {
    let CCAs = [];
    contractDataSet.forEach((contract) => {
      CCAs.push(contract.email);
    });

    CCAs = CCAs.join(',');

    try {
      let emailData = [];
      let mail_body = '';
      let mail_to_address = CCAs;
      let mail_cc_address = userDetails.results[0].email;
      let mail_from_address = 'donotreply@spglobal.com';
      let contractId = contracts[0].contractNumber;

      const currentYear = new Date().getFullYear();

      mail_body = `
        <div style='background-color: #F2F2F2; padding: 20px; font-family: Arial, sans-serif; color: #333333; margin: 0;'>
          <table width='100%' cellpadding='0' cellspacing='0' style='margin: 0; padding: 0; border-collapse: collapse; font-family: Arial, sans-serif; color: #333333;'>
            <tr>
            <td align='center'>
              <table class='container' width='600' cellpadding='0' cellspacing='0' style='background-color: #FFFFFF; padding: 20px; border-radius: 5px; border-collapse: collapse; font-family: Arial, sans-serif; color: #333333;'>
              <tr>
                <td align='left' style='padding-bottom: 20px;'>
                <img src='https://www.spglobal.com/commodityinsights/images/spglobal-commodity-insights-logo.png' alt='S&P Global' width='230' height='80' style='margin-top: 16px; float: left; display: block; border: 0;'>
                </td>
              </tr>
              <tr>
                <td style='padding: 0 20px;'>
                  <div style="font-size: 14; font-family: Arial">Attention: Contract Admin</p><br>
                    <p>${userDetails.results[0].contactName} has requested approval to add new entitlements to their access via CI Subscription Manager.</p>
                    <p>Please review the request at your earliest convenience to ensure uninterrupted access for the user.</p>
                    <p>To approve or reject the entitlement request, please click the link below:</br><a href='${window.location.origin}${UrlPath.entitlements}' target="_blank" rel="noopener noreferrer" ><span>Approve/Reject Entitlement Request</span></a></p>
                    <p>For further details or assistance, please refer to the internal system or contact support.</br> Thank you for your prompt attention.</p><br>
                    <p>Kind regards,<br>
                    S&P Global Commodity Insights</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td style='padding-top: 10px; text-align: left;'>
                <hr width='93%' style='border: none; border-top: 1px solid #dddddd; margin: 0;'>
                <p style='font-size: 12px; padding-left: 4%; padding-bottom:10px; color: #999999; margin: 10px 0 0 0;'>
                  © ${currentYear} S&P Global. All rights reserved.<br><br>
                  <a href='${UrlPath.contact}' target="_blank" rel="noopener noreferrer" style='color: #0563C1; text-decoration: underline;'>Support</a> | 
                  <a href='${UrlPath.termsOfUse}' target="_blank" rel="noopener noreferrer" style='color: #0563C1; text-decoration: underline;'>Terms of Use</a> | 
                  <a href='${UrlPath.privacy}' target="_blank" rel="noopener noreferrer" style='color: #0563C1; text-decoration: underline;'>Privacy Policy</a> | 
                  <a href='${UrlPath.cookieNotice}' target="_blank" rel="noopener noreferrer" style='color: #0563C1; text-decoration: underline;'>Cookie Notice</a>
                </p>
                </td>
              </tr>
              </table>
            </td>
            </tr>
          </table>
        </div>`;

      let mail_subject =
        'New Entitlements Approval Request | Contract ID: ' +
        contractId +
        ' | User: ' +
        userDetails.results[0].contactName;
      emailData.push({
        MAIL_FROM_ADDRESS: mail_from_address,
        MAIL_TO_ADDRESS: mail_to_address,
        MAIL_CC_ADDRESS: mail_cc_address,
        MAIL_BODY: mail_body,
        MAIL_SUBJECT: mail_subject,
        CONTRACT_ID: contractId,
      });

      httpService
        .post(Endpoints.sendEmailApi, emailData[0])
        .then((response) => {
          if (response.status === 200) {
            setTimeout(() => {}, 200);
          }
        })
        .catch((err) => {
          Notify({
            alert: true,
            type: 'error',
            title: GenericError.somethingWentWrong,
          });
        });
    } catch (err) {}
  };

  const onSaveHandler = () => {
    try {
      if (!userDetails?.results[0]?.groupId) {
        let entitlementChanges = [];
        let contactData =
          userDetails?.results[0].contactName +
          ' (' +
          userDetails?.results[0].email +
          ')';
        finalRowNodeData.forEach((node) => {
          // remove entitlements that have a status and aren't selected
          if (
            node.data?.status &&
            (node.data?.status === Active ||
              node.data?.status === PendingActive ||
              node.data?.status === PendingApproval ||
              node.data?.status === Blocked) &&
            !node.selected
          ) {
            entitlementChanges.push({
              contactData: contactData,
              contactId: userDetails?.results[0]?.contactId,
              contractNumber: node.data.contractNumber,
              deliveryType: node.data.deliveryType,
              distributor: node.data.distributor,
              distributorDisplayName: node.data.distributorDisplayName,
              distributorData: node.data.distributorPlatform,
              distributorId: node.data.distributorId,
              distributorPlatform: node.data.distributorPlatform,
              distributorPlatformDisplayName:
                node.data.distributorPlatformDisplayName,
              fulfillmentCode: node.data.shortCode,
              packageId: node.data.packageId,
              packageName: node.data.packageName,
              platformCategories: node.data.platformCategories,
              productComponentId: node.data.productComponentId,
              status: PendingInactive,
            });
          }
          // add entitlements that don't have a status and are selected
          if (
            (!node.data?.status ||
              node.data?.status === Cancelled ||
              node.data?.status === PendingInactive ||
              node.data?.status === Rejected) &&
            node.selected
          ) {
            entitlementChanges.push({
              contactData: contactData,
              contactId: userDetails?.results[0]?.contactId,
              contractNumber: node.data.contractNumber,
              deliveryType: node.data.deliveryType,
              distributor: node.data.distributor,
              distributorDisplayName: node.data.distributorDisplayName,
              distributorData: node.data.distributorPlatform,
              distributorId: node.data.distributorId,
              distributorPlatform: node.data.distributorPlatform,
              distributorPlatformDisplayName:
                node.data.distributorPlatformDisplayName,
              fulfillmentCode: node.data.shortCode,
              packageId: node.data.packageId,
              packageName: node.data.packageName,
              platformCategories: node.data.platformCategories,
              productComponentId: node.data.productComponentId,
              status: PendingActive,
            });
          }
        });

        if (
          (!(
            selectedPackages.length === 0 || selectedDistributors.length === 0
          ) &&
            packageDistributorData.length > 0) ||
          entitlementChanges.length > 0
        ) {
          const matchesInitialEntitlement = (newEntitlement) => {
            return initialEntitlements.some(
              (initialEntitlement) =>
                newEntitlement.distributor === initialEntitlement.distributor &&
                newEntitlement.distributorPlatform ===
                  initialEntitlement.distributorPlatform &&
                newEntitlement.deliveryType ===
                  initialEntitlement.deliveryType &&
                newEntitlement.packageName === initialEntitlement.packageName &&
                ![PendingInactive, Cancelled, Rejected].includes(
                  initialEntitlement.status,
                ),
            );
          };

          let filteredEntitlements = packageDistributorData.filter(
            (entitlement) => !matchesInitialEntitlement(entitlement),
          );

          const filteredEntitlementChanges = entitlementChanges.filter(
            (ec) =>
              !filteredEntitlements.some(
                (fe) =>
                  fe.distributorId === ec.distributorId &&
                  fe.productComponentId === ec.productComponentId,
              ),
          );

          filteredEntitlements = filteredEntitlements.concat(
            filteredEntitlementChanges,
          );

          setFinalEntitlementSaveData(filteredEntitlements);

          let requiredEntitlements = filteredEntitlements.filter((en) =>
            DistributorUserIDRequiredList.includes(en.distributor),
          );
          if (requiredEntitlements && requiredEntitlements.length > 0) {
            validateUserId(filteredEntitlements, requiredEntitlements);
          } else {
            saveEntitlements(filteredEntitlements);
          }
        }
      }
    } catch (err) {}
  };

  const validateUserId = (filteredEntitlements, requiredEntitlements) => {
    try {
      let distributorIdCollection = [];
      let contactIdCollection = [];
      let uniqueRequiredEntitlements = [];
      requiredEntitlements.forEach((el, i) => {
        // Get only unique records
        if (
          !uniqueRequiredEntitlements.some(
            (x) =>
              x.contactId === el.contactId &&
              x.distributorId === el.distributorId,
          )
        ) {
          uniqueRequiredEntitlements.push(el);
        }

        distributorIdCollection.push(el.distributorId);
        contactIdCollection.push(el.contactId);
      });

      // create Unique ID's of distributor with userId required
      let uniqueDistributorIdCollection = [...new Set(distributorIdCollection)];
      let uniqueContactIdCollection = [...new Set(contactIdCollection)];

      // create query using above data
      distributorIdCollection = uniqueDistributorIdCollection
        .map((el) => el)
        .join(',');
      contactIdCollection = uniqueContactIdCollection
        .map((el) => `"${el}"`)
        .join(',');

      let queryParams = {
        Filter: `distributorId in(${distributorIdCollection}) and contactId in(${contactIdCollection})`,
        PageSize: maxPageSize,
        id: String(Date.now()),
      };

      httpService
        .get(Endpoints.distributorUserApi, queryParams)
        .then(async (res) => {
          let distributorUser = res?.data?.results;

          uniqueRequiredEntitlements.map((re) => {
            let userDistributorData = distributorUser.find(
              (ud) =>
                ud.distributorId === re.distributorId &&
                ud.contactId === re.contactId,
            );
            re.isValidated =
              userDistributorData?.userId &&
              userDistributorData?.userId?.trim();
            re.userId = userDistributorData?.userId;
          });
          if (
            uniqueRequiredEntitlements.every(
              (x) => x.isValidated || negativeStatuses.includes(x.status),
            )
          ) {
            saveEntitlements(filteredEntitlements);
          } else {
            let distributorUserIdModalData = uniqueRequiredEntitlements.filter(
              (en) => !en.isValidated,
            );
            // Set User ID mandatory data to show in modal
            setShowDistributorUserIdModal(distributorUserIdModalData);
          }
        })
        .catch((err) => {
          Notify({
            alert: true,
            type: 'error',
            title: GenericError.somethingWentWrong,
          });
        });
    } catch (err) {}
  };

  const saveEntitlements = async (
    postData,
    showAlert = true,
    resetProductDD,
  ) => {
    try {
      isTabLoading(true);
      let contractrestrictions = await getIsRestricted();
      setIsRestrictedMap(contractrestrictions);
      let sendEmail = false;
      let entitlementsForSave = postData.map((en) => {
        let newObj = {
          userEntitlementId: en.userEntitlementId ?? '-1',
          contactId: en.contactId,
          productComponentId: en.productComponentId,
          contractNumber: en.contractNumber,
          status:
            en.status === PendingInactive
              ? PendingInactive
              : contractrestrictions[en.contractNumber]
              ? PendingApproval
              : PendingActive,
        };

        if (!sendEmail && newObj.status === PendingApproval) {
          sendEmail = true;
        }

        return newObj;
      });

      let entitlementModel = {
        userEntitlements: entitlementsForSave,
        userId: userDetails.results[0].email,
      };
      httpService
        .post(Endpoints.userentitlementsApi, entitlementModel)
        .then((res) => {
          if (res.data === 0) {
            getEntitlements(store.getState().userInfo.userInfo.email);

            const hasInactive = entitlementsForSave.some(
              (en) => en.status == PendingInactive,
            );
            const hasRestricted = entitlementsForSave.some(
              (en) => en.status == PendingApproval,
            );
            const hasUnrestricted = entitlementsForSave.some(
              (en) => en.status == PendingActive,
            );

            let title;
            if (hasInactive) {
              title = Verbiage.commonEntitlementsSave;
            } else if (hasRestricted && hasUnrestricted) {
              title = Verbiage.mixedEntitlementsAdded;
            } else if (hasRestricted) {
              title = Verbiage.restrictedEntitlementsAdded;
            } else {
              title = Verbiage.unrestrictedEntitlementsAdded;
            }

            if (activeTab === entitlementsTabName) isTabLoading(false);
            Notify({
              alert: showAlert,
              type: 'success',
              title: title,
              onClose: () => afterSaveClick(resetProductDD),
            });

            if (sendEmail) sendEmailToCCA();
          }
        })
        .catch((err) => {
          Notify({
            alert: true,
            type: 'error',
            title: GenericError.somethingWentWrong,
          });
          isTabLoading(false);
        });
    } catch (error) {
      isTabLoading(false);
    }
  };

  const isTabLoading = (flag) => {
    // Common loader state handler
    if (activeTab === entitlementsTabName) setLoadingTab(flag);
    else if (activeTab === emailPreferencesTabName) setLoadingProductTab(flag);
  };

  const afterSaveClick = (resetProductDD) => {
    if (activeTab === entitlementsTabName) {
      setKeyValue((keyValue) => keyValue + 1);
    } else if (activeTab === emailPreferencesTabName)
      // Reset selection of Product dropdown
      resetProductDD && resetProductDD();
  };

  const handleEntitlementSave = async () => {
    await saveEntitlements(finalEntitlementSaveData);
    setShowDistributorUserIdModal([]);
  };

  const handleCloneUserEntitlementsSuccess = () => {
    getEntitlements(store.getState().userInfo.userInfo.email);
  };

  const handleClose = () => {
    setShowDistributorUserIdModal([]);
  };

  const isRowSelectable = useMemo(() => {
    return (params) => {
      return !params?.data?.groupId;
    };
  }, []);

  return (
    <>
      {showDistributorUserIdModal.length > 0 && (
        <DistributorUserIdModal
          filteredDistributorUserIdData={showDistributorUserIdModal}
          handleEntitlementSave={handleEntitlementSave}
          handleClose={handleClose}
        />
      )}

      {!userDetails ? (
        <Loader type="scaleLoader" />
      ) : (
        <>
          <div className="row">
            <div className="col-9">
              <span
                id="user-location-header"
                className="page-header breadcrumb-nav-wrapper"
              >
                <span>My Subscription</span>
              </span>
            </div>
          </div>

          {/* Tab section */}
          <div className="tabs-main">
            <Tabs
              fill
              justify
              defaultActiveKey={entitlementsTabName}
              activeKey={activeTab}
              onSelect={onTabSelect}
            >
              <Tab eventKey={entitlementsTabName} title="Entitlements">
                {userDetails && !userDetails?.results[0]?.groupId ? (
                  <Container fluid className="mt-2">
                    <div className="row">
                      <div className="col">
                        <PackagesDropdown
                          DataSet={filteredPackages}
                          callBackPackages={callBackPackages}
                          key={keyValue}
                        />
                      </div>
                      <div className="col">
                        <DistributorsDropdown
                          DataSet={filteredDistributors}
                          callBackDistributors={callBackDistributors}
                          key={keyValue}
                        />
                      </div>
                    </div>
                  </Container>
                ) : (
                  <Container fluid>
                    <div className="row">
                      <div className="col-6 mt-2">
                        <span className="email-preference-note-label">
                          {Verbiage.reachOutToContractAdminToUpdateEntitlements}
                        </span>
                      </div>
                    </div>
                  </Container>
                )}

                <MySubscriptionDetails
                  userEntitlements={userEntitlements}
                  userEntitlementsData={userEntitlementsData}
                  updatedPackageDistributors={updatedPackageDistributors}
                  loadingTab={loadingTab}
                  setFinalRowNodeData={setFinalRowNodeData}
                  userDetails={userDetails}
                  handleCloneUserEntitlementsSuccess={
                    handleCloneUserEntitlementsSuccess
                  }
                  isRowSelectable={isRowSelectable}
                ></MySubscriptionDetails>
                <Container fluid>
                  <div className="row">
                    <div className="col d-flex justify-content-end mb-2">
                      <button
                        disabled={userDetails?.results[0]?.groupId}
                        className="btn btn-dark btn-opacity"
                        onClick={onSaveHandler}
                      >
                        SAVE ENTITLEMENTS
                      </button>
                    </div>
                  </div>
                </Container>
              </Tab>
              <Tab eventKey={emailPreferencesTabName} title="Email preferences">
                <MySubscriptionEmailPreferenceDetails
                  userEntitlements={userEntitlements}
                  userEntitlementsData={userEntitlementsData}
                  contracts={contracts}
                  userDetails={userDetails}
                  loadingProductTab={loadingProductTab}
                  setLoadingProductTab={setLoadingProductTab}
                  saveEntitlements={saveEntitlements}
                  isRowSelectable={isRowSelectable}
                />
              </Tab>
            </Tabs>
          </div>
        </>
      )}
    </>
  );
};

export default MySubscription;
