import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import Content from '../components/Content';
import Footer from '../components/Footer';
import Main from '../components/Main';
import Wrapper from '../components/Wrapper';
import Navbar from '../components/navbar/Navbar';
import NavbarLogout from '../components/navbar/NavbarLogout';
import NavbarCSA from '../components/navbar/NavbarCSA';
import store from './../redux/store';
import { useSelector } from 'react-redux';

import ErrorBoundary from './ErrorBoundary';
import { UrlPath } from './../constants';
import NavbarMySubscription from '../components/navbar/NavbarMySubscription';

const Dashboard = ({ children }) => {
  const [showNavbar, setShowNavbar] = useState([]);

  const location = useLocation();

  useEffect(() => {
    let storeData = store.getState();
    let storeUserInfo = storeData?.userInfo;
    if (storeUserInfo?.userInfo?.SPGGroups) {
      setShowNavbar(storeUserInfo.userInfo.SPGGroups);
    }
  }, [location.pathname]);

  return (
    <React.Fragment>
      <Wrapper>
        {/* <Sidebar items={allItems} /> */}
        <Main>
          {location.pathname !== UrlPath.logout &&
          location.pathname !== UrlPath.endUserRegistrationSuccess &&
          location.pathname !== UrlPath.invalidRegistrationLink &&
          !location.pathname.includes(
            UrlPath.endUserRegistration.replace(':id', ''),
          ) ? (
            showNavbar.includes('GRP_SFS_CSA') ||
            showNavbar.includes('GRP_SFS_VIEWER') ? (
              <NavbarCSA />
            ) : showNavbar.includes('SFS_ENDUSER') ? (
              <NavbarMySubscription />
            ) : (
              <Navbar />
            )
          ) : (
            <NavbarLogout />
          )}
          <Content>
            <ErrorBoundary>
              {children}
              <Outlet />
            </ErrorBoundary>
          </Content>
          <Footer />
        </Main>
      </Wrapper>
    </React.Fragment>
  );
};

export default Dashboard;
