import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import { UrlPath } from '../../constants';
import { addContract } from '../../redux/actions';
import httpService from '../../services/http-service';
import MultiSelectDropdown from '../../sharedcomponents/multi-select-dropdown/multi-select-dropdown';
import {
  Endpoints,
  userPreferencesKey,
  ContractDisplayOptions,
} from './../../constants';
import AddEntitlements from './AddEntitlements';
import ExportEntitlements from './ExportEntitlements';
import ProductEntitlements from './ProductEntitlements';
import Entitlements from './Entitlements';
import EntitlementsServerside from './Entitlements-serverside';
import store from './../../redux/store';
import { useSelector } from 'react-redux';
import persistDataStore from './../../redux/persistStore';
import { getCSAContractsData } from './../../helpers/component-helper-methods';

const EntitlementsContainer = () => {
  const [loading, setLoading] = useState(true);
  const [dropDownLoader, setDropDownLoader] = useState(true);
  const [breadCrumbValue, setBreadCrumbValue] = useState();
  const [contractsResult, setContractsResult] = useState([]);
  const [isViewerUser, setIsViewerUser] = useState(false);
  const [contractDisplayOption, setContractDisplayOption] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getContractsDataSet = async (userEmail) => {
    let csaActionData =
      persistDataStore.getState()?.userActionData?.csaUserActionInfo;
    userEmail = userEmail.toLowerCase();
    try {
      let queryParamsContract = {
        PageSize: 5000,
        Field:
          'contractNumber,contractType,contractDescription,bulkEntitlementEnable',
        Filter: `email:"${userEmail}"`,
      };
      let storeData = store.getState();
      let storeContracts = storeData?.contract;
      if (
        storeContracts &&
        storeContracts.contracts &&
        storeContracts.contracts.length > 0
      ) {
        setContractsResult(storeContracts.contracts);
      } else {
        if (csaActionData?.type && csaActionData?.value) {
          let csaContracts = await getCSAContractsData();
          setDropDownLoader(false);
          setContractsResult(csaContracts);
          dispatch(addContract(csaContracts));
        } else {
          const contractResponse = await httpService.get(
            Endpoints.contractApi,
            queryParamsContract,
          );
          let tempState = contractResponse.data?.results?.map((el, i) => {
            return {
              label:
                el.contractNumber +
                (el.contractDescription ? ' - ' + el.contractDescription : ''),
              id: el.contractNumber,
              checked: true,
              bulkEntitlementEnable: el.bulkEntitlementEnable,
              ...el,
            };
          });
          setContractsResult(tempState);
          dispatch(addContract(tempState));
        }
      }
    } catch (err) {
    } finally {
      setDropDownLoader(false);
      setLoading(false);
    }
  };

  useEffect(async () => {
    let storeData = store.getState();
    let storeUserInfo = storeData.userInfo;
    if (storeUserInfo && storeUserInfo.userInfo) {
      setIsViewerUser(
        storeUserInfo.userInfo.SPGGroups?.includes('GRP_SFS_VIEWER'),
      );
      await getContractDisplayOption(storeUserInfo.userInfo.userPreferences);
      getContractsDataSet(storeUserInfo.userInfo.email);
    }
  }, [useSelector((x) => x.userInfo)]);

  // set header breadcrumb to CSA selected value
  useEffect(() => {
    let csaActionData =
      persistDataStore.getState()?.userActionData?.csaUserActionInfo;
    let breadCrumbData =
      csaActionData?.type === 'account'
        ? csaActionData?.data?.account
        : csaActionData?.type === 'hccompany'
        ? csaActionData?.data?.homogenizedCompany
        : csaActionData?.value;
    setBreadCrumbValue(breadCrumbData);
  }, []);

  const selectedCount = contractsResult.reduce(
    (counter, obj) => (obj.checked === true ? (counter += 1) : counter),
    0,
  );

  const handleTabSelect = (tabName) => {
    if (
      // tabName === 'entitlements' &&
      window.location.pathname !== UrlPath.entitlements
    ) {
      navigate(UrlPath.entitlements);
    }
  };
  const onContractChangeHandler = (contracts) => {
    setContractsResult(contracts);
    dispatch(addContract(contracts));
  };

  const getContractDisplayOption = async (userPreferences) => {
    try {
      if (
        userPreferences?.find(
          (x) => x.preferenceKey === userPreferencesKey['displayContract'],
        )
      ) {
        setContractDisplayOption(
          userPreferences.find(
            (x) => x.preferenceKey === userPreferencesKey['displayContract'],
          ),
        );
      } else {
        setContractDisplayOption({
          preferenceValue: ContractDisplayOptions[0].contractDisplayOption,
        });
      }
    } catch (err) {}
  };

  const getContractLabelKey = () => {
    if (contractDisplayOption) {
      if (
        contractDisplayOption.preferenceValue ===
        ContractDisplayOptions[1].contractDisplayOption
      ) {
        return 'contractDescription';
      }
      if (
        contractDisplayOption.preferenceValue ===
        ContractDisplayOptions[2].contractDisplayOption
      ) {
        return 'label';
      }
    }

    return 'contractNumber';
  };

  return (
    <>
      {loading ? (
        <Loader type="scaleLoader" />
      ) : (
        <>
          <div className="row">
            <div className="col-lg-8 col-md-5">
              <span id="entitlements-header" className="page-header">
                <span className=" breadcrumb-nav-wrapper">
                  <span className="cursor-pointer" onClick={handleTabSelect}>
                    Manage Entitlements{' '}
                  </span>
                  {window.location.pathname === UrlPath.entitlements ? (
                    <span className="breadcrumb-nav" title={breadCrumbValue}>
                      {breadCrumbValue ? ` / ${breadCrumbValue}` : ''}
                    </span>
                  ) : window.location.pathname === UrlPath.addEntitlements ? (
                    <>
                      <span className="breadcrumb-nav"> / New Entitlement</span>
                      <span className="breadcrumb-nav" title={breadCrumbValue}>
                        {breadCrumbValue ? ` / ${breadCrumbValue}` : ''}
                      </span>
                    </>
                  ) : window.location.pathname ===
                    UrlPath.exportEntitlements ? (
                    <>
                      <span className="breadcrumb-nav">/ Export</span>
                      <span className="breadcrumb-nav" title={breadCrumbValue}>
                        {breadCrumbValue ? ` / ${breadCrumbValue}` : ''}
                      </span>
                    </>
                  ) : window.location.pathname ===
                    UrlPath.productEntitlements ? (
                    <>
                      <span className="breadcrumb-nav">
                        / Manage Email Preferences
                      </span>
                      <span className="breadcrumb-nav" title={breadCrumbValue}>
                        {breadCrumbValue ? ` / ${breadCrumbValue}` : ''}
                      </span>
                    </>
                  ) : (
                    ''
                  )}
                </span>
              </span>
            </div>
            <div className="col-lg-4 col-md-7">
              <MultiSelectDropdown
                // searchKeys={["label"]}
                // enableSearch={true}
                enableSelectAll={true}
                defaultSelectAll={true}
                labelKeyName={getContractLabelKey()}
                ddOptionsKey="id"
                dropDownLoader={dropDownLoader}
                ddOptions={contractsResult}
                setddOptions={onContractChangeHandler}
                ddName={'contracts'}
                ddPlaceHolder={
                  selectedCount === contractsResult.length
                    ? 'Contracts: All Contracts'
                    : `Contracts: (${selectedCount})`
                }
              />
            </div>
          </div>
          {/* Tab section */}
          <div>
            {window.location.pathname === UrlPath.entitlements ? (
              <EntitlementsServerside
                contracts={contractsResult}
                isViewerUser={isViewerUser}
              />
            ) : window.location.pathname === UrlPath.addEntitlements ? (
              <div className="tabs-main">
                <AddEntitlements contracts={contractsResult} />
              </div>
            ) : window.location.pathname === UrlPath.exportEntitlements ? (
              <div className="tabs-main">
                <ExportEntitlements contracts={contractsResult} />
              </div>
            ) : (
              <div className="tabs-main">
                <ProductEntitlements contracts={contractsResult} />
              </div>
            )}
            {/* <Tabs
              fill
              justify
              defaultActiveKey="entitlements"
              onSelect={handleTabSelect}
            >
              <Tab eventKey="entitlements" title="All Entitlements">
                {window.location.pathname === UrlPath.entitlements ? (
                  <Entitlements contracts={contractsResult}></Entitlements>
                ) : (
                  <AddEntitlements contracts={contractsResult} />
                )}
              </Tab>
              <Tab eventKey="bundles" title="Entitlements Bundles">
                <Container className="entitlemets-bundles-content">
                  <h4>You don't have any bundles</h4>
                  <p>
                    Create entitlement bundles to easily assign multiple users,
                    or user groups, the same set of entitlements.
                  </p>
                  <p>
                    Select Users, Packages or Delivery Platform to start the
                    entitlement bundles creation process.
                  </p>
                </Container>
              </Tab>
            </Tabs> */}
          </div>
        </>
      )}
    </>
  );
};

const mapDispatchToProps = {
  addContract,
};

export default connect(null, mapDispatchToProps)(EntitlementsContainer);
