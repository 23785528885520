import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { ReduxAction } from '../../../../constants';
import { debounce } from '../../../../helpers/debounce';
import AgGridComponent from '../../../../sharedcomponents/ag-grid/AgGrid';

const UserGroupManageUser = ({
  editData,
  groupUsersData,
  onSaveClick,
  isViewerUser,
}) => {
  const [initialSelectedUser, setInitialSelectedUser] = useState([]);
  const [isManageUserGridChanged, setIsManageUserGridChanged] = useState(false);
  const [isRowDataLoaded, setIsRowDataLoaded] = useState(false);
  const gridRef = useRef(null);
  const dispatch = useDispatch();
  const manageUserSearchFilterInput = 'manage-user-search-filter-input';
  let selectedUsers = [];

  useEffect(() => {
    try {
      if (groupUsersData?.length > 0) {
        // Set initially selected Users
        let tempData = groupUsersData.filter((el) => el?.groupId);
        setInitialSelectedUser(tempData);

        // Pre select existing users
        setTimeout(() => {
          const groupUserDataLength = groupUsersData?.length - 1;
          gridRef.current.api.forEachNode((node, i) => {
            if (node?.data?.groupId) {
              let { email } = node.data;
              selectedUsers.push(email);
              node.setSelected(true);
            }
            if (groupUserDataLength === i) {
              // to stop initial flickering due to redrawRows
              setTimeout(() => setIsRowDataLoaded(true), 150);
            }
          });
        }, 100);
      }
    } catch (err) {}
  }, [groupUsersData]);

  const defaultConfig = {
    rowModelType: 'clientSide',
    isExportCSV: false,
    isExportExcel: false,
    suppressDragLeaveHidesColumns: true,
    pivotPanelShow: '',
    pagination: false,
    isAutoSizeColumns: true,
    enableCharts: false,
    rowGroupPanelShow: false,
    sideBar: false,
    overlayNoRowsTemplate: 'This user group has no users',
  };

  const isFirstColumn = (params) => {
    let displayedColumns = params?.api?.getAllDisplayedColumns();
    let thisIsFirstColumn = displayedColumns[0] === params?.column;
    if (thisIsFirstColumn) {
      return thisIsFirstColumn;
    }
  };

  const defaultColdef = {
    sortable: true,
    resizable: true,
    headerCheckboxSelectionFilteredOnly: true,
    headerCheckboxSelection: !isViewerUser && isFirstColumn,
    checkboxSelection: !isViewerUser && isFirstColumn,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };

  // To manage color code changes
  const getColumnClass = (params) => {
    if (
      initialSelectedUser?.length >= 0 &&
      initialSelectedUser?.some(
        (x) => x.contactId === params?.node?.data?.contactId,
      ) &&
      !params?.node?.selected &&
      !workInProgress
    ) {
      return 'unselected-entitlement-row';
    }

    if (
      initialSelectedUser?.length >= 0 &&
      !initialSelectedUser?.some(
        (x) => x.contactId === params?.node?.data?.contactId,
      ) &&
      params?.node?.selected
    ) {
      return 'new-selected-entitlement-row';
    } else {
      return '';
    }
  };

  const manageUserColumns = [
    {
      headerName: 'Name',
      headerTooltip: 'Name',
      field: 'contactName',
      tooltipField: 'contactName',
      initialFlex: 3,
      cellClass: getColumnClass,
    },
    {
      headerName: 'Email ID',
      headerTooltip: 'Email ID',
      field: 'email',
      tooltipField: 'email',
      initialFlex: 3,
      cellClass: getColumnClass,
    },
    {
      headerName: 'Location',
      headerTooltip: 'Location',
      field: 'officeLocation',
      tooltipField: 'officeLocation',
      initialFlex: 4,
      cellClass: getColumnClass,
    },
  ];

  const objectsEqual = (o1, o2) =>
    Object.keys(o1).length === Object.keys(o2).length &&
    Object.keys(o1).every((p) => o1[p] === o2[p]);

  const validateManageUserGridChange = () => {
    // Validate any new changes before enabling save btn
    if (gridRef.current) {
      let currentlySelectedUsers = gridRef.current.api?.getSelectedRows();
      currentlySelectedUsers = currentlySelectedUsers.sort((a, b) =>
        a.contactId.localeCompare(b.contactId, undefined, {
          sensitivity: 'base',
        }),
      );

      if (currentlySelectedUsers && initialSelectedUser) {
        if (objectsEqual(currentlySelectedUsers, initialSelectedUser)) {
          setIsManageUserGridChanged(false);
        } else {
          setIsManageUserGridChanged(true);
        }
      }
    } else {
      if (isManageUserGridChanged) {
        setIsManageUserGridChanged(false);
      }
    }
  };

  const validateManageUserGridChangeDebounce = debounce(
    validateManageUserGridChange,
    100,
  );

  const onManageUserGridReadyHandler = (params) => {
    gridRef.current = params;
  };

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById(manageUserSearchFilterInput).value,
    );
  }, []);

  const onFilterTextBoxChangedDebounce = debounce(onFilterTextBoxChanged, 1000);

  const moveToTopOfGrid = (nodeData, index) => {
    // Move single/multiple row at any index of the grid
    gridRef.current.api.applyTransaction({
      remove: nodeData,
    });

    gridRef.current.api.applyTransaction({
      add: nodeData,
      addIndex: index,
    });
  };

  // avoid all actions of selection(after effects)
  let workInProgress = false;
  const onRowSelected = (n) => {
    if (!workInProgress) {
      workInProgress = true;
      let selected = n.node.selected;
      let nodeData = n.data;
      if (selected) {
        // add user email to selected list
        let { email } = nodeData;
        selectedUsers.push(email);

        moveToTopOfGrid([n.node.data], 0);

        // Select user checkbox logic
        setTimeout(
          () =>
            gridRef.current.api.forEachNode((node) => {
              if (selectedUsers.includes(node?.data?.email)) {
                node.setSelected(true);
              }
            }),
          50,
        );
      } else {
        // remove user email from selected list
        let index = selectedUsers.findIndex((el) => el === nodeData.email);
        if (index >= 0) {
          selectedUsers.splice(index, 1);

          let lastSelectedUserIndex =
            gridRef.current.api.getSelectedRows().length;
          moveToTopOfGrid([n.node.data], lastSelectedUserIndex);
        }
      }
      // avoid all actions of selection(after effects)
      setTimeout(() => (workInProgress = false), 100);
    }
  };

  const onSelectionChanged = () => {
    if (gridRef?.current) {
      validateManageUserGridChangeDebounce();

      // Save selection in redux store
      let allSelectedUsers = gridRef.current.api.getSelectedRows();

      // Reset selectedUser array if select/unselect all
      if (allSelectedUsers.length === groupUsersData.length) {
        selectedUsers = [];
      }

      dispatch({
        type: ReduxAction.updateUGManageUser,
        payload: {
          manageUserSelection: allSelectedUsers,
        },
      });
      if (isRowDataLoaded) {
        setTimeout(
          () =>
            gridRef.current.api.refreshCells({
              force: true,
            }),
          150,
        );
      }
    }
  };

  return (
    <>
      <Container className="pt-2-5">
        <div className="row mt-2">
          <div className="col-6">
            <input
              type="text"
              id={manageUserSearchFilterInput}
              className="form-control search-box"
              placeholder="Search by name, email or location"
              onInput={onFilterTextBoxChangedDebounce}
            ></input>
          </div>
        </div>
        <div className="row ag-grid-modal-content">
          <AgGridComponent
            config={defaultConfig}
            defaultColumnDef={defaultColdef}
            data={groupUsersData}
            columns={manageUserColumns}
            onRowSelected={onRowSelected}
            onSelectionChanged={onSelectionChanged}
            onGridReady={onManageUserGridReadyHandler}
          />
        </div>
        <Form className="row">
          <Form.Group className="col-12 user-save-btn-body">
            <button
              type="button"
              disabled={!isManageUserGridChanged || isViewerUser}
              className="ug-save-btn user-save-btn btn btn-dark float-end btn-opacity"
              onClick={onSaveClick}
            >
              {editData ? 'Update' : 'Save'}
            </button>
          </Form.Group>
        </Form>
      </Container>
    </>
  );
};

export default UserGroupManageUser;
