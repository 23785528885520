import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import DropdownInput from '../../../sharedcomponents/DropdownInput/DropdownInput';
import TextFieldInput from '../../../sharedcomponents/TextFieldInput/TextFieldInput';
import {
  Endpoints,
  GenericError,
  ReduxAction,
  UrlPath,
} from './../../../constants';
import store from './../../../redux/store';
import httpService from './../../../services/http-service';
import { Notify } from './../../../sharedcomponents/Alert/Notify';
import Loader from './../../../components/Loader';
import './end-user-registration.scss';
import LocationAddEdit from './../../locations/location-add-edit';
import DuplicateAddressPopup from './../../locations/duplicate-address-popup';
import { Country } from 'country-state-city';
import { verifyHash } from './../../../helpers/hash';

const EndUserRegistration = ({}) => {
  const [officeLocationName, setOfficeLocationName] = useState([]);
  const [jobDesignationCollection, setJobDesignationCollection] = useState({});
  const [accountsData, setAccountsData] = useState([]);
  const [newLocations, setNewLocations] = useState([]);
  const [contract, setContract] = useState();
  const [groupId, setGroupId] = useState();
  const [contractType, setContractType] = useState();
  const [showModal, setShowModal] = useState(false);
  const [duplicateAddressModal, setDuplicateAddressModal] = useState({
    show: false,
    data: null,
    isEdit: false,
    isFromRecommendationClick: false,
  });
  const [duplicateLocations, setDuplicateLocations] = useState();
  const [submittedAddress, setSubmittedAddress] = useState('');
  const [initialValue, setInitialValue] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    department: '',
    jobFunction: '',
    designation: '',
    accountId: '',
    officeLocationId: '',
    contactId: '-1',
  });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationAlreadyExistMsg =
    'Location already exists. Please reach out to the contract admin if you can not see the location in the dropdown.';
  const { id } = useParams();

  useEffect(async () => {
    try {
      let splitId = id.split('&');
      let decodedContract = atob(splitId?.[0]);
      let contract = decodedContract.split('||')[0];
      let contractHash = decodedContract.split('||')[1];
      let isCorrectURL = await verifyHash(contract, contractHash);
      if (!isCorrectURL) {
        return navigate(UrlPath.invalidRegistrationLink);
      } else {
        setContract(contract);
        if (splitId?.length > 1) {
          let decodedGroupId = atob(splitId?.[1]);
          let groupId = decodedGroupId.split('||')[0];
          let groupHash = decodedGroupId.split('||')[1];
          isCorrectURL = await verifyHash(groupId, groupHash);
          if (!isCorrectURL) {
            return navigate(UrlPath.invalidRegistrationLink);
          }
          setGroupId(groupId);
        }
      }
      getContractType(contract);
      getLocations(contract);
      getjobFunctionDesignationData();
    } catch (err) {
      return navigate(UrlPath.invalidRegistrationLink);
    }
  }, []);

  const validate = Yup.object({
    firstName: Yup.string()
      .trim()
      .max(80, 'Must be 80 characters or less')
      .required('Please enter first name to proceed')
      .matches('^[a-zA-Z ]*$', 'No numerical or symbolic values are accepted'),
    lastName: Yup.string()
      .trim()
      .max(80, 'Must be 80 characters or less')
      .required('Please enter last name to proceed')
      .matches('^[a-zA-Z ]*$', 'No numerical or symbolic values are accepted'),
    email: Yup.string()
      .max(80, 'Must be 80 characters or less')
      .required('Please enter email to proceed')
      .email('Invalid email address'),
    phone: Yup.string()
      .max(40, 'Must be 40 characters or less')
      .matches(
        '^[+]*[0-9 ()-]*$',
        'Numerical values with +, -, (, ) are accepted',
      ),
    department: Yup.string()
      .max(80, 'Must be 80 characters or less')
      .matches(
        '^[A-Za-z0-9 _-]*[A-Za-z0-9][A-Za-z0-9 _-]*$',
        'No special characters values are accepted',
      ),
    jobFunction: Yup.string()
      .max(80, 'Must be 80 characters or less')
      .required('Please select Job function'),
    designation: Yup.string()
      .max(80, 'Must be 80 characters or less')
      .required('Please select Role'),
    accountId: Yup.string().required('Please select account to proceed'),
    officeLocationId: Yup.string().required(
      'Please select location to proceed',
    ),
  });
  const saveLocation = (values) => {
    setLoading(true);
    let locationToSave =
      newLocations?.length > 0
        ? newLocations.find(
            (x) => x.officeLocationId === values.officeLocationId,
          )
        : {};
    if (locationToSave.officeLocationId) {
      let previousOfficeLocationId = locationToSave.officeLocationId;
      locationToSave.officeLocationId = '-1';

      let country = Country.getAllCountries().find(
        (obj) => obj.name === locationToSave.country,
      );
      if (country) {
        locationToSave.countryIsoCode = country.isoCode;
      } else {
        let countryObj = Country.getCountryByCode(locationToSave.country);
        if (countryObj) {
          locationToSave.countryIsoCode = countryObj.isoCode;
        }
      }

      httpService
        .post(Endpoints.userRegistrationLocationApi, locationToSave)
        .then((resp) => {
          if (resp?.data?.code === 0) {
            values.officeLocationId = resp?.data?.results;

            let updatedLocations = newLocations.filter(
              (x) => x.officeLocationId != locationToSave.officeLocationId,
            );
            setNewLocations(updatedLocations);
            try {
              officeLocationName.find(
                (l) => l.officeLocationId == previousOfficeLocationId,
              ).officeLocationId = resp?.data?.results;
              setOfficeLocationName([...officeLocationName]);
              let locationData = {
                target: {
                  id: 'officeLocationId',
                  value: resp?.data?.results,
                },
              };
              formik.handleChange(locationData);
            } catch {}

            saveEndUserForm(values);
          }
        })
        .catch((err) => {
          let errorMsg = GenericError.somethingWentWrong;
          if (err?.response?.data?.resultCode === '-2') {
            errorMsg = locationAlreadyExistMsg;
          }
          if (
            err?.response?.data?.resultCode === '-3' ||
            err?.response?.data?.resultCode === '-4'
          ) {
            errorMsg = err?.response?.data?.cause;
          }

          Notify({
            alert: true,
            type: 'error',
            title: errorMsg,
          });
          setLoading(false);
        });
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValue,
    validationSchema: validate,
    onSubmit: (values) => {
      if (
        parseInt(values.officeLocationId) &&
        Math.sign(parseInt(values.officeLocationId)) == -1
      ) {
        saveLocation(values);
      } else {
        saveEndUserForm(values);
      }
    },
  });

  const saveEndUserForm = (userDetails) => {
    try {
      setLoading(true);
      let postData = {
        userid: userDetails.email.trim(),
        isDeleted: 0,
        isSendWelcomeEmail: true,
      };
      for (let key in userDetails) {
        if (typeof userDetails[key] === 'string') {
          postData[key] = userDetails[key].trim();
        } else {
          postData[key] = userDetails[key];
        }
      }
      httpService
        .post(Endpoints.userRegistrationUserPostApi, [postData])
        .then((resp) => {
          if (resp?.data?.code === 0) {
            formik.resetForm();
            if (groupId) saveUserGroupContact(resp?.data?.results[0]);
            else navigate(UrlPath.endUserRegistrationSuccess);
          }
        })
        .catch((err) => {
          let errorMsg =
            err?.response?.data?.resultCode === '-2' ||
            err?.response?.data?.resultCode === '-3' ||
            err?.response?.data?.resultCode === '-4'
              ? err?.response?.data?.cause
              : GenericError.somethingWentWrong;
          Notify({
            alert: true,
            type: 'error',
            title: errorMsg,
          });
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
    }
  };

  const saveUserGroupContact = (contact) => {
    try {
      setLoading(true);
      let userGroupContact = {
        userGroupContacts: [
          {
            groupId: groupId,
            contractNumber: contract,
            contactId: contact.contactId,
            isDeleted: 0,
          },
        ],
        userId: contact.email,
      };
      httpService
        .post(Endpoints.userRegistrationUserGroupContactApi, userGroupContact)
        .then((res) => {
          if (res.data === 0) {
            navigate(UrlPath.endUserRegistrationSuccess);
          }
        })
        .catch((err) => {
          Notify({
            alert: true,
            type: 'error',
            title: GenericError.somethingWentWrong,
          });
        })
        .finally(() => setLoading(false));
    } catch (err) {
      setLoading(false);
    }
  };

  const dropdownOnChangeHandler = (val, name) => {
    let obj = {
      target: { name: name, value: val[name] ?? val },
    };
    formik.handleChange(obj);
    if (name === 'officeLocationId') {
      resetLocationDropdown();
      if (val[name] !== '' && name === 'officeLocationId') {
        applyAcctBasedOnLocation(val);
      } else {
        setOfficeLocationName();
      }
    }
  };

  const resetLocationDropdown = () => {
    let locationData = {
      target: {
        id: 'accountId',
        value: '',
      },
    };
    formik.handleChange(locationData);
  };

  const applyAcctBasedOnLocation = (val) => {
    let accData = {
      target: {
        id: 'accountId',
        value: val.accountId ?? '',
      },
    };
    formik.handleChange(accData);
  };

  const getAccounts = (contract) => {
    if (contract) {
      let queryParams = {
        pageSize: 5000,
        field: 'accountId,accountName',
        filter: `contractNumber in("${contract}")`,
      };
      httpService
        .get(Endpoints.userRegistrationAccountApi, queryParams)
        .then((res) => {
          setAccountsData(res.data?.results);
        })
        .catch((err) => {});
    } else {
      setAccountsData([]);
    }
  };

  const getContractType = (contract) => {
    if (contract) {
      let queryContractTypearams = {
        PageSize: 1,
        id: new Date().getTime(),
        field: 'contractNumber,contractType',
        filter: `contractNumber:"${contract}"`,
      };
      httpService
        .get(Endpoints.userRegistrationAccountApi, queryContractTypearams)
        .then((res) => {
          setContractType(res.data?.results[0]?.contractType);
        })
        .catch((err) => {});
    }
  };

  const getLocations = (contract) => {
    if (contract) {
      let queryLocationParams = {
        PageSize: 5000,
        id: new Date().getTime(),
        field:
          'accountId,accountName,contractNumber,officeLocationName,officeLocationId',
        filter: `contractNumber:"${contract}"`,
      };
      httpService
        .get(Endpoints.userRegistrationAccountApi, queryLocationParams)
        .then((res) => {
          if (res) {
            setOfficeLocationName(
              getDuplicateLocationsWithContractNum(res.data?.results),
            );
          }
        })
        .catch((err) => {
          setOfficeLocationName([]);
        });
    }
  };

  const getDuplicateLocationsWithContractNum = (locations) => {
    let uniqueLocations = [];
    let duplicateLocations = [];
    let tempLocationsWithContractNum = locations?.filter((el) => {
      if (!uniqueLocations?.includes(el.officeLocationId)) {
        uniqueLocations?.push(el.officeLocationId);

        return el;
      } else {
        duplicateLocations?.push(el.officeLocationId);
      }
    });

    tempLocationsWithContractNum = locations?.filter((el) => {
      if (duplicateLocations?.includes(el.officeLocationId)) {
        el.officeLocationName =
          el.officeLocationName + ' (' + el.contractNumber + ')';
      }
      return el;
    });
    return tempLocationsWithContractNum;
  };

  const getjobFunctionDesignationData = () => {
    try {
      let storeData = store.getState();
      let storeJobDesignationDetails = storeData.jobDesignationDetails;
      let queryParams = {
        keyItem: `JOBFUNCTIONCOLLECTION, DESIGNATIONCOLLECTION`,
      };
      if (
        storeJobDesignationDetails?.jobFunctionCollection?.length > 0 ||
        storeJobDesignationDetails?.designationCollection?.length > 0
      ) {
        setJobDesignationCollection(storeJobDesignationDetails);
      } else {
        httpService
          .get(Endpoints.userRegistrationConfigurationApi, queryParams)
          .then((res) => {
            const response = res?.data?.results;
            let jobDesignationDetails = {};
            response.map((item) => {
              if (item.keyItem === 'JOBFUNCTIONCOLLECTION') {
                let jobFunc = JSON.parse(item.keyValue);

                jobDesignationDetails.jobFunctionCollection = jobFunc;
              } else if (item.keyItem === 'DESIGNATIONCOLLECTION') {
                let designation = JSON.parse(item.keyValue);

                jobDesignationDetails.designationCollection = designation;
              }
            });
            dispatch({
              type: ReduxAction.jobFuncDesignation,
              payload: jobDesignationDetails,
            });
            setJobDesignationCollection(jobDesignationDetails);
          })
          .catch((err) => {});
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleAddLocation = () => {
    setShowModal(true);
  };

  const closeAddLocationModal = () => {
    setShowModal(false);
  };

  const getOfficeLocationName = (location) => {
    return (
      (location.address_1 ? location.address_1 + ', ' : '') +
      (location.address_2 ? location.address_2 + ', ' : '') +
      (location.city ? location.city + ', ' : '') +
      (location.state ? location.state + ', ' : '') +
      (location.postalCode ? location.postalCode + ', ' : '') +
      (location.country ? location.country : '')
    );
  };

  const validateDuplicateLocations = (location) => {
    return officeLocationName.some(
      (x) => x.officeLocationName === getOfficeLocationName(location),
    );
  };

  const handleLocationSave = (location, isEdit, isFromRecommendationClick) => {
    let fullAddress =
      location.address_1 +
      location.address_2 +
      location.city +
      location.state +
      location.postalCode +
      location.country;
    fullAddress = fullAddress.replace(/\s+/g, '').toLowerCase();
    let queryData = {
      Filter: `ValidateAddress:"${fullAddress}"`,
    };

    // only validate duplicate for new addresses
    httpService
      .get(Endpoints.userRegistrationDuplicateAddressValidatorApi, queryData)
      .then((response) => {
        let dLocation = response?.data;
        if (dLocation) {
          Notify({
            alert: true,
            type: 'error',
            title: locationAlreadyExistMsg,
          });
        } else {
          let queryParams = {
            PageSize: 50000,
            Filter: `contractNumber.Contains("")
            AND hc_accountid:"${location.hC_AccountID}"`,
          };
          httpService
            .get(Endpoints.userRegistrationLocationApi, queryParams)
            .then((response) => {
              const allLocations = response?.data?.results;
              const { city, state, country, address_1, address_2, postalCode } =
                location;
              const existingLocations = allLocations.filter(
                (item) =>
                  item.city.toLowerCase() === city.toLowerCase() &&
                  item.state === state &&
                  item.country === country,
              );
              if (existingLocations.length > 0) {
                setDuplicateLocations(existingLocations);
                let fullAddress = `${address_1 ? address_1 + ', ' : ''}${
                  address_2 ? address_2 + ', ' : ''
                }${city}, ${state}, ${postalCode}, ${country}`;
                setSubmittedAddress(fullAddress);
                if (validateDuplicateLocations(location)) {
                  Notify({
                    alert: true,
                    type: 'error',
                    title: locationAlreadyExistMsg,
                  });
                } else {
                  setDuplicateAddressModal({
                    show: true,
                    data: location,
                    isEdit: isEdit,
                    isFromRecommendationClick: isFromRecommendationClick,
                  });
                }
              } else {
                addLocationToDropdown(location);
              }
            })
            .catch((err) => {});
        }
      });
  };
  const addLocationToDropdown = (location) => {
    if (validateDuplicateLocations(location)) {
      Notify({
        alert: true,
        type: 'error',
        title: locationAlreadyExistMsg,
      });
    } else {
      let newLocationIds =
        newLocations?.length > 0
          ? newLocations.map((x) => parseInt(x.officeLocationId, 10))
          : [parseInt(location.officeLocationId, 10)];
      location.officeLocationId = (Math.min(...newLocationIds) - 1).toString();
      newLocations.push(location);
      setNewLocations(newLocations);
      let newLocationDropdownValue = {
        accountId: location.accountId,
        accountName: location.accountName,
        contractNumber: location.contractNumber,
        officeLocationName: getOfficeLocationName(location),
        officeLocationId: location.officeLocationId,
      };
      officeLocationName.push(newLocationDropdownValue);
      setOfficeLocationName([...officeLocationName]);
      let locationData = {
        target: {
          id: 'officeLocationId',
          value: location.officeLocationId,
        },
      };
      formik.handleChange(locationData);
      dropdownOnChangeHandler(newLocationDropdownValue, 'officeLocationId');
      closeAddLocationModal();
    }
  };
  const locationContracts = [
    {
      label: contract,
      id: contract,
      checked: true,
      contractNumber: contract,
    },
  ];
  return (
    <>
      {loading ? (
        <Loader type="scaleLoader" />
      ) : (
        <div>
          {showModal && (
            <LocationAddEdit
              show={showModal}
              handleClose={closeAddLocationModal}
              contracts={locationContracts}
              locationDetails={{}}
              sourceType="EndUser"
              onSaveClick={handleLocationSave}
              // loading={loading}
            ></LocationAddEdit>
          )}
          {duplicateAddressModal.show && (
            <DuplicateAddressPopup
              loading={loading}
              handleClose={() => setDuplicateAddressModal({ show: false })}
              handleSubmit={() => {
                setDuplicateAddressModal({ show: false });
                addLocationToDropdown(duplicateAddressModal.data);
              }}
              submittedAddress={submittedAddress}
              duplicateLocations={duplicateLocations}
            />
          )}
          <Row sm={12} className="user-registartion-section">
            <Col sm={12} className="user-info-sub-header">
              User Registration
            </Col>
            <Form className="row" onSubmit={formik.handleSubmit}>
              <TextFieldInput
                formLabel="First name"
                placeholder="First name"
                id="firstName"
                {...formik.getFieldProps('firstName')}
                formik={formik}
                addAsterisk
              />
              <TextFieldInput
                formLabel="Last name"
                placeholder="Last name"
                id="lastName"
                {...formik.getFieldProps('lastName')}
                formik={formik}
                addAsterisk
              />
              <TextFieldInput
                formLabel="Email"
                placeholder="Email address"
                id="email"
                {...formik.getFieldProps('email')}
                formik={formik}
                addAsterisk
              />
              <DropdownInput
                enableSearch={true}
                searchKeys={['officeLocationName']}
                formLabel="Location"
                name="officeLocationId"
                dropdownOptions={officeLocationName}
                formik={formik}
                ddPlaceHolder={
                  formik.values.officeLocationId !== '' &&
                  officeLocationName?.length > 0
                    ? officeLocationName?.filter(
                        (el) =>
                          el.officeLocationId ===
                          formik.values.officeLocationId,
                      )[0]?.officeLocationName
                    : 'Select'
                }
                labelKeyName="officeLocationName"
                ddName={'officeLocation'}
                onChangeHandler={(e) =>
                  dropdownOnChangeHandler(e, 'officeLocationId')
                }
                addAsterisk
              />
              {contractType && contractType?.toLowerCase() !== 'primary' && (
                <>
                  <div className="col-6"> </div>
                  <div className="col-6 user-registration-location-text pb-1">
                    <span className="email-label" onClick={handleAddLocation}>
                      Click here
                    </span>{' '}
                    to add a location that is not present in the list
                  </div>
                </>
              )}
              <DropdownInput
                formLabel="Account"
                name="accountId"
                targetValue="accountId"
                dropdownOptions={officeLocationName}
                formik={formik}
                ddPlaceHolder={
                  formik.values.accountId !== '' &&
                  officeLocationName?.length > 0
                    ? officeLocationName?.filter(
                        (el) => el.accountId === formik.values.accountId,
                      )[0]?.accountName
                    : 'Select'
                }
                labelKeyName="accountName"
                ddName={'accountsData'}
                disabled
                addAsterisk
              />
              <DropdownInput
                enableSearch={true}
                searchKeys={[]}
                formLabel="Job function"
                {...formik.getFieldProps('jobFunction')}
                name="jobFunction"
                dropdownOptions={jobDesignationCollection.jobFunctionCollection}
                formik={formik}
                ddPlaceHolder={
                  formik.values.jobFunction !== ''
                    ? formik.values.jobFunction
                    : 'Select'
                }
                ddName={'jobFunction'}
                onChangeHandler={(e) =>
                  dropdownOnChangeHandler(e, 'jobFunction')
                }
                addAsterisk
              />
              <DropdownInput
                enableSearch={true}
                formLabel="Role"
                {...formik.getFieldProps('designation')}
                name="designation"
                dropdownOptions={jobDesignationCollection.designationCollection}
                formik={formik}
                ddPlaceHolder={
                  formik.values.designation !== ''
                    ? formik.values.designation
                    : 'Select'
                }
                ddName={'designation'}
                onChangeHandler={(e) =>
                  dropdownOnChangeHandler(e, 'designation')
                }
                addAsterisk
              />
              <TextFieldInput
                formLabel="Department"
                placeholder="Department"
                id="department"
                {...formik.getFieldProps('department')}
                formik={formik}
              />
              <TextFieldInput
                formLabel="Business Phone number"
                placeholder="Business Phone number"
                id="phone"
                {...formik.getFieldProps('phone')}
                formik={formik}
              />
              <div className="user-details-fix d-flex">
                <Form.Group className="col-12 user-save-btn-body">
                  <button
                    type="Submit"
                    className="user-save-btn btn btn-dark float-end btn-opacity"
                  >
                    Submit
                  </button>
                </Form.Group>
              </div>
            </Form>
          </Row>
        </div>
      )}
    </>
  );
};

export default EndUserRegistration;
