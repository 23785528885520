import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import { debounce } from '../../helpers/debounce';
import httpService from '../../services/http-service';
import AgGridComponent from '../../sharedcomponents/ag-grid/AgGrid';
import bell_icon from './../../assets/icons/avatars/bell_icon.svg';
import AddUserRegistration from '../../pages/manage-email-domains/add-user-registration';
import {
  Endpoints,
  GenericError,
  PendingActive,
  PendingApproval,
  PendingInactive,
  UrlPath,
  UserId,
  ContractDisplayOptions,
  Verbiage,
  ReduxAction,
  contractDisplayOptionKey,
} from './../../constants';

import DropdownInput from './../../sharedcomponents/DropdownInput/DropdownInput';
import { Notify } from './../../sharedcomponents/Alert/Notify';
import copy_icon from '../../assets/icons/entitlements/mailing.svg';
import CopyLinkCell from '../../sharedcomponents/copy-link-cell/CopyLinkCell';
import { hashText } from '../../helpers/hash';
import ContractDescriptionModal from './contract-description-modal';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { addContract } from './../../redux/actions';
import store from './../../redux/store';

const UserDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [initialValue, setInitialValue] = useState({
    contractDisplayOption: ContractDisplayOptions[0].contractDisplayOption,
  });
  const [userDetails, setUserDetails] = useState();
  const [filteredContracts, setFilteredContracts] = useState();
  const [entitlementsData, setEntitlementsData] = useState();
  const [isEmailOpt, setIsEmailOpt] = useState(false);
  const [profileUser, setProfileUser] = useState();
  const [loggedInUserContractNumber, setLoggedInUserContractNumber] =
    useState();
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [clickedRowData, setClickedRowData] = useState([]);
  const [showContractDescriptionModal, setShowContractDescriptionModal] =
    useState();
  const [contractsMissingDescription, setContractsMissingDescription] =
    useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const UserRole = ['Admin', 'Contract Admin'];
  const gridRefContract = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userPreferences = useSelector(
    (state) => state.userPreferences?.userPreferences,
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValue,
  });

  const onChangeHandler = (val, name) => {
    let contractDisplayOptionValue = val.target.defaultValue ?? val;
    let obj = {
      target: { name: name, value: contractDisplayOptionValue },
    };

    if (contractsMissingDescription) {
      if (contractDisplayOptionValue == 'ContractDescription') {
        Notify({
          alert: true,
          type: 'error',
          title: Verbiage.displayOptionContractDescriptionFailed,
          dialogClassName: 'contractDescriptionPreference-alert',
        });

        // don't update display option if user selects Contract Description and description(s) are missing
        return;
      }

      if (contractDisplayOptionValue == 'ContractNumberDescription') {
        Notify({
          alert: true,
          type: 'success',
          title: Verbiage.displayOptionContractIDAndDescription,
          dialogClassName: 'contractDescriptionPreference-alert',
        });
      }
    }

    let queryParams = {
      email: localStorage.userID,
      preferencekey: contractDisplayOptionKey,
      isactive: 1,
      preferencevalue: contractDisplayOptionValue,
    };

    httpService
      .post(Endpoints.userPreferenceApi, queryParams)
      .then((resp) => {
        if (resp.data === 0) {
          let storeData = store.getState();
          let storeUserInfo = storeData?.userInfo.userInfo;
          storeUserInfo.userPreferences = [
            {
              preferenceKey: contractDisplayOptionKey,
              preferenceValue: contractDisplayOptionValue,
            },
          ];
          dispatch({ type: ReduxAction.userInfoSet, payload: storeUserInfo });
        }
      })
      .catch((err) => {});

    formik.handleChange(obj);
  };

  const getUserById = (email, contractNumbersString) => {
    try {
      let queryParams = {
        Filter:
          'email:"' +
          email.toLowerCase() +
          '" AND contractNumber in(' +
          contractNumbersString +
          ')',
        Field:
          'contactName,contactId,contractNumber,email,phone,department,jobFunction,isEmailOptOut,account,accountId,officeLocation,officeLocationId,entitled,modifiedDate,middlename,iscontractadmin',
      };
      httpService
        .get(Endpoints.userApi, queryParams)
        .then(({ data }) => {
          let rowData = data?.results ?? data?.aggResultValue;
          setProfileUser(data?.results[0]);
          data?.results[0]?.isEmailOptOut == 1
            ? setIsEmailOpt(true)
            : setIsEmailOpt(false);
        })
        .catch(() => {
          Notify({
            alert: true,
            type: 'error',
            title: GenericError.somethingWentWrong,
          });
        });
    } catch (err) {}
  };

  useEffect(() => {
    if (localStorage?.userID) {
      getContractsDataSet();
      getDisplayOptionUserPreference();
    }
  }, [localStorage]);

  useEffect(() => {
    if (filteredContracts && filteredContracts.length > 0) {
      const allContractsHaveDescription = filteredContracts.every(
        (contract) =>
          contract.contractDescription &&
          contract.contractDescription.length > 0,
      );

      setContractsMissingDescription(!allContractsHaveDescription);
    }
  }, [filteredContracts]);

  const getDisplayOptionUserPreference = async () => {
    if (userPreferences && userPreferences.length > 0) {
      const displayContractPreference = userPreferences.find(
        (item) => item.preferenceKey === 'DISPLAY_CONTRACT',
      );

      if (displayContractPreference) {
        formik.setFieldValue(
          'contractDisplayOption',
          displayContractPreference.preferenceValue,
        );
        return;
      }
    }

    let queryParams = {
      pageSize: 5000,
      Filter: `email:"${localStorage.userID.toLowerCase()}" AND preferencekey: "${contractDisplayOptionKey}"`,
      id: String(Date.now()),
    };

    try {
      const resp = await httpService.get(
        Endpoints.userPreferenceApi,
        queryParams,
      );
      const response = resp.data.results;

      if (response.length > 0) {
        const displayContractPreference = response.find(
          (item) => item.preferenceKey === contractDisplayOptionKey,
        );

        if (displayContractPreference) {
          formik.setFieldValue(
            'contractDisplayOption',
            displayContractPreference.preferenceValue,
          );
        }
      }
    } catch (err) {
      console.error('Error fetching user preferences:', err);
    }
  };

  const getContractsDataSet = async () => {
    try {
      gridRefContract?.current?.api?.showLoadingOverlay();
      let queryParams = {
        pageSize: 5000,
      };
      const contractResponse = await httpService.get(
        Endpoints.contractApi,
        queryParams,
      );
      let allContracts = contractResponse?.data?.results;

      // set User Details after filtering email id of logged in user
      let tempUserDetails = allContracts?.filter(
        (el) =>
          el.email?.toLowerCase().trim() ===
          localStorage.userID?.toLowerCase().trim(),
      );
      // List of logged in user's contract numbers
      let contractIDs = tempUserDetails?.map((el) => el.contractNumber);

      let contractNumbersToString = contractIDs
        ?.map((contract) => (contract ? '"' + contract + '"' : ''))
        .join(',');

      setLoggedInUserContractNumber(contractNumbersToString);
      getUserById(UserId, contractNumbersToString);
      // Comparator array to distinct values for contract admin
      let uniqueArr = [];
      let tempFilteredContracts = [];
      await Promise.all(
        allContracts?.map(async (el) => {
          if (
            el.email?.toLowerCase().trim() ===
              localStorage.userID?.toLowerCase().trim() &&
            contractIDs?.includes(el.contractNumber) &&
            !uniqueArr?.includes(String(el.contractNumber + el.officeLocation))
          ) {
            uniqueArr?.push(String(el.contractNumber + el.officeLocation));
            let contractHash = await hashText(el.contractNumber);
            const encodedContract = btoa(
              el.contractNumber + '||' + contractHash,
            );
            let obj = {
              ...el,
              uniqueLink:
                window.location.origin +
                UrlPath.endUserRegistration.replace(':id', encodedContract),
            };
            tempFilteredContracts.push(obj);
          }
        }),
      );

      // Get alternate CCAs for user's contracts
      let alternateCCAs = [];
      contractIDs.forEach((id) => {
        let matchingContracts = allContracts.filter(
          (contract) => contract.contractNumber === id,
        );
        let matchingEmails = matchingContracts
          .map((contract) => contract.email)
          .filter(
            (email) =>
              email?.toLowerCase().trim() !==
              localStorage.userID?.toLowerCase().trim(),
          );
        alternateCCAs[id] = matchingEmails;
      });

      // Add alternate CCAs to table data
      for (let i = 0; i < tempFilteredContracts.length; i++) {
        let altEmails = alternateCCAs[tempFilteredContracts[i].contractNumber];
        tempFilteredContracts[i].alternateCCAs = altEmails;
      }

      setUserDetails(tempUserDetails[0]);

      setFilteredContracts(tempFilteredContracts);
    } catch (err) {
    } finally {
      setLoading(false);
      gridRefContract?.current?.api?.hideOverlay();
    }
  };

  const getEntitlementsStatusCount = async () => {
    try {
      setLoading(true);
      gridRefContract?.current?.api?.showLoadingOverlay();
      let queryParams = {
        Filter: `status IN ("${PendingActive}","${PendingInactive}","${PendingApproval}") AND contractNumber in(${loggedInUserContractNumber})`,
        Field: 'count(status)',
        groupBy: 'status',
        pageSize: 50,
        id: String(Date.now()),
      };

      const entitlementResponse = await httpService.get(
        Endpoints.userentitlementsApi,
        queryParams,
      );

      let statusWithCount = {
        pending_active: 0,
        pending_inActive: 0,
        pending_approval: 0,
        totalPendingActAndInActEnt: 0,
      };

      entitlementResponse?.data?.aggResultValue?.forEach((obj) => {
        if (obj.status === PendingActive)
          statusWithCount.pending_active = obj.count;
        else if (obj.status === PendingInactive)
          statusWithCount.pending_inActive = obj.count;
        else if (obj.status === PendingApproval)
          statusWithCount.pending_approval = obj.count;
        if ([PendingActive, PendingInactive].includes(obj.status))
          statusWithCount.totalPendingActAndInActEnt += obj.count;
      });

      setEntitlementsData(statusWithCount);
    } catch (err) {
    } finally {
      setLoading(false);
      gridRefContract?.current?.api?.hideOverlay();
    }
  };

  useEffect(() => {
    if (loggedInUserContractNumber) {
      getEntitlementsStatusCount();
    }
  }, [loggedInUserContractNumber]);

  const redirectToUserEntitlementsPage = (entitlementStatus) => {
    navigate(UrlPath.entitlements, { state: entitlementStatus });
  };

  const closeEmailModal = () => {
    setShowEmailModal(false);
  };

  // Ag Grid Table

  const defaultConfig = {
    rowModelType: 'clientSide',
    isExportCSV: false,
    isExportExcel: false,
    pivotPanelShow: '',
    suppressDragLeaveHidesColumns: true,
    pagination: false,
    isAutoSizeColumns: true,
    enableCharts: false,
    rowGroupPanelShow: false,
    sideBar: false,
    rowSelection: 'single',
    suppressRowClickSelection: false,
  };

  const defaultColdef = {
    resizable: true,
  };

  const columnFilterParams = {
    filter: 'agSetColumnFilter',
    sortable: true,
    enableRowGroup: false,
    enablePivot: false,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };

  // Contract admins grid column
  const contractAdminColumn = [
    {
      headerName: 'Contract ID',
      headerTooltip: 'Contract ID',
      field: 'contractNumber',
      tooltipField: 'contractNumber',
      initialFlex: 1.6,
      cellRenderer: (params) => {
        if (params.value != null) {
          return (
            <div
              className="email-label"
              title={params.value}
              onClick={() => {
                setSelectedRow(params);
                setShowContractDescriptionModal(true);
              }}
            >
              {params.value}
            </div>
          );
        } else return '';
      },
      ...columnFilterParams,
    },
    {
      headerName: 'Contract Description',
      headerTooltip: 'Contract Description',
      field: 'contractDescription',
      tooltipField: 'contractDescription',
      initialFlex: 3,
      ...columnFilterParams,
    },
    {
      headerName: 'Alternate CCAs',
      headerTooltip: 'Alternate CCAs',
      field: 'alternateCCAs',
      tooltipField: 'alternateCCAs',
      initialFlex: 3,
      ...columnFilterParams,
    },
    {
      headerName: 'Allowed Email Domains',
      headerTooltip: 'Allowed Email Domains',
      field: 'emailDomain',
      tooltipField: 'emailDomain',
      initialFlex: 3,
      ...columnFilterParams,
    },
    {
      headerName: 'Invite Users',
      headerTooltip: 'Invite Users',
      field: 'uniqueLink',
      tooltipField: '',
      initialFlex: 1.2,
      ...columnFilterParams,
      filter: false,
      sortable: false,
      cellRenderer: (params) => {
        return (
          <>
            <div className="link-block">
              <CopyLinkCell value={params.value} />
              <span
                onClick={() => {
                  setShowEmailModal(true);
                  setClickedRowData(params.data);
                }}
              >
                <img
                  className="cursor-pointer"
                  src={copy_icon}
                  width={18}
                  height={18}
                  title="Click here to send registration invite to end users"
                />
              </span>
            </div>
          </>
        );
      },
    },
    {
      headerName: 'Approval Required',
      headerTooltip: 'Approval Required',
      field: 'isRestricted',
      initialFlex: 1.5,
      ...columnFilterParams,
      menuTabs: ['generalMenuTab'],

      cellRenderer: (params) => {
        let switchElement = document.getElementById(params.data.contractNumber);
        if (switchElement)
          switchElement.checked = params.data && params.data.isRestricted === 1;
        return (
          <>
            <div className="form-check form-switch form-switch-custom">
              <input
                type="checkbox"
                role="switch"
                name="isRestricted"
                id={params.data.contractNumber}
                className="form-check-input form-check-input-custom"
                onChange={saveApprovalStatusDebounce}
                defaultChecked={params.data && params.data.isRestricted === 1}
                prevchecked={(
                  params.data && params.data.isRestricted === 1
                ).toString()}
              />
            </div>
          </>
        );
      },
    },
  ];

  const saveApprovalStatus = (e) => {
    const { checked, id: contractNumber, prevChecked } = e.target;

    // To avoid extra API calls for same status
    if (prevChecked !== checked) {
      // set new value for previous checked state
      let switchElement = document.getElementById(contractNumber);

      switchElement.disabled = true;

      const postData = {
        contractNumber: contractNumber,
        userId: UserId,
        isRestricted: checked ? 1 : 0,
      };

      httpService
        .post(Endpoints.contractApi, postData)
        .then((resp) => {
          if (resp?.data !== 0) throw 'error';
          else switchElement.prevChecked = checked;
        })
        .catch((err) => {
          switchElement.checked = prevChecked;
          Notify({
            alert: true,
            type: 'error',
            title: GenericError.somethingWentWrong,
          });
        })
        .finally(() => (switchElement.disabled = false));
    }
  };

  const saveApprovalStatusDebounce = debounce(saveApprovalStatus, 300);

  const onGridReadyContract = (params) => {
    gridRefContract.current = params;
  };

  const updateUserEmailNotification = (checked) => {
    try {
      if (profileUser?.contactId) {
        setLoading(true);
        let User_Info = {
          ...profileUser,
          isEmailOptOut: checked ? 1 : 0,
          userid: UserId,
          isDeleted: 0,
        };

        let postData = {};
        for (let key in User_Info) {
          if (typeof User_Info[key] === 'string') {
            postData[key] = User_Info[key].trim();
          } else {
            postData[key] = User_Info[key];
          }
        }
        httpService
          .post(Endpoints.userApi, [postData])
          .then((resp) => {
            if (resp?.data?.code === 0) {
              //after success
              Notify({
                alert: true,
                type: 'success',
                title: 'User has been updated',
              });
            }
          })
          .catch((err) => {
            let errorMsg =
              err?.response?.data?.resultCode === '-2'
                ? err?.response?.data?.cause
                : GenericError.somethingWentWrong;
            Notify({
              alert: true,
              type: 'error',
              title: GenericError.somethingWentWrong,
            });
            setIsEmailOpt(!checked);
          })
          .finally(() => setLoading(false));
      } else {
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const emailNotificationChangeHandler = (event) => {
    let checked = event.target.checked;

    Notify({
      alert: true,
      type: '',
      title: `Do you want to opt ${
        checked ? 'out of' : 'into'
      } email notifications?`,
      confirmBtnText: 'Yes',
      cancelBtnText: 'No',
      showCloseBtn: false,
      onConfirm: () => updateUserEmailNotification(checked),
      onCancel: () => {
        setIsEmailOpt(!checked);
      },
    });

    setIsEmailOpt(checked);
  };

  const closeContractDescriptionModal = () => {
    setShowContractDescriptionModal(false);
  };

  const saveContractDescriptionModal = (newDesc) => {
    // clear stored contracts to refresh dropdown values
    dispatch(addContract([]));

    const updatedData = filteredContracts.map((row, index) =>
      index === selectedRow.rowIndex
        ? { ...row, contractDescription: newDesc }
        : row,
    );

    if (updatedData && updatedData.length > 0) {
      const allContractsHaveDescription = updatedData.every(
        (contract) =>
          contract.contractDescription &&
          contract.contractDescription.trim().length > 0,
      );

      // reset display option dropdown to default and update user preference if any contracts are missing descriptions
      if (
        !allContractsHaveDescription &&
        formik.values.contractDisplayOption == 'ContractDescription'
      ) {
        let notifyConfig = {
          confirmBtnText: 'Ok',
          onConfirm: () => resetDisplayOption(updatedData),
          cancelBtnText: 'Cancel',
          onCancel: () => setLoading(false),
        };
        Notify({
          alert: true,
          type: 'warning',
          title: Verbiage.resetDisplayOption,
          dialogClassName: 'resetDisplayOption-alert',
          ...notifyConfig,
        });
      } else {
        setFilteredContracts(updatedData);
        setLoading(false);
        setShowContractDescriptionModal(false);
      }
    }
  };

  const resetDisplayOption = (updatedData) => {
    formik.setFieldValue('contractDisplayOption', 'ContractNumber');

    let queryParams = {
      email: localStorage.userID,
      preferencekey: contractDisplayOptionKey,
      isactive: 1,
      preferencevalue: 'ContractNumber',
    };

    httpService
      .post(Endpoints.userPreferenceApi, queryParams)
      .then((resp) => {
        let storeData = store.getState();
        let storeUserInfo = storeData?.userInfo.userInfo;
        storeUserInfo.userPreferences = [
          {
            preferenceKey: contractDisplayOptionKey,
            preferenceValue: ContractDisplayOptions[0].contractDisplayOption,
          },
        ];
        dispatch({ type: ReduxAction.userInfoSet, payload: storeUserInfo });
        if (resp.data == 0) {
          // display option successfully saved
          Notify({
            alert: true,
            type: 'success',
            title: 'Your contract description has been saved.',
          });
        }
      })
      .catch((err) => {});

    setFilteredContracts(updatedData);
    setLoading(false);
    setShowContractDescriptionModal(false);
  };

  return (
    <>
      {showContractDescriptionModal && (
        <div>
          <ContractDescriptionModal
            handleClose={closeContractDescriptionModal}
            onSave={saveContractDescriptionModal}
            setLoading={setLoading}
            data={selectedRow.data}
            allContracts={filteredContracts}
          ></ContractDescriptionModal>
        </div>
      )}
      {showEmailModal && (
        <div>
          <AddUserRegistration
            handleClose={closeEmailModal}
            clickedRowData={clickedRowData}
          ></AddUserRegistration>
        </div>
      )}
      {loading ? (
        <Loader type="scaleLoader" />
      ) : (
        <>
          <div className="content mx-3">
            <div className="container-fluid">
              <Row>
                <Col sm={12}>
                  <Col sm={12} className="user-right-section px-3 py-2">
                    <div className="d-flex">
                      <img
                        src={bell_icon}
                        className="user-bell-icon img-fluid rounded-circle"
                        alt="Admin"
                      />
                      {entitlementsData?.totalPendingActAndInActEnt >= 0 ? (
                        <div className="user-history-wrapper">
                          <span className="user-history-title">
                            There are{' '}
                            <span
                              className="spg-red cursor-pointer"
                              onClick={() =>
                                redirectToUserEntitlementsPage([
                                  PendingActive,
                                  PendingInactive,
                                ])
                              }
                            >
                              {entitlementsData?.totalPendingActAndInActEnt}{' '}
                              entitlement changes
                            </span>{' '}
                            pending
                          </span>
                        </div>
                      ) : (
                        <span className="text-center w-25">
                          <Loader type="scaleLoaderDropdown" />
                        </span>
                      )}
                    </div>
                  </Col>
                  {entitlementsData?.pending_approval > 0 && (
                    <Col sm={12} className="user-right-section mt-3 px-3 py-2">
                      <div className="d-flex">
                        <img
                          src={bell_icon}
                          className="user-bell-icon img-fluid rounded-circle"
                          alt="Admin"
                        />

                        <div className="user-history-wrapper">
                          <span className="user-history-title">
                            There are new entitlement requests pending approval.{' '}
                            <span
                              className="spg-red cursor-pointer"
                              onClick={() =>
                                redirectToUserEntitlementsPage([
                                  PendingApproval,
                                ])
                              }
                            >
                              Click here
                            </span>{' '}
                            to view and approve
                          </span>
                        </div>
                      </div>
                    </Col>
                  )}
                  <Row>
                    <Col sm={3} className="user-sub-header mt-2">
                      Contracts
                    </Col>
                    <Col
                      sm={9}
                      className="mt-3 d-flex align-items-center justify-content-end"
                    >
                      <span className="me-2 fw-bolder">
                        Contract ID and Description display options across
                        Subscription Manager:
                      </span>
                      <div
                        role="group"
                        aria-labelledby="contractDisplayOptionGroup"
                        className="d-flex align-items-center"
                      >
                        {ContractDisplayOptions.map((option, index) => (
                          <div
                            key={option.contractDisplayOption}
                            className="form-check"
                          >
                            <input
                              type="radio"
                              id={`contractDisplayOption_${index}`}
                              name="contractDisplayOption"
                              value={option.contractDisplayOption}
                              className="form-check-input"
                              checked={
                                formik.values.contractDisplayOption
                                  ? formik.values.contractDisplayOption ===
                                    option.contractDisplayOption
                                  : index === 0
                              }
                              onChange={(e) => {
                                onChangeHandler(e, 'contractDisplayOption');
                              }}
                            />
                            <label
                              htmlFor={`contractDisplayOption_${index}`}
                              className="form-check-label"
                            >
                              {option.contractDisplayOptionLabel}
                            </label>
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                  <Col sm={12}>
                    <div className="ag-grid-table user">
                      <AgGridComponent
                        config={defaultConfig}
                        defaultColumnDef={defaultColdef}
                        data={filteredContracts}
                        columns={contractAdminColumn}
                        onGridReady={onGridReadyContract}
                      />
                    </div>
                  </Col>
                </Col>
              </Row>
              <Row>
                <Col sm={12} className="mt-2">
                  <div className="d-flex">
                    <Form.Check type="checkbox" className="user-history-title">
                      <Form.Check.Input
                        type={'checkbox'}
                        onChange={emailNotificationChangeHandler}
                        checked={isEmailOpt}
                      />
                      <Form.Check.Label>
                        <div className="label-mt-2 mb-2">
                          Marketing Email Opt-Out : You will not receive S&P
                          Global Commodity Insights promotional emails,such as
                          insightful content, event invitations and product
                          information.
                        </div>
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                </Col>
              </Row>
              <div className="dashboard-header">
                <Col sm={3}>
                  <div
                    className="user-name-wrapper"
                    title={
                      userDetails?.contactName +
                      '  (' +
                      UserRole[userDetails?.isContractAdmin ?? 1] +
                      ')'
                    }
                  >
                    {userDetails?.contactName}
                    <span className="user-role">
                      ({UserRole[userDetails?.isContractAdmin ?? 1]})
                    </span>
                  </div>
                </Col>
                <Col sm={9}>
                  <div className="user-clickable-content">
                    <div className="user-content-wrapper col-sm-3">
                      <span className="user-key">Email</span>
                      <span className="user-value" title={userDetails?.email}>
                        {userDetails?.email}
                      </span>
                    </div>
                    <div className="user-content-wrapper col-sm-6">
                      <span className="user-key">Location</span>
                      <span
                        className="user-value"
                        title={userDetails?.officeLocation}
                      >
                        {userDetails?.officeLocation}
                      </span>
                    </div>
                    <div className="user-content-wrapper col-sm-3">
                      <span className="user-key">Support</span>
                      <span
                        className="user-value support"
                        title="Contact CI Support"
                      >
                        <a
                          href="mailto:CI.support@spglobal.com"
                          className="user-blue"
                          target="_blank"
                        >
                          Contact CI Support
                        </a>
                      </span>
                    </div>
                  </div>
                </Col>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UserDashboard;
