import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Loader from '../../components/Loader';
import { Notify } from '../../sharedcomponents/Alert/Notify';
import TileRepeater from '../../sharedcomponents/tiles/tile_repeater';
import {
  AutoSelectPkgDist,
  Endpoints,
  GenericError,
  ReduxAction,
  contractTypeCSMPlus,
  distributorDatasetProps,
} from './../../constants';
import store from './../../redux/store';
import HttpService from './../../services/http-service';
import {
  distributorImageNames,
  distributorImageNamesCSMPlus,
} from './distributorImages';

const Distributors = ({
  searchText,
  subscriptionType,
  isAllPrimaryContracts,
}) => {
  const [distributors, setDistributors] = useState([]);
  const [isShowAll, setIsShowAll] = useState(false);
  const [filteredDistributors, setFilteredDistributors] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const setInitialScroll = () => {
    let storeData = store.getState();
    let distributorStoreDetails = storeData?.packageDetails;

    if (distributorStoreDetails?.tabType?.tabId === 2) {
      setTimeout(() => {
        if (!document.getElementById(distributorStoreDetails?.tabType?.id)) {
          setIsShowAll(true);
        } else {
          setIsShowAll(false);
        }
      }, 100);

      if (distributorStoreDetails?.tabType?.id) {
        setTimeout(() => {
          if (document.getElementById(distributorStoreDetails?.tabType?.id)) {
            document
              .getElementById(distributorStoreDetails?.tabType?.id)
              .scrollIntoView({ block: 'center' });
          }
        }, 200);
      }
    }
  };
  useEffect(() => {
    let storeData = store.getState();
    let storeDistributors = storeData?.distributorsDetails?.distributorDetails;
    if (storeDistributors) {
      // Filter data if all primary contracts
      if (isAllPrimaryContracts)
        storeDistributors = storeDistributors.filter(
          (el) =>
            el?.distributorPlatformDisplayName ===
            AutoSelectPkgDist.Market_Basics.distPlatform,
        );
      setDistributors(storeDistributors);
      setLoading(false);
      setInitialScroll();
    } else {
      getDistributors();
    }
  }, []);

  useEffect(() => {
    let tempDistributorDataset =
      subscriptionType === ''
        ? distributors
        : distributors.filter((el) => el?.contractType === subscriptionType);

    let filteredDist =
      searchText?.length >= 2
        ? tempDistributorDataset.filter((dataSet) =>
            distributorDatasetProps.some((prop) =>
              (dataSet[prop]?.toString() || '')
                .toLowerCase()
                .includes(searchText.toLowerCase()),
            ),
          )
        : tempDistributorDataset;
    setFilteredDistributors(filteredDist);
  }, [distributors, searchText, subscriptionType]);

  const getDistributors = () => {
    HttpService.get(Endpoints.distributorApi)
      .then((res) => {
        const keys = Object.keys(distributorImageNames);
        let dataset = [];
        res.data?.results?.forEach((el, index) => {
          if (el.contractType) {
            let NewDataSet = {
              BG_img:
                el.contractType == contractTypeCSMPlus
                  ? distributorImageNamesCSMPlus[el.distributorPlatform] ||
                    distributorImageNames[keys[index % keys.length]]
                  : distributorImageNames[keys[index % keys.length]],
              tile_title: '',
              tile_main_heading:
                el.distributorDisplayName +
                ' - ' +
                el.distributorPlatformDisplayName,
              tile_description: el.platformDescription
                .replace(/<p>|<\/p>|<div>|<\/div>/g, '')
                .trim(),
              keyUseCases: el.keyUseCases,
              recentlyAdded: el.recentlyAdded,
              recentlyEnhanced: el.recentlyEnhanced,
              longDescription: `<p>${el.distributorDescription}</p><p>${el.platformDescription}</p>`, //el.marketplaceLongDescription.replaceAll('"', '')
              dataProviderDesc: '',
              name: el.distributor + '-' + el.distributorPlatform,
              entityType: 'distributor',
              ...el,
              contractType: el?.contractType,
            };
            dataset.push(NewDataSet);
          }
        });
        // Filter data if all primary contracts
        if (isAllPrimaryContracts)
          dataset = dataset?.filter(
            (el) =>
              el?.distributorPlatformDisplayName ===
              AutoSelectPkgDist.Market_Basics.distPlatform,
          );
        setDistributors(dataset);
        setInitialScroll();
        store.dispatch({
          type: ReduxAction.distributorsDetail,
          payload: { distributorDetails: dataset },
        });
      })
      .catch((err) => {
        if (err.code !== 'ERR_CANCELED') {
          Notify({
            alert: true,
            type: 'error',
            title: GenericError.somethingWentWrong,
          });
        }
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      {loading ? (
        <Loader type="scaleLoader" />
      ) : (
        <Container fluid className="ms-2">
          {distributors.length > 0 && filteredDistributors?.length > 0 ? (
            <TileRepeater
              DataSet={filteredDistributors}
              initialTile={8}
              showAll={isShowAll}
            />
          ) : (
            <div className="search-results">
              <h2>No distributors found</h2>
              <ul>
                <li>Try changing or using less filter criteria</li>
                <li>Try fewer search keywords</li>
                <li>Make sure your search keywords are spelled correctly</li>
              </ul>
            </div>
          )}
        </Container>
      )}
    </>
  );
};
export default Distributors;
