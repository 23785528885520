import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import AgGridComponent from '../../../sharedcomponents/ag-grid/AgGrid';
import {
  ProductDeliveryPlatforms,
  ReduxAction,
  AutoSelectPkgDist,
} from './../../../constants';
import DistributorsDropdown from './../../Entitlements/DistributorsDropdown';
import PackagesDropdown from './../../Entitlements/PackagesDropdown';
import { ReactComponent as CloneIcon } from './../../../assets/icons/common/clone.svg';
import CloneUserGroupEntitlements from './../../user-group/clone-user-group-entitlements/clone-user-group-entitlements';
import { Notify } from './../../../sharedcomponents/Alert/Notify';

const UserGroupEntitlements = memo(
  ({
    editData,
    distributorDataSet,
    entitlementMappingData,
    getEntitlementMappings,
    userEntitlementsData,
    packageDataSet,
    getAllUserEntitlements,
    onSaveClick,
    isViewerUser,
  }) => {
    //variable declarations
    const gridApi = useRef(null);
    const [filteredPackages, setFilteredPackages] = useState();
    const [filteredDistributors, setFilteredDistributors] = useState();
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentlySelectedEntitlements, setCurrentlySelectedEntitlements] =
      useState([]); // set value for default selection
    const dispatch = useDispatch();
    const [isRowDataLoaded, setIsRowDataLoaded] = useState(false);
    const [initialEntitlements, setInitialEntitlements] = useState([]);
    const [entitlementsChanged, setEntitlementsChanged] = useState(false);
    const [selectedPackages, setSelectedPackages] = useState([]);
    const [selectedParentCascade, setSelectedParentCascade] = useState();
    const [selectedDistributorsId, setSelectedDistributorsId] = useState([]);
    const [selectedDistributors, setSelectedDistributors] = useState([]);
    const [deselectRowsData, setDeselectRowsData] = useState([]);
    const [initialEntitlementIds, setInitialEntitlementIds] = useState([]);
    const [showCloneUserGroupEntitlements, setShowCloneUserGroupEntitlements] =
      useState(false);

    const defaultConfig = {
      rowModelType: 'clientSide',
      isExportCSV: false,
      isExportExcel: false,
      pivotPanelShow: '',
      suppressDragLeaveHidesColumns: true,
      pagination: false,
      isAutoSizeColumns: true,
      enableCharts: false,
      rowGroupPanelShow: false,
      sideBar: false,
      overlayNoRowsTemplate: 'This user group has no entitlements',
    };
    const isAllSelect = (params) => {
      let displayedColumns = params.api.getAllDisplayedColumns();
      let thisIsFirstColumn = displayedColumns[0] === params.column;
      if (thisIsFirstColumn) {
        return thisIsFirstColumn;
      }
    };

    const isFirstColumn = (params) => {
      let displayedColumns = params.api.getAllDisplayedColumns();
      let thisIsFirstColumn = displayedColumns[0] === params.column;
      if (thisIsFirstColumn) {
        return thisIsFirstColumn;
      }
    };
    const defaultColdef = {
      sortable: true,
      resizable: true,
      headerCheckboxSelectionFilteredOnly: true,
      headerCheckboxSelection: !isViewerUser && isAllSelect,
      checkboxSelection: !isViewerUser && isFirstColumn,
      menuTabs: ['generalMenuTab', 'filterMenuTab'],
    };
    const distributorGetter = (params) => {
      if (params?.data)
        return (
          params?.data?.distributor +
          ' : ' +
          params?.data?.distributorPlatform +
          ' (' +
          params?.data?.deliveryType +
          ')'
        );

      return '';
    };

    const objectsEqual = (o1, o2) =>
      Object.keys(o1).length === Object.keys(o2).length &&
      Object.keys(o1).every((p) => o1[p] === o2[p]);

    const getColumnClass = (params) => {
      if (
        params?.node?.data?.isDeleted == undefined &&
        params?.node?.selected &&
        isRowDataLoaded
      ) {
        return 'new-selected-entitlement-row';
      }
      if (
        params?.node?.data?.isDeleted != undefined &&
        params?.node?.data?.isDeleted === 0 &&
        !params?.node?.selected &&
        isRowDataLoaded
      ) {
        return 'unselected-entitlement-row';
      } else {
        return '';
      }
    };
    const groupEntitlementsColumns = [
      {
        headerName: '',
        field: 'id',
        resizable: true,
        filter: 'agTextColumnFilter',
        suppressHeaderMenuButton: true,
        sortable: false,
        hide: true,
      },
      {
        headerName: 'Package',
        headerTooltip: 'Package',
        field: 'packageName',
        tooltipField: 'packageName',
        initialFlex: 3,
        cellClass: getColumnClass,
      },
      {
        headerName: 'Delivery Platform (Type)',
        headerTooltip: 'Delivery Platform (Type)',
        field: 'deliveryPlatform',
        initialFlex: 3,
        valueGetter: distributorGetter,
        tooltipValueGetter: distributorGetter,
        cellClass: getColumnClass,
      },
    ];
    const onSelectionChanged = () => {
      gridApi.current.api.refreshCells();

      let currentlySelected = [];
      gridApi.current.api.getSelectedNodes().forEach((node) => {
        currentlySelected.push(node.data);
      });
      setCurrentlySelectedEntitlements(currentlySelected);
      setTimeout(() => {
        let postData = getPostEntitlementChanges();

        dispatch({
          type: ReduxAction.updateUGEntitlement,
          payload: {
            entitlementsToSave: postData,
            initialEntitlements: initialEntitlements,
            selectedEntitlements: currentlySelected,
          },
        });
      }, 300);
    };

    const getPostEntitlementChanges = () => {
      try {
        let groupId = editData?.groupId;
        let entitlementChanges = [];
        if (groupId) {
          gridApi.current.api.forEachNode((node) => {
            // remove entitlements that aren't selected
            if (node.data?.isDeleted === 0 && !node.selected) {
              entitlementChanges.push({
                groupId: groupId,
                productcomponentid: node.data.productComponentId,
                isDeleted: 1,
              });
            }
            // add entitlements that are selected
            if (node.data?.isDeleted == undefined && node.selected) {
              entitlementChanges.push({
                groupId: groupId,
                productcomponentid: node.data.productComponentId,
                isDeleted: 0,
              });
            }
          });
        }
        return entitlementChanges;
      } catch (err) {}
    };

    //useeffect starts
    useEffect(() => {
      const reduceCallback = setTimeout(() => {
        if (rowData?.length > 0) {
          setSelectionsInGrid();
        } else {
          if (rowData?.length === 0 && initialEntitlements?.length === 0) {
            setEntitlementsChanged(false);
          }
        }
      }, 100);
      return () => clearTimeout(reduceCallback);
    }, [rowData]);

    useEffect(() => {
      // Set initial state
      if (packageDataSet) setFilteredPackages(packageDataSet);
      if (distributorDataSet) setFilteredDistributors(distributorDataSet);
    }, [packageDataSet, distributorDataSet]);
    // filter and display user's initial entitlements
    useEffect(() => {
      if (
        entitlementMappingData &&
        userEntitlementsData.length > 0 &&
        gridApi?.current
      ) {
        let filtered = entitlementMappingData.filter(function (el) {
          return (
            el.groupId === editData.groupId &&
            !ProductDeliveryPlatforms.includes(el.distributor)
          );
        });

        if (filtered?.length === 0) {
          setIsRowDataLoaded(true);
          gridApi.current.api.showNoRowsOverlay();
        } else {
          gridApi.current.api.hideOverlay();
        }

        let ids = [];
        filtered.forEach((initialEntitlement) => {
          ids.push(initialEntitlement.productComponentId);
        });

        setInitialEntitlements(filtered);
        setInitialEntitlementIds(ids);
        setCurrentlySelectedEntitlements(filtered);
        setRowData(filtered);
        setLoading(false);
        dispatch({
          type: ReduxAction.updateUGEntitlement,
          payload: {
            initialEntitlements: filtered,
          },
        });
      }
    }, [userEntitlementsData, entitlementMappingData]);

    useEffect(() => {
      if (gridApi.current) {
        if (loading) {
          gridApi.current.api.showLoadingOverlay();
        } else {
          if (rowData.length === 0) {
            gridApi.current.api.showNoRowsOverlay();
          } else {
            gridApi.current.api.hideOverlay();
          }
        }
      }
    }, [loading, rowData]);

    useEffect(() => {
      if (objectsEqual(currentlySelectedEntitlements, initialEntitlements)) {
        setEntitlementsChanged(false);
      } else {
        setEntitlementsChanged(true);
      }
    }, [currentlySelectedEntitlements]);
    useEffect(() => {
      if (selectedParentCascade === 'PackageDropdown' && userEntitlementsData) {
        // Filter distributor based on package selection
        let filterData = userEntitlementsData.filter((el) =>
          selectedPackages.includes(el.packageName),
        );
        setFilteredDistributors({ results: filterData });
      }
    }, [selectedPackages]);

    useEffect(() => {
      if (
        selectedParentCascade === 'DistributorDropdown' &&
        userEntitlementsData
      ) {
        // Filter packages based on distributor selection and contract number
        let filterData = userEntitlementsData.filter((el) => {
          return (
            selectedDistributorsId.includes(el.distributorId) &&
            editData?.contractNumber === el.contractNumber
          );
        });
        setFilteredPackages({ results: filterData });
      }
    }, [selectedDistributorsId]);

    const cascadingPackageDistributor = () => {
      // On empty selection, set to default data
      if (
        selectedPackages.length === 0 &&
        selectedParentCascade === 'PackageDropdown'
      ) {
        setFilteredDistributors(distributorDataSet);
      } else if (
        selectedDistributorsId.length === 0 &&
        selectedParentCascade === 'DistributorDropdown'
      ) {
        setFilteredPackages(packageDataSet);
      }

      // Cascading functionality to set which dropdown is parent
      if (selectedPackages.length === 0 && selectedDistributors.length === 0) {
        setSelectedParentCascade();
      } else if (!selectedParentCascade) {
        if (selectedPackages.length > 0) {
          setSelectedParentCascade('PackageDropdown');
        }
        if (selectedDistributors.length > 0) {
          setSelectedParentCascade('DistributorDropdown');
        }
      }
    };

    // Memoized selection to stop loop from dropdown callback
    const cascadingPackageDistributorMemo = useMemo(
      cascadingPackageDistributor,
      [selectedPackages, selectedDistributors],
    );

    useEffect(() => {
      // Reduce callback onchange of contract selection
      const reduceCallback = setTimeout(() => {
        // Cartesian Start

        if (
          selectedPackages?.length > 0 ||
          selectedDistributors?.length > 0 ||
          (selectedPackages?.length === 0 && selectedDistributors?.length === 0)
        ) {
          let newSelectedDistributors = [...selectedDistributors];
          if (selectedPackages.length > 0 && isAnyAutoSelectPkg()) {
            newSelectedDistributors = [
              ...selectedDistributors,
              AutoSelectPkgDist.Market_Basics.distName,
            ];
          }
          let cartesian = (...selectedPackages) =>
            selectedPackages.reduce(
              (selectedPackages, newSelectedDistributors) =>
                selectedPackages.flatMap((d) =>
                  newSelectedDistributors.map((e) => [d, e].flat()),
                ),
            );
          let result = cartesian(selectedPackages, newSelectedDistributors);
          let newRows = [];

          result.forEach((product) => {
            let splitDist = product[1].split(':');
            for (let i = 0; i < userEntitlementsData.length; i++) {
              // check cartesian match
              if (
                userEntitlementsData[i].packageName === product[0] &&
                userEntitlementsData[i].distributor === splitDist[0] &&
                userEntitlementsData[i].distributorPlatform === splitDist[1] &&
                userEntitlementsData[i].deliveryType === splitDist[2] &&
                userEntitlementsData[i].contractNumber ===
                  editData?.contractNumber &&
                (userEntitlementsData[i].packageName.includes(
                  AutoSelectPkgDist.Market_Basics.pkgName,
                ) ||
                  selectedDistributorsId.some(
                    (id) => id == userEntitlementsData[i].distributorId,
                  ))
              ) {
                newRows.push(userEntitlementsData[i]);
              }
            }
          });
          // filter out duplicate rows
          initialEntitlementIds.forEach((id) => {
            let nodeIndex = newRows.findIndex(
              (nid) => nid.productComponentId === id,
            );
            if (nodeIndex !== -1) {
              newRows.splice(nodeIndex, 1);
            }
          });
          //set deselected rows from previous state
          let selectedRows = gridApi?.current?.api?.getSelectedRows();
          if (rowData?.length > 0) {
            let deselectedRows =
              selectedRows?.length > 0
                ? rowData.filter(
                    (x) =>
                      !selectedRows.some(
                        (s) => s.productComponentId === x.productComponentId,
                      ),
                  )
                : rowData;
            setDeselectRowsData(deselectedRows);
          } else {
            setDeselectRowsData([]);
          }

          let finalRows = [];
          // ensure new entitlements remain top of the grid
          newRows.forEach((row) => {
            finalRows.push(row);
          });
          initialEntitlements.forEach((initialEntitlement) => {
            finalRows.push(initialEntitlement);
          });
          setRowData(finalRows);
        }
      }, 100);
      return () => clearTimeout(reduceCallback);
    }, [selectedPackages, selectedDistributors]);
    //useeffect ends

    const isAnyAutoSelectPkg = () => {
      try {
        let isAutoSelect = false;
        const marketBasicsSelected = selectedPackages.some((pkg) =>
          pkg.includes(AutoSelectPkgDist.Market_Basics.pkgName),
        );

        // Check if Market Basics is selected
        if (marketBasicsSelected) {
          const plattsConnectSelected = selectedDistributorsId.find(
            (dstId) => dstId == AutoSelectPkgDist.Market_Basics.distId,
          );
          // Platts Connect is not selected
          if (!plattsConnectSelected) {
            isAutoSelect = true;
          }
        }
        return isAutoSelect;
      } catch (error) {}
    };

    const callBackPackages = (params) => {
      let pkgs = [];
      params.forEach((pkg) => {
        pkgs.push(pkg.packageName);
      });
      setSelectedPackages(pkgs);
    };
    const callBackDistributors = (params) => {
      let dsts = [];
      let dstsId = [];
      params.forEach((dst) => {
        dsts.push(dst.distributor);
        dstsId.push(dst.distributorId);
      });
      setSelectedDistributors(dsts);
      setSelectedDistributorsId(dstsId);
    };

    const onEntitlementGridReadyHandler = (params) => {
      gridApi.current = params;
      params.api.showLoadingOverlay();

      if (!packageDataSet?.results?.length > 0) {
        getAllUserEntitlements(editData?.contractNumber);
        getEntitlementMappings();
      }
    };

    const setSelectionsInGrid = () => {
      gridApi.current.api.selectAll();
      const nodesToDeselect = [];

      if (isRowDataLoaded && deselectRowsData?.length > 0) {
        gridApi.current.api.forEachNode((node) => {
          if (
            deselectRowsData.some(
              (nd) => nd.productComponentId === node?.data?.productComponentId,
            )
          )
            nodesToDeselect.push(node);
        });
      }
      gridApi.current.api.setNodesSelected({
        nodes: nodesToDeselect,
        newValue: false,
      });
      setIsRowDataLoaded(true);
      setDeselectRowsData([]);
    };

    const onFirstEntitlementDataRendered = useCallback((params) => {
      setSelectionsInGrid();
    }, []);

    const userGroupCloneClickHandler = () => {
      setShowCloneUserGroupEntitlements(true);
    };

    const handleCloseCloneUserGroupEntitlements = () => {
      setShowCloneUserGroupEntitlements(false);
    };

    const handleSaveCloneEntitlements = (sourceEntitlements) => {
      let entitlementChanges = [];

      let groupInitialEntitlements = entitlementMappingData.filter(
        (el) => el.groupId === editData.groupId,
      );
      sourceEntitlements.forEach((ent) => {
        if (
          !groupInitialEntitlements?.some(
            (x) => x.productComponentId === ent.productComponentId,
          )
        ) {
          entitlementChanges.push({
            groupId: editData.groupId,
            productcomponentid: ent.productComponentId,
            isDeleted: 0,
          });
        }
      });
      if (entitlementChanges?.length > 0) {
        dispatch({
          type: ReduxAction.updateUGEntitlement,
          payload: {
            entitlementsToSave: entitlementChanges,
            selectedEntitlements: sourceEntitlements,
            isGroupCloneAction: true,
          },
        });
        onSaveClick();
      } else {
        Notify({
          alert: true,
          type: 'error',
          title: 'No new entitlements present in the source group',
        });
      }
    };

    return (
      <>
        {showCloneUserGroupEntitlements && (
          <CloneUserGroupEntitlements
            showCloneUserGroupEntitlements={showCloneUserGroupEntitlements}
            handleCloseCloneUserGroupEntitlements={
              handleCloseCloneUserGroupEntitlements
            }
            handleSaveClone={handleSaveCloneEntitlements}
            selectedToUserGroup={editData}
          />
        )}
        <Container className="pt-3">
          <Row>
            <Col className="col-5">
              <div>
                <PackagesDropdown
                  DataSet={filteredPackages}
                  callBackPackages={callBackPackages}
                />
              </div>
            </Col>
            <Col className="col-5">
              <div>
                <DistributorsDropdown
                  DataSet={filteredDistributors}
                  callBackDistributors={callBackDistributors}
                />
              </div>
            </Col>
            <div className="col col-2 action-item-bar">
              {!isViewerUser && (
                <span className="user-grid-icons icon-divider ">
                  <CloneIcon
                    className="icon-active clone-entitlements-icon"
                    alt="Clone entitlements from"
                    width="18"
                    title="Clone entitlements from"
                    onClick={userGroupCloneClickHandler}
                  ></CloneIcon>
                </span>
              )}
            </div>
          </Row>
          <Row>
            <div className="ag-grid-modal-content">
              <AgGridComponent
                config={defaultConfig}
                defaultColumnDef={defaultColdef}
                data={rowData}
                columns={groupEntitlementsColumns}
                onSelectionChanged={onSelectionChanged}
                onGridReady={onEntitlementGridReadyHandler}
                onFirstDataRendered={onFirstEntitlementDataRendered}
              />
            </div>
          </Row>

          <Row className="modal-row entitlement-fix">
            <Form>
              <Form.Group className="col-12 user-save-btn-body">
                <button
                  type="button"
                  disabled={!entitlementsChanged || isViewerUser}
                  onClick={onSaveClick}
                  className="ug-save-btn user-save-btn btn btn-dark float-end btn-opacity"
                >
                  {Boolean(editData) ? 'Update' : 'Save'}
                </button>
              </Form.Group>
            </Form>
          </Row>
        </Container>
      </>
    );
  },
  (op, np) =>
    op?.editData === np?.editData &&
    op?.entitlementMappingData === np?.entitlementMappingData &&
    op?.packageDataSet === np?.packageDataSet &&
    op?.userEntitlementsData === np?.userEntitlementsData,
);

export default UserGroupEntitlements;
