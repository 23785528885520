import React, { memo, useEffect, useState } from 'react';
import { Endpoints } from './../../constants';
import HttpService from './../../services/http-service';
import TreeMultiSelectDropdown from '../../sharedcomponents/tree-multi-select-dropdown/tree-multi-select-dropdown';
import { createTreeData } from '../../helpers/create-tree-json';

const PackagesDropdown = memo(
  ({ contracts, DataSet, callBackPackages, disabled = false }) => {
    const [packagesResult, setPackagesResult] = useState([]);
    const [productData, setProductData] = useState();
    const [pkgCount, setPkgCount] = useState(0);
    const parentKeys = ['packageCategory', 'packageLabel'];

    useEffect(() => {
      getPackagesDataSet();
    }, [contracts, DataSet]);

    const createKeyValuePair = (data = []) => {
      let newArr = data?.map((el) => {
        let arr = el?.packageName?.split(' - ', 2);
        let obj = { ...el, packageCategory: arr[0], packageLabel: arr[1] };
        return obj;
      });
      return newArr;
    };

    const getPackagesDataSet = async () => {
      try {
        if (!DataSet) {
          if (contracts?.length > 0 && contracts.some((el) => el.checked)) {
            let queryParamsPackage = {
              PageSize: 5000,
              Field: 'packageName,packageId',
              Filter: `contractNumber in(${contracts
                .filter((x) => x.checked)
                .map((t) => (t.id ? '"' + t.id + '"' : ''))
                .join(',')})`,
            };

            HttpService.get(Endpoints.contractPackagesApi, queryParamsPackage)
              .then((res) => {
                let tempState = res?.data?.results;
                tempState = tempState?.map((el, i) => ({
                  id: el.packageId ?? i,
                  checked: false,
                  ...el,
                }));

                // Create tree structured json
                let keyValuePair = createKeyValuePair(tempState)?.sort(
                  (a, b) =>
                    a[parentKeys[0]].localeCompare(b[parentKeys[0]]) ||
                    a[parentKeys[1]].localeCompare(b[parentKeys[1]]),
                );
                let treeData = createTreeData(keyValuePair, parentKeys);
                setProductData(treeData ?? []);
                setPackagesResult(tempState ?? []);
              })
              .catch((err) => {
                setProductData([]);
                setPackagesResult([]);
              });
          } else {
            setProductData();
            setPackagesResult([]);
          }
        } else {
          if (DataSet) {
            let tempState = DataSet?.results?.map((el, i) => {
              return { id: el.packageId ?? i, checked: false, ...el };
            });

            // Create tree structured json
            let keyValuePair = createKeyValuePair(tempState)?.sort(
              (a, b) =>
                a[parentKeys[0]].localeCompare(b[parentKeys[0]]) ||
                a[parentKeys[1]].localeCompare(b[parentKeys[1]]),
            );
            let treeData = createTreeData(keyValuePair, parentKeys);
            let sortedTreeData = treeData?.sort((a, b) =>
              a.label > b.label ? 1 : -1,
            );
            setProductData(sortedTreeData ?? []);
            setPackagesResult(tempState ?? []);
          }
        }
      } catch (err) {}
    };

    const handleSelection = (selectedTreeData) => {
      let uniqueArr = [];
      let updatedState = [];
      packagesResult?.forEach((obj) => {
        if (
          selectedTreeData?.some((el) => String(el) === String(obj.id)) &&
          !uniqueArr.some((el) => el === obj.id)
        ) {
          uniqueArr.push(obj.id);
          obj.checked = true;
          updatedState.push(obj);
        }
      });

      setPkgCount(selectedTreeData?.length);
      // return the selected packages to parent component
      callBackPackages(updatedState);
    };

    return (
      <TreeMultiSelectDropdown
        ddOptions={productData}
        getSelectedTreeData={handleSelection}
        enableSearch
        enableSelectAll
        ddPlaceHolder={
          <>
            Packages
            <span className={pkgCount === 0 ? 'd-none' : 'd-inline'}>
              ({pkgCount})
            </span>
          </>
        }
        disabled={disabled}
      />
    );
  },
  (op, np) => op?.contracts === np?.contracts && op?.DataSet === np?.DataSet,
);

export default PackagesDropdown;
