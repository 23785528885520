import React, { forwardRef } from 'react';
import './StickyTooltip.scss';

const StickyTooltip = forwardRef(({ text, onClose }, ref) => {
  return (
    <div
      className="sticky-tooltip"
      onClick={(e) => e.stopPropagation()}
      ref={ref}
    >
      <button className="close-button" onClick={onClose}>
        &times;
      </button>
      <div>{text}</div>
    </div>
  );
});

export default StickyTooltip;
