import React, { memo, useEffect, useState } from 'react';
import { createTreeData } from '../../helpers/create-tree-json';
import TreeMultiSelectDropdown from '../../sharedcomponents/tree-multi-select-dropdown/tree-multi-select-dropdown';
import { GenericName, ProductDeliveryPlatforms } from './../../constants';

const DistributorsDropdown = memo(
  ({ DataSet, Type, callBackDistributors, disabled = false }) => {
    const [distributorsResult, setDistributorsResult] = useState([]);
    const [treeDistributorsData, setTreeDistributorsData] = useState();
    const [dstCount, setDstCount] = useState(0);
    const CI_ParentKeys = ['distributorPlatform'];
    const CP_ParentKeys = ['distributor', 'distributorPlatform'];

    useEffect(() => {
      getDistributorsDataSet();
    }, [DataSet]);

    const getDistributorsDataSet = async () => {
      try {
        if (DataSet) {
          let finalDistributorsResult = await DataSet?.results?.map((el, i) => {
            if (
              el.platformCategories &&
              el.platformCategories !== '' &&
              el.distributor &&
              el.distributor !== '' &&
              el.distributorPlatform &&
              el.distributorPlatform !== '' &&
              el.deliveryType &&
              el.deliveryType !== ''
            )
              return {
                id: el.distributorId ?? i,
                checked: Type === 'ProductEntitlements',
                distributorLabel:
                  el.distributor + ' : ' + el.distributorPlatform,
                ...el,
              };
            else return;
          });

          if (Type !== 'ExportEntitlements') {
            finalDistributorsResult = finalDistributorsResult.filter((x) =>
              x
                ? Type === 'ProductEntitlements'
                  ? ProductDeliveryPlatforms.includes(x.distributor)
                  : !ProductDeliveryPlatforms.includes(x.distributor)
                : false,
            );
          }

          let CP_Distributor = finalDistributorsResult
            .filter(
              (el) => el.platformCategories === GenericName.channelPartners,
            )
            .sort(
              (a, b) =>
                a[CP_ParentKeys[0]].localeCompare(b[CP_ParentKeys[0]]) ||
                a[CP_ParentKeys[1]].localeCompare(b[CP_ParentKeys[1]]),
            );

          let CI_Distributor = finalDistributorsResult
            .filter(
              (el) => el.platformCategories === GenericName.commodityInsights,
            )
            .sort((a, b) =>
              a[CI_ParentKeys[0]].localeCompare(b[CI_ParentKeys[0]]),
            );
          let CP_TreeData = await createTreeData(
            CP_Distributor,
            CP_ParentKeys,
            'CP',
          );
          let CI_TreeData = await createTreeData(
            CI_Distributor,
            CI_ParentKeys,
            'CI',
          );

          let treeData = [];
          if (CI_TreeData?.length > 0)
            treeData.push({
              label: GenericName.commodityInsights,
              value: 'CI',
              children: CI_TreeData,
            });

          if (CP_TreeData?.length > 0)
            treeData.push({
              label: GenericName.channelPartners,
              value: 'CP',
              children: CP_TreeData,
            });

          setTreeDistributorsData(
            finalDistributorsResult.length > 0 ? treeData : [],
          );
          setDistributorsResult(finalDistributorsResult ?? []);
        }
      } catch (err) {}
    };

    const handleSelection = (selectedTreeData) => {
      let uniqueArr = [];
      let updatedState = [];
      distributorsResult?.forEach((obj) => {
        if (
          selectedTreeData?.some((el) => String(el) === String(obj.id)) &&
          !uniqueArr.some((el) => el === obj.id)
        ) {
          uniqueArr.push(obj.id);
          obj.checked = true;
          obj.distributor =
            obj.distributor +
            ':' +
            obj.distributorPlatform +
            ':' +
            obj.deliveryType;
          updatedState.push(obj);
        }
      });

      setDstCount(selectedTreeData?.length);
      // return the selected distributors to parent component
      callBackDistributors(updatedState);
    };

    return (
      <TreeMultiSelectDropdown
        ddOptions={treeDistributorsData}
        getSelectedTreeData={handleSelection}
        enableSearch
        enableSelectAll
        ddPlaceHolder={
          <>
            Delivery Platform
            <span className={dstCount === 0 ? 'd-none' : 'd-inline'}>
              ({dstCount})
            </span>
          </>
        }
        disabled={disabled}
      />
    );
  },
  (op, np) => op?.DataSet === np?.DataSet,
);

export default DistributorsDropdown;
