import { useFormik } from 'formik';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Form, Tab, Tabs, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import DropdownInput from '../../../sharedcomponents/DropdownInput/DropdownInput';
import TextFieldInput from '../../../sharedcomponents/TextFieldInput/TextFieldInput';
import ManageUserIds from '../manage-user-ids/manage-user-ids';
import UserEntitlements from '../user-entitlements/user-entitlements';
import UserProductEntitlements from '../user-product-entitlements/user-product-entitlements';
import WelcomeEmails from '../welcome-emails/welcome-emails';
import {
  Endpoints,
  GenericError,
  Verbiage,
  JobTitleCollection,
  UserId,
  maxPageSize,
  ReduxAction,
  queryParamFields,
  contractTypePrimary,
  ContractDisplayOptions,
} from './../../../constants';
import httpService from './../../../services/http-service';
import { Notify } from './../../../sharedcomponents/Alert/Notify';
import ModalBox from './../../../sharedcomponents/modal-box/ModalBox';
import store from './../../../redux/store';

const AddEditUser = ({
  show,
  contracts,
  handleClose,
  editData,
  setEditData,
  getUsers,
  accounts,
  handleCloneFromEditUser,
  setCloneEntitlementData,
  contractDisplayOption,
  isViewerUser,
}) => {
  const [loading, setLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [contractType, setContractType] = useState('');
  const [ristrictLocation, setRistrictLocation] = useState(false);
  const [isEntitlementEnabled, setIsEntitlementEnabled] = useState(false);
  const [isProductEnabled, setIsProductEnabled] = useState(false);
  const [isUserIdEnabled, setIsUserIdEnabled] = useState(false);
  const [isWelcomeEmailsEnabled, setIsWelcomeEmailsEnabled] = useState(false);
  const [officeLocationName, setOfficeLocationName] = useState([]);
  const [jobDesignationCollection, setJobDesignationCollection] = useState({});
  const [initialValue, setInitialValue] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    department: '',
    jobFunction: '',
    designation: '',
    accountId: '',
    officeLocationId: '',
    officeLocationName: '',
    country: '',
    contactId: '',
    isEmailOptOut: false,
  });
  const [packageDataSet, setPackageDataSet] = useState();
  const [distributorDataSet, setDistributorDataSet] = useState();
  const [distributorEntitlementTab, setDistributorEntitlementTab] = useState();
  const [userEntitlementsData, setUserEntitlementsData] = useState([]);
  const [entitlementMappingData, setEntitlementMappingData] = useState();

  const [activeTab, setActiveTab] = useState();
  const dispatch = useDispatch();
  const entitlementTabName = 'entitlements-tab';
  const productEntitlementTabName = 'product-entitlements-tab';
  const userIdTabName = 'manage-user-ids-tab';
  const welcomeEmailsTabName = 'welcome-emails-tab';

  useEffect(() => {
    // Controller to abort previous API calls if any ongoing flight
    const controller = new AbortController();
    const signal = controller.signal;
    setEditMode(Boolean(editData));

    if (editData) {
      setInitialValue({
        firstName: editData.firstName ?? '',
        lastName: editData.lastName ?? '',
        email: editData.email ?? '',
        phone: editData.phone ?? '',
        department: editData.department ?? '',
        jobFunction: editData.jobFunction ?? '',
        designation: editData.designation ?? '',
        accountId: editData.accountId ?? '',
        officeLocationId: editData.officeLocationId ?? '',
        officeLocationName: editData.officeLocation ?? '',
        country: editData.country ?? '',
        contactId: editData.contactId ?? '',
        isEmailOptOut: editData.isEmailOptOut == 1 ? true : false,
      });
      getLocations(editData.accountId, signal);
    }
    return () => {
      controller?.abort();
    };
  }, [editData]);

  useEffect(() => {
    // Controller to abort previous API calls if any ongoing flight
    const controller = new AbortController();
    const signal = controller.signal;
    getDeliveryPlatformData(signal);
    clearReduxStoreAddEditModal();
    return () => {
      controller?.abort();
    };
  }, []);

  useEffect(() => {
    let storeData = store.getState();
    // Retrieve contractType from storeData
    let contractType = storeData?.contract?.contracts[0]?.contractType;

    if (contractType) {
      setContractType(contractType);
    }
  }, [useSelector((x) => x.userContracts)]);

  const clearReduxStoreAddEditModal = () => {
    dispatch({
      type: ReduxAction.updateEntitlement,
      payload: {
        updatedEntitlements: {},
        initialEntitlements: [],
        selectedEntitlements: [],
      },
    });
    dispatch({
      type: ReduxAction.updateProductEntitlement,
      payload: {
        initialProductEntitlements: [],
        updatedProductEntitlements: [],
      },
    });
  };
  const validate = Yup.object({
    firstName: Yup.string()
      .trim()
      .max(80, 'Must be 80 characters or less')
      .required('Please enter first name to proceed')
      .matches('^[a-zA-Z ]*$', 'No numerical or symbolic values are accepted'),
    lastName: Yup.string()
      .trim()
      .max(80, 'Must be 80 characters or less')
      .required('Please enter last name to proceed')
      .matches('^[a-zA-Z ]*$', 'No numerical or symbolic values are accepted'),
    email: Yup.string()
      .max(80, 'Must be 80 characters or less')
      .required('Please enter email to proceed')
      .email('Invalid email address'),
    phone: Yup.string()
      .max(40, 'Must be 40 characters or less')
      .matches(
        '^[+]*[0-9 ()-]*$',
        'Numerical values with +, -, (, ) are accepted',
      ),
    department: Yup.string()
      .max(80, 'Must be 80 characters or less')
      .matches(
        '^[A-Za-z0-9 _-]*[A-Za-z0-9][A-Za-z0-9 _-]*$',
        'No special characters values are accepted',
      ),
    jobFunction: Yup.string()
      .max(80, 'Must be 80 characters or less')
      .required('Please select Job function'),
    designation: Yup.string()
      .max(80, 'Must be 80 characters or less')
      .required('Please select Role'),
    accountId: Yup.string().required('Please select account to proceed'),
    officeLocationId: Yup.string().required(
      'Please select location to proceed',
    ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValue,
    validationSchema: validate,
    onSubmit: (values) => {
      AddUser(values);
    },
  });
  const AccountHandler = (val, name) => {
    let obj = {
      target: { name: name, value: val[name] ?? val },
    };
    if (name == 'officeLocationId') {
      // List of restricted locations
      let restrictedLocation = jobDesignationCollection.restrictedCountries
        .split(',')
        .map((country) => country.trim().toLowerCase());

      // Get the initial location's country
      let initialCountry = initialValue?.country?.toLowerCase();

      // Get the new location's country
      let countryName = val?.country?.toLowerCase();

      if (
        restrictedLocation.includes(countryName) &&
        restrictedLocation.includes(initialCountry)
      ) {
        setRistrictLocation(false); // Both are restricted, so location is not restricted
      } else if (
        restrictedLocation.includes(countryName) ||
        restrictedLocation.includes(initialCountry)
      ) {
        setRistrictLocation(true); // Either one is restricted, so location is restricted
      } else {
        setRistrictLocation(false);
      }
    }

    formik.handleChange(obj);
    if (name === 'accountId') {
      resetLocationDropdown();
      if (val[name] !== '' && name === 'accountId') {
        getLocations(val[name]);
      } else {
        setOfficeLocationName();
      }
    }
  };

  const getDuplicateLocationsWithContractNum = (locations) => {
    let uniqueLocations = [];
    let duplicateLocations = [];
    let tempLocationsWithContractNum = locations?.filter((el) => {
      if (!uniqueLocations?.includes(el.officeLocationId)) {
        uniqueLocations?.push(el.officeLocationId);

        return el;
      } else {
        duplicateLocations?.push(el.officeLocationId);
      }
    });

    tempLocationsWithContractNum = locations?.filter((el) => {
      if (duplicateLocations?.includes(el.officeLocationId)) {
        el.officeLocationName =
          el.officeLocationName + ' (' + el.contractNumber + ')';
      }
      return el;
    });
    return tempLocationsWithContractNum;
  };
  // get all user entitlement mappings
  const getEntitlementMappings = () => {
    if (contracts && contracts.some((x) => x.checked)) {
      let queryParams = {
        id: new Date().getTime(),
        pageSize: maxPageSize,
        Filter: `contractNumber in(${contracts
          .filter((x) => x.checked)
          .map((t) => (t.id ? '"' + t.id + '"' : ''))
          .join(',')}) ${
          editData?.contactId
            ? ' AND contactid:"' + editData?.contactId + '"'
            : ''
        }`,
        Sort: 'modifiedDate:desc',
      };
      httpService
        .get(Endpoints.userentitlementsApi, queryParams)
        .then((res) => {
          const response = res?.data?.results?.map((c, index) => {
            c.id = index;
            return c;
          });

          setEntitlementMappingData(response);
        })
        .catch((err) => {
          setEntitlementMappingData([]);
        });
    } else {
      setEntitlementMappingData([]);
    }
  };
  const getLocations = (accountId, signal) => {
    if (contracts) {
      let queryLocationParams = {
        PageSize: 5000,
        id: new Date().getTime(),
        field: 'contractNumber,officeLocationName,officeLocationId,Country',
        filter: `accountId:"${accountId}" AND contractNumber in(${contracts
          .filter((x) => x.checked)
          .map((t) => (t.id ? '"' + t.id + '"' : ''))
          .join(',')})`,
      };
      setOfficeLocationName();
      httpService
        .get(Endpoints.accountApi, queryLocationParams, { signal })
        .then((res) => {
          if (res) {
            setOfficeLocationName(
              getDuplicateLocationsWithContractNum(res.data?.results),
            );
          }
        })
        .catch((err) => {
          setOfficeLocationName([]);
        });
    }
  };

  const getjobFunctionDesignationData = () => {
    try {
      let storeData = store.getState();
      let storeJobDesignationDetails = storeData.jobDesignationDetails;
      let queryParams = {
        keyItem: `JOBFUNCTIONCOLLECTION, DESIGNATIONCOLLECTION, RESTRICTED_COUNTRIES`,
      };
      if (
        storeJobDesignationDetails?.jobFunctionCollection?.length > 0 ||
        storeJobDesignationDetails?.designationCollection?.length > 0
      ) {
        setJobDesignationCollection(storeJobDesignationDetails);
      } else {
        setLoading(true); // Set loading before sending API request
        httpService.get(Endpoints.configurationApi, queryParams).then((res) => {
          const response = res?.data?.results;
          let jobDesignationDetails = {};
          response.map((item) => {
            if (item.keyItem === 'JOBFUNCTIONCOLLECTION') {
              let jobFunc = JSON.parse(item.keyValue);

              jobDesignationDetails.jobFunctionCollection = jobFunc;
            } else if (item.keyItem === 'RESTRICTED_COUNTRIES') {
              let ristrictedCountry = item.keyValue;

              jobDesignationDetails.restrictedCountries = ristrictedCountry;
            } else if (item.keyItem === 'DESIGNATIONCOLLECTION') {
              let designation = JSON.parse(item.keyValue);

              jobDesignationDetails.designationCollection = designation;
            }
          });
          dispatch({
            type: ReduxAction.jobFuncDesignation,
            payload: jobDesignationDetails,
          });
          setJobDesignationCollection(jobDesignationDetails);

          setLoading(false); // Stop loading
        });
      }
    } catch (err) {
      setLoading(false); // Stop loading in case of error
    }
  };

  useEffect(() => {
    getjobFunctionDesignationData();
  }, []);

  // populate packages for entitlements tab in User Details
  const getAllUserEntitlements = (contractParam) => {
    // getDeliveryPlatformData();
    let queryParams = {
      pageSize: maxPageSize,
      id: new Date().getTime(),
      filter: `contractNumber in ("${contractParam}")`,
      Field:
        'packageId,packageName,distributor,distributorId,distributorPlatform,distributorDisplayName,distributorPlatformDisplayName,deliveryType,productComponentId,contractNumber,platformCategories,userIdFormat',
    };
    httpService
      .get(Endpoints.contractPackagesApi, queryParams)
      .then((res) => {
        if (res) {
          let metadata = res?.data?.metadata;
          if (contracts && contracts.some((x) => x.checked)) {
            let contractNumbers = [];
            contracts.filter((x) => {
              if (x.checked) {
                contractNumbers.push(x.id);
              }
            });
            let filteredContractPackages = res?.data?.results.filter((item) =>
              contractNumbers.includes(item.contractNumber),
            );
            setUserEntitlementsData(filteredContractPackages);
            // Set distributor data
            let distributorData = [];
            distributorData.results = filteredContractPackages?.filter((item) =>
              contractParam
                ? contractParam === item?.contractNumber
                : editData?.contractNumber === item?.contractNumber,
            );
            setDistributorEntitlementTab(distributorData);

            let packageData = res.data?.results.map(
              ({
                distributorId,
                packageId,
                packageName,
                productComponentId,
                contractNumber,
              }) => ({
                distributorId,
                packageId,
                packageName,
                productComponentId,
                contractNumber,
              }),
            );

            // Filtering based on contracts
            packageData = packageData?.filter((item) =>
              contractParam
                ? contractParam === item?.contractNumber
                : editData?.contractNumber === item?.contractNumber,
            );

            packageData = [
              ...new Map(
                packageData.map((item) => [item['packageId'], item]),
              ).values(),
            ];

            metadata.count = packageData.length;
            let finalPackageData = [];
            finalPackageData.metadata = metadata;
            finalPackageData.results = packageData;
            setPackageDataSet(finalPackageData);
          } else {
            setUserEntitlementsData([]);
            setPackageDataSet([]);
            setDistributorEntitlementTab([]);
          }
        }
      })
      .catch((err) => {
        setUserEntitlementsData([]);
        setPackageDataSet([]);
        setDistributorEntitlementTab([]);
      });
  };

  const resetLocationDropdown = () => {
    let locationData = {
      target: {
        id: 'officeLocationId',
        value: '',
      },
    };
    formik.handleChange(locationData);
  };

  useEffect(() => {
    if (Boolean(editData)) {
      if (JSON.stringify(initialValue) !== JSON.stringify(formik.values)) {
        setIsDirty(true);
      } else {
        setIsDirty(false);
      }
    }
  }, [formik.values]);

  const onConfirmAction = (newPostData) => {
    if (!editMode) {
      setActiveTab('entitlements-tab');
      setIsEntitlementEnabled(true);
      setEditData({ ...newPostData });
      getAllUserEntitlements(newPostData?.contractNumber);
      setIsDirty(false);
    }
  };

  const notifyAddEditUser = (postData, response) => {
    let notify_msg = '';
    // Custom msg on Add/Edit & API response
    switch (String(response?.code)) {
      // 0 - Data added/edited to DB
      case '0': {
        notify_msg = editMode
          ? 'User has been updated'
          : 'User details have been saved successfully';
        break;
      }
      // -2 - Duplicate email id
      case '-2': {
        notify_msg = response?.cause;
        break;
      }
      default:
        notify_msg = GenericError.somethingWentWrong;
    }

    let newPostData = {};
    if (!editMode) {
      let contractNumberFromOfficeID = officeLocationName.filter(
        (el) => el.officeLocationId === postData.officeLocationId,
      );

      newPostData = {
        ...postData,
        contactId: response?.results[0]?.contactId,
        contractNumber: contractNumberFromOfficeID[0]?.contractNumber,
      };
    }

    // Notify config to show conditional alert after adding new user
    let notifyConfig = {
      confirmBtnText: 'Add entitlements',
      onConfirm: () => onConfirmAction(newPostData),
      cancelBtnText: 'Close',
      onCancel: () => onAddEditModalClose(),
      secondBtnText: 'Clone entitlements',
      onSecondAction: () => handleCloneEntitlement(newPostData),
      showCloseBtn: false,
    };
    Notify({
      alert: true,
      type: 'success',
      title: notify_msg,
      dialogClassName: !editMode ? 'alert-max-width' : '',
      ...(!editMode && notifyConfig),
    });
  };

  const handleCloneEntitlement = (newPostData) => {
    setCloneEntitlementData(newPostData);
    handleCloneFromEditUser(newPostData);
    onAddEditModalClose();
  };

  const AddUser = (userDetails) => {
    if (ristrictLocation && editMode) {
      Notify({
        alert: true,
        type: 'error',
        title:
          'Location can not be updated. Please reach out to CI Support for further assistance',
      });
    } else {
      setLoading(true);
      let User_Info = {
        userid: UserId,
        isDeleted: 0,
        ...userDetails,
      };
      User_Info.contactId = editMode ? editData?.contactId : '-1';
      User_Info.isEmailOptOut = User_Info.isEmailOptOut ? 1 : 0;
      if (editMode) {
        User_Info.email = editData?.email;
      }
      let postData = {};
      for (let key in User_Info) {
        if (typeof User_Info[key] === 'string') {
          postData[key] = User_Info[key].trim();
        } else {
          postData[key] = User_Info[key];
        }
      }
      httpService
        .post(Endpoints.userApi, [postData])
        .then((resp) => {
          if (resp?.data?.code === 0) {
            getUsers();
            notifyAddEditUser(postData, resp.data);
            editMode && onAddEditModalClose();
          }
        })
        .catch((err) => {
          let errorMsg =
            err?.response?.data?.resultCode === '-2' ||
            err?.response?.data?.resultCode === '-3'
              ? err?.response?.data?.cause
              : GenericError.somethingWentWrong;
          Notify({
            alert: true,
            type: 'error',
            title: errorMsg,
          });
        })
        .finally(() => setLoading(false));
    }
  };

  const onAddEditModalClose = async () => {
    await setInitialValue({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      department: '',
      jobFunction: '',
      designation: '',
      accountId: '',
      officeLocationId: '',
      groupName: '',
    });
    setEditData();
    handleClose();
  };
  const getDeliveryPlatformData = (signal) => {
    let storeData = store.getState();
    let storeDistributors = storeData?.distributors;
    if (storeDistributors?.userDistributors) {
      setDistributorDataSet(storeDistributors.userDistributors);
    } else {
      let queryParams = {
        pageSize: maxPageSize,
        id: new Date().getTime(),
        Field: queryParamFields.contractPackagesAPIFields,
      };
      httpService
        .get(Endpoints.contractPackagesApi, queryParams, { signal })
        .then((res) => {
          if (res) {
            let distributorData = res.data?.results.map(
              ({
                distributor,
                distributorDisplayName,
                distributorId,
                distributorPlatform,
                distributorPlatformDisplayName,
                deliveryType,
                packageId,
                productComponentId,
                platformCategories,
                userIdFormat,
              }) => ({
                distributor,
                distributorDisplayName,
                distributorId,
                distributorPlatform,
                distributorPlatformDisplayName,
                deliveryType,
                packageId,
                productComponentId,
                platformCategories,
                userIdFormat,
              }),
            );
            distributorData = distributorData.filter(
              (e, i) =>
                distributorData.findIndex(
                  (a) => a['distributorId'] === e['distributorId'],
                ) === i,
            );
            let metadata = res?.data?.metadata;
            metadata.count = distributorData?.length;
            let finalDistributorData = [];
            finalDistributorData.metadata = metadata;
            finalDistributorData.results = distributorData;
            setDistributorDataSet(finalDistributorData);
            dispatch({
              type: ReduxAction.distributors,
              payload: {
                distributors: finalDistributorData,
              },
            });
          }
        })
        .catch((err) => {
          setDistributorDataSet([]);
        });
    }
  };

  const onTabSelect = (key) => {
    switch (key) {
      case entitlementTabName:
        setIsEntitlementEnabled(true);
        break;
      case productEntitlementTabName:
        setIsEntitlementEnabled(true);
        setIsProductEnabled(true);
        break;
      case userIdTabName:
        setIsEntitlementEnabled(true);
        setIsProductEnabled(true);
        setIsUserIdEnabled(true);
        break;
      case welcomeEmailsTabName:
        setIsEntitlementEnabled(true);
        setIsProductEnabled(true);
        setIsUserIdEnabled(true);
        setIsWelcomeEmailsEnabled(true);
        break;
    }
    setActiveTab(key);
  };

  return (
    <>
      <ModalBox
        backdrop="static"
        show={show}
        size="xl"
        contentClassName="modal-height-fix"
        handleClose={onAddEditModalClose}
        showLoader={loading}
        modalTitle={
          editMode ? (
            <>
              {initialValue.firstName} {initialValue.lastName}
              {editData?.contractNumber && (
                <span className="edit-user-subtitle">
                  {' '}
                  {contractDisplayOption?.preferenceValue ===
                  ContractDisplayOptions[2].contractDisplayOption
                    ? `(${editData?.contractNumber}${
                        editData?.contractDescription
                          ? ` - ${editData.contractDescription}`
                          : ''
                      })`
                    : contractDisplayOption?.preferenceValue ===
                      ContractDisplayOptions[1].contractDisplayOption
                    ? `(${editData.contractDescription})`
                    : `(${editData.contractNumber})`}
                </span>
              )}
            </>
          ) : (
            'Add a New User'
          )
        }
      >
        <div className="tabs-main mt-0">
          <Tabs
            fill
            justify
            defaultActiveKey="user-details-tab"
            activeKey={activeTab}
            onSelect={onTabSelect}
          >
            <Tab eventKey="user-details-tab" title="User Details">
              <Container className="pt-1">
                <Form className="row" onSubmit={formik.handleSubmit}>
                  <TextFieldInput
                    formLabel="First name"
                    placeholder="First name"
                    id="firstName"
                    {...formik.getFieldProps('firstName')}
                    formik={formik}
                    addAsterisk
                  />
                  <TextFieldInput
                    formLabel="Last name"
                    placeholder="Last name"
                    id="lastName"
                    {...formik.getFieldProps('lastName')}
                    formik={formik}
                    addAsterisk
                  />
                  <TextFieldInput
                    formLabel="Email"
                    placeholder="Email address"
                    id="email"
                    disabled={editMode}
                    {...formik.getFieldProps('email')}
                    formik={formik}
                    addAsterisk
                  />
                  <DropdownInput
                    formLabel="Account"
                    name="accountId"
                    targetValue="accountId"
                    dropdownOptions={accounts}
                    formik={formik}
                    ddPlaceHolder={
                      formik.values.accountId !== '' && accounts?.length > 0
                        ? accounts?.filter(
                            (el) => el.accountId === formik.values.accountId,
                          )[0]?.accountName
                        : 'Select'
                    }
                    labelKeyName="accountName"
                    ddName={'account'}
                    onChangeHandler={(e) => AccountHandler(e, 'accountId')}
                    addAsterisk
                  />
                  <DropdownInput
                    formLabel="Location"
                    name="officeLocationId"
                    dropdownOptions={officeLocationName}
                    formik={formik}
                    ddPlaceHolder={
                      formik.values.officeLocationId !== '' &&
                      officeLocationName?.length > 0
                        ? officeLocationName?.filter(
                            (el) =>
                              el.officeLocationId ===
                              formik.values.officeLocationId,
                          )[0]?.officeLocationName
                        : 'Select'
                    }
                    labelKeyName="officeLocationName"
                    ddName={'officeLocation'}
                    onChangeHandler={(e) =>
                      AccountHandler(e, 'officeLocationId')
                    }
                    addAsterisk
                  />
                  <DropdownInput
                    enableSearch={true}
                    searchKeys={[]}
                    formLabel="Job function"
                    {...formik.getFieldProps('jobFunction')}
                    name="jobFunction"
                    dropdownOptions={
                      jobDesignationCollection.jobFunctionCollection
                    }
                    formik={formik}
                    ddPlaceHolder={
                      formik.values.jobFunction !== ''
                        ? formik.values.jobFunction
                        : 'Select'
                    }
                    ddName={'jobFunction'}
                    onChangeHandler={(e) => AccountHandler(e, 'jobFunction')}
                    addAsterisk
                  />
                  <DropdownInput
                    enableSearch={true}
                    formLabel="Role"
                    {...formik.getFieldProps('designation')}
                    name="designation"
                    dropdownOptions={
                      jobDesignationCollection.designationCollection
                    }
                    formik={formik}
                    ddPlaceHolder={
                      formik.values.designation !== ''
                        ? formik.values.designation
                        : 'Select'
                    }
                    ddName={'designation'}
                    onChangeHandler={(e) => AccountHandler(e, 'designation')}
                    addAsterisk
                  />
                  <TextFieldInput
                    formLabel="Department"
                    placeholder="Department"
                    id="department"
                    {...formik.getFieldProps('department')}
                    formik={formik}
                  />
                  <TextFieldInput
                    formLabel="Business Phone number"
                    placeholder="Business Phone number"
                    id="phone"
                    {...formik.getFieldProps('phone')}
                    formik={formik}
                  />

                  {editData?.groupName && (
                    <TextFieldInput
                      formLabel="Group Name"
                      placeholder="Group Name"
                      id="groupName"
                      disabled={true}
                      value={editData?.groupName}
                    />
                  )}
                  <div className="user-details-fix d-flex">
                    <Form.Group className={'col-11'}>
                      <Form.Check
                        type="checkbox"
                        className={''}
                        {...formik.getFieldProps('isEmailOptOut')}
                        formik={formik}
                      >
                        <Form.Check.Input
                          type={'checkbox'}
                          id="notificationOptIn"
                          {...formik.getFieldProps('isEmailOptOut')}
                          formik={formik}
                          checked={formik?.values?.isEmailOptOut}
                        />
                        <Form.Check.Label>
                          <div className="market-email-label d-flex">
                            <span className="text-nowrap me-2">
                              Marketing Email Opt-Out :
                            </span>
                            <span>
                              You will not receive S&P Global Commodity Insights
                              promotional emails,such as insightful content,
                              event invitations and product information.
                            </span>
                          </div>
                        </Form.Check.Label>
                      </Form.Check>
                    </Form.Group>
                    <Form.Group className="col-1 user-save-btn-body align-items-start">
                      <button
                        disabled={!isDirty || isViewerUser}
                        type="Submit"
                        className="user-save-btn btn btn-dark float-end btn-opacity"
                      >
                        {editMode ? 'Update' : 'Save'}
                      </button>
                    </Form.Group>
                  </div>
                </Form>
              </Container>
            </Tab>
            <Tab
              eventKey="entitlements-tab"
              title="Entitlements"
              disabled={!editMode}
            >
              {editMode && isEntitlementEnabled && (
                <UserEntitlements
                  editMode={editMode}
                  editData={editData}
                  distributorDataSet={distributorEntitlementTab}
                  entitlementMappingData={entitlementMappingData}
                  getEntitlementMappings={getEntitlementMappings}
                  getUsers={getUsers}
                  onAddEditModalClose={onAddEditModalClose}
                  formik={formik}
                  initialValue={initialValue}
                  userEntitlementsData={userEntitlementsData}
                  packageDataSet={packageDataSet}
                  getAllUserEntitlements={getAllUserEntitlements}
                  setLoading={setLoading}
                  setActiveTab={setActiveTab}
                  setIsUserIdEnabled={setIsUserIdEnabled}
                  isViewerUser={isViewerUser}
                ></UserEntitlements>
              )}
            </Tab>
            <Tab
              eventKey="product-entitlements-tab"
              title="Email preferences"
              disabled={!editMode}
            >
              {editMode && isProductEnabled && (
                <UserProductEntitlements
                  contractNumber={editData?.contractNumber}
                  editMode={editMode}
                  distributorDataSet={distributorDataSet}
                  userEntitlementData={entitlementMappingData}
                  packageMappingData={userEntitlementsData}
                  editData={editData}
                  getUsers={getUsers}
                  onAddEditModalClose={onAddEditModalClose}
                  setLoading={setLoading}
                  setActiveTab={setActiveTab}
                  setIsUserIdEnabled={setIsUserIdEnabled}
                  isViewerUser={isViewerUser}
                ></UserProductEntitlements>
              )}
            </Tab>
            {/* Manage User IDs */}
            {
              // Check if the CCA, CSA and End user is not a primary user
              ((editData &&
                !(editData?.contractType === contractTypePrimary)) ||
                (contractType && !(contractType === contractTypePrimary))) && (
                <Tab
                  eventKey="manage-user-ids-tab"
                  title="Manage User IDs"
                  disabled={!editMode}
                >
                  {editMode && isUserIdEnabled && (
                    <ManageUserIds
                      editMode={editMode}
                      distributorDataSet={distributorDataSet}
                      editData={editData}
                      getUsers={getUsers}
                      onAddEditModalClose={onAddEditModalClose}
                      setLoading={setLoading}
                      isEndUser={false}
                      isViewerUser={isViewerUser}
                    ></ManageUserIds>
                  )}
                </Tab>
              )
            }
            <Tab
              eventKey="welcome-emails-tab"
              title="Welcome Emails"
              disabled={!editMode}
            >
              {editMode && isWelcomeEmailsEnabled && (
                <WelcomeEmails
                  editMode={editMode}
                  editData={editData}
                  onAddEditModalClose={onAddEditModalClose}
                  setLoading={setLoading}
                  isViewerUser={isViewerUser}
                ></WelcomeEmails>
              )}
            </Tab>
          </Tabs>
        </div>
      </ModalBox>
    </>
  );
};

export default memo(AddEditUser);
