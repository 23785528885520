import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Container, Form, Row } from 'react-bootstrap';
import AgGridComponent from '../../../sharedcomponents/ag-grid/AgGrid';
import { Endpoints, GenericError, Verbiage } from './../../../constants';
import httpService from './../../../services/http-service';
import { Notify } from './../../../sharedcomponents/Alert/Notify';
import store from './../../../redux/store';
import './welcome-emails.scss';

const WelcomeEmails = ({
  editMode,
  editData,
  onAddEditModalClose,
  setLoading,
  isViewerUser,
}) => {
  //state variable declarations starts
  const [welcomeEmailRowData, setWelcomeEmailRowData] = useState();
  const [userId, setUserId] = useState();
  const welcomeEmailsgridApi = useRef(null);
  const userEmail = editData?.email;
  const gridRef = useRef();
  //state variable declarations ends

  const onWelcomeEmailsGridReadyHandler = (params) => {
    welcomeEmailsgridApi.current = params;
    welcomeEmailsgridApi.current?.api.showLoadingOverlay();
  };

  const defaultConfig = {
    rowModelType: 'clientSide',
    isExportCSV: false,
    isExportExcel: false,
    suppressDragLeaveHidesColumns: true,
    pivotPanelShow: '',
    pagination: false,
    isAutoSizeColumns: true,
    enableCharts: false,
    rowGroupPanelShow: false,
    sideBar: false,
    overlayLoadingTemplate: 'Loading...',
    overlayNoRowsTemplate:
      'This user does not have any entitled delivery platforms',
  };

  const defaultColdef = {
    sortable: true,
    resizable: true,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };

  //useEffects start
  useEffect(() => {
    let storeData = store.getState();
    setUserId(storeData?.userInfo.userInfo.email);

    // Controller to abort previous API calls if any ongoing flight
    const controller = new AbortController();
    const signal = controller.signal;
    if (editMode) {
      getWelcomeEmails(signal);
    }
    return () => {
      controller?.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);
  //useeffect ends

  const welcomeEmailsColumns = [
    {
      headerName: '',
      field: 'id',
      resizable: true,
      filter: 'agTextColumnFilter',
      suppressHeaderMenuButton: true,
      sortable: false,
      hide: true,
    },
    {
      headerName: 'Delivery Platform',
      headerTooltip: 'Delivery Platform',
      field: 'deliveryPlatform',
      tooltipField: 'deliveryPlatform',
      initialFlex: 3,
    },
    {
      headerName: 'User Status',
      headerTooltip: 'User Status',
      field: 'userStatus',
      tooltipField: 'userStatus',
      initialFlex: 3,
    },
    {
      headerName: 'Last Trigger (UTC)',
      headerTooltip: 'Last Trigger (UTC)',
      field: 'lastTrigger',
      tooltipField: 'lastTrigger',
      initialFlex: 3,
    },
    {
      headerName: 'Retrigger Welcome Email',
      headerTooltip: 'Retrigger Welcome Email',
      field: 'retriggerEmail',
      tooltipField: 'retriggerEmail',
      initialFlex: 3,
      cellRenderer: (params) => {
        let cell = document.createElement('div');
        cell.classList.add('email-label-container');
        let cellText = document.createElement('span');
        cellText.title = 'Retrigger Email';
        cellText.innerText = 'Retrigger Email';
        if (
          params.data != null &&
          params.data.emailNotified &&
          params.data.emailNotifiedDT
        ) {
          cellText.classList.add('email-label');
          cellText.addEventListener('click', () => {
            if (!cellText.hasAttribute('data-disabled') && !isViewerUser) {
              retriggerEmail(params, cellText);
            }
          });
        } else {
          cellText.classList.add('disabled-retrigger-link');
        }
        cell.appendChild(cellText);

        //return cell;
        return (
          <div className="email-label-container">
            <span
              title="Retrigger Email"
              className={
                params.data != null &&
                params.data.emailNotified &&
                params.data.emailNotifiedDT &&
                !isViewerUser
                  ? 'email-label'
                  : 'disabled-retrigger-link'
              }
              onClick={() => {
                if (
                  params.data != null &&
                  params.data.emailNotified &&
                  params.data.emailNotifiedDT &&
                  !cellText.hasAttribute('data-disabled') &&
                  !isViewerUser
                ) {
                  retriggerEmail(params, cellText);
                }
              }}
            >
              Retrigger Email
            </span>
          </div>
        );
      },
    },
  ];

  const retriggerEmail = (params, cellText) => {
    setLoading(true);
    params.data.userid = userId;
    httpService
      .post(Endpoints.welcomeEmailApi, params.data)
      .then((res) => {
        if (res) getWelcomeEmails();

        Notify({
          alert: true,
          type: 'success',
          title: Verbiage.retriggerSuccess,
        });
      })
      .catch((err) => {
        Notify({
          alert: true,
          type: 'error',
          title: GenericError.somethingWentWrong,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getWelcomeEmails = (signal) => {
    let queryParams = {
      id: new Date().getTime(),
      pageSize: 5000,
      emailId: userEmail,
    };
    httpService
      .get(Endpoints.welcomeEmailApi, queryParams, { signal })
      .then((res) => {
        let results = res?.data?.results;

        results = results?.map((el) => {
          const userStatus =
            el.hasProcessed &&
            el.hasProcessed === 'Y' &&
            el.emailNotified &&
            el.emailNotifiedDT
              ? 'Success'
              : 'In Progress';
          const lastTrigger = el.emailNotifiedDT
            ? el.emailNotifiedDT.replace('T', ' ').split('.')[0]
            : '';

          const deliveryPlatform =
            el.distributor + ' : ' + el.distributorPlatform;

          return {
            ...el,
            deliveryPlatform,
            userStatus,
            lastTrigger,
          };
        });

        setWelcomeEmailRowData(results);
      })
      .catch((err) => {
        if (err.code !== 'ERR_CANCELED') {
          gridRef?.current?.api?.showNoRowsOverlay();
          Notify({
            alert: true,
            type: 'error',
            title: GenericError.somethingWentWrong,
          });
        }
      });
  };

  return (
    <>
      <Container className={'pt-3'}>
        <Row>
          <div className="ag-grid-modal-content welcome-email-tab">
            <AgGridComponent
              config={defaultConfig}
              defaultColumnDef={defaultColdef}
              data={welcomeEmailRowData}
              columns={welcomeEmailsColumns}
              onGridReady={onWelcomeEmailsGridReadyHandler}
            />
          </div>
        </Row>
        <Row
          className={
            editData?.groupId
              ? 'modal-row group-mapped-entitlement-fix'
              : 'modal-row'
          }
        >
          <Form>
            <Form.Group className="col-12 user-save-btn-body">
              <button
                type="button"
                className="user-save-btn btn btn-dark float-end btn-opacity"
                onClick={onAddEditModalClose}
              >
                Close
              </button>
            </Form.Group>
          </Form>
        </Row>
      </Container>
    </>
  );
};

export default WelcomeEmails;
