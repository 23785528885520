import React, { memo, useMemo, useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {
  DistributorUserIDRequiredList,
  Endpoints,
  GenericError,
  UrlPath,
  UserId,
  Verbiage,
  loaderStyle,
  maxPageSize,
  ProductDeliveryPlatforms,
  AutoSelectPkgDist,
} from '../../constants';
import httpService from '../../services/http-service';
import AgGridComponent from '../../sharedcomponents/ag-grid/AgGrid';
import { Notify } from './../../sharedcomponents/Alert/Notify';
import DistributorUserIdModal from './DistributorUserIdModal';
import DistributorsDropdown from './DistributorsDropdown';
import PackagesDropdown from './PackagesDropdown';
import UsersDropdown from './UsersDropdown';
import Loader from './../../components/Loader';
import InfoIcon from './../../assets/icons/common/info.png';
import StickyTooltip from './../../sharedcomponents/StickyTooltip/StickyTooltip';

const AddEntitlements = memo(
  ({ contracts }) => {
    const getColumnClass = (params) => {
      if (params?.node?.data?.isEntitlementExists == false) {
        return 'new-selected-entitlement-row grid-cell-left';
      } else {
        return 'grid-cell-left';
      }
    };

    const columns = [
      {
        headerName: 'User',
        headerTooltip: 'User',
        field: 'contactData',
        tooltipField: 'contactData',
        initialFlex: 3,
        initialHide: false,
        cellClass: getColumnClass,
      },
      {
        headerName: 'Package',
        headerTooltip: 'Package',
        field: 'packageName',
        tooltipField: 'packageName',
        initialFlex: 3,
        initialHide: false,
        cellClass: getColumnClass,
      },
      {
        headerName: 'Delivery Platform (Type)',
        headerTooltip: 'Delivery Platform (Type)',
        field: 'distributorData',
        tooltipField: 'distributorData',
        initialFlex: 2,
        initialHide: false,
        cellClass: getColumnClass,
      },
      {
        headerName: 'Status',
        headerTooltip: 'Status',
        field: 'status',
        tooltipField: 'status',
        initialFlex: 2,
        initialHide: false,
        cellClass: getColumnClass,
        cellRenderer: (params) => {
          const isTooltipVisible =
            tooltip.showTooltip && tooltip.rowId === params.rowIndex;

          return (
            <div style={{ position: 'relative' }} data-row-id={params.rowIndex}>
              <span>
                {params.value}
                {params.value === 'Blocked' && (
                  <img
                    src={InfoIcon}
                    className="info-icon"
                    style={{
                      cursor: 'pointer',
                      marginLeft: '5px',
                      width: '20px',
                    }}
                    title={params.data.blockedReason}
                    onClick={(event) => {
                      handleTooltipClick(
                        event,
                        params.rowIndex,
                        params.data.blockedReason,
                      );
                    }}
                  />
                )}
                {isTooltipVisible && (
                  <StickyTooltip
                    ref={tooltipRef}
                    text={tooltip.text}
                    onClose={() => setTooltip({})}
                  />
                )}
              </span>
            </div>
          );
        },
      },
    ];
    const gridRef = useRef();
    const onDragStoppedHandler = (params) => {
      const columnDefs = columns;
      let groupCols = params.api.columnModel.rowGroupColumns;
      columnDefs.forEach(function (colDef, index) {
        if (groupCols.find((x) => x.colId === colDef.field)) {
          colDef.hide = true;
        } else {
          colDef.hide = false;
        }
      });
      gridRef.current.api.setColumnDefs(columnDefs);
    };

    const handleTooltipClick = (event, rowId, blockedReason) => {
      event.stopPropagation();
      if (tooltip.rowId === rowId) {
        setTooltip({});
      } else {
        setTooltip({
          showTooltip: true,
          rowId: rowId,
          text: blockedReason,
        });
      }
    };

    const defaultConfig = {
      rowModelType: 'clientSide',
      isExportCSV: false,
      isExportExcel: false,
      pivotPanelShow: '',
      pagination: false,
      isAutoSizeColums: true,
      enableCharts: false,
      sideBar: false,
      onDragStopped: onDragStoppedHandler,
    };

    const defaultColdef = {
      sortable: true,
      resizable: true,
      menuTabs: ['generalMenuTab', 'filterMenuTab'],
      enableValue: false,
    };

    const autoGroupColumnDef = {
      // column definition for grouped column
      enableRowGroup: false,
      lockPosition: 'left',
      lockPinned: true,
      sortable: true,
      cellRenderer: 'agGroupCellRenderer',
    };

    const [loading, setLoading] = useState(false);
    const [packageDataSet, setPackageDataSet] = useState();
    const [distributorDataSet, setDistributorDataSet] = useState();
    const [selectedPackages, setSelectedPackages] = useState([]);
    const [selectedDistributors, setSelectedDistributors] = useState([]);
    const [selectedDistributorsId, setSelectedDistributorsId] = useState([]);
    const [selectedParentCascade, setSelectedParentCascade] = useState();
    const [filteredPackages, setFilteredPackages] = useState();
    const [filteredDistributors, setFilteredDistributors] = useState();
    const [userEntitlementsData, setUserEntitlementsData] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [updatedUsers, setUpdatedUsers] = useState([]);
    const [packageDistributorData, setPackageDistributorData] = useState([]);
    const [updatedPackageDistributors, setUpdatedPackageDistributors] =
      useState([]);
    const [showDistributorUserIdModal, setShowDistributorUserIdModal] =
      useState([]);
    const [tooltip, setTooltip] = useState({});
    const tooltipRef = useRef(null);
    const gridContainerRef = useRef(null);

    useEffect(() => {
      const reduceCallback = setTimeout(() => {
        setPackageDistributorData([]);
        setFilteredPackages();
        setSelectedPackages([]);
        setSelectedDistributors([]);
        setSelectedDistributorsId([]);
        setSelectedUsers([]);
        getAllUserEntitlements();
      }, 1000);
      return () => clearTimeout(reduceCallback);
    }, [contracts]);

    useEffect(() => {
      // Set initial state
      if (packageDataSet) setFilteredPackages(packageDataSet);
      if (distributorDataSet) setFilteredDistributors(distributorDataSet);
    }, [packageDataSet, distributorDataSet]);

    useEffect(() => {
      // Controller to abort previous API calls if any ongoing flight
      const controller = new AbortController();
      const signal = controller.signal;
      const reduceCallback = setTimeout(() => {
        if (selectedUsers.length > 0 && selectedPackages.length > 0) {
          if (isAnyAutoSelectPkg()) {
            getContractPackages(
              signal,
              false,
              AutoSelectPkgDist.Market_Basics.distId,
            );
            return;
          }
        }

        if (selectedPackages.length > 0 && selectedDistributors.length > 0) {
          getContractPackages(signal, false);
        } else if (
          selectedPackages.length === 0 ||
          selectedDistributors.length === 0
        ) {
          setPackageDistributorData(
            packageDistributorData.filter((c) => c.isEntitlementExists),
          );
        }
      }, 1000);
      setTooltip({});
      return () => {
        clearTimeout(reduceCallback);
        controller?.abort();
      };
    }, [selectedPackages, selectedDistributors, selectedUsers]);

    useEffect(() => {
      if (selectedParentCascade === 'PackageDropdown' && userEntitlementsData) {
        // Filter distributor based on package selection
        let pkgId = [];
        selectedPackages.forEach((el) => {
          pkgId.push(el.packageName);
        });
        let filterData = userEntitlementsData.filter((el) =>
          pkgId.includes(el.packageName),
        );
        setFilteredDistributors({ results: filterData });
      }
    }, [selectedPackages]);

    useEffect(() => {
      if (
        selectedParentCascade === 'DistributorDropdown' &&
        userEntitlementsData
      ) {
        // Filter packages based on distributor selection
        let filterData = userEntitlementsData.filter((el) => {
          return selectedDistributorsId.includes(el.distributorId);
        });
        setFilteredPackages({ results: filterData });
      }
    }, [selectedDistributorsId]);

    useEffect(() => {
      handleUserCallback(updatedUsers);
    }, [updatedUsers]);

    useEffect(() => {
      if (tooltip.showTooltip && gridContainerRef.current) {
        const scrollContainer =
          gridContainerRef.current.querySelector('.ag-body-viewport');
        const handleScroll = () => {
          const rowElement = document.querySelector(
            `[data-row-id="${tooltip.rowId}"]`,
          );
          if (rowElement) {
            const gridRect = scrollContainer.getBoundingClientRect();
            const rowRect = rowElement.getBoundingClientRect();
            if (
              rowRect.bottom < gridRect.top ||
              rowRect.top > gridRect.bottom
            ) {
              setTooltip({});
            }
          } else {
            setTooltip({});
          }
        };
        if (scrollContainer) {
          scrollContainer.addEventListener('scroll', handleScroll);
        }
        return () => {
          if (scrollContainer) {
            scrollContainer.removeEventListener('scroll', handleScroll);
          }
        };
      }
    }, [tooltip.showTooltip, tooltip.rowId]);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          tooltipRef.current &&
          !tooltipRef.current.contains(event.target) &&
          !event.target.classList.contains('info-icon')
        ) {
          setTooltip({});
        }
      };

      if (tooltip.showTooltip) {
        document.addEventListener('click', handleClickOutside);
      } else {
        document.removeEventListener('click', handleClickOutside);
      }

      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [tooltip.showTooltip]);

    const isAnyAutoSelectPkg = () => {
      try {
        let isAutoSelect = false;
        const marketBasicsSelected = selectedPackages.some((pkg) =>
          pkg.packageName.includes(AutoSelectPkgDist.Market_Basics.pkgName),
        );

        // Check if Market Basics is selected
        if (marketBasicsSelected) {
          const plattsConnectSelected = selectedDistributors.find(
            (dst) =>
              dst.distributorId == AutoSelectPkgDist.Market_Basics.distId,
          );
          // Platts Connect is not selected
          if (!plattsConnectSelected) {
            isAutoSelect = true;
          }
        }
        return isAutoSelect;
      } catch (error) {}
    };

    const getContractPackages = async (
      signal,
      isUserChanged = false,
      autoSelectedDistributor = '',
    ) => {
      try {
        let packageDistributorResponse;
        if (!isUserChanged) {
          const packageIdCollection = selectedPackages
            .map((el) => String(el.packageId))
            .join(',');

          let distributorIdCollection = selectedDistributors
            .map((el) => String(el.distributorId))
            .join(',');

          if (autoSelectedDistributor) {
            distributorIdCollection = distributorIdCollection
              ? `${distributorIdCollection},${autoSelectedDistributor}`
              : autoSelectedDistributor;
          }

          if (packageIdCollection && distributorIdCollection) {
            let queryParamsUser = {
              PageSize: maxPageSize,
              Filter: `packageId in(${packageIdCollection}) and distributorId in(${distributorIdCollection}) and contractNumber in(${contracts
                .filter((x) => x.checked)
                .map((t) => (t.id ? '"' + t.id + '"' : ''))
                .join(',')})`,
            };
            setLoading(true);
            packageDistributorResponse = await httpService.get(
              Endpoints.contractPackagesApi,
              queryParamsUser,
              { signal },
            );
            setLoading(false);
            setUpdatedPackageDistributors(packageDistributorResponse);
          }
        } else {
          packageDistributorResponse = updatedPackageDistributors;
        }
        if (
          selectedPackages.length !== 0 &&
          (selectedDistributors.length !== 0 || autoSelectedDistributor) &&
          selectedUsers.length !== 0
        ) {
          let preparePackageDistributorData;
          if (packageDistributorData?.length > 0) {
            preparePackageDistributorData = packageDistributorData.filter(
              (x) => x.isEntitlementExists,
            );
          } else {
            preparePackageDistributorData = [];
          }

          selectedUsers.forEach((x) => {
            packageDistributorResponse?.data?.results?.forEach(
              (packagePlatform) => {
                if (packagePlatform.contractNumber === x.contractNumber) {
                  if (
                    !preparePackageDistributorData.some(
                      (k) =>
                        k.contactId === x.contactId &&
                        k.productComponentId ===
                          packagePlatform.productComponentId &&
                        k.status !== 'Pending Inactive' &&
                        k.status !== 'Cancelled' &&
                        k.status !== 'Rejected',
                    )
                  ) {
                    packagePlatform.distributorData =
                      packagePlatform.distributor +
                      ' : ' +
                      packagePlatform.distributorPlatform +
                      ' (' +
                      packagePlatform.deliveryType +
                      ')';
                    packagePlatform.contactData = x.contactName;
                    packagePlatform.status = 'Pending Active';
                    packagePlatform.contactId = x.contactId;
                    packagePlatform.contractNumber =
                      packagePlatform.contractNumber;
                    packagePlatform.isEntitlementExists = false;
                    preparePackageDistributorData.unshift({
                      ...packagePlatform,
                    });
                  }
                }
              },
            );
          });

          if (
            preparePackageDistributorData.length > 0 &&
            selectedPackages.some((pkg) =>
              pkg.packageName.includes(AutoSelectPkgDist.Market_Basics.pkgName),
            )
          ) {
            preparePackageDistributorData =
              preparePackageDistributorData.filter(
                (el) =>
                  el.packageName.includes(
                    AutoSelectPkgDist.Market_Basics.pkgName,
                  ) ||
                  selectedDistributorsId.some((id) => id == el.distributorId) ||
                  el.isEntitlementExists,
              );
          }
          setPackageDistributorData(preparePackageDistributorData);
        }
      } catch (err) {
        setLoading(false);
      }
    };

    const getAllUserEntitlements = () => {
      try {
        if (contracts?.length > 0 && contracts.some((el) => el.checked)) {
          // Fetch all packages and distributor data
          let queryParams = {
            pageSize: maxPageSize,
            id: new Date().getTime(),
            filter: `contractNumber in (${contracts
              .filter((x) => x.checked)
              .map((t) => (t.id ? '"' + t.id + '"' : ''))
              .join(',')})`,
            Field:
              'packageId,packageName,distributor,distributorDisplayName,distributorPlatformDisplayName,distributorId,distributorPlatform,deliveryType,productComponentId,platformCategories',
          };
          setLoading(true);
          httpService
            .get(Endpoints.contractPackagesApi, queryParams)
            .then((res) => {
              if (res) {
                let metadata = res?.data?.metadata;
                if (contracts && contracts.some((x) => x.checked)) {
                  let contractNumbers = [];
                  contracts.filter((x) => {
                    if (x.checked) {
                      contractNumbers.push(x.id);
                    }
                  });
                  let filteredContractPackages = res?.data?.results;
                  setUserEntitlementsData(filteredContractPackages);
                  let packageData = filteredContractPackages.map(
                    ({
                      distributorId,
                      packageId,
                      packageName,
                      productComponentId,
                      contractNumber,
                    }) => ({
                      distributorId,
                      packageId,
                      packageName,
                      productComponentId,
                      contractNumber,
                    }),
                  );
                  packageData = [
                    ...new Map(
                      packageData.map((item) => [item['packageId'], item]),
                    ).values(),
                  ];
                  metadata.count = packageData.length;
                  let finalPackageData = [];
                  finalPackageData.metadata = metadata;
                  finalPackageData.results = packageData;
                  setPackageDataSet(finalPackageData);

                  // Set distributor data
                  let distributorData = [];
                  distributorData.metadata = filteredContractPackages.length;
                  distributorData.results = filteredContractPackages;
                  setDistributorDataSet(distributorData);
                } else {
                  setUserEntitlementsData([]);
                  setPackageDataSet([]);
                }
              }
            })
            .catch((err) => {})
            .finally(() => setLoading(false));
        } else {
          setUserEntitlementsData([]);
          setPackageDataSet([]);
        }
      } catch (err) {}
    };

    const cascadingPackageDistributor = () => {
      // On empty selection, set to default data
      if (
        selectedPackages.length === 0 &&
        selectedParentCascade === 'PackageDropdown'
      ) {
        setFilteredDistributors(distributorDataSet);
      } else if (
        selectedDistributorsId.length === 0 &&
        selectedParentCascade === 'DistributorDropdown'
      ) {
        setFilteredPackages(packageDataSet);
      }

      // Cascading functionality to set which dropdown is parent
      if (selectedPackages.length === 0 && selectedDistributors.length === 0) {
        setSelectedParentCascade();
      } else if (!selectedParentCascade) {
        if (selectedPackages.length > 0) {
          setSelectedParentCascade('PackageDropdown');
        }
        if (selectedDistributors.length > 0) {
          setSelectedParentCascade('DistributorDropdown');
        }
      }
    };

    // Memoized selection to stop loop from dropdown callback
    const cascadingPackageDistributorMemo = useMemo(
      cascadingPackageDistributor,
      [selectedPackages, selectedDistributors],
    );
    // get all entitlement mappings for user
    const getEntitlementMappings = (userIds, props) => {
      if (contracts && contracts.some((x) => x.checked)) {
        let queryParams = {
          id: new Date().getTime(),
          pageSize: maxPageSize,
          Filter: `contractNumber in(${contracts
            .filter((x) => x.checked)
            .map((t) => (t.id ? '"' + t.id + '"' : ''))
            .join(',')})
               AND contactid in(${userIds
                 ?.map((t) => (t.contactId ? '"' + t.contactId + '"' : ''))
                 .join(',')})
          `,
          Sort: 'modifiedDate:desc',
        };
        setLoading(true);
        httpService
          .get(Endpoints.userentitlementsApi, queryParams)
          .then((res) => {
            const response = res?.data?.results
              ?.filter((x) => !ProductDeliveryPlatforms.includes(x.distributor))
              .map((c, index) => {
                c.id = index;
                c.isEntitlementExists = true;
                c.distributorData =
                  c.distributor +
                  ' : ' +
                  c.distributorPlatform +
                  ' (' +
                  c.deliveryType +
                  ')';
                c.contactData = userIds.find(
                  (x) => x.contactId == c.contactId,
                ).contactName;
                return c;
              });
            if (packageDistributorData.length > 0) {
              setPackageDistributorData([
                ...packageDistributorData,
                ...response,
              ]);
            } else {
              setPackageDistributorData(response);
            }
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
            if (props) setSelectedUsers(JSON.parse(JSON.stringify(props)));
          });
      } else {
        if (props) setSelectedUsers(JSON.parse(JSON.stringify(props)));
      }
    };

    const handleUserCallback = (props) => {
      //get users diff
      let removedUsers =
        props?.length > 0
          ? selectedUsers?.filter(
              (c) => !props.some((pc) => pc.contactId == c.contactId),
            )
          : selectedUsers;
      let addedUsers =
        selectedUsers?.length > 0
          ? props.filter(
              (c) => !selectedUsers.some((pc) => pc.contactId == c.contactId),
            )
          : props;

      // user added
      // add data to initials user entitlements
      if (addedUsers?.length > 0) {
        getEntitlementMappings(addedUsers, props);
      }
      // user removed
      // remove data from initial user entitlements
      if (removedUsers?.length > 0) {
        setPackageDistributorData(
          packageDistributorData.filter(
            (x) => !removedUsers.some((k) => k.contactId === x.contactId),
          ),
        );
        if (props) setSelectedUsers(JSON.parse(JSON.stringify(props)));
      }
    };
    const callBackUsers = (props) => setUpdatedUsers(props);
    const callBackPackages = (props) => setSelectedPackages(props);
    const callBackDistributors = (params) => {
      let dstsId = [];
      params.forEach((dst) => {
        dstsId.push(dst.distributorId);
      });
      setSelectedDistributors(params);
      setSelectedDistributorsId(dstsId);
    };

    const onGridReadyHandler = (params) => {
      gridRef.current = params;
      params.api.showLoadingOverlay();
    };

    const onSaveHandler = () => {
      try {
        if (
          selectedPackages.length !== 0 &&
          (selectedDistributors.length !== 0 || isAnyAutoSelectPkg()) &&
          selectedUsers.length !== 0 &&
          packageDistributorData.length > 0
        ) {
          let entitleToSave = packageDistributorData.filter(
            (e) => !e.isEntitlementExists,
          );
          let requiredEntitlements = entitleToSave.filter((en) =>
            DistributorUserIDRequiredList.includes(en.distributor),
          );
          if (requiredEntitlements && requiredEntitlements.length > 0) {
            validateUserId(requiredEntitlements);
          } else {
            saveEntitlements(entitleToSave);
          }
        }
      } catch (err) {}
    };

    const validateUserId = (requiredEntitlements) => {
      try {
        let distributorIdCollection = [];
        let contactIdCollection = [];
        let uniqueRequiredEntitlements = [];
        requiredEntitlements.forEach((el, i) => {
          // Get only unique records
          if (
            !uniqueRequiredEntitlements.some(
              (x) =>
                x.contactId === el.contactId &&
                x.distributorId === el.distributorId,
            )
          ) {
            uniqueRequiredEntitlements.push(el);
          }

          distributorIdCollection.push(el.distributorId);
          contactIdCollection.push(el.contactId);
        });

        // create Unique ID's of distributor with userId required
        let uniqueDistributorIdCollection = [
          ...new Set(distributorIdCollection),
        ];
        let uniqueContactIdCollection = [...new Set(contactIdCollection)];

        // create query using above data
        distributorIdCollection = uniqueDistributorIdCollection
          .map((el) => el)
          .join(',');
        contactIdCollection = uniqueContactIdCollection
          .map((el) => `"${el}"`)
          .join(',');

        let queryParams = {
          Filter: `distributorId in(${distributorIdCollection}) and contactId in(${contactIdCollection})`,
          PageSize: maxPageSize,
          id: String(Date.now()),
        };
        setLoading(true);
        httpService
          .get(Endpoints.distributorUserApi, queryParams)
          .then(async (res) => {
            let distributorUser = res?.data?.results;

            uniqueRequiredEntitlements.map((re) => {
              let userDistributorData = distributorUser.find(
                (ud) =>
                  ud.distributorId === re.distributorId &&
                  ud.contactId === re.contactId,
              );
              re.isValidated =
                userDistributorData?.userId &&
                userDistributorData?.userId?.trim();
              re.userId = userDistributorData?.userId;
            });
            // Check if all Distributor Platforms has User ID
            if (uniqueRequiredEntitlements.every((x) => x.isValidated)) {
              saveEntitlements(packageDistributorData);
            } else {
              let distributorUserIdModalData =
                uniqueRequiredEntitlements.filter((en) => !en.isValidated);
              // Set User ID mandatory data to show in modal
              setShowDistributorUserIdModal(distributorUserIdModalData);
            }
          })
          .catch((err) => {
            Notify({
              alert: true,
              type: 'error',
              title: GenericError.somethingWentWrong,
            });
          })
          .finally(() => setLoading(false));
      } catch (err) {}
    };

    const saveEntitlements = (postData, showAlert = true) => {
      let entitlementsForSave = postData.map((en) => {
        return {
          userEntitlementId: '-1',
          contactId: en.contactId,
          productComponentId: en.productComponentId,
          contractNumber: en.contractNumber,
          status: 'Pending Active',
        };
      });
      let entitlementModel = {
        userEntitlements: entitlementsForSave,
        userId: UserId,
      };
      setLoading(true);
      httpService
        .post(Endpoints.userentitlementsApi, entitlementModel)
        .then((res) => {
          if (res.data === 0) {
            Notify({
              alert: showAlert,
              type: 'success',
              title: Verbiage.entitlementsAdded,
              onConfirm: () => afterSaveClick(),
            });
          }
        })
        .catch((err) => {
          Notify({
            alert: true,
            type: 'error',
            title: GenericError.somethingWentWrong,
          });
        })
        .finally(() => setLoading(false));
    };

    const navigate = useNavigate();
    const afterSaveClick = () => {
      navigate(UrlPath.entitlements);
    };

    const handleEntitlementSave = () => {
      saveEntitlements(packageDistributorData);
      setShowDistributorUserIdModal([]);
    };

    const handleClose = () => {
      setShowDistributorUserIdModal([]);
    };

    let isGridDataAvailable =
      packageDistributorData &&
      packageDistributorData.length > 0 &&
      selectedUsers.length !== 0;
    return (
      <>
        {showDistributorUserIdModal.length > 0 && (
          <DistributorUserIdModal
            filteredDistributorUserIdData={showDistributorUserIdModal}
            handleEntitlementSave={handleEntitlementSave}
            handleClose={handleClose}
          />
        )}
        {loading && <Loader type="scaleLoader" cssClass={loaderStyle} />}

        <>
          <Container fluid>
            <Row>
              <Col>
                <div className="add-entitlements left-0 mt-2">
                  {/* New Entitlement */}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <UsersDropdown
                  contracts={contracts}
                  callBackUsers={callBackUsers}
                  isGroupFilterApply={true}
                />
              </Col>
              <Col>
                <PackagesDropdown
                  DataSet={filteredPackages}
                  callBackPackages={callBackPackages}
                />
              </Col>
              <Col>
                <DistributorsDropdown
                  DataSet={filteredDistributors}
                  callBackDistributors={callBackDistributors}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="ag-grid-add-entitlement-table">
                  <div
                    className={
                      isGridDataAvailable ? 'd-none' : 'd-block starter-text'
                    }
                  >
                    <h4>Select any item</h4>
                    <p>
                      Select Users, Packages and Delivery Platform to start the
                      Entitlement Provision Process.
                    </p>
                  </div>
                  <div
                    className={
                      isGridDataAvailable
                        ? 'd-block ag-grid-add-entitlement-table'
                        : 'd-none'
                    }
                    ref={gridContainerRef}
                  >
                    <AgGridComponent
                      autoGroupColumnDef={autoGroupColumnDef}
                      config={defaultConfig}
                      defaultColumnDef={defaultColdef}
                      data={packageDistributorData}
                      columns={columns}
                      onGridReady={onGridReadyHandler}
                    />
                    <Col className="float-end mt-2 mb-2">
                      <button
                        className="btn btn-dark btn-opacity"
                        onClick={onSaveHandler}
                        disabled={packageDistributorData?.every(
                          (x) => x.isEntitlementExists,
                        )}
                      >
                        SAVE ENTITLEMENTS
                      </button>
                    </Col>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </>
      </>
    );
  },
  (op, np) => op?.contracts === np?.contracts,
);

export default AddEntitlements;
