import React, { memo, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import DistributorUserIdModal from '../../Entitlements/DistributorUserIdModal';
import {
  DistributorUserIDRequiredList,
  Endpoints,
  GenericError,
  ReduxAction,
  UserId,
  maxPageSize,
  UserGroupTab,
  entitlementTabName,
  productEntitlementTabName,
  manageUsersTabName,
} from './../../../constants';
import { store } from './../../../redux/store';
import httpService from './../../../services/http-service';
import { Notify } from './../../../sharedcomponents/Alert/Notify';
import ModalBox from './../../../sharedcomponents/modal-box/ModalBox';
import UserGroupEntitlements from './../user-group-entitlements/user-group-entitlements';
import './add-user-group.scss';
import CreateUserGroup from './create-user-group/create-user-group';
import UserGroupEmailPreferences from './user-group-email-preferences';
import UserGroupManageUser from './user-group-manage-user/user-group-manage-user';
import { arrayObjUniqueByKey } from '../../../helpers/unique-arr-obj-by-key';

const AddUserGroup = ({
  show,
  contracts,
  editData,
  setEditData,
  showGroupModal,
  getUserGroup,
  isViewerUser,
}) => {
  const [loading, setLoading] = useState(false);
  const [isGroupCreated, setIsGroupCreated] = useState(false);
  const [activeTab, setActiveTab] = useState('user-group-tab');

  const [showDistributorUserIdModal, setShowDistributorUserIdModal] = useState(
    [],
  );

  //saved entitlements mappings
  const [entitlementMappingData, setEntitlementMappingData] = useState();
  const [distributorEntitlementTab, setDistributorEntitlementTab] = useState();
  const [packageDataSet, setPackageDataSet] = useState();
  //Available entitlements mappings
  const [userEntitlementsData, setUserEntitlementsData] = useState([]);
  // Group users in given contract number
  const [groupUsersData, setGroupUsersData] = useState();

  //variables to lazy load component
  const [isEntitlementEnabled, setIsEntitlementEnabled] = useState(false);
  const [isProductEnabled, setIsProductEnabled] = useState(false);
  const [isManageUsersEnabled, setIsManageUsersEnabled] = useState(false);

  // Collection of postEntitlement & PostGroupUser data
  const [postDataCollection, setPostDataCollection] = useState();
  const onSuccessEntitlementAndUsersMsg =
    'User & Entitlement saved successfully';
  const dispatch = useDispatch();

  useEffect(() => {
    // Clear redux store data on initial load
    clearReduxStoreAddUserGroup();
  }, []);

  useEffect(() => {
    //  Set on create group to avoid extra entitlement api call
    if (editData) {
      setIsGroupCreated(true);
    }
  }, [editData]);

  useEffect(() => {
    checkManageUserDataFetched();
  }, [isEntitlementEnabled, isManageUsersEnabled]);

  const clearReduxStoreAddUserGroup = () => {
    dispatch({
      type: ReduxAction.updateUGEntitlement,
      payload: {
        entitlementsToSave: [],
        initialEntitlements: [],
        selectedEntitlements: [],
      },
    });

    dispatch({
      type: ReduxAction.updateUGProductEntitlement,
      payload: {
        productEntitlementsToSave: [],
        initialProductEntitlements: [],
      },
    });

    dispatch({
      type: ReduxAction.updateUGManageUser,
      payload: {
        initialUsers: [],
        manageUserSelection: [],
      },
    });

    dispatch({
      type: ReduxAction.updateUG_API_Flag,
      payload: {
        entitlementAPISuccess: false,
        groupUsersAPISuccess: false,
      },
    });
  };

  const onAddUserGroupModalClose = () => {
    setEditData();
    showGroupModal(false);
  };

  const onTabSelect = (key) => {
    if (
      [
        entitlementTabName,
        productEntitlementTabName,
        manageUsersTabName,
      ].includes(key)
    ) {
      setIsEntitlementEnabled(true);
      setIsProductEnabled(true);
      setIsManageUsersEnabled(true);
    }

    setActiveTab(key);
  };

  // get all user entitlement mappings
  const getEntitlementMappings = () => {
    let entitlementAPISuccess = true;
    if (isGroupCreated && contracts && contracts.some((x) => x.checked)) {
      let queryParams = {
        id: new Date().getTime(),
        pageSize: maxPageSize,
        Filter: `contractNumber in("${editData?.contractNumber}") ${
          editData?.groupId ? ' AND groupId:"' + editData?.groupId + '"' : ''
        }`,
        Sort: 'modifiedDate:desc',
      };
      httpService
        .get(Endpoints.userGroupEntitlementsApi, queryParams)
        .then((res) => {
          const response = res?.data?.results?.map((c, index) => {
            c.id = index;
            c.isDeleted = 0;
            return c;
          });

          setEntitlementMappingData(response);
        })
        .catch((err) => {
          setEntitlementMappingData([]);
          entitlementAPISuccess = false;
        })
        .finally(() => applyAPIFlagForEntitlements(entitlementAPISuccess));
    } else {
      setEntitlementMappingData([]);
      applyAPIFlagForEntitlements(true);
    }
  };

  const applyAPIFlagForEntitlements = (flag) => {
    // Flag for Entitlements API success or failure
    dispatch({
      type: ReduxAction.updateUG_API_Flag,
      payload: {
        entitlementAPISuccess: flag,
      },
    });
  };

  // populate packages for entitlements tab in User Details
  const getAllUserEntitlements = (contractParam) => {
    let queryParams = {
      pageSize: maxPageSize,
      id: new Date().getTime(),
      filter: `contractNumber in ("${contractParam}")`,
      Field:
        'packageId,packageName,distributor,distributorId,distributorPlatform,distributorDisplayName,distributorPlatformDisplayName,deliveryType,productComponentId,contractNumber,platformCategories,userIdFormat',
    };
    httpService
      .get(Endpoints.contractPackagesApi, queryParams)
      .then((res) => {
        if (res) {
          let metadata = res?.data?.metadata;
          let filteredContractPackages = res?.data?.results;
          setUserEntitlementsData(filteredContractPackages);
          let distributorData = {};
          distributorData.results = filteredContractPackages;
          setDistributorEntitlementTab(distributorData);

          let packageData = res.data?.results.map(
            ({
              distributorId,
              packageId,
              packageName,
              productComponentId,
              contractNumber,
            }) => ({
              distributorId,
              packageId,
              packageName,
              productComponentId,
              contractNumber,
            }),
          );

          // Filtering based on contracts
          packageData = packageData?.filter((item) =>
            contractParam
              ? contractParam === item?.contractNumber
              : editData?.contractNumber === item?.contractNumber,
          );

          packageData = [
            ...new Map(
              packageData.map((item) => [item['packageId'], item]),
            ).values(),
          ];

          metadata.count = packageData.length;
          let finalPackageData = [];
          finalPackageData.metadata = metadata;
          finalPackageData.results = packageData;
          setPackageDataSet(finalPackageData);
        }
      })
      .catch((err) => {
        setUserEntitlementsData([]);
        setPackageDataSet([]);
        setDistributorEntitlementTab([]);
      });
  };

  const saveEntitlements = (
    postData,
    showAlert = true,
    hasUsers, // required only for user api call
    postDataGroupUsers, // required only for user api call
  ) => {
    setLoading(true);
    let isGroupCloneAction =
      store.getState()?.userGroupEntitlement?.isGroupCloneAction;
    httpService
      .post(Endpoints.userGroupEntitlementsApi, postData)
      .then((res) => {
        if (res.data === 0) {
          let successMsg = 'User Group Entitlements have been updated';
          if (isGroupCloneAction) {
            successMsg = 'Group Entitlements have been cloned successfully';
          }

          // START - IF will only execute on saveBothEntitlementsAndGroupUsers()
          if (hasUsers && typeof hasUsers === 'boolean') {
            if (postDataGroupUsers?.userGroupContacts?.length > 0) {
              saveGroupUsers(
                postDataGroupUsers,
                true,
                onSuccessEntitlementAndUsersMsg,
              );
              return;
            }
          } else if (hasUsers && typeof hasUsers === 'string') {
            successMsg = onSuccessEntitlementAndUsersMsg;
          }
          // END - IF will only execute on saveBothEntitlementsAndGroupUsers()

          afterUpdateAction(successMsg, showAlert);
        }
      })
      .catch((err) => {
        Notify({
          alert: true,
          type: 'error',
          title: GenericError.somethingWentWrong,
        });
      })
      .finally(() => {
        setLoading(false);
        dispatch({
          type: ReduxAction.updateUGEntitlement,
          payload: {
            isGroupCloneAction: false,
          },
        });
      });
  };

  const afterUpdateAction = (displayMessage, showAlert) => {
    getUserGroup();
    onAddUserGroupModalClose();
    Notify({
      alert: showAlert,
      type: 'success',
      title: displayMessage,
    });
  };

  let inProgress = false;
  // Fetch Group Users for manage user tab
  const getGroupUsers = () => {
    try {
      let groupUsersAPISuccess = true;
      if (!inProgress) {
        inProgress = true;
        let queryParams = {
          pageSize: maxPageSize,
          filter: `contractNumber in ("${editData?.contractNumber}")`,
          Field: 'email,contactname,contactId,officeLocation,groupid',
        };
        httpService
          .get(Endpoints.userApi, queryParams)
          .then(async ({ data }) => {
            if (data) {
              let groupUser = [];
              let contractUser = [];
              await data.results.forEach((obj) => {
                if (obj?.groupId === editData.groupId) {
                  obj.isDeleted = 0;
                  groupUser.push(obj);
                } else if (!obj.groupId) {
                  contractUser.push(obj);
                }
              });
              groupUser = groupUser.sort((a, b) =>
                a.contactId.localeCompare(b.contactId, undefined, {
                  sensitivity: 'base',
                }),
              );

              let combinedUser = [...groupUser, ...contractUser];
              setGroupUsersData(combinedUser);

              // Save initial group user to redux store
              dispatch({
                type: ReduxAction.updateUGManageUser,
                payload: {
                  initialUsers: groupUser,
                },
              });
            }
            inProgress = false;
          })
          .catch((err) => {
            setGroupUsersData([]);
            inProgress = false;
            groupUsersAPISuccess = false;
          })
          .finally(() => applyAPIFlagForGroupUser(groupUsersAPISuccess));
      }
    } catch (err) {
      setGroupUsersData([]);
      applyAPIFlagForGroupUser(false);
    }
  };

  const applyAPIFlagForGroupUser = (flag) => {
    // Flag for Group User API success or failure
    dispatch({
      type: ReduxAction.updateUG_API_Flag,
      payload: {
        groupUsersAPISuccess: flag,
      },
    });
  };

  const saveGroupUsers = (
    postData,
    showAlert = true,
    hasEntitlements, // required only for Entitlement api call
    postDataEntitlement, // required only for Entitlement api call
  ) => {
    // To save Users in group API call
    setLoading(true);
    httpService
      .post(Endpoints.userGroupContactApi, postData)
      .then((res) => {
        if (res.data === 0) {
          let successMsg = 'Group Users have been updated';

          // START - IF will only execute on saveBothEntitlementsAndGroupUsers()
          if (hasEntitlements && typeof hasEntitlements === 'boolean') {
            if (postDataEntitlement?.userGroupEntitlements?.length > 0) {
              saveEntitlements(
                postDataEntitlement,
                true,
                onSuccessEntitlementAndUsersMsg,
              );
              return;
            }
          } else if (hasEntitlements && typeof hasEntitlements === 'string') {
            successMsg = onSuccessEntitlementAndUsersMsg;
          }
          // END - IF will only execute on saveBothEntitlementsAndGroupUsers()

          getUserGroup();
          onAddUserGroupModalClose();
          Notify({
            alert: showAlert,
            type: 'success',
            title: successMsg,
          });
        }
      })
      .catch((err) => {
        Notify({
          alert: true,
          type: 'error',
          title: GenericError.somethingWentWrong,
        });
      })
      .finally(() => setLoading(false));
  };

  const checkManageUserDataFetched = () => {
    if (editData && (isEntitlementEnabled || isManageUsersEnabled)) {
      getGroupUsers();
    }
  };

  const handleSave = () => {
    // Common save method for tab 2,3,4
    try {
      let { userGroupEntitlement } = store.getState();
      let {
        entitlementAPISuccess,
        groupUsersAPISuccess,
        selectedEntitlements,
        initialUsers,
        manageUserSelection,
      } = userGroupEntitlement;

      const removedUsers = getRemovedUsers(initialUsers, manageUserSelection);
      // Check if any object was removed
      const userRemoved = removedUsers?.length > 0;
      if (userRemoved) {
        Notify({
          alert: true,
          type: 'warning',
          title:
            'Do you want to unlink the user' +
            (removedUsers?.length === 1 ? '' : 's') +
            ' from the group?',
          confirmBtnText: 'YES',
          cancelBtnText: 'NO',
          onConfirm: () =>
            handleFinalSave(
              userGroupEntitlement,
              manageUserSelection,
              entitlementAPISuccess,
              groupUsersAPISuccess,
              selectedEntitlements,
              userRemoved,
            ),
        });
      } else
        handleFinalSave(
          userGroupEntitlement,
          manageUserSelection,
          entitlementAPISuccess,
          groupUsersAPISuccess,
          selectedEntitlements,
          userRemoved,
        );
    } catch (err) {
      dispatch({
        type: ReduxAction.updateUGEntitlement,
        payload: {
          isGroupCloneAction: false,
        },
      });
    }
  };

  const handleFinalSave = async (
    userGroupEntitlement,
    manageUserSelection,
    entitlementAPISuccess,
    groupUsersAPISuccess,
    selectedEntitlements,
    userRemoved,
  ) => {
    try {
      // Check if Entitlement as well as Group user API call is completed
      if (entitlementAPISuccess && groupUsersAPISuccess) {
        // Create PostData for Entitlement
        let postDataEntitlement =
          checkForPostDataEntitlements(userGroupEntitlement);

        // Create PostData for Group Users
        let postDataGroupUsers =
          checkForPostDataGroupUsers(userGroupEntitlement);

        // Check for postData availability
        let postDataEntitlementExist =
          postDataEntitlement?.userGroupEntitlements.length > 0;

        let selectedGroupUsersExist = manageUserSelection.length > 0;

        // START - Pop up open logic when CP and user exist in selection
        let selected_ChannelPartnerExist = false;

        // Filter distributor id based on channel partners like Bloomberg
        let distributorIdCollection = [];
        selectedEntitlements.forEach((data) => {
          if (
            DistributorUserIDRequiredList.includes(data.distributor) &&
            !distributorIdCollection.includes(data.distributorId) // check to avoid duplicate
          ) {
            distributorIdCollection.push(data.distributorId);
          }
        });
        selected_ChannelPartnerExist = distributorIdCollection.length > 0;

        let requiredCPEntitlementsUserId;
        if (selected_ChannelPartnerExist && selectedGroupUsersExist) {
          requiredCPEntitlementsUserId = await getRequiredChannelPartnerData(
            selectedEntitlements,
            manageUserSelection,
            distributorIdCollection,
          );
        }
        // END - Pop up open logic when CP n user exist in selection

        // START - Logic to decide which api to hit first
        let hitEntitlementApiFirst = true;
        if (
          (selected_ChannelPartnerExist && userRemoved) ||
          !postDataEntitlementExist
        ) {
          hitEntitlementApiFirst = false;
        }
        // END - Logic to decide which api to hit first

        // Set Post Data to global
        setPostDataCollection({
          postDataEntitlement,
          postDataGroupUsers,
          hitEntitlementApiFirst,
        });

        // Check if all Distributor Platforms has User ID
        if (
          !requiredCPEntitlementsUserId ||
          requiredCPEntitlementsUserId.every((x) => x.isValidated)
        ) {
          // Save both entitlements & Users
          saveBothEntitlementsAndGroupUsers(
            postDataEntitlement,
            postDataGroupUsers,
            hitEntitlementApiFirst,
          );
        } else {
          // Show UserId Modal
          let distributorUserIdModalData = requiredCPEntitlementsUserId.filter(
            (en) => !en.isValidated,
          );
          // Set User ID mandatory data to show in modal
          setShowDistributorUserIdModal(distributorUserIdModalData);
        }
      } else {
        Notify({
          alert: true,
          type: 'error',
          title: GenericError.somethingWentWrong,
        });
      }
    } catch (err) {
      dispatch({
        type: ReduxAction.updateUGEntitlement,
        payload: {
          isGroupCloneAction: false,
        },
      });
    }
  };

  // Call Mandatory Distributors users api to verify userId
  const getRequiredChannelPartnerData = async (
    selectedEntitlements,
    manageUserSelection,
    distributorIdCollection,
  ) => {
    try {
      // get combined data of entitlement with userId & user data
      let uniqueRequiredEntitlements = getContactIDEachEntitlement(
        selectedEntitlements,
        manageUserSelection,
      );

      // Filter contact id of selected User from Manage user tab
      let contactIdCollection = [];
      manageUserSelection.forEach((data) => {
        contactIdCollection.push(data.contactId);
      });

      // create Unique ID's of distributor with userId required
      let uniqueDistributorIdCollection = [...new Set(distributorIdCollection)];
      let uniqueContactIdCollection = [...new Set(contactIdCollection)];

      // create query using above data
      distributorIdCollection = uniqueDistributorIdCollection
        .map((el) => el)
        .join(',');
      contactIdCollection = uniqueContactIdCollection
        .map((el) => `"${el}"`)
        .join(',');

      if (distributorIdCollection && contactIdCollection) {
        let queryParams = {
          Filter: `distributorId in(${distributorIdCollection}) and contactId in(${contactIdCollection})`,
          PageSize: maxPageSize,
        };

        // Get API: User ID for Distributor based on Contact ID
        setLoading(true);
        let distributorUserApiResponse = await httpService.get(
          Endpoints.distributorUserApi,
          queryParams,
        );

        // After Response
        let distributorUser = [...distributorUserApiResponse?.data?.results];

        uniqueRequiredEntitlements = uniqueRequiredEntitlements.map((re) => {
          let userDistributorData = distributorUser.find(
            (ud) =>
              ud.distributorId === re.distributorId &&
              ud.contactId === re.contactId,
          );
          re.isValidated =
            userDistributorData?.userId && userDistributorData?.userId?.trim();
          re.userId = userDistributorData?.userId;
          return re;
        });

        return uniqueRequiredEntitlements;
      }
    } catch (error) {
      Notify({
        alert: true,
        type: 'error',
        title: GenericError.somethingWentWrong,
      });
    } finally {
      setLoading(false);
    }
  };

  const saveBothEntitlementsAndGroupUsers = (
    postDataEntitlement,
    postDataGroupUsers,
    hitEntitlementApiFirst,
  ) => {
    try {
      // Save API is handled here
      const hasUsers = Boolean(
        postDataGroupUsers && postDataGroupUsers.userGroupContacts !== null,
      );
      const hasEntitlements = Boolean(
        postDataEntitlement &&
          postDataEntitlement.userGroupEntitlements !== null,
      );

      if (hitEntitlementApiFirst) {
        // Save Entitlement first
        saveEntitlements(
          postDataEntitlement,
          !hasUsers,
          hasUsers,
          postDataGroupUsers,
        );
      } else {
        // Save Group users first
        saveGroupUsers(
          postDataGroupUsers,
          !hasEntitlements,
          hasEntitlements,
          postDataEntitlement,
        );
      }
    } catch (err) {
      dispatch({
        type: ReduxAction.updateUGEntitlement,
        payload: {
          isGroupCloneAction: false,
        },
      });
    }
  };

  const getContactIDEachEntitlement = (
    selectedEntitlements,
    manageUserSelection,
  ) => {
    try {
      // get combined data of entitlement with userId & user data
      let userGroupEntitlements = selectedEntitlements.filter((obj) =>
        DistributorUserIDRequiredList.includes(obj.distributor),
      );

      // Create Unique Array of object with given key
      userGroupEntitlements = arrayObjUniqueByKey(
        userGroupEntitlements,
        'distributorId',
      );

      let tempData = [];
      manageUserSelection.forEach((obj1) => {
        userGroupEntitlements.forEach((obj2) => {
          let newObj = { ...obj1, ...obj2 };
          newObj.distributorData = `${newObj.distributor} : ${newObj.distributorPlatform} (${newObj.deliveryType})`;
          newObj.contactData = `${newObj.contactName}  (${newObj.email})`;
          tempData.push(newObj);
        });
      });

      return tempData;
    } catch (err) {}
  };

  const checkForPostDataEntitlements = (userGroupEntitlement) => {
    // Fnc to merge entitlement & product entitlement postdata
    try {
      let { entitlementsToSave, productEntitlementsToSave } =
        userGroupEntitlement;

      let isUGEntitlementToSaveAvailable = entitlementsToSave?.length > 0;
      let isUGProductEntitlementToSaveAvailable =
        productEntitlementsToSave?.length > 0;

      let mergedEntitlements = [];

      if (
        isUGEntitlementToSaveAvailable ||
        isUGProductEntitlementToSaveAvailable
      ) {
        if (isUGEntitlementToSaveAvailable) {
          mergedEntitlements.push(...entitlementsToSave);
        }
        if (isUGProductEntitlementToSaveAvailable) {
          mergedEntitlements.push(...productEntitlementsToSave);
        }
        let finalResult = {
          userGroupEntitlements: mergedEntitlements,
          userId: UserId,
        };

        return finalResult;
      } else {
        return null;
      }
    } catch (err) {}
  };

  const checkForPostDataGroupUsers = (userGroupEntitlement) => {
    // Fnc to merge entitlement & product entitlement postdata
    try {
      let { initialUsers, manageUserSelection } = userGroupEntitlement;

      let currentUsersWithGroupId = [];
      let currentUsersWithoutGroupId = [];

      manageUserSelection.forEach((obj) => {
        if (obj?.groupId) {
          currentUsersWithGroupId.push(obj.contactId);
        } else {
          currentUsersWithoutGroupId.push(obj);
        }
      });

      let combinedData_Initial_And_NewUsers = [
        ...initialUsers,
        ...currentUsersWithoutGroupId,
      ];

      let groupUsersPostData = [];
      combinedData_Initial_And_NewUsers.forEach((obj) => {
        let newObj = {
          groupId: editData.groupId,
          groupName: editData.groupName,
          contractNumber: editData.contractNumber,
          contactId: obj.contactId,
          contactName: obj.contactName,
          email: obj.email,
          isDeleted: 0,
        };
        let userRemoved = !currentUsersWithGroupId.includes(obj.contactId);
        if (obj?.groupId && userRemoved) {
          newObj.isDeleted = 1;
        }

        // To avoid existing user from been added to postData
        if (
          !obj.groupId ||
          (obj?.isDeleted !== undefined && obj?.isDeleted !== newObj.isDeleted)
        )
          groupUsersPostData.push(newObj);
      });

      if (groupUsersPostData?.length > 0) {
        let finalResult = {
          userGroupContacts: groupUsersPostData,
          userId: UserId,
        };

        return finalResult;
      } else {
        return null;
      }
    } catch (error) {}
  };

  const afterSaveDistributorUserId = () => {
    try {
      // close distributor id modal
      handleClose();

      const {
        postDataEntitlement,
        postDataGroupUsers,
        hitEntitlementApiFirst,
      } = postDataCollection;

      saveBothEntitlementsAndGroupUsers(
        postDataEntitlement,
        postDataGroupUsers,
        hitEntitlementApiFirst,
      );
    } catch (err) {}
  };

  const getRemovedUsers = (initialUsers, manageUserSelection) => {
    try {
      const removedObjects = initialUsers.filter(
        (obj1) =>
          !manageUserSelection.some(
            (obj2) => obj2.contactId === obj1.contactId,
          ),
      );

      return removedObjects;
    } catch (err) {}
  };

  const handleClose = () => {
    setShowDistributorUserIdModal([]);
  };

  return (
    <>
      {showDistributorUserIdModal && (
        <DistributorUserIdModal
          filteredDistributorUserIdData={showDistributorUserIdModal}
          handleEntitlementSave={afterSaveDistributorUserId}
          handleClose={handleClose}
          isUserGroupComp={true}
        />
      )}

      <ModalBox
        backdrop="static"
        show={show}
        size="xl"
        contentClassName="modal-height-fix"
        handleClose={onAddUserGroupModalClose}
        showLoader={loading}
        modalTitle={(editData ? 'Edit' : 'Create') + ' Group'}
      >
        <div className="ug-tab tabs-main mt-0">
          <Tabs
            fill
            justify
            defaultActiveKey={UserGroupTab}
            activeKey={activeTab}
            onSelect={onTabSelect}
          >
            <Tab eventKey={UserGroupTab} title="Group Details">
              <CreateUserGroup
                contracts={contracts}
                setLoading={setLoading}
                setActiveTab={setActiveTab}
                editData={editData}
                setEditData={setEditData}
                getUserGroup={getUserGroup}
                handleClose={onAddUserGroupModalClose}
                setIsEntitlementEnabled={setIsEntitlementEnabled}
                setIsManageUsersEnabled={setIsManageUsersEnabled}
                isViewerUser={isViewerUser}
              />
            </Tab>
            {/* Manage User */}
            <Tab
              eventKey={manageUsersTabName}
              title="Manage Users"
              disabled={!Boolean(editData)}
            >
              {isManageUsersEnabled && (
                <UserGroupManageUser
                  editData={editData}
                  groupUsersData={groupUsersData}
                  onSaveClick={handleSave}
                  isViewerUser={isViewerUser}
                ></UserGroupManageUser>
              )}
            </Tab>
            <Tab
              eventKey={entitlementTabName}
              title="Entitlements"
              disabled={!Boolean(editData)}
            >
              {isEntitlementEnabled && (
                <UserGroupEntitlements
                  editData={editData}
                  getEntitlementMappings={getEntitlementMappings}
                  distributorDataSet={distributorEntitlementTab}
                  entitlementMappingData={entitlementMappingData}
                  userEntitlementsData={userEntitlementsData}
                  packageDataSet={packageDataSet}
                  isGroupCreated={isGroupCreated}
                  getAllUserEntitlements={getAllUserEntitlements}
                  setActiveTab={setActiveTab}
                  onSaveClick={handleSave}
                  isViewerUser={isViewerUser}
                ></UserGroupEntitlements>
              )}
            </Tab>
            <Tab
              eventKey={productEntitlementTabName}
              title="Email preferences"
              disabled={!Boolean(editData)}
            >
              {isProductEnabled && (
                <UserGroupEmailPreferences
                  contractNumber={editData?.contractNumber}
                  entitlementMappingData={entitlementMappingData}
                  editData={editData}
                  onSaveClick={handleSave}
                  isViewerUser={isViewerUser}
                ></UserGroupEmailPreferences>
              )}
            </Tab>
          </Tabs>
        </div>
      </ModalBox>
    </>
  );
};

export default memo(AddUserGroup);
