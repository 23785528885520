import React, { useState, useEffect } from 'react';
import { Container, ListGroup, Navbar } from 'react-bootstrap';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { NavLink } from 'reactstrap';

import NavbarUser from './NavbarUser';

import logo from './../../assets/img/logo/logo.svg';
import LogoutButton from '../../pages/auth/LogoutButton';
import store from '../../redux/store';
import { useSelector } from 'react-redux';
import { UrlPath, contractTypePrimary } from '../../constants';

const NavbarMySubscription = () => {
  const location = useLocation();
  const [menu, setMenu] = useState([]);
  const [userName, setUserName] = useState('');
  const [isHovering, setIsHovering] = useState(false);
  const [contractType, setContractType] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    let storeData = store.getState();
    let storeUserInfo = storeData?.userInfo;
    if (
      storeUserInfo &&
      storeUserInfo.userInfo &&
      userName !==
        storeUserInfo.userInfo.FirstName + ' ' + storeUserInfo.userInfo.LastName
    ) {
      setUserName(
        storeUserInfo.userInfo.FirstName +
          ' ' +
          storeUserInfo.userInfo.LastName,
      );
    }
  }, [useSelector((x) => x.userInfo)]);

  useEffect(() => {
    let storeData = store.getState();
    // Retrieve contractType from storeData
    let contractType = storeData?.userContracts?.userContracts[0]?.contractType;
    if (contractType) {
      setContractType(contractType);
    }
  }, [useSelector((x) => x.userContracts)]);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  useEffect(() => {
    setMenu([
      {
        name: 'My Subscription',
        key: 1,
        keyName: '',
        to: '/my-subscription',
        tag: Link,
        path: ['/my-subscription'],
      },
      {
        name: 'Packages & Delivery',
        key: 2,
        keyName: '',
        to: '/packages',
        tag: Link,
        path: [
          '/packages',
          '/packages/package-details',
          '/packages/delivery-platform-details',
        ],
      },
      {
        name: userName,
        key: 3,
        keyName: 'username',
        to: '/user-info',
        tag: Link,
        path: ['/user-info'],
      },
    ]);
  }, [userName]);

  const onChangeHistoryClick = () => {
    navigate(UrlPath.changeHistory);
  };

  const onManageUserIDsClick = () => {
    navigate(UrlPath.manageUserIDs);
  };

  return (
    <Navbar variant="light" expand="lg" className="navbar-bg" sticky="top">
      <Container fluid className="mx-lg-1">
        <div className="logo-section">
          <a className="navbar-brand" href="/my-subscription">
            <img src={logo} className="img-fluid me-1 logo-img" alt="Admin" />
          </a>
          <span className="logo-sub-heading">Subscription Manager</span>
        </div>
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            {menu.map((m) => (
              <li className="nav-item" key={m.key}>
                {m.keyName !== 'username' ? (
                  <NavLink
                    tag={m.tag}
                    key={m.key}
                    className={
                      m.path.some((p) => location.pathname.includes(p))
                        ? 'active'
                        : ''
                    }
                    to={m.to}
                  >
                    {m.name}
                  </NavLink>
                ) : (
                  <div
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <NavbarUser
                      name={m.name}
                      navLinkData={{
                        tag: Link,
                        key: m.key,
                        className: m.path.includes(location.pathname)
                          ? 'active'
                          : '',
                        to: m.to,
                      }}
                    />
                    {isHovering && (
                      <div className="position-absolute">
                        <ListGroup className="position-fixed option-list">
                          {/* <ListGroup.Item className="option-list-item">
                            <div
                              id="history_option"
                              className="option-list-item-text"
                              onClick={onChangeHistoryClick}
                            >
                              Change History
                            </div>
                          </ListGroup.Item> */}
                          {contractType !== contractTypePrimary && (
                            <ListGroup.Item className="option-list-item">
                              <div
                                id="manage_user_ids_option"
                                className="option-list-item-text"
                                onClick={onManageUserIDsClick}
                              >
                                Manage User IDs
                              </div>
                            </ListGroup.Item>
                          )}
                          <ListGroup.Item className="option-list-item">
                            <LogoutButton />
                          </ListGroup.Item>
                        </ListGroup>
                      </div>
                    )}
                  </div>
                )}
              </li>
            ))}
          </ul>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarMySubscription;
