import persistDataStore from './../redux/persistStore';
import store from '../redux/store';
import httpService from './../services/http-service';
import { Endpoints, ReduxAction } from './../constants';
import { useDispatch } from 'react-redux';

export const getCSAContractsData = async () => {
  let csaActionData =
    persistDataStore.getState()?.userActionData?.csaUserActionInfo;
  let queryParamsContract = {
    PageSize: 5000,
    Field: 'contractNumber,hc_AccountID',
  };
  if (csaActionData?.type && csaActionData?.value) {
    switch (csaActionData?.type) {
      case 'hccompany': {
        queryParamsContract.Filter = `hC_AccountID:"${csaActionData?.value}"`;

        const contractResponse = await httpService.get(
          Endpoints.locationApi,
          queryParamsContract,
        );
        let tempState = contractResponse.data?.results?.map((el, i) => {
          return {
            label: el.contractNumber,
            id: el.contractNumber,
            checked: true,
            ...el,
          };
        });
        return tempState;
      }
      case 'account': {
        queryParamsContract.Filter = `accountId:"${csaActionData?.value}"`;

        const contractResponse = await httpService.get(
          Endpoints.locationApi,
          queryParamsContract,
        );
        let tempState = contractResponse.data?.results?.map((el, i) => {
          return {
            label: el.contractNumber,
            id: el.contractNumber,
            checked: true,
            ...el,
          };
        });
        return tempState;
      }

      case 'contract': {
        let queryParams = {
          PageSize: 5000,
          Field: 'contractNumber,hc_AccountID',
          Filter: `contractNumber:"${csaActionData?.value}"`,
        };
        const contractResponse = await httpService.get(
          Endpoints.locationApi,
          queryParams,
        );
        let hc_accountid = contractResponse.data?.results[0].hC_AccountID;
        let csaSelectedContract = [
          {
            label: csaActionData?.value,
            id: csaActionData?.value,
            checked: true,
            contractNumber: csaActionData?.value,
            hC_AccountID: hc_accountid,
          },
        ];
        return csaSelectedContract;
      }
      case 'user': {
        let queryParams = {
          PageSize: 5000,
          Field: 'contractNumber,hc_AccountID',
          Filter: `email:"${csaActionData?.value}"`,
        };
        const usersContractResponse = await httpService.get(
          Endpoints.userApi,
          queryParams,
        );
        let tempState = usersContractResponse.data?.results?.map((el, i) => {
          return {
            label: el.contractNumber,
            id: el.contractNumber,
            checked: true,
            ...el,
          };
        });
        return tempState;
      }
    }
  }
  return [];
};

export const getDbConfigData = async (dispatch, token) => {
  try {
    // Get the dbConfig data from the DB and save it in store
    let dbConfigData = store.getState()?.dbConfig;
    if (Object.keys(dbConfigData).length > 0) {
      return dbConfigData;
    }

    let queryParams = {
      keyItem: `JOBFUNCTIONCOLLECTION, DESIGNATIONCOLLECTION, SFS_EXPORT_LIMIT, MANAGE_USER_ID_DISTRIBUTORS, REPORT_CRITERIAS`,
    };
    let dbConfigResponse = await httpService.get(
      Endpoints.configurationApi,
      queryParams,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    let newDbConfig = {
      jobFunctionCollection: [],
      designationCollection: [],
      ManageUserIdDistributors: [],
      reportCriterias: [],
      exportLimit: 25000,
    };

    dbConfigResponse?.data?.results?.map((item) => {
      switch (item.keyItem) {
        case 'JOBFUNCTIONCOLLECTION':
          newDbConfig.jobFunctionCollection = JSON.parse(item.keyValue);
          break;
        case 'DESIGNATIONCOLLECTION':
          newDbConfig.designationCollection = JSON.parse(item.keyValue);
          break;
        case 'SFS_EXPORT_LIMIT':
          newDbConfig.exportLimit = JSON.parse(item.keyValue);
          break;
        case 'MANAGE_USER_ID_DISTRIBUTORS':
          newDbConfig.ManageUserIdDistributors = JSON.parse(item.keyValue);
          break;
        case 'REPORT_CRITERIAS':
          newDbConfig.reportCriterias = JSON.parse(item.keyValue);
        default:
          break;
      }
    });

    dispatch({
      type: ReduxAction.dbConfig,
      payload: newDbConfig,
    });

    return newDbConfig;
  } catch (err) {}
};

export const fetchUserPreferences = async (email, dispatch, token) => {
  const queryParams = {
    pageSize: 5000,
    Filter: `email:"${email.toLowerCase()}"`,
  };

  try {
    const resp = await httpService.get(
      Endpoints.userPreferenceApi,
      queryParams,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const response = resp.data.results;
    return response;
  } catch (error) {
    console.error('Error fetching user preferences:', error);
  }
};

// Fetch user contracts from user API to get contract type for primary user
export const fetchUserContracts = async (email, dispatch, token) => {
  try {
    const queryParams = {
      pageSize: 5000,
      Filter: `email:"${email.toLowerCase()}"`,
    };
    // Make an API request to fetch user contracts
    const resp = await httpService.get(Endpoints.userApi, queryParams, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const response = resp.data.results;
    // Dispatch an action to store the fetched user contracts in the Redux store
    dispatch({
      type: ReduxAction.userContractsSet,
      payload: response,
    });
    return response;
  } catch (error) {
    console.error('Error fetching user contracts:', error);
  }
};
