import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';
import { Col, Row } from 'react-bootstrap';
import AgGridComponent from '../../sharedcomponents/ag-grid/AgGrid';
import { useSelector } from 'react-redux';
import store from '../../redux/store';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import httpService from '../../services/http-service';
import {
  Endpoints,
  maxExportSizeLimit,
  ContractDisplayOptions,
  Verbiage,
  userPreferencesKey,
  UserId,
  columnDisplayOptionKey,
  GenericError,
} from './../../constants';
import { getSortColumns } from '../../sharedcomponents/ag-grid/ag-grid-helper';
import Loader from '../../components/Loader';
import { debounce } from '../../helpers/debounce';
import { ReactComponent as FilterIcon } from '../../assets/icons/common/filter.svg';
import { ReactComponent as GearIcon } from '../../assets/icons/common/gear.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/common/download.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/common/cross-icon.svg';
import './reports.scss';
import MultiSelectDropdown from './../../sharedcomponents/multi-select-dropdown/multi-select-dropdown';
import persistDataStore from './../../redux/persistStore';
import { getCSAContractsData } from './../../helpers/component-helper-methods';
import { addContract } from '../../redux/actions';
import DropdownInput from '../../sharedcomponents/DropdownInput/DropdownInput';
import ReportFilterDropdown from './ReportFilterDropdown';
import ReportsColumnsOptions from './reports-columns-options';
import InfoIcon from './../../assets/icons/common/info.png';
import StickyTooltip from './../../sharedcomponents/StickyTooltip/StickyTooltip';
import { Notify } from './../../sharedcomponents/Alert/Notify';
import FileSaver from 'file-saver';

const Reports = () => {
  const [overlayTemplateMessage, setOverlayTemplateMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [dropDownLoader, setDropDownLoader] = useState(true);
  const [contractsResult, setContractsResult] = useState([]);
  const [loadGrid, setLoadGrid] = useState(false);
  const [isCSAUser, setIsCSAUser] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [columnState, setColumnState] = useState([]);
  const [contractDisplayOption, setContractDisplayOption] = useState();
  const [isFilterDropdownVisible, setIsFilterDropdownVisible] = useState(true); // State variable for filter dropdown visibility
  const [criteriaDropdowns, setCriteriaDropdowns] = useState([]);
  const [selectedCriteriaOption, setSelectedCriteriaOption] = useState('');
  const [selectedCriterias, setSelectedCriterias] = useState([]);
  const [currentDropdownUpdate, setCurrentDropdownUpdate] = useState(); // State variable to track the current dropdown update for disable/enable
  const [keyIndex, setKeyIndex] = useState(1);
  const [initialCriteriaList, setInitialCriteriaList] = useState();
  const [criteriaList, setCriteriaList] = useState();
  const [totalExportCount, setTotalExportCount] = useState(0);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [availableCriteriaAmount, setAvailableCriteriaAmount] = useState(0);
  const [disableAlphamail, setDisableAlphamail] = useState(false);
  const [disableDeliveryAndPackages, setDisableDeliveryAndPackages] =
    useState(false);
  const [disableAlphamailColumn, setDisableAlphamailColumn] = useState(false);
  const tooltipRef = useRef(null);
  const infoIconRef = useRef(null);

  const [isExportInprogress, setIsExportInprogress] = useState(false);
  const [initialContracts, setInitialContracts] = useState();
  const [lastSelection, setLastSelection] = useState();
  const [defColumnValue, setDefColumnValue] = useState();
  const [payload, setPayload] = useState();
  //Integer filter keys
  const IntegerFilterKeys = ['productId', 'packageId', 'distributorId'];
  const formik = useFormik({
    initialValues: {
      criteriaId: '', // Default empty value for the dropdown
    },
    validationSchema: Yup.object({
      criteriaId: Yup.string().required('Criteria is required'),
    }),
  });
  const gridRef = useRef();
  const dispatch = useDispatch();

  const toggleFilterDropdown = () => {
    setIsFilterDropdownVisible(!isFilterDropdownVisible);
  };

  const closeColumnsOptionsModal = () => {
    setShowModal(false);
  };

  const getColumnOptionUserPreference = async () => {
    let storeData = store.getState();
    let storeUserInfo = storeData?.userInfo.userInfo;
    if (storeUserInfo?.userPreferences?.length > 0) {
      const displayColumnOptionPreference = storeUserInfo.userPreferences.find(
        (item) => item.preferenceKey === columnDisplayOptionKey,
      );
      if (displayColumnOptionPreference) {
        localStorage.setItem(
          'columnState',
          displayColumnOptionPreference.preferenceValue,
        );
      }
    }
  };

  const handleSaveColumnOption = (
    newColumns,
    newSelectedItems,
    DefaultColumnOption,
  ) => {
    const updatedColumnState = newColumns.map((col) => {
      if (col.field === 'productName') {
        return {
          ...col,
          checked: newSelectedItems[col.field],
          hide: disableAlphamailColumn,
          suppressColumnsToolPanel: false,
        };
      }

      return {
        field: col.field,
        headerName: col.headerName,
        checked: newSelectedItems[col.field],
        hide: col.hide,
        suppressColumnsToolPanel: col.suppressColumnsToolPanel,
      };
    });
    const newDefaultColumns = defColumnValue.map((item) =>
      item.field === 'productName'
        ? {
            ...item,
            hide: disableAlphamailColumn,
            suppressColumnsToolPanel: disableAlphamailColumn,
          }
        : item,
    );

    let queryParams = {
      email: UserId,
      preferencekey: columnDisplayOptionKey,
      isactive: 1,
      preferencevalue: DefaultColumnOption
        ? JSON.stringify(newDefaultColumns)
        : JSON.stringify(updatedColumnState),
    };
    gridRef?.current?.api.showLoadingOverlay();
    httpService
      .post(Endpoints.userPreferenceApi, queryParams)
      .then((resp) => {
        if (resp.data === 0) {
          const newState = DefaultColumnOption
            ? newDefaultColumns
            : updatedColumnState;
          setColumnState(newState);
          let storeData = store.getState();
          let storeUserInfo = storeData?.userInfo.userInfo;
          let setPreferences = storeUserInfo.userPreferences.map((pref) =>
            pref.preferenceKey === columnDisplayOptionKey
              ? {
                  ...pref,
                  preferenceValue: JSON.stringify(newState),
                }
              : pref,
          );
          storeUserInfo.userPreferences = setPreferences;
          localStorage.setItem('columnState', JSON.stringify(newState));
          if (totalExportCount > 0 && isExportInprogress) {
            gridRef?.current?.api?.setGridOption(
              'serverSideDatasource',
              getServerSideDatasource(),
            );
          } else {
            setLoadGrid(false);
          }
          gridRef?.current?.api?.hideOverlay();
          Notify({
            alert: true,
            type: 'success',
            title: DefaultColumnOption
              ? 'Column options reset successfully.'
              : 'Column options saved succesfully',
          });
        }
      })
      .catch((err) => {
        Notify({
          alert: true,
          type: 'error',
          title: GenericError.somethingWentWrong,
        });
      });
  };

  const handleResetColumnOption = (columns, selectedItems) => {
    handleSaveColumnOption(columns, selectedItems, true);
  };

  const removeCriteria = (index, type) => {
    if (type == 'Delivery Platforms') {
      setDisableAlphamailColumn(false);
    }
    //push it to the dropdown list
    let deletedCriteria = initialCriteriaList.find(
      (x) => x.criteriaName === type,
    );
    criteriaList.push(deletedCriteria);
    setCriteriaList([...criteriaList]);

    //remove from filter criteria
    let remainingCriterias = criteriaDropdowns.slice(
      criteriaDropdowns.findIndex((d) => d.dropdownType === type) + 1,
    );
    let updatedIndex = index;
    criteriaDropdowns.splice(index, criteriaDropdowns.length - index);
    setCriteriaDropdowns([...criteriaDropdowns]);

    setTimeout(() => {
      for (let j = 0; j < remainingCriterias.length; j++) {
        if (remainingCriterias[j].dropdownType) {
          addCriteriaDropdown(
            remainingCriterias[j].dropdownType,
            true,
            updatedIndex,
          );
          updatedIndex = updatedIndex + 1;
        }
      }
      formik.setFieldValue('criteriaId', '');
      setSelectedCriteriaOption('');
    }, 50);
  };

  const resetCriteria = () => {
    criteriaDropdowns.forEach((item, index) => {
      item.values = [];
      if (index > 0) {
        item.isDropdownDisabled = true;
      }
    });
    setCriteriaDropdowns([...criteriaDropdowns]);
    formik.setFieldValue('criteriaId', '');
    setSelectedCriteriaOption('');
    let resetContracts = initialContracts?.map((x) => {
      return { ...x };
    });
    setContractsResult(resetContracts);
    if (!isCSAUser) dispatch(addContract(resetContracts));
    setDisableAlphamailColumn(false);
  };

  const getFilterCriteriaParams = (keyNumber) => {
    if (keyNumber === 0) {
      if (
        contractsResult?.length > 0 &&
        contractsResult.some((el) => el.checked)
      )
        return `contractNumber in (${contractsResult
          .filter((x) => x.checked)
          .map((t) => (t.id ? '"' + t.id + '"' : ''))
          .join(',')})`;
      else return '';
    } else {
      return criteriaDropdowns[keyNumber - 1]?.values?.length > 0
        ? `${
            criteriaDropdowns[keyNumber - 1]?.filterKey
          } IN(${criteriaDropdowns[keyNumber - 1]?.values
            .map((t) =>
              t[criteriaDropdowns[keyNumber - 1]?.filterKey]
                ? IntegerFilterKeys.includes(
                    criteriaDropdowns[keyNumber - 1]?.filterKey,
                  )
                  ? t[criteriaDropdowns[keyNumber - 1]?.filterKey]
                  : '"' + t[criteriaDropdowns[keyNumber - 1]?.filterKey] + '"'
                : '',
            )
            .join(',')})`
        : '';
    }
  };
  const selectedCriteriaDropdown = (option, isManuallyadded, keyNumber) => {
    let filterParam = getFilterCriteriaParams(keyNumber);
    let dropdownDetails = {};
    dropdownDetails.filterParam = filterParam;
    dropdownDetails.keyIndex = keyNumber;
    dropdownDetails.isDropdownDisabled = true;
    switch (option) {
      case initialCriteriaList[0].criteriaName:
        dropdownDetails.dropdownType = 'Accounts';
        dropdownDetails.filterKey = 'accountId';
        break;
      case initialCriteriaList[1].criteriaName:
        dropdownDetails.dropdownType = 'Locations';
        dropdownDetails.filterKey = 'officeLocationId';
        break;
      case initialCriteriaList[2].criteriaName:
        dropdownDetails.dropdownType = 'Groups';
        dropdownDetails.filterKey = 'groupId';
        break;
      case initialCriteriaList[3].criteriaName:
        dropdownDetails.dropdownType = 'Users';
        dropdownDetails.filterKey = 'emailId';
        break;
      case initialCriteriaList[4].criteriaName:
        dropdownDetails.dropdownType = 'Delivery Platforms';
        dropdownDetails.filterKey = 'distributorId';
        break;
      case initialCriteriaList[5].criteriaName:
        dropdownDetails.dropdownType = 'Alphamail Reports';
        dropdownDetails.filterKey = 'productId';
        break;
      case initialCriteriaList[6].criteriaName:
        dropdownDetails.dropdownType = 'Packages';
        dropdownDetails.filterKey = 'packageId';
        break;
    }
    return dropdownDetails;
  };

  const getRowId = (params) => {
    if (
      params?.data?.userEntitlementId != null &&
      params?.data?.userEntitlementId != ''
    ) {
      return 'entitlement-' + params.data.userEntitlementId;
    } else {
      // assign a unique ID to each data item
      return Object.entries(params?.data).join(',');
    }
  };

  const clickApply = () => {
    if (gridRef.current) {
      setIsExportInprogress(true);
      gridRef?.current?.api?.setGridOption(
        'serverSideDatasource',
        getServerSideDatasource(),
      );
    }
  };

  const clickApplyDebounce = debounce(clickApply, 500);

  const addCriteriaDropdown = async (
    newCriteria = '',
    isManuallyAdded = false,
    keyNumber,
  ) => {
    if ((isManuallyAdded && newCriteria) || selectedCriteriaOption) {
      let dropdownOption = criteriaDropdowns;
      dropdownOption.push(
        selectedCriteriaDropdown(
          isManuallyAdded && newCriteria ? newCriteria : selectedCriteriaOption,
          isManuallyAdded,
          criteriaDropdowns.length,
        ),
      );
      setCriteriaDropdowns([...dropdownOption]);

      setCriteriaList(
        criteriaList.filter(
          (item) => item.criteriaName !== selectedCriteriaOption,
        ),
      );
      setSelectedCriteriaOption('');
      setDropDownLoader(false);
      setKeyIndex(keyIndex + 1);
    }
  };

  const defaultConfig = {
    editable: true,
    isExportCSV: false,
    isExportExcel: false,
    pivotPanelShow: '',
    pagination: false,
    isAutoSizeColums: true,
    suppressDragLeaveHidesColumns: true,
    enableCharts: false,
    sideBar: false,
    rowGroupPanelShow: false,
    overlayNoRowsTemplate: overlayTemplateMessage,
    domLayout: 'normal',
  };

  // Common column filter params
  const columnFilterParams = {
    filter: 'agTextColumnFilter',
    enableRowGroup: false,
    enablePivot: false,
    menuTabs: ['generalMenuTab', 'columnsMenuTab'],
    enableValue: false, // disable value aggregation in column header
  };

  const defaultColdef = {
    ...columnFilterParams,
    resizable: true,
    sortable: true,
  };

  const getContractsDataSet = async (userEmail, isCSAUser) => {
    userEmail = userEmail.toLowerCase();
    try {
      let queryParamsContract = {
        PageSize: 50000,
        Field:
          'contractNumber,contractType,contractDescription,bulkEntitlementEnable',
        Filter: !isCSAUser ? `email:"${userEmail}"` : '',
      };
      const contractResponse = await httpService.get(
        Endpoints.contractApi,
        queryParamsContract,
      );

      let storeData = store.getState();
      let storeContracts = storeData.contract;
      let csaActionData =
        persistDataStore.getState()?.userActionData?.csaUserActionInfo;
      let otherContracts = storeContracts?.contracts;
      if (storeContracts?.contracts?.length <= 0) {
        otherContracts = await getCSAContractsData();
      }
      let tempState = contractResponse?.data?.results?.map((el) => {
        let isChecked = true;
        if (isCSAUser && csaActionData?.value) {
          isChecked =
            otherContracts?.length > 0
              ? otherContracts.some(
                  (x) => x.contractNumber == el.contractNumber && x.checked,
                )
              : false;
        }

        return {
          label:
            el.contractNumber +
            (el.contractDescription ? ' - ' + el.contractDescription : ''),
          id: el.contractNumber,
          checked: isChecked,
          bulkEntitlementEnable: el.bulkEntitlementEnable,
          contractDescription: el.contractDescription,
          ...el,
        };
      });
      setDropDownLoader(false);
      setContractsResult(tempState);
      setInitialContracts(
        tempState.map((x) => {
          return { ...x };
        }),
      );
      selectedCriterias.push({
        filterKey: 'contractNumber',
        values: tempState.filter((x) => x.checked),
      });
      if (!isCSAUser) dispatch(addContract(tempState));
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(async () => {
    let storeData = store.getState();
    let storeUserInfo = storeData?.userInfo;

    if (storeUserInfo && storeUserInfo.userInfo) {
      let csaUser = false;
      if (
        storeUserInfo.userInfo.SPGGroups.includes('GRP_SFS_CSA') ||
        storeUserInfo.userInfo.SPGGroups.includes('GRP_SFS_VIEWER')
      ) {
        csaUser = true;
      }
      setIsCSAUser(csaUser);
      await getContractDisplayOption(storeUserInfo.userInfo.userPreferences);
      let storeContracts = storeData?.contract;
      if (csaUser || !storeContracts?.contracts?.length > 0) {
        getContractsDataSet(storeUserInfo.userInfo.email, csaUser);
      } else {
        setContractsResult(storeContracts.contracts);
        setInitialContracts(
          storeContracts.contracts.map((x) => {
            return { ...x };
          }),
        );
        setLoading(false);
        selectedCriterias.push({
          filterKey: 'contractNumber',
          values: storeContracts.contracts.filter((x) => x.checked),
        });
      }
    }
  }, [useSelector((x) => x.userInfo)]);

  useEffect(() => {
    if (lastSelection && criteriaDropdowns[lastSelection.index]) {
      criteriaDropdowns[lastSelection.index].values = lastSelection.values;
      if (lastSelection.filterKey === 'contractNumber') {
        if (
          contractsResult?.length > 0 &&
          contractsResult.some((el) => el.checked)
        ) {
          criteriaDropdowns[0].filterParam = `contractNumber in (${contractsResult
            .filter((x) => x.checked)
            .map((t) => (t.id ? '"' + t.id + '"' : ''))
            .join(',')})`;
          criteriaDropdowns[0].isDropdownDisabled = false;
        } else {
          criteriaDropdowns[0].filterParam = '';
          criteriaDropdowns[0].isDropdownDisabled = true;
        }
        criteriaDropdowns[0].values = [];
        for (let i = 1; i < criteriaDropdowns.length; i++) {
          criteriaDropdowns[i].values = [];
          criteriaDropdowns[i].filterParam = '';
          criteriaDropdowns[i].isDropdownDisabled = true;
        }
      } else {
        for (
          let i = lastSelection.index + 1;
          i < criteriaDropdowns.length;
          i++
        ) {
          criteriaDropdowns[i].filterParam =
            criteriaDropdowns[i - 1]?.values?.length > 0
              ? `${criteriaDropdowns[i - 1]?.filterKey} IN(${criteriaDropdowns[
                  i - 1
                ]?.values
                  .map((t) =>
                    t[criteriaDropdowns[i - 1]?.filterKey]
                      ? IntegerFilterKeys.includes(
                          criteriaDropdowns[i - 1]?.filterKey,
                        )
                        ? t[criteriaDropdowns[i - 1]?.filterKey]
                        : '"' + t[criteriaDropdowns[i - 1]?.filterKey] + '"'
                      : '',
                  )
                  .join(',')})`
              : '';
          criteriaDropdowns[i].values = [];
          if (!criteriaDropdowns[i].filterParam)
            criteriaDropdowns[i].isDropdownDisabled = true;
        }
      }
      setCriteriaDropdowns([...criteriaDropdowns]);
    }
  }, [lastSelection]);
  const selectedCount = contractsResult.reduce(
    (counter, obj) => (obj.checked === true ? (counter += 1) : counter),
    0,
  );

  // set selected contracts
  useEffect(() => {
    const reduceCallback = setTimeout(() => {
      if (!isCSAUser) dispatch(addContract(contractsResult));
    }, 1000);
    return () => clearTimeout(reduceCallback);
  }, [contractsResult]);

  const getContractDisplayOption = async (userPreferences) => {
    try {
      if (
        userPreferences?.find(
          (x) => x.preferenceKey === userPreferencesKey['displayContract'],
        )
      ) {
        setContractDisplayOption(
          userPreferences.find(
            (x) => x.preferenceKey === userPreferencesKey['displayContract'],
          ),
        );
      } else {
        setContractDisplayOption({
          preferenceValue: ContractDisplayOptions[0].contractDisplayOption,
        });
      }
    } catch (err) {}
  };

  const positionTooltip = () => {
    if (infoIconRef.current && tooltipRef.current) {
      const iconRect = infoIconRef.current.getBoundingClientRect();
      const tooltipEl = tooltipRef.current;

      tooltipEl.style.position = 'absolute';
      tooltipEl.style.top = `${iconRect.top - 6 + window.scrollY}px`;
      tooltipEl.style.left = `${iconRect.right + 7}px`;
    }
  };

  useEffect(() => {
    if (isTooltipVisible) {
      positionTooltip();
    }

    const handleClickOutside = (event) => {
      if (isTooltipVisible && !event.target.classList.contains('info-icon')) {
        setIsTooltipVisible(false);
      }
    };

    if (isTooltipVisible) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isTooltipVisible]);

  const criteriaHandler = (e, key) => {
    formik.setFieldValue(key, e.criteriaId);
    setSelectedCriteriaOption(e.criteriaName);
  };

  const getContractDisplayColumns = (contractDisplayType) => {
    return !(
      contractDisplayType === contractDisplayOption?.preferenceValue ||
      contractDisplayOption?.preferenceValue ===
        ContractDisplayOptions[2].contractDisplayOption
    );
  };

  const getContractLabelKey = () => {
    if (contractDisplayOption) {
      if (
        contractDisplayOption.preferenceValue ===
        ContractDisplayOptions[1].contractDisplayOption
      ) {
        return 'contractDescription';
      }
      if (
        contractDisplayOption.preferenceValue ===
        ContractDisplayOptions[2].contractDisplayOption
      ) {
        return 'label';
      }
    }

    return 'contractNumber';
  };

  useEffect(() => {
    const selectedDropdownTypes = criteriaDropdowns.map(
      (item) => item.dropdownType,
    );
    const newDisableAlphamail =
      selectedDropdownTypes.includes('Delivery Platforms');
    const newDisableDeliveryAndPackages =
      selectedDropdownTypes.includes('Alphamail Reports');

    setDisableAlphamail(newDisableAlphamail);
    setDisableDeliveryAndPackages(newDisableDeliveryAndPackages);
  }, [criteriaDropdowns]);

  useEffect(() => {
    setCriteriaList((prevList) =>
      (Array.isArray(prevList) ? [...prevList] : []).map((item) => ({
        ...item,
        disabled:
          (disableAlphamail && item.criteriaName === 'Alphamail Reports') ||
          (disableDeliveryAndPackages &&
            (item.criteriaName === 'Delivery Platforms' ||
              item.criteriaName === 'Packages')),
      })),
    );
  }, [disableAlphamail, disableDeliveryAndPackages, criteriaDropdowns]);

  useEffect(() => {
    try {
      if (!contractDisplayOption) return;
      getColumnOptionUserPreference();
      let savedState = JSON.parse(localStorage.getItem('columnState'));
      savedState = Object.keys(savedState)?.length > 0 ? savedState : '';
      const defaultColumns = [
        {
          headerName: 'Email ID',
          headerTooltip: 'Email ID',
          field: 'emailId',
          checked: true,
        },
        {
          headerName: 'Name',
          headerTooltip: 'Name',
          field: 'contactName',
          checked: true,
        },
        {
          headerName: 'Account',
          headerTooltip: 'Account',
          field: 'account',
          checked: true,
        },
        {
          headerName: 'Location',
          headerTooltip: 'Location',
          field: 'location',
          checked: true,
        },
        {
          headerName: 'Contract ID',
          headerTooltip: 'Contract ID',
          field: 'contractNumber',
          checked: true,
          hide: isCSAUser
            ? false
            : contractDisplayOption?.preferenceValue
            ? getContractDisplayColumns(
                ContractDisplayOptions[0].contractDisplayOption,
              )
            : true,
          suppressColumnsToolPanel: isCSAUser
            ? false
            : contractDisplayOption?.preferenceValue
            ? getContractDisplayColumns(
                ContractDisplayOptions[0].contractDisplayOption,
              )
            : true,
        },
        {
          headerName: 'Contract Description',
          headerTooltip: 'Contract Description',
          field: 'contractDescription',
          checked: true,
          hide: isCSAUser
            ? false
            : getContractDisplayColumns(
                ContractDisplayOptions[1].contractDisplayOption,
              ),
          suppressColumnsToolPanel: isCSAUser
            ? false
            : getContractDisplayColumns(
                ContractDisplayOptions[1].contractDisplayOption,
              ),
        },
        {
          headerName: 'Group Name',
          headerTooltip: 'Group Name',
          field: 'groupName',
          checked: true,
        },
        {
          headerName: 'Product',
          headerTooltip: 'Product',
          field: 'productName',
          hide: disableAlphamailColumn,
          checked: true,
        },
        {
          headerName: 'Package',
          headerTooltip: 'Package',
          field: 'packageName',
          checked: true,
        },
        {
          headerName: 'Delivery Platform (Type)',
          headerTooltip: 'Delivery Platform (Type)',
          field: 'deliveryPlatform',
          checked: true,
        },
        {
          headerName: 'Job Function',
          headerTooltip: 'Job Function',
          field: 'jobFunction',
          checked: true,
        },
        {
          headerName: 'Role',
          headerTooltip: 'Role',
          field: 'designation',
          checked: true,
        },
      ];
      setDefColumnValue([...defaultColumns]);
      // If saved state is available, update the column state based on saved state & user preferences
      let columnsToSet = savedState
        ? defaultColumns.map((col) => {
            let colSavedState = savedState.find((el) => el.field === col.field);
            if (
              col.field === 'contractNumber' ||
              col.field === 'contractDescription'
            ) {
              return {
                ...col,
                checked: colSavedState?.checked ?? col?.checked,
              };
            }
            return {
              ...col,
              checked: colSavedState?.checked,
              hide: colSavedState?.hide,
            };
          })
        : [...defaultColumns];

      if (savedState) {
        // rearrange columnstoset array based on savedstate
        const savedStateMap = new Map(
          savedState.map((item, index) => [item.field, index]),
        );

        const sortedColumnsToSet = columnsToSet.sort((a, b) => {
          const indexA = savedStateMap.has(a.field)
            ? savedStateMap.get(a.field)
            : Infinity;
          const indexB = savedStateMap.has(b.field)
            ? savedStateMap.get(b.field)
            : Infinity;
          return indexA - indexB;
        });
        columnsToSet = sortedColumnsToSet;
      }

      columnsToSet = columnsToSet.map((col) => {
        if (col.field === 'productName') {
          return {
            ...col,
            hide: disableAlphamailColumn,
            suppressColumnsToolPanel: disableAlphamailColumn,
          };
        }
        return col;
      });

      // update saved column options if user's contract display preference changes
      if (savedState) {
        columnsToSet.forEach((column) => {
          const { field } = column;

          if (field === 'contractNumber' || field === 'contractDescription') {
            if (isCSAUser) {
              column.hide = false;
              column.suppressColumnsToolPanel = false;
            } else {
              let hide;

              switch (contractDisplayOption.preferenceValue) {
                case 'ContractNumber':
                  hide = field === 'contractDescription';
                  break;
                case 'ContractDescription':
                  hide = field === 'contractNumber';
                  break;
                case 'ContractNumberDescription':
                  hide = false;
                  break;
                default:
                  hide = true;
                  break;
              }

              column.hide = hide;
              column.suppressColumnsToolPanel = hide;
            }
          }
        });
      }

      setColumnState(columnsToSet);

      if (gridRef.current && gridRef.current.api) {
        gridRef.current.api.setColumnDefs(
          columnsToSet
            .filter((col) => col.checked)
            .map((col) => ({ ...col, headerTooltip: col.headerName })),
        );
      }
    } catch (err) {}
  }, [contractDisplayOption, isCSAUser, disableAlphamailColumn]);

  const reportsGridColumns = columnState
    .filter((col) => col.checked)
    .map((col) => ({
      ...col,
      headerTooltip: col.headerName,
    }));

  const getCriteriaDropdownList = async () => {
    let storeData = store.getState();
    setInitialCriteriaList(storeData.dbConfig.reportCriterias || []);
    setCriteriaList(storeData.dbConfig.reportCriterias || []);
    setAvailableCriteriaAmount(storeData?.dbConfig?.reportCriterias?.length);
  };

  const openColumnsOptionsModal = () => {
    setShowModal(true);
  };

  const formatDataByFilterKey = (data, params) => {
    const result = {};
    data.forEach((item) => {
      if (item.values != undefined && item.values.length > 0) {
        const filteredValues = item.values.filter((value) => value.checked);
        if (filteredValues.length > 0) {
          result[item.filterKey] = filteredValues
            .map((value) => {
              return `${value[item.filterKey]}`;
            })
            .join("','");
        }
      }
    });
    // Combine result with contract numbers
    const contractNumbers = contractsResult
      ?.filter((contract) => contract.checked)
      .map((contract) => contract.contractNumber)
      .join("','");

    return {
      ...result,
      contractNumber: contractNumbers,
      status: "Active','Pending Active",
    };
  };

  const filterColumnOption = (params) => {
    let displayedColumns = params?.api?.getAllDisplayedColumns();
    if (displayedColumns.length === 0) return '';
    const checkedColumn = displayedColumns
      .map((item) => {
        let newValue = '';
        switch (item.colId) {
          case 'emailId':
            newValue = 'email';
            break;
          case 'account':
            newValue = 'accountname';
            break;
          case 'location':
            newValue = 'officelocationname';
            break;
          case 'contactName':
            newValue = 'firstname,lastname';
            break;
          case 'deliveryPlatform':
            newValue =
              'display_distributor,display_distributorplatform,deliverytype';
            break;
          default:
            newValue = item.colId;
        }
        return newValue.toLowerCase();
      })
      .join(',');
    return checkedColumn;
  };

  const getServerSideDatasource = (filters) => {
    return {
      getRows: async (params) => {
        try {
          let AgParams = params?.request;

          let filteredContract = contractsResult
            ?.filter((contract) => contract.checked)
            .map((contract) => contract.id);
          if (filteredContract) {
            const payload = formatDataByFilterKey(criteriaDropdowns, params);
            setPayload(payload);
            const defaultSort = 'contactName:asc';
            const sortColumns = await getSortColumns(AgParams, defaultSort);
            const sort = await removeDuplicateFields(sortColumns);

            const queryParams = {
              PageSize: AgParams.endRow - AgParams.startRow,
              FilteredColumns: filterColumnOption(params),
              Page:
                AgParams.startRow / (AgParams.endRow - AgParams.startRow) + 1,
              sort: sort,
            };

            const buildQueryString = (params) => {
              const query = new URLSearchParams(params).toString();
              return `?${query}`;
            };
            params.api.showLoadingOverlay();

            httpService
              .post(
                Endpoints.entitlementReportApi + buildQueryString(queryParams),
                payload,
              )
              .then((res) => {
                if (res?.data?.metadata?.count == 0) {
                  setOverlayTemplateMessage('No Data Available');
                }
                const response = res?.data?.results.map((c, index) => {
                  c.id = index;
                  c.isEntitlementExists = true;
                  c.deliveryPlatform =
                    c.distributor +
                    ' : ' +
                    c.distributorPlatform +
                    ' (' +
                    c.deliveryType +
                    ')';
                  c.location = c.officeLocation;
                  return c;
                });
                setTotalExportCount(res?.data?.metadata?.count);
                params.success({
                  rowData: response,
                  rowCount: res?.data?.metadata?.count,
                });
                if (response?.length === 0) {
                  setOverlayTemplateMessage('No Data Available');
                  gridRef?.current?.api?.showNoRowsOverlay();
                } else {
                  gridRef?.current?.api?.hideOverlay();
                }
              })
              .catch((err) => {
                params.fail();
                gridRef?.current?.api?.hideOverlay();
              });
          } else {
            setOverlayTemplateMessage('No Data Available');
            gridRef?.current?.api?.showNoRowsOverlay();
          }
        } catch (err) {
          params.fail();
        }
      },
    };
  };

  const removeDuplicateFields = async (sortString) => {
    // Remove duplicate fields in sorting string
    const fields = sortString.split(',');
    const uniqueFields = new Set();
    const result = [];

    await fields.forEach((field) => {
      let [fieldName, order] = field.trim().split(':'); // Split by ':'

      // Replace 'distributorData' with the specified fields
      if (fieldName === 'deliveryPlatform') {
        const replacements = [
          `display_distributor:${order}`,
          `display_distributorplatform:${order}`,
          `deliverytype:${order}`,
        ];
        replacements.forEach((newlyReplaced) => {
          const [replacementFieldName] = newlyReplaced.split(':');
          if (!uniqueFields.has(replacementFieldName)) {
            uniqueFields.add(replacementFieldName);
            result.push(newlyReplaced);
          }
        });
      } else if (fieldName === 'contactName') {
        const replacements = [`firstname:${order}`, `lastname:${order}`];
        replacements.forEach((newlyReplaced) => {
          const [replacementFieldName] = newlyReplaced.split(':');
          if (!uniqueFields.has(replacementFieldName)) {
            uniqueFields.add(replacementFieldName);
            result.push(newlyReplaced);
          }
        });
      } else if (fieldName === 'emailId') {
        uniqueFields.add(fieldName);
        result.push(`email:${order}`);
      } else if (fieldName === 'account') {
        uniqueFields.add(fieldName);
        result.push(`accountname:${order}`);
      } else if (fieldName === 'location') {
        uniqueFields.add(fieldName);
        result.push(`officelocationname:${order}`);
      } else {
        if (!uniqueFields.has(fieldName)) {
          uniqueFields.add(fieldName);
          result.push(`${fieldName}:${order}`);
        }
      }
    });

    return result.join(',');
  };

  const onGridReadyHandler = (params) => {
    gridRef.current = params;
    gridRef?.current?.api?.showNoRowsOverlay();
    getCriteriaDropdownList();

    params.api.addEventListener('columnMoved', onColumnMoved);
  };

  const onColumnMoved = () => {
    const gridColumnState = gridRef.current.api.getColumnState();

    const columnStateMap = columnState.reduce((acc, col) => {
      acc[col.field] = col;
      return acc;
    }, {});

    const reorderedColumnState = gridColumnState.map((gc) => {
      const existingCol = columnStateMap[gc.colId];
      if (existingCol) {
        return { ...existingCol, hide: gc.hide };
      } else {
        return {
          field: gc.colId,
          headerName: gc.colId,
          checked: true,
          hide: gc.hide,
          suppressColumnsToolPanel: gc.suppressColumnsToolPanel,
        };
      }
    });
    const missingColumns = columnState
      .filter((col) => !gridColumnState.some((gc) => gc.colId === col.field))
      .map((col) => ({ ...col, checked: false }));

    const finalColumnState = [...reorderedColumnState, ...missingColumns];

    setColumnState(finalColumnState);
    localStorage.setItem('columnState', JSON.stringify(finalColumnState));
  };

  // Check if the export size limit is fetched from the API
  function checkAndLoadGrid() {
    if (!loadGrid) {
      if (maxExportSizeLimit !== null) {
        setLoadGrid(true);
        setOverlayTemplateMessage(`<p>
                                    Specify criteria using 'Filter' option on top-right of the grid. Use 'Columns'
                                    option to define the column list and order.<br><br>
                                
                                    Please note only Active User Entitlements can be exported. User Entitlements
                                    for Alphamail and other platforms must be downloaded separately.<br><br>

                                    Maximum of ${maxExportSizeLimit} records can be downloaded at once. Please update your
                                    criteria to ensure number of records for download is less than the limit.
                                </p>`);
        clearInterval(intervalID);
        return;
      }
    }
  }
  let intervalID = setInterval(checkAndLoadGrid, 1000);

  const onDropdownSelectionChange = (e, k, dropdown) => {
    setLastSelection({
      filterKey: dropdown.filterKey,
      values: e?.filter((x) => x.checked),
      index: k,
    });

    if (dropdown.filterKey === 'distributorId') {
      setDisableAlphamailColumn(e.some((item) => item.checked));
    }
  };

  const onDropdownSelectionChangedDebounce = debounce(
    onDropdownSelectionChange,
    1000,
  );

  const onContractDropdownChange = (e) => {
    setContractsResult(e);
    onDropdownSelectionChangedDebounce(e, 0, { filterKey: 'contractNumber' });
  };

  const toggleTooltip = () => {
    setIsTooltipVisible(!isTooltipVisible);
  };

  const getEntitlementsQueryParams = async () => {
    try {
      if (gridRef) {
        const AgParams = gridRef.current?.api;
        const defaultSort = 'contactName:asc';
        const sortColumns = await getSortColumns(AgParams, defaultSort);
        const sort = await removeDuplicateFields(sortColumns);
        // Get the column headers for excel export
        const excelColumnHeaders = gridRef.current?.api
          ?.getAllDisplayedColumns()
          .map((item) => item.colDef.headerName);

        let queryParams = {
          Page: 1,
          PageSize: maxExportSizeLimit,
          FilteredColumns: filterColumnOption(gridRef.current),
          excelColumnHeaders,
          // userPreferences: contractDisplayOption?.preferenceValue,
          Field:
            'packageId,packageName,distributor,distributorDisplayName,distributorPlatformDisplayName,distributorId,distributorPlatform,deliveryType,productComponentId,platformCategories',
          Sort: sort,
        };

        return queryParams;
      }
    } catch (error) {}
  };

  const downloadDetailsInCSV = async () => {
    if (totalExportCount > 0 && totalExportCount <= maxExportSizeLimit) {
      try {
        setIsExportInprogress(false);
        Notify({
          alert: true,
          type: '',
          title: 'Your download is in progress',
        });
        var queryParams = await getEntitlementsQueryParams();

        const buildQueryString = (params) => {
          const query = new URLSearchParams(params).toString();
          return `?${query}`;
        };

        await httpService
          .post(
            Endpoints.entitlementExportApi + buildQueryString(queryParams),
            payload,
            {},
            {
              responseType: 'blob',
            },
          )
          .then(async (response) => {
            let currentDate = new Date();
            const customFormatted = `${currentDate.getFullYear()}-${
              currentDate.getMonth() + 1
            }-${currentDate.getDate()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}`;
            FileSaver.saveAs(
              response.data,
              'SubscriptionManager_ActiveUsersreport_' + customFormatted,
            );
            Notify({
              alert: true,
              type: 'success',
              title: 'User Entitlements Exported Successfully',
            });
          })
          .catch((error) => {
            Notify({
              alert: true,
              type: 'error',
              title: 'Something went wrong',
            });
          })
          .finally(() => {
            setIsExportInprogress(true);
          });
      } catch (error) {
        setIsExportInprogress(false);
      }
    } else if (totalExportCount > maxExportSizeLimit) {
      Notify({
        alert: true,
        type: 'error',
        dialogClassName: 'alert-export-success-max-width',
        title:
          'Number of records for export (' +
          totalExportCount +
          ') are greater than the allowed limit (' +
          maxExportSizeLimit +
          '). Please adjust the filter criteria to ensure the number of records being exported are within limit.',
      });
    }
  };
  // update current disable state after get dropdown data
  useEffect(() => {
    try {
      if (currentDropdownUpdate && currentDropdownUpdate.index !== null) {
        criteriaDropdowns[currentDropdownUpdate.index].isDropdownDisabled =
          !currentDropdownUpdate.isData;
        setCriteriaDropdowns([...criteriaDropdowns]);
      }
    } catch (err) {}
  }, [currentDropdownUpdate]);

  //method to handle the dropdown data options change
  const onAfterDropdownDataChange = (index, isData) => {
    setCurrentDropdownUpdate({ index: index, isData: isData });
  };

  return (
    <>
      {!loadGrid || loading ? (
        <Loader type="scaleLoader" />
      ) : (
        <>
          <div className="row">
            <div className="col-lg-7 col-md-6">
              <span id="" className="page-header">
                Active User Entitlements Export
              </span>
            </div>
            <div className="col-lg-5 col-md-6 action-item-bar">
              <span className="me-2 cursor-pointer filterIcon icon-divider position-unset">
                <DownloadIcon
                  className={`${
                    totalExportCount > 0 && isExportInprogress
                      ? 'icon-active'
                      : 'cursor-default'
                  }`}
                  alt="Download"
                  width="14"
                  height="18"
                  title="Download"
                  onClick={downloadDetailsInCSV}
                ></DownloadIcon>
              </span>
              <span
                onClick={downloadDetailsInCSV}
                className={`filterIconText ${
                  totalExportCount > 0 && isExportInprogress
                    ? 'cursor-pointer downloadIconTextColor'
                    : 'cursor-default downloadIconTextGrayOutColour'
                }`}
              >
                Download ({totalExportCount})
              </span>
              <span className="fw-normal ms-2 me-2 separator"></span>
              <span className="me-2 filterIcon cursor-pointer">
                <FilterIcon
                  className="icon-active"
                  alt="Filter"
                  width="14"
                  title="Filter"
                  onClick={toggleFilterDropdown}
                ></FilterIcon>
              </span>
              <span
                onClick={toggleFilterDropdown}
                className="filterIconText cursor-pointer"
              >
                Filter
              </span>
              <span className="fw-normal ms-2 me-2 separator"></span>
              <span className="me-1 filterIcon cursor-pointer">
                <GearIcon
                  className="icon-active"
                  alt="Columns"
                  width="18"
                  title="Columns"
                  onClick={openColumnsOptionsModal}
                ></GearIcon>
              </span>
              <span
                className="filterIconText cursor-pointer"
                onClick={() => openColumnsOptionsModal()}
              >
                Columns
              </span>
            </div>
          </div>
          {showModal && (
            <ReportsColumnsOptions
              show={showModal}
              handleClose={closeColumnsOptionsModal}
              loading={loading}
              reportsGridColumns={columnState}
              contractDisplayOption={contractDisplayOption?.preferenceValue}
              isCSAUser={isCSAUser}
              gridRef={gridRef}
              handleSave={handleSaveColumnOption}
              handleReset={handleResetColumnOption}
              disableAlphamailColumn={disableAlphamailColumn}
            />
          )}
          <div className="row">
            <div
              className={
                isFilterDropdownVisible
                  ? 'col-lg-12 col-md-12'
                  : 'col-lg-9 col-md-8'
              }
            >
              <div className="ag-grid-table reports">
                <AgGridComponent
                  config={defaultConfig}
                  defaultColumnDef={defaultColdef}
                  columns={reportsGridColumns}
                  onGridReady={onGridReadyHandler}
                  getRowId={getRowId}
                />
              </div>
              <div>
                Maximum of{' '}
                <span style={{ fontWeight: 1000 }}>
                  {maxExportSizeLimit !== undefined
                    ? maxExportSizeLimit
                    : 'N/A'}
                </span>{' '}
                records can be downloaded at once.
              </div>
            </div>
            <div
              className={
                isFilterDropdownVisible
                  ? 'd-none'
                  : 'col-lg-3 col-md-4 filter-panel'
              }
            >
              <div className="pt-1 pb-1 d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <p className="fw-bolder mb-0">Filter Criteria</p>
                  <img
                    ref={infoIconRef}
                    src={InfoIcon}
                    className="info-icon ms-1 mt-1"
                    style={{
                      cursor: 'pointer',
                      width: '15px',
                      height: '15px',
                    }}
                    onClick={toggleTooltip}
                  />
                  {isTooltipVisible &&
                    ReactDOM.createPortal(
                      <StickyTooltip
                        ref={(el) => {
                          tooltipRef.current = el;
                          if (el) {
                            el.style.height = 'auto';
                            positionTooltip();
                          }
                        }}
                        text={Verbiage.filterCriteriaTooltip}
                        onClose={toggleTooltip}
                      />,
                      document.body,
                    )}
                </div>
                <div className="d-flex">
                  <button
                    className="btn-opacity border-0 ms-3 me-1 filterButton"
                    onClick={resetCriteria}
                  >
                    Reset
                  </button>
                  <span className="fw-normal ms-1 me-2 separator"></span>
                  <button
                    className="btn-opacity border-0 filterButton"
                    disabled={selectedCount == 0}
                    onClick={clickApplyDebounce}
                  >
                    Apply
                  </button>
                  <CrossIcon
                    onClick={toggleFilterDropdown}
                    className="mt-2 ms-2 cursor-pointer"
                    alt="Close"
                    title="Close"
                  ></CrossIcon>
                </div>
              </div>
              <div
                id="container-block"
                className="report-parent filter-dropdowns"
              >
                <Row className="report-mb-3">
                  <Col className="col-lg-11 col-md-11 filter-dropdown-pr1">
                    <MultiSelectDropdown
                      searchKeys={['label']}
                      enableSearch={true}
                      enableSelectAll={true}
                      defaultSelectAll={true}
                      labelKeyName={getContractLabelKey()}
                      ddOptionsKey="id"
                      dropDownLoader={dropDownLoader}
                      ddOptions={contractsResult}
                      setddOptions={onContractDropdownChange}
                      ddName={'contracts'}
                      ddPlaceHolder={`Contracts (${selectedCount} of ${contractsResult.length} selected)`}
                    />
                  </Col>
                </Row>
                {criteriaDropdowns.map((dropdown, index) => (
                  <Row className="report-mb-3">
                    <Col>
                      <ReportFilterDropdown
                        dropdownType={dropdown.dropdownType}
                        initialCriteriaList={initialCriteriaList}
                        filterValue={dropdown.filterParam}
                        entityKey={index}
                        handleSetDropdownOptions={(e, k) => {
                          onDropdownSelectionChange(e, k, dropdown);
                        }}
                        handleRemoveCriteria={removeCriteria}
                        contractsResult={contractsResult}
                        dropdownRef={criteriaDropdowns}
                        dropDownDisabled={dropdown?.isDropdownDisabled}
                        handleAfterDropdownChange={onAfterDropdownDataChange}
                      ></ReportFilterDropdown>
                    </Col>
                  </Row>
                ))}
              </div>
              <div className="newCriteria">
                {/* {criteriaDropdowns.length === 0 &&
                  criteriaList?.length === availableCriteriaAmount && (
                    <div className="filter-font">
                      Select desired criteria in dropdown and click '+ Add
                      Criteria'. Ensure that at least one option is selected in
                      the last criteria before adding new one
                    </div>
                  )} */}
                <div className="filter-font">
                  Select desired criteria in dropdown and click '+ Add
                  Criteria'. Ensure that at least one option is selected in the
                  last criteria before adding new one
                </div>
                <div className="addCriteriaDD">
                  <DropdownInput
                    name="criteriaId"
                    targetValue="criteriaId"
                    dropdownOptions={criteriaList?.sort((a, b) =>
                      (a.criteriaId || '').localeCompare(b.criteriaId || ''),
                    )}
                    formik={formik}
                    ddPlaceHolder={
                      formik.values.criteriaId !== '' &&
                      criteriaList?.length > 0 &&
                      criteriaList.some(
                        (item) => item.criteriaId === formik.values.criteriaId,
                      )
                        ? criteriaList?.filter(
                            (el) => el.criteriaId === formik.values.criteriaId,
                          )[0]?.criteriaName
                        : 'Select new criteria here'
                    }
                    labelKeyName="criteriaName"
                    ddName={'criteria'}
                    onChangeHandler={(e) => criteriaHandler(e, 'criteriaId')}
                  />
                  <span
                    className={`addCriteriaText me-1 ${
                      selectedCount === 0 ||
                      (criteriaDropdowns.length > 0 &&
                        (!criteriaDropdowns[criteriaDropdowns.length - 1]
                          ?.values ||
                          criteriaDropdowns[criteriaDropdowns.length - 1].values
                            ?.length === 0))
                        ? 'disabled'
                        : ''
                    }`}
                    onClick={
                      selectedCount === 0 ||
                      (criteriaDropdowns.length > 0 &&
                        (!criteriaDropdowns[criteriaDropdowns.length - 1]
                          ?.values ||
                          criteriaDropdowns[criteriaDropdowns.length - 1].values
                            ?.length === 0))
                        ? null
                        : addCriteriaDropdown
                    }
                  >
                    + Add Criteria
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Reports;
