import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Modal } from 'react-bootstrap';
import Loader from '../../components/Loader';
import { ReactComponent as DragIcon } from './../../assets/icons/common/drag-icon.svg';
import { ContractDisplayOptions, loaderStyle } from './../../constants';
import { Notify } from './../../sharedcomponents/Alert/Notify';

const ReportsColumnsOptions = ({
  show,
  handleClose,
  loading,
  reportsGridColumns,
  contractDisplayOption,
  isCSAUser,
  gridRef,
  handleSave,
  handleReset,
  disableAlphamailColumn,
}) => {
  const [selectedItems, setSelectedItems] = useState({});
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (!gridRef.current) return;

    let gridColumns = gridRef.current.api.getColumnState();

    const reorderedReportsGridColumns = gridColumns
      .map((gc) => {
        return reportsGridColumns.find((rgc) => rgc.field === gc.colId);
      })
      .filter(Boolean);

    const uncheckedItems = reportsGridColumns.filter(
      (rgc) => !gridColumns.some((gc) => gc.colId === rgc.field),
    );

    let finalColumns = [...reorderedReportsGridColumns, ...uncheckedItems];

    {
      const idx = finalColumns.findIndex((col) => col.field === 'productName');
      if (idx !== -1) {
        finalColumns[idx] = disableAlphamailColumn
          ? { ...finalColumns[idx], disabled: true, checked: false }
          : { ...finalColumns[idx], disabled: false };
      } else {
        const alphamailColumn = reportsGridColumns.find(
          (col) => col.field === 'productName',
        );
        if (alphamailColumn)
          finalColumns.push(
            disableAlphamailColumn
              ? { ...alphamailColumn, disabled: true, checked: false }
              : alphamailColumn,
          );
      }
    }

    finalColumns = finalColumns.filter((column) => {
      if (isCSAUser) return true;
      switch (contractDisplayOption) {
        case ContractDisplayOptions[0].contractDisplayOption:
          return column.field !== 'contractDescription';
        case ContractDisplayOptions[1].contractDisplayOption:
          return column.field !== 'contractNumber';
        case ContractDisplayOptions[2].contractDisplayOption:
        default:
          return true;
      }
    });

    const initialSelectedItems = {};
    finalColumns.forEach((column) => {
      initialSelectedItems[column.field] = column.checked;
    });

    setSelectedItems(initialSelectedItems);
    setColumns(finalColumns);
  }, [reportsGridColumns, gridRef, disableAlphamailColumn]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setSelectedItems((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedColumns = Array.from(columns);
    const [removed] = reorderedColumns.splice(result.source.index, 1);
    reorderedColumns.splice(result.destination.index, 0, removed);

    setColumns(reorderedColumns);
  };

  const saveColumnOption = () => {
    if (!ifAnyOneColumnIsChecked()) return;
    handleSave(columns, selectedItems, false);
    handleClose();
  };

  const resetColumnOption = () => {
    handleReset(columns, selectedItems);
    handleClose();
  };

  const ifAnyOneColumnIsChecked = () => {
    try {
      // Check if any one column is selected else show alert
      let anyOneColumnSelected = false;
      Object.keys(selectedItems).forEach((key) => {
        if (selectedItems[key]) {
          anyOneColumnSelected = true;
        }
      });

      if (!anyOneColumnSelected)
        Notify({
          alert: true,
          type: 'warning',
          title: 'Please select at least one column before saving',
        });

      return anyOneColumnSelected;
    } catch (err) {}
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="md"
      centered
      backdrop="static"
    >
      {loading && <Loader type="scaleLoader" cssClass={loaderStyle} />}

      <Modal.Header closeButton className="user-form-title">
        Column Options
      </Modal.Header>
      <Modal.Body>
        <>
          <p>
            You can check or uncheck columns below to remove or hide them from
            your report. You can also drag and drop columns to define column
            order.
          </p>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="columns">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {columns.map((column, index) => (
                    <Draggable
                      key={column.field}
                      draggableId={column.field}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="d-flex align-items-center"
                        >
                          <div
                            className={column.disabled ? 'disabledWrapper' : ''}
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name={column.field}
                              id={column.field}
                              checked={selectedItems[column.field]}
                              onChange={handleCheckboxChange}
                              disabled={column.disabled || false}
                            />
                          </div>
                          <DragIcon
                            className="drag-icon"
                            width="20"
                            height="20"
                            title={`Drag ${column.headerName}`}
                          />
                          <label
                            className="form-check-label ms-2"
                            htmlFor={column.field}
                          >
                            {column.headerName}
                          </label>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-dark btn-opacity" onClick={saveColumnOption}>
          Save
        </button>
        <button
          className="btn btn-dark btn-opacity"
          onClick={resetColumnOption}
        >
          Reset
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReportsColumnsOptions;
