import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import Loader from '../../components/Loader';
import httpService from '../../services/http-service';
import { Notify } from '../../sharedcomponents/Alert/Notify';
import DropdownInput from '../../sharedcomponents/DropdownInput/DropdownInput';
import TileRepeater from '../../sharedcomponents/tiles/tile_repeater';
import {
  Endpoints,
  GenericError,
  ProductDeliveryPlatforms,
  ReduxAction,
  contractType,
  contractTypeCSMPlus,
  contractTypePrimary,
  contractTypeMarketBasics,
  maxPageSize,
  packageDatasetProps,
} from './../../constants';
import { addContract } from './../../redux/actions';
import { store } from './../../redux/store';
import Distributors from './Distributors';
import { packageImageNames, packageImageNamesCSMPlus } from './packageImages';

const Packages = () => {
  const PackageTabName = 'PackageTab';
  const DistributorTabName = 'DistributorTab';
  const [loading, setLoading] = useState(true);
  const [packageDataSets, setPackageDataSet] = useState();
  const [marketDataPackage, setMarketDataPackage] = useState([]);
  const [marketBasicsPackage, setMarketBasicsPackage] = useState([]);
  const [marketRiskDataPackage, setMarketRiskDataPackage] = useState([]);
  const [marketInsightsPackage, setMarketInsightsPackage] = useState([]);
  const [softwarePackage, setSoftwarePackage] = useState([]);
  const [dynamicWorkflowPackage, setDynamicWorkflowPackage] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState(PackageTabName);
  const [contractsResult, setContractsResult] = useState();
  const [currentPackCat, setCurrentPackCat] = useState('');
  const [spgGroups, setSPGGroups] = useState([]);
  const [subscriptionType, setSubscriptionType] = useState('');
  const [initialValue, setInitialValue] = useState({ contractType: '' });
  const [isAllPrimaryContracts, setIsAllPrimaryContracts] = useState(false);

  const dispatch = useDispatch();
  const storeData = store.getState();
  const packageCategories = [
    'Market Data',
    'Market Insight',
    'Risk Market Data',
    'Software',
    'Market Basics',
    'Dynamic Workflow Tools',
  ];
  const initialPackageDataset = [];

  const validate = Yup.object({
    contractType: Yup.string().trim(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValue,
    validationSchema: validate,
  });

  const packageCompare = (current, next) => {
    // Sort by tile_disabled i.e,subscribed pkg first, then by commodity name
    return (
      !next.tile_disabled - !current.tile_disabled ||
      current.commodity.localeCompare(next.commodity, undefined, {
        sensitivity: 'base',
      })
    );
  };

  useEffect(() => {
    if (
      !(
        localStorage.getItem('previousRoute') &&
        (localStorage
          .getItem('previousRoute')
          .includes('delivery-platform-details') ||
          localStorage.getItem('previousRoute').includes('package-details'))
      )
    ) {
      dispatch({
        type: ReduxAction.tabType,
        payload: {},
      });
    }
  }, []);

  useEffect(() => {
    try {
      if (contractsResult) {
        let storeData = store.getState();
        let storePackageDatasets = storeData?.datasets?.datasetDetails;
        if (storePackageDatasets) {
          initialPackageDataset.push([...storePackageDatasets]);
          setPackageDataSet(storePackageDatasets);
          setLoading(false);
        } else if (contractsResult) {
          let contractCollection = contractsResult.map(
            (el) => el.contractNumber,
          );
          getContractPackage(contractCollection);
        }
        // add contract type using route data by fitering it
        let packageStoreDetails = storeData?.packageDetails?.tabType;
        packageStoreDetails?.tabId === 2
          ? setActiveTab(DistributorTabName)
          : setActiveTab(PackageTabName);

        let storeContracttype = packageStoreDetails?.contractType;
        if (storeContracttype) {
          formik.setFieldValue(
            'contractType',
            storeContracttype === contractTypePrimary
              ? contractTypeMarketBasics
              : storeContracttype,
          );
          setSubscriptionType(storeContracttype);
        }

        if (packageStoreDetails?.tabId === 1) {
          setTimeout(() => {
            if (!document.getElementById(packageStoreDetails?.id))
              setCurrentPackCat(packageStoreDetails?.category);
          }, 100);
        }
      }
    } catch (err) {}
  }, [contractsResult]);

  useEffect(() => {
    const reduceCallback = setTimeout(async () => {
      try {
        let storeData = store.getState();
        let packageStoreDetails = storeData?.packageDetails?.tabType;
        let storeUserInfo = storeData?.userInfo?.userInfo;
        let storeContract = storeData.contract;
        let storeSPGGroups = storeUserInfo?.SPGGroups;
        setSPGGroups(storeSPGGroups);

        // check user's contractType and set it as SubscriptionType for filtering
        if (
          (storeSPGGroups?.includes('GRP_SFS_CSA') ||
            storeSPGGroups?.includes('GRP_SFS_VIEWER')) &&
          !packageStoreDetails?.contractType
        ) {
          setInitialValue({ contractType: contractType[0] });
          setSubscriptionType(contractType[0]);
        } else {
          if (Object.keys(storeContract).length) {
            let userContractType =
              storeContract?.contracts[0]?.contractType ?? '';

            if (userContractType && !packageStoreDetails?.contractType) {
              setInitialValue({ contractType: userContractType });
              setSubscriptionType(userContractType);
            }
          }
        }

        if (Object.keys(storeUserInfo)?.length > 0 && !contractsResult) {
          getContractsDataSet();
        }
      } catch (err) {}
    }, 1000);
    return () => clearTimeout(reduceCallback);
  }, [useSelector((x) => x.userInfo), contractsResult]);

  useEffect(() => {
    if (packageDataSets?.length > 0) {
      let tempPackageDataset =
        subscriptionType === ''
          ? packageDataSets
          : packageDataSets.filter(
              (el) => el.contractType === subscriptionType,
            );
      setMarketDataPackage(
        tempPackageDataset
          .filter((p) => p.category?.trim()?.toLowerCase() === 'market data')
          ?.sort(packageCompare),
      );
      setMarketBasicsPackage(
        tempPackageDataset
          .filter((p) => p.category?.trim()?.toLowerCase() === 'market basics')
          ?.sort(packageCompare),
      );
      setMarketInsightsPackage(
        tempPackageDataset
          .filter((p) => p.category?.trim()?.toLowerCase() === 'market insight')
          ?.sort(packageCompare),
      );
      setMarketRiskDataPackage(
        tempPackageDataset
          .filter(
            (p) => p.category?.trim()?.toLowerCase() === 'risk market data',
          )
          ?.sort(packageCompare),
      );
      setSoftwarePackage(
        tempPackageDataset
          .filter((p) => p.category?.trim()?.toLowerCase() === 'software')
          ?.sort(packageCompare),
      );
      setDynamicWorkflowPackage(
        tempPackageDataset
          .filter(
            (p) =>
              p.category?.trim()?.toLowerCase() === 'dynamic workflow tools',
          )
          ?.sort(packageCompare),
      );
    }
  }, [packageDataSets, subscriptionType]);

  const checkIfAllContractsPrimary = (allContracts) => {
    // Check if all contracts are primary or not
    let tempIsPrimaryCon = false;
    if (allContracts?.length > 0) {
      tempIsPrimaryCon = allContracts?.every(
        (el) => el?.contractType === contractTypePrimary,
      );
    }
    setIsAllPrimaryContracts(tempIsPrimaryCon);
  };

  const getContractsDataSet = async () => {
    // Fetch contracts as per user email
    try {
      let storeData = store.getState();
      let storeContracts = storeData.contract?.contracts;
      let storeUserInfo = storeData?.userInfo.userInfo;
      if (!contractsResult && !packageDataSets) {
        if (storeContracts && storeContracts.length > 0) {
          setContractsResult(storeContracts);
          checkIfAllContractsPrimary(storeContracts);
        } else {
          let userEmail = storeUserInfo?.email;
          let userContractNumber = storeUserInfo?.contractNumber;
          let isEndUser = !!storeUserInfo?.SPGGroups?.includes('SFS_ENDUSER');

          if (isEndUser && !userContractNumber) {
            let queryParams = {
              Filter: `email:"${userEmail}"`,
              Field:
                'contactName,contactId,groupid,groupName,contractNumber,email,phone,department,jobFunction,designation,isEmailOptOut,account,accountId,officeLocation,officeLocationId,entitled,modifiedDate',
              PageSize: 50,
            };

            const userApiResponse = await httpService.get(
              Endpoints.userApi,
              queryParams,
            );
            let userResult = userApiResponse?.data?.results[0];
            if (Object.keys(userResult)?.length > 0) {
              userContractNumber = userResult.contractNumber;
              dispatch({
                type: ReduxAction.userInfoSet,
                payload: { ...storeUserInfo, ...userResult },
              });
            }
          }

          let queryParamsContract = {
            PageSize: 5000,
            Field:
              'contractNumber,contractType,contractDescription,bulkEntitlementEnable',
            Filter: `email:"${userEmail.toLowerCase()}"`,
          };

          if (isEndUser && userContractNumber) {
            queryParamsContract.Filter = `contractNumber in ("${userContractNumber}")`;
          }

          if (!isEndUser || (isEndUser && userContractNumber)) {
            let tempState = [];
            httpService
              .get(Endpoints.contractApi, queryParamsContract)
              .then((res) => {
                tempState = res.data?.results?.map((el) => {
                  return {
                    label:
                      el.contractNumber +
                      (el.contractDescription
                        ? ' - ' + el.contractDescription
                        : ''),
                    id: el.contractNumber,
                    checked: true,
                    bulkEntitlementEnable: el.bulkEntitlementEnable,
                    ...el,
                  };
                });
                checkIfAllContractsPrimary(tempState);
              })
              .catch(() => {
                tempState = [];
              })
              .finally(() => {
                setContractsResult(tempState);
                dispatch(addContract(tempState));
              });
          }
        }
      }
    } catch (err) {}
  };

  const getContractPackage = async (contractCollection) => {
    // Fetch contract packages
    try {
      const contractExist = contractCollection.length > 0;
      if (contractExist) {
        const url = Endpoints.contractPackagesApi;
        let queryParams = {
          field: 'contractNumber,packageName,packageId',
          Filter: `contractNumber IN(${contractCollection
            .map((contractNumber) => '"' + contractNumber + '"')
            .join(',')})`,
          PageSize: 50000,
        };
        httpService
          .get(url, queryParams)
          .then((res) => {
            const packageNameArr = res.data?.results?.map((el) => {
              if (el?.packageId) return String(el.packageId);
              else return '';
            });
            getPackageDataSet(packageNameArr, contractExist);
          })
          .catch((err) => {});
      } else {
        getPackageDataSet([], contractExist);
      }
    } catch (err) {}
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredPackageDataSets =
    searchTerm.length >= 2
      ? packageDataSets?.filter((dataSet) =>
          packageDatasetProps.some((prop) =>
            (dataSet[prop]?.toString() || '')
              .toLowerCase()
              .includes(searchTerm.toLowerCase()),
          ),
        )
      : packageDataSets;

  const filteredMDPackage =
    searchTerm.length >= 2
      ? marketDataPackage.filter((dataSet) =>
          packageDatasetProps.some((prop) =>
            (dataSet[prop]?.toString() || '')
              .toLowerCase()
              .includes(searchTerm.toLowerCase()),
          ),
        )
      : marketDataPackage;

  const filteredMBPackage =
    searchTerm.length >= 2
      ? marketBasicsPackage.filter((dataSet) =>
          packageDatasetProps.some((prop) =>
            (dataSet[prop]?.toString() || '')
              .toLowerCase()
              .includes(searchTerm.toLowerCase()),
          ),
        )
      : marketBasicsPackage;

  const filteredMRDPackage =
    searchTerm.length >= 2
      ? marketRiskDataPackage.filter((dataSet) =>
          packageDatasetProps.some((prop) =>
            (dataSet[prop]?.toString() || '')
              .toLowerCase()
              .includes(searchTerm.toLowerCase()),
          ),
        )
      : marketRiskDataPackage;
  const filteredMIPackage =
    searchTerm.length >= 2
      ? marketInsightsPackage.filter((dataSet) =>
          packageDatasetProps.some((prop) =>
            (dataSet[prop]?.toString() || '')
              .toLowerCase()
              .includes(searchTerm.toLowerCase()),
          ),
        )
      : marketInsightsPackage;

  const filteredSPackage =
    searchTerm.length >= 2
      ? softwarePackage.filter((dataSet) =>
          packageDatasetProps.some((prop) =>
            (dataSet[prop]?.toString() || '')
              .toLowerCase()
              .includes(searchTerm.toLowerCase()),
          ),
        )
      : softwarePackage;

  const filteredDWPackage =
    searchTerm.length >= 2
      ? dynamicWorkflowPackage.filter((dataSet) =>
          packageDatasetProps.some((prop) =>
            (dataSet[prop]?.toString() || '')
              .toLowerCase()
              .includes(searchTerm.toLowerCase()),
          ),
        )
      : dynamicWorkflowPackage;

  useEffect(() => {
    let packageStoreDetails = storeData?.packageDetails?.tabType;
    if (packageStoreDetails?.id)
      setTimeout(() => {
        if (document.getElementById(packageStoreDetails?.id)) {
          document
            .getElementById(packageStoreDetails?.id)
            .scrollIntoView({ block: 'center' });
        }
      }, 100);
  }, [currentPackCat, filteredMDPackage]);

  const getUserEntitlementPackages = async () => {
    let userEmail = storeData?.userInfo?.userInfo?.email;
    // fetch active user entitlements
    let queryParams = {
      pageSize: maxPageSize,
      Field:
        'userEntitlementId,deliveryPlatform,distributorDisplayName,distributorPlatformDisplayName,emailId,packageName,packageId,productName,status,contactName,modifiedDate',
      Filter: `emailId:"${userEmail}"`,
    };

    let response = await httpService.get(
      Endpoints.userentitlementsApi,
      queryParams,
    );
    let userEntitlementPackages = response?.data?.results
      .filter(
        (ue) =>
          !ProductDeliveryPlatforms.includes(ue.distributor) &&
          (ue.status === 'Pending Active' || ue.status === 'Active'),
      )
      .map((entitlement, index) => {
        entitlement.id = index;
        return String(entitlement.packageId);
      });

    return userEntitlementPackages;
  };

  const getPackageDataSet = async (packageNameArr, contractExist) => {
    try {
      let isCCA_User =
        storeData?.userInfo.userInfo?.SPGGroups?.includes('SFS_CCA');
      let isEndUser =
        storeData?.userInfo.userInfo?.SPGGroups?.includes('SFS_ENDUSER');

      let userEntitlementPackages = [];
      if (isEndUser) {
        userEntitlementPackages = await getUserEntitlementPackages();
      }
      const url = Endpoints.packageDataset;
      httpService
        .get(url)
        .then((res) => {
          let dataset = [];
          res.data?.results?.forEach((el) => {
            let tileDisabled = false;
            if (isCCA_User) {
              tileDisabled = contractExist
                ? !packageNameArr.includes(el.packageId)
                : true;
            } else if (isEndUser) {
              let isEntitledPkg = userEntitlementPackages.some(
                (pkgId) => pkgId === el.packageId,
              );
              tileDisabled = !isEntitledPkg;
            }
            if (el.contractType) {
              let NewDataSet = {
                BG_img:
                  el.contractType == contractTypeCSMPlus ||
                  el.contractType == contractTypePrimary
                    ? packageImageNamesCSMPlus[el.imagePath]
                    : packageImageNames[el.imagePath],
                tile_title: el.industryCoverageData.replaceAll('"', ''),
                tile_main_heading: el.marketplaceDatasetName.replaceAll(
                  ',',
                  '',
                ),
                tile_description: el.marketplaceLongDescription.replaceAll(
                  '"',
                  '',
                ),
                recentlyAdded: el.recentlyAdded,
                recentlyEnhanced: el.recentlyEnhanced,
                longDescription: el.marketplaceLongDescription.replaceAll(
                  '"',
                  '',
                ),
                dataProviderDesc: el.marketplaceDataProviderDesc.replaceAll(
                  '"',
                  '',
                ),
                entityType: 'package',
                tile_disabled: tileDisabled,
                ...el,
                contractType: el?.contractType,
              };
              if (
                isEndUser &&
                contractsResult[0]?.contractType === contractTypePrimary
              ) {
                if (
                  el.category?.toLowerCase() ===
                  packageCategories[4].toLowerCase()
                )
                  dataset.push(NewDataSet);
              } else dataset.push(NewDataSet);
            }
          });
          initialPackageDataset.push([...dataset]);
          setPackageDataSet(dataset);
          store.dispatch({
            type: ReduxAction.datasets,
            payload: { datasetDetails: dataset },
          });
        })
        .catch((err) => {
          if (err.code !== 'ERR_CANCELED') {
            Notify({
              alert: true,
              type: 'error',
              title: GenericError.somethingWentWrong,
            });
          }
        })
        .finally(() => setLoading(false));
    } catch (err) {}
  };

  const contractTypeHandle = (val, name) => {
    let contractTypeValue = val[name] ?? val;
    let obj = {
      target: { name: name, value: contractTypeValue },
    };
    formik.handleChange(obj);
    if (contractTypeValue === contractTypeMarketBasics) {
      contractTypeValue = contractTypePrimary;
    }
    setSubscriptionType(contractTypeValue);
  };

  return (
    <>
      {loading ? (
        <Loader type="scaleLoader" />
      ) : (
        <>
          <div className="row">
            <div className="col-lg-7 col-md-6">
              <span
                id="user-location-header"
                className="page-header breadcrumb-nav-wrapper"
              >
                <span className="page-header">
                  Packages & Delivery Platform
                </span>
              </span>
            </div>
          </div>
          <div className="row position-relative">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <input
                type="text"
                placeholder="Find CSM Packages or Delivery Platform..."
                className="form-control search-box mt-2 mb-2"
                value={searchTerm}
                onChange={handleChange}
              />
            </div>
            {(spgGroups?.includes('GRP_SFS_CSA') ||
              spgGroups?.includes('GRP_SFS_VIEWER')) && (
              <div className="col-lg-6 col-md-12 col-sm-12 packages-dropdown">
                <DropdownInput
                  formLabel="Please select Subscription Type:"
                  {...formik.getFieldProps('contractType')}
                  name="contractType"
                  dropdownOptions={contractType}
                  formik={formik}
                  ddPlaceHolder={
                    formik.values.contractType !== ''
                      ? formik.values.contractType
                      : 'Please select Subscription Type'
                  }
                  ddName={'contractType'}
                  onChangeHandler={(e) => contractTypeHandle(e, 'contractType')}
                />
              </div>
            )}
          </div>
          {/* Tab section */}
          <div className="tabs-main">
            <Tabs fill justify defaultActiveKey={activeTab}>
              <Tab eventKey={PackageTabName} title="Packages">
                <Container fluid className="ms-2">
                  {initialPackageDataset.length === 0 &&
                  filteredMDPackage.length == 0 &&
                  filteredMRDPackage.length == 0 &&
                  filteredMIPackage.length == 0 &&
                  filteredSPackage.length == 0 &&
                  filteredMBPackage.length == 0 &&
                  filteredDWPackage.length == 0 ? (
                    <div className="search-results">
                      <h2>No products found</h2>
                      <ul>
                        <li>Try changing or using less filter criteria</li>
                        <li>Try fewer search keywords</li>
                        <li>
                          Make sure your search keywords are spelled correctly
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <div>
                      {contractType !== contractTypePrimary ? (
                        <>
                          {filteredMDPackage.length > 0 ? (
                            <TileRepeater
                              DataSet={filteredMDPackage}
                              categoryName={packageCategories[0]}
                              initialTile={4}
                              showAll={currentPackCat === packageCategories[0]}
                            />
                          ) : (
                            ''
                          )}
                          {filteredMIPackage.length > 0 ? (
                            <TileRepeater
                              DataSet={filteredMIPackage}
                              categoryName={packageCategories[1]}
                              initialTile={4}
                              showAll={currentPackCat === packageCategories[1]}
                            />
                          ) : (
                            ''
                          )}
                          {filteredMRDPackage.length > 0 ? (
                            <TileRepeater
                              DataSet={filteredMRDPackage}
                              categoryName={packageCategories[2]}
                              initialTile={4}
                              showAll={currentPackCat === packageCategories[2]}
                            />
                          ) : (
                            ''
                          )}
                          {filteredSPackage.length > 0 ? (
                            <TileRepeater
                              DataSet={filteredSPackage}
                              categoryName={packageCategories[3]}
                              initialTile={4}
                              showAll={currentPackCat === packageCategories[3]}
                            />
                          ) : (
                            ''
                          )}
                          {filteredDWPackage.length > 0 ? (
                            <TileRepeater
                              DataSet={filteredDWPackage}
                              categoryName={packageCategories[5]}
                              initialTile={4}
                              showAll={currentPackCat === packageCategories[5]}
                            />
                          ) : (
                            ''
                          )}
                        </>
                      ) : (
                        ''
                      )}
                      {filteredMBPackage.length > 0 ? (
                        <TileRepeater
                          DataSet={filteredMBPackage}
                          categoryName={packageCategories[4]}
                          initialTile={4}
                          showAll={currentPackCat === packageCategories[4]}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  )}
                </Container>
              </Tab>
              <Tab eventKey={DistributorTabName} title="Delivery Platform">
                <Distributors
                  subscriptionType={subscriptionType}
                  searchText={searchTerm}
                  isAllPrimaryContracts={isAllPrimaryContracts}
                />
              </Tab>
            </Tabs>
          </div>
        </>
      )}
    </>
  );
};

export default Packages;
