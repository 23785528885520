import React, { useEffect, useState } from 'react';
import { Container, ListGroup, Navbar } from 'react-bootstrap';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { NavLink } from 'reactstrap';

import NavbarUser from './NavbarUser';

import { useSelector } from 'react-redux';
import { UrlPath } from '../../constants';
import logo from './../../assets/img/logo/logo.svg';
import LogoutButton from './../../pages/auth/LogoutButton';
import persistDataStore from './../../redux/persistStore';
import store from './../../redux/store';

const NavbarCSA = () => {
  const location = useLocation();
  const [menu, setMenu] = useState([]);
  const [userName, setUserName] = useState('');
  const [isHovering, setIsHovering] = useState(false);
  const navigate = useNavigate();
  const nonCSAPath = ['/user-location', '/entitlements', '/user-group'];
  const csaActionData =
    persistDataStore.getState()?.userActionData?.csaUserActionInfo;

  useEffect(() => {
    let storeData = store.getState();
    let storeUserInfo = storeData?.userInfo;
    if (
      storeUserInfo &&
      storeUserInfo.userInfo &&
      userName !==
        storeUserInfo.userInfo.FirstName + ' ' + storeUserInfo.userInfo.LastName
    ) {
      setUserName(
        storeUserInfo.userInfo.FirstName +
          ' ' +
          storeUserInfo.userInfo.LastName,
      );
    }
    // eslint-disable-next-line
  }, [useSelector((x) => x.userInfo)]);

  const getMenuClassName = (menu) => {
    let className = '';
    if (
      nonCSAPath.includes(menu.to) &&
      (!csaActionData?.type || location.pathname === UrlPath.adminDashboard)
    )
      className = 'menu-disable';
    else
      className = menu.path.some((p) => location.pathname.includes(p))
        ? 'active'
        : '';
    return className;
  };

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  useEffect(() => {
    setMenu([
      {
        name: 'Home',
        key: 1,
        keyName: '',
        to: '/admin-dashboard',
        tag: Link,
        path: ['/admin-dashboard'],
      },
      {
        name: 'Users & Locations',
        key: 2,
        keyName: '',
        to: '/user-location',
        tag: Link,
        path: ['/user-location', '/export-users'],
      },
      {
        name: 'My Groups',
        key: 3,
        keyName: '',
        to: '/user-group',
        tag: Link,
        path: ['/user-group'],
      },
      {
        name: 'Entitlements',
        key: 4,
        keyName: '',
        to: '/entitlements',
        tag: Link,
        path: [
          '/entitlements',
          '/add-entitlements',
          '/export-entitlements',
          '/product-entitlements',
        ],
      },
      {
        name: 'Packages & Delivery',
        key: 5,
        keyName: '',
        to: '/packages',
        tag: Link,
        path: [
          '/packages',
          '/packages/package-details',
          '/packages/delivery-platform-details',
        ],
      },
      {
        name: userName,
        key: 6,
        keyName: 'username',
      },
    ]);
  }, [userName]);

  const onChangeHistoryClick = () => {
    navigate(UrlPath.changeHistory);
  };
  const onManageEmailDomainsClick = () => {
    navigate(UrlPath.manageEmailDomains);
  };
  const onReportsClick = () => {
    navigate(UrlPath.reports);
  };

  return (
    <Navbar variant="light" expand="lg" className="navbar-bg" sticky="top">
      <Container fluid className="mx-lg-1">
        <div className="logo-section">
          <a className="navbar-brand" href="/admin-dashboard">
            <img src={logo} className="img-fluid me-1 logo-img" alt="Admin" />
          </a>
          <span className="logo-sub-heading">Subscription Manager</span>
        </div>
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            {menu.map((m) => (
              <li className="nav-item" key={m.key}>
                {m.keyName !== 'username' ? (
                  <NavLink
                    tag={m.tag}
                    key={m.key}
                    className={getMenuClassName(m)}
                    to={m.to}
                  >
                    {m.name}
                  </NavLink>
                ) : (
                  <div
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <NavbarUser name={m.name} navLinkData={{}} />
                    {isHovering && (
                      <div className="position-absolute">
                        <ListGroup className="position-fixed option-list">
                          <ListGroup.Item className="option-list-item">
                            <div
                              className="option-list-item-text"
                              onClick={onReportsClick}
                            >
                              Active Users Export
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="option-list-item">
                            <div
                              id="history_option"
                              className="option-list-item-text"
                              onClick={onChangeHistoryClick}
                            >
                              Change History
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="option-list-item">
                            <div
                              className="cursor-pointer"
                              onClick={onManageEmailDomainsClick}
                            >
                              Manage Email Domains
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="option-list-item">
                            <LogoutButton />
                          </ListGroup.Item>
                        </ListGroup>
                      </div>
                    )}
                  </div>
                )}
              </li>
            ))}
          </ul>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarCSA;
