import React, { memo, useCallback, useEffect, useState } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { Dropdown } from 'react-bootstrap';
import { ReactComponent as DownArrow } from '../../assets/icons/down-arrow-icon.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/common/check.svg';
import { ReactComponent as UnCheckIcon } from '../../assets/icons/common/uncheck.svg';
import { ReactComponent as Indeterminate } from '../../assets/icons/common/indeterminate.svg';
import { ReactComponent as Arrow } from '../../assets/icons/common/arrow.svg';
import cn from '../../helpers/cn';
import Loader from '../../components/Loader';
import './tree-multi-select-dropdown.scss';

// Example: Tree Structure
// ddOptions = [
//     {
//       id: '1',
//       label: 'Demo',
//       value: 'demo',
//       children: [
//         { id: '2', label: 'Demo 1', value: 'demo1', checked: false },
//         { id: '3', label: 'Demo 2', value: 'demo2', checked: true },
//       ],
//     },
//   ],

const TreeMultiSelectDropdown = memo(
  ({
    ddClassName,
    ddOptions,
    getSelectedTreeData,
    ddPlaceHolder,
    defaultChecked = [],
    enableSearch = false,
    enableSelectAll = false,
    disabled = false,
  }) => {
    const [searchText, setSearchText] = useState('');
    const [expanded, setExpanded] = useState(['Select All']);
    const [treeNodes, setTreeNodes] = useState([]);
    const [filteredNodes, setFilteredNodes] = useState([]);
    const [checkedNode, setCheckedNode] = useState([]);
    const [checkedState, setCheckedState] = useState([]);

    const ResetSearchText = () => enableSearch && setSearchText('');

    useEffect(() => {
      // Initial Load, set TreeNode variable as a reference to original node
      if (ddOptions?.length > 0) {
        let treeMenuData = JSON.parse(JSON.stringify(ddOptions));
        // Select all is enabled
        if (enableSelectAll) {
          let selectAllTreeMenuData = [
            {
              label: 'Select All',
              value: 'Select All',
              children: treeMenuData,
            },
          ];
          setTreeNodes(selectAllTreeMenuData);
        } else {
          // if select all is disabled
          setTreeNodes(treeMenuData);
        }
      } else {
        // Reset when new data is given
        setTreeNodes([]);
      }
    }, [ddOptions]);

    useEffect(() => {
      // Set FilteredNode variable on initial load & reset all other data
      setFilteredNodes(treeNodes?.length > 0 ? treeNodes : []);
      ResetSearchText();
      setCheckedNode([...defaultChecked]);
      setExpanded(['Select All']);
    }, [treeNodes]);

    useEffect(() => {
      // On search filter data
      const reduceCallback = setTimeout(() => {
        treeNodes.length > 0 && onSearchInputChange(searchText);
      }, 300);
      return () => clearTimeout(reduceCallback);
    }, [searchText]);

    useEffect(() => {
      // Merge new options with the existing checked state
      const updatedCheckedState = getNodeValueToSelect(treeNodes, checkedState);
      onCheckHandler(updatedCheckedState);
    }, [treeNodes]);

    const getNodeValueToSelect = (nodes, checkBoxResult) => {
      try {
        let result = [];
        nodes.forEach((obj, idx) => {
          // If child has no further child and match found
          if (!obj?.children || obj?.children?.length === 0) {
            if (checkBoxResult.includes(obj.id)) {
              result.push(obj.value);
            }
          } else if (obj?.children?.length > 0) {
            // Parent has children then loop again
            let nextNode = getNodeValueToSelect(obj.children, checkBoxResult);
            result.push(...nextNode);
          }
        });
        return result;
      } catch (e) {}
    };

    const onSearchInputChange = (keyword, nodes) => {
      let filterData =
        keyword?.length > 2
          ? filterTree(JSON.parse(JSON.stringify(treeNodes)), keyword)
          : treeNodes;
      setFilteredNodes([...filterData]);
    };

    const filterTree = (nodes, keyword) => {
      try {
        let newNodes = [];
        for (let n of nodes) {
          // Search nodes with keyword included
          let nodeFound = n.label
            .toLowerCase()
            .includes(keyword?.toLowerCase());
          // If node is Parent
          if (n.children) {
            // If node has children then loop again
            const nextNodes = filterTree(n.children, keyword);
            // If node found then collect whole parent obj
            if (nodeFound) {
              newNodes.push(n);
            } else if (nextNodes?.length > 0) {
              // Check child node for the same
              n.children = nextNodes;
              newNodes.push(n);
            }
          } else {
            // If node is Child
            if (nodeFound) {
              newNodes.push(n);
            }
          }
        }
        return newNodes;
      } catch (e) {}
    };

    const onCheckHandler = (checked) => {
      // All checked values are further process here
      setCheckedNode(checked);
      // Get original ID from checked values
      let actualCheckedValues = getSelectedCheckValue(treeNodes, checked);
      getSelectedTreeData(actualCheckedValues);
      setCheckedState([...actualCheckedValues]);
    };

    const getSelectedCheckValue = (nodes, checkBoxResult) => {
      try {
        let result = [];
        nodes.forEach((obj, idx) => {
          // If child has no further child and match found
          if (!obj?.children || obj?.children?.length === 0) {
            if (checkBoxResult.includes(obj.value)) {
              result.push(obj.id);
            }
          } else if (obj?.children?.length > 0) {
            // Parent has children then loop again
            let nextNode = getSelectedCheckValue(obj.children, checkBoxResult);
            result.push(...nextNode);
          }
        });
        return result;
      } catch (e) {}
    };

    return (
      <>
        <Dropdown
          className={cn('entitlements-wrapper', ddClassName)}
          onToggle={ResetSearchText}
        >
          <Dropdown.Toggle
            variant="light"
            className="bg-white w-100 text-start shadow-sm"
          >
            {ddPlaceHolder}
            <DownArrow />
          </Dropdown.Toggle>
          <Dropdown.Menu className="m-0">
            {enableSearch && (
              <div className="add-entitlements-block" key={'-1'}>
                <div className="add-entitlements-dropdown">
                  <input
                    className="w-100 px-2 py-1 search-box"
                    type="text"
                    id="dd-search-input"
                    placeholder="Search"
                    value={searchText}
                    onChange={({ target }) => setSearchText(target.value)}
                    autoComplete="off"
                  />
                </div>
              </div>
            )}

            {!ddOptions ? (
              <h6 className="text-center pt-2">
                <Loader type="scaleLoaderDropdown" />
              </h6>
            ) : filteredNodes?.length !== 0 ? (
              <div className="mx-3">
                <CheckboxTree
                  nodes={filteredNodes}
                  checked={checkedNode}
                  expanded={expanded}
                  onCheck={onCheckHandler}
                  onExpand={(expanded) => setExpanded(expanded)}
                  icons={{
                    check: <CheckIcon className="dropdown-checkbox-icon" />,
                    uncheck: <UnCheckIcon className="dropdown-checkbox-icon" />,
                    halfCheck: (
                      <Indeterminate className="dropdown-checkbox-icon" />
                    ),
                    expandClose: <Arrow className="dropdown-arrow-icon" />,
                    expandOpen: <Arrow className="dropdown-down-arrow-icon" />,
                  }}
                  showNodeIcon={false}
                  disabled={disabled}
                />
              </div>
            ) : (
              ddOptions?.length >= 0 && (
                <h6 className="text-center pt-2">No results found</h6>
              )
            )}
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  },
  (op, np) =>
    op?.ddOptions === np?.ddOptions && op?.ddPlaceHolder === np?.ddPlaceHolder,
);

export default TreeMultiSelectDropdown;
