import React, {
  memo,
  useMemo,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import {
  Endpoints,
  loaderStyle,
  maxPageSize,
  maxExportSizeLimit,
} from '../../constants';
import httpService from '../../services/http-service';
import { ReactComponent as PreviewIcon } from './../../assets/icons/common/view.svg';
import { ReactComponent as ExportIcon } from './../../assets/icons/common/export.svg';
import DistributorsDropdown from './DistributorsDropdown';
import PackagesDropdown from './PackagesDropdown';
import UsersDropdown from './UsersDropdown';
import Loader from '../../components/Loader';
import LocationsDropdown from './LocationsDropdown';
import StatusDropdown from './StatusDropdown';
import AgGridComponent from '../../sharedcomponents/ag-grid/AgGrid';
import { debounce } from '../../helpers/debounce';
import { getSortColumns } from '../../sharedcomponents/ag-grid/ag-grid-helper';
import { Notify } from './../../sharedcomponents/Alert/Notify';
import FileSaver from 'file-saver';

const ExportEntitlements = memo(
  ({ contracts }) => {
    const [isGridDataAvailable, setIsGridDataAvailable] = useState(false);
    const [packageDataSet, setPackageDataSet] = useState();
    const [distributorDataSet, setDistributorDataSet] = useState();
    const [selectedPackages, setSelectedPackages] = useState([]);
    const [selectedDistributors, setSelectedDistributors] = useState([]);
    const [selectedDistributorsId, setSelectedDistributorsId] = useState([]);
    const [selectedParentCascade, setSelectedParentCascade] = useState();
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [filteredPackages, setFilteredPackages] = useState();
    const [filteredDistributors, setFilteredDistributors] = useState();
    const [userEntitlementsData, setUserEntitlementsData] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [enablePreview, setEnablePreview] = useState(false);
    const [enableExport, setEnableExport] = useState(false);
    const [exportCount, setExportCount] = useState('0');
    const [isExportInprogress, setIsExportInprogress] = useState(false);
    const [resetStatus, setResetStatus] = useState(0);
    const gridRef = useRef();

    const getColumnClass = (params) => {
      if (params?.node?.data?.isEntitlementExists == false) {
        return 'new-selected-entitlement-row grid-cell-left';
      } else {
        return 'grid-cell-left';
      }
    };
    const columns = [
      {
        headerName: '',
        field: 'entitlementFilter',
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: [
            'inRange',
            {
              displayKey: 'inRange',
              displayName: 'Range Between',
              predicate: ([filterValue], cellValue) => {
                return cellValue == null || cellValue < filterValue;
              },
            },
          ],
        },
        suppressHeaderMenuButton: true,
        sortable: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        headerName: 'User',
        headerTooltip: 'User',
        field: 'contactName',
        tooltipField: 'contactName',
        initialFlex: 3,
      },
      {
        headerName: 'Location',
        headerTooltip: 'Location',
        field: 'officeLocationName',
        tooltipField: 'officeLocationName',
        initialFlex: 3,
      },
      {
        headerName: 'Package',
        headerTooltip: 'Package',
        field: 'packageName',
        tooltipField: 'packageName',
        initialFlex: 3,
      },
      {
        headerName: 'Product',
        headerTooltip: 'Product',
        field: 'productName',
        tooltipField: 'productName',
        initialFlex: 3,
      },
      {
        headerName: 'Delivery Platform (Type)',
        headerTooltip: 'Delivery Platform (Type)',
        field: 'distributorData',
        tooltipField: 'distributorData',
        initialFlex: 3,
      },
      {
        headerName: 'Status',
        headerTooltip: 'Status',
        field: 'status',
        tooltipField: 'status',
        initialFlex: 2,
      },
    ];

    const onDragStoppedHandler = (params) => {
      const columnDefs = columns;
      let groupCols = params.api.columnModel.rowGroupColumns;
      columnDefs.forEach(function (colDef, index) {
        if (groupCols.find((x) => x.colId === colDef.field)) {
          colDef.hide = true;
        } else {
          colDef.hide = false;
        }
      });
      gridRef.current.api.setColumnDefs(columnDefs);
    };

    const autoGroupColumnDef = {
      // column definition for grouped column
      enableRowGroup: false,
      lockPosition: 'left',
      lockPinned: true,
      sortable: true,
      cellRenderer: 'agGroupCellRenderer',
    };

    const defaultConfig = {
      rowGroupPanelShow: 'never',
      isExportCSV: false,
      isExportExcel: false,
      pivotPanelShow: '',
      pagination: false,
      isAutoSizeColums: true,
      enableCharts: false,
      sideBar: true,
      initialHide: false,
      cellClass: getColumnClass,
    };

    const defaultColdef = {
      sortable: true,
      resizable: true,
      menuTabs: ['generalMenuTab', 'columnsMenuTab'],
      enableValue: false,
    };

    useEffect(() => {
      const reduceCallback = setTimeout(() => {
        setIsGridDataAvailable(false);
        setSelectedUsers([]);
        setFilteredPackages();
        setSelectedPackages([]);
        setSelectedDistributors([]);
        setSelectedDistributorsId([]);
        setSelectedLocations([]);
        setSelectedStatus([]);
        setResetStatus((prev) => prev + 1);
        getAllUserEntitlements();
      }, 1000);
      return () => clearTimeout(reduceCallback);
    }, [contracts]);

    useEffect(() => {
      // Set initial state
      if (packageDataSet) setFilteredPackages(packageDataSet);
      if (distributorDataSet) setFilteredDistributors(distributorDataSet);
    }, [packageDataSet, distributorDataSet]);

    useEffect(() => {
      if (selectedParentCascade === 'PackageDropdown' && userEntitlementsData) {
        // Filter distributor based on package selection
        let pkgId = [];
        selectedPackages.forEach((el) => {
          pkgId.push(el.packageName);
        });
        let filterData = userEntitlementsData.filter((el) =>
          pkgId.includes(el.packageName),
        );
        setFilteredDistributors({ results: filterData });
      }
    }, [selectedPackages]);

    useEffect(() => {
      if (
        selectedParentCascade === 'DistributorDropdown' &&
        userEntitlementsData
      ) {
        // Filter packages based on distributor selection
        let filterData = userEntitlementsData.filter((el) => {
          return selectedDistributorsId.includes(el.distributorId);
        });
        setFilteredPackages({ results: filterData });
      }
    }, [selectedDistributorsId]);

    useEffect(() => {
      const hasSelectedItems =
        (selectedUsers && selectedUsers.length > 0) ||
        (selectedLocations && selectedLocations.length > 0) ||
        (selectedPackages && selectedPackages.length > 0) ||
        (selectedDistributors && selectedDistributors.length > 0) ||
        (selectedStatus && selectedStatus.length > 0);

      if (hasSelectedItems) {
        setEnablePreview(true);
        setEnableExport(true);
      } else {
        setEnablePreview(false);
        setEnableExport(false);
        setExportCount('0');
      }
    }, [
      selectedUsers,
      selectedLocations,
      selectedPackages,
      selectedDistributors,
      selectedStatus,
    ]);

    const onGridReadyHandler = (params) => {
      gridRef.current = params;
      gridRef?.current?.api?.setGridOption(
        'serverSideDatasource',
        getServerSideDatasource(),
      );
    };

    const getAllUserEntitlements = () => {
      try {
        if (contracts?.length > 0 && contracts.some((el) => el.checked)) {
          // Fetch all packages and distributor data
          let queryParams = {
            pageSize: maxPageSize,
            id: new Date().getTime(),
            filter: `contractNumber in (${contracts
              .filter((x) => x.checked)
              .map((t) => (t.id ? '"' + t.id + '"' : ''))
              .join(',')})`,
            Field:
              'packageId,packageName,distributor,distributorDisplayName,distributorPlatformDisplayName,distributorId,distributorPlatform,deliveryType,productComponentId,platformCategories',
          };
          httpService
            .get(Endpoints.contractPackagesApi, queryParams)
            .then((res) => {
              if (res) {
                let metadata = res?.data?.metadata;
                if (contracts && contracts.some((x) => x.checked)) {
                  let contractNumbers = [];
                  contracts.filter((x) => {
                    if (x.checked) {
                      contractNumbers.push(x.id);
                    }
                  });
                  let filteredContractPackages = res?.data?.results;
                  setUserEntitlementsData(filteredContractPackages);
                  let packageData = filteredContractPackages.map(
                    ({
                      distributorId,
                      packageId,
                      packageName,
                      productComponentId,
                      contractNumber,
                    }) => ({
                      distributorId,
                      packageId,
                      packageName,
                      productComponentId,
                      contractNumber,
                    }),
                  );
                  packageData = [
                    ...new Map(
                      packageData.map((item) => [item['packageId'], item]),
                    ).values(),
                  ];
                  metadata.count = packageData.length;
                  let finalPackageData = [];
                  finalPackageData.metadata = metadata;
                  finalPackageData.results = packageData;
                  setPackageDataSet(finalPackageData);

                  // Set distributor data
                  let distributorData = [];
                  distributorData.metadata = filteredContractPackages.length;
                  distributorData.results = filteredContractPackages;
                  setDistributorDataSet(distributorData);
                } else {
                  setUserEntitlementsData([]);
                  setPackageDataSet([]);
                }
              }
            })
            .catch((err) => {});
        } else {
          setUserEntitlementsData([]);
          setPackageDataSet([]);
        }
      } catch (err) {}
    };

    const cascadingPackageDistributor = () => {
      // On empty selection, set to default data
      if (
        selectedPackages.length === 0 &&
        selectedParentCascade === 'PackageDropdown'
      ) {
        setFilteredDistributors(distributorDataSet);
      } else if (
        selectedDistributorsId.length === 0 &&
        selectedParentCascade === 'DistributorDropdown'
      ) {
        setFilteredPackages(packageDataSet);
      }

      // Cascading functionality to set which dropdown is parent
      if (selectedPackages.length === 0 && selectedDistributors.length === 0) {
        setSelectedParentCascade();
      } else if (!selectedParentCascade) {
        if (selectedPackages.length > 0) {
          setSelectedParentCascade('PackageDropdown');
        }
        if (selectedDistributors.length > 0) {
          setSelectedParentCascade('DistributorDropdown');
        }
      }
    };
    // Memoized selection to stop loop from dropdown callback
    const cascadingPackageDistributorMemo = useMemo(
      cascadingPackageDistributor,
      [selectedPackages, selectedDistributors],
    );

    // get all entitlement mappings for user
    const getServerSideDatasource = (filters) => {
      return {
        getRows: async (params) => {
          try {
            let AgParams = params?.request;

            if (contracts && contracts.some((x) => x.checked) && AgParams) {
              let entitlementFilter =
                AgParams?.filterModel['entitlementFilter']?.filter;

              let entFilters = JSON.parse(entitlementFilter);

              if (Object.keys(entFilters).length === 0) return;

              const payload = {
                contractNumber: contracts
                  ?.filter((contract) => contract.checked === true)
                  .map((item) => item.contractNumber)
                  .join("','"),
                packageId:
                  entFilters.selectedPackages &&
                  entFilters.selectedPackages.length > 0
                    ? entFilters.selectedPackages
                        .map((item) => item.packageId)
                        .join("','")
                    : '',
                distributorId:
                  entFilters.selectedDistributors &&
                  entFilters.selectedDistributors.length > 0
                    ? entFilters.selectedDistributors
                        .map((item) => item.distributorId)
                        .join("','")
                    : '',
                emailId:
                  entFilters.selectedUsers &&
                  entFilters.selectedUsers.length > 0
                    ? entFilters.selectedUsers
                        .map((item) => item.emailId)
                        .join("','")
                    : '',
                officeLocationId:
                  entFilters.selectedLocations &&
                  entFilters.selectedLocations.length > 0
                    ? entFilters.selectedLocations
                        .map((item) => item.officeLocationId)
                        .join("','")
                    : '',
                status:
                  entFilters.selectedStatus &&
                  entFilters.selectedStatus.length > 0
                    ? entFilters.selectedStatus
                        .map((item) => item.statusValue)
                        .join("','")
                    : '',
              };

              const defaultSort =
                'contactName:asc,officeLocationName:asc,packageName:asc,display_distributor:asc,display_distributorplatform:asc,deliverytype:asc';
              const sortColumns = await getSortColumns(AgParams, defaultSort);
              const sort = await removeDuplicateFields(sortColumns);

              const queryParams = {
                PageSize: AgParams.endRow - AgParams.startRow,
                Page:
                  AgParams.startRow / (AgParams.endRow - AgParams.startRow) + 1,
                sort: sort,
              };

              const buildQueryString = (params) => {
                const query = new URLSearchParams(params).toString();
                return `?${query}`;
              };
              setIsGridDataAvailable(true);
              params.api.showLoadingOverlay();

              httpService
                .post(
                  Endpoints.entitlementReportApi +
                    buildQueryString(queryParams),
                  payload,
                )
                .then((res) => {
                  setExportCount(res?.data?.metadata?.count?.toLocaleString());
                  const response = res?.data?.results.map((c, index) => {
                    c.id = index;
                    c.isEntitlementExists = true;
                    c.distributorData =
                      c.distributor +
                      ' : ' +
                      c.distributorPlatform +
                      ' (' +
                      c.deliveryType +
                      ')';
                    c.contactName = c.contactName + ' (' + c.emailId + ')';
                    c.officeLocationName = c.officeLocation;
                    return c;
                  });
                  params.success({
                    rowData: response,
                    rowCount: res?.data?.metadata?.count,
                  });
                  if (response?.length === 0) {
                    gridRef?.current?.api?.showNoRowsOverlay();
                  } else {
                    gridRef?.current?.api?.hideOverlay();
                  }
                })
                .catch((err) => {
                  params.fail();
                  gridRef?.current?.api?.hideOverlay();
                });
            } else {
              gridRef?.current?.api?.showNoRowsOverlay();
            }
          } catch (err) {
            params.fail();
          }
        },
      };
    };

    const removeDuplicateFields = async (sortString) => {
      // Remove duplicate fields in sorting string
      const fields = sortString.split(',');
      const uniqueFields = new Set();
      const result = [];

      await fields.forEach((field) => {
        let [fieldName, order] = field.trim().split(':'); // Split by ':'

        // Replace 'distributorData' with the specified fields
        if (fieldName === 'distributorData') {
          const replacements = [
            `display_distributor:${order}`,
            `display_distributorplatform:${order}`,
            `deliverytype:${order}`,
          ];
          replacements.forEach((newlyReplaced) => {
            const [replacementFieldName] = newlyReplaced.split(':');
            if (!uniqueFields.has(replacementFieldName)) {
              uniqueFields.add(replacementFieldName);
              result.push(newlyReplaced);
            }
          });
        } else {
          if (!uniqueFields.has(fieldName)) {
            uniqueFields.add(fieldName);
            result.push(`${fieldName}:${order}`);
          }
        }
      });

      return result.join(',');
    };

    const onDropdownChanged = () => {
      if (gridRef.current && contracts?.length > 0 && enablePreview) {
        let filterValue = {
          filter: JSON.stringify({
            selectedPackages,
            selectedDistributors,
            selectedUsers,
            selectedLocations,
            selectedStatus,
          }),
          filterType: 'text',
          type: 'inRange',
        };

        let newFilter = getAllFilterModel('entitlementFilter', filterValue);
        gridRef.current.api.setFilterModel(newFilter);
      }
    };

    const getAllFilterModel = (colId, newModel) => {
      let currentModel = gridRef.current.api.getFilterModel();
      currentModel[colId] = newModel;
      return currentModel;
    };

    const clickPreview = debounce(onDropdownChanged, 500);

    const callBackUsers = (params) => setSelectedUsers(params);
    const callBackPackages = (params) => setSelectedPackages(params);
    const callBackLocations = (params) => setSelectedLocations(params);
    const callBackDistributors = (params) => {
      let dstsId = [];
      params.forEach((dst) => {
        dstsId.push(dst.distributorId);
      });
      setSelectedDistributors(params);
      setSelectedDistributorsId(dstsId);
    };
    const callBackStatus = useCallback((params) => {
      setSelectedStatus(params);
    }, []);

    const getRowId = (params) => {
      if (params?.data?.userEntitlementId != null) {
        return 'entitlement-' + params.data.userEntitlementId;
      } else {
        // assign a unique ID to each data item
        return Object.entries(params?.data).join(',');
      }
    };

    const getDate = () => {
      let currentDate = new Date();
      return (
        currentDate.getDate() +
        '-' +
        (currentDate.getMonth() + 1) +
        '-' +
        currentDate.getFullYear()
      );
    };

    const getEntitlementsQueryParams = async () => {
      try {
        if (gridRef) {
          const AgParams = gridRef.current?.api;

          let queryParams = {
            Page: 1,
            PageSize: maxExportSizeLimit,
            Field:
              'packageId,packageName,distributor,distributorDisplayName,distributorPlatformDisplayName,distributorId,distributorPlatform,deliveryType,productComponentId,platformCategories',
            Sort: await getSortColumns(
              AgParams,
              'contactName:asc,officeLocationName:asc,packageName:asc,display_distributor:asc,display_distributorplatform:asc,deliverytype:asc',
            ),
          };

          return queryParams;
        }
      } catch (error) {}
    };

    const clickExport = async () => {
      if (!isExportInprogress && enableExport) {
        try {
          setIsExportInprogress(true);
          Notify({
            alert: true,
            type: '',
            title: 'Your download is in progress',
          });

          const entFilters = {
            selectedPackages,
            selectedDistributors,
            selectedUsers,
            selectedLocations,
            selectedStatus,
          };

          const payload = {
            contractNumber: contracts
              ?.filter((contract) => contract.checked === true)
              .map((item) => item.contractNumber)
              .join("','"),
            packageId:
              entFilters.selectedPackages &&
              entFilters.selectedPackages.length > 0
                ? entFilters.selectedPackages
                    .map((item) => item.packageId)
                    .join("','")
                : '',
            distributorId:
              entFilters.selectedDistributors &&
              entFilters.selectedDistributors.length > 0
                ? entFilters.selectedDistributors
                    .map((item) => item.distributorId)
                    .join("','")
                : '',
            emailId:
              entFilters.selectedUsers && entFilters.selectedUsers.length > 0
                ? entFilters.selectedUsers
                    .map((item) => item.emailId)
                    .join("','")
                : '',
            officeLocationId:
              entFilters.selectedLocations &&
              entFilters.selectedLocations.length > 0
                ? entFilters.selectedLocations
                    .map((item) => item.officeLocationId)
                    .join("','")
                : '',
            status:
              entFilters.selectedStatus && entFilters.selectedStatus.length > 0
                ? entFilters.selectedStatus
                    .map((item) => item.statusValue)
                    .join("','")
                : '',
          };

          var queryParams = await getEntitlementsQueryParams();

          const buildQueryString = (params) => {
            const query = new URLSearchParams(params).toString();
            return `?${query}`;
          };

          await httpService
            .post(
              Endpoints.entitlementExportApi + buildQueryString(queryParams),
              payload,
              {},
              {
                responseType: 'blob',
              },
            )
            .then(async (response) => {
              const totalCount = response.headers['total-count'];
              setExportCount(totalCount);
              if (totalCount < maxExportSizeLimit) {
                FileSaver.saveAs(response.data, 'Entitlements' + getDate());

                Notify({
                  alert: true,
                  type: 'success',
                  title: 'User Entitlements Exported Successfully',
                });
              } else {
                Notify({
                  alert: true,
                  type: 'error',
                  dialogClassName: 'alert-export-success-max-width',
                  title:
                    'Number of records for export (' +
                    totalCount +
                    ') are greater than the allowed limit (' +
                    maxExportSizeLimit +
                    '). Please adjust the export criteria to ensure that the number of records for export are within the allowed limit.',
                });
              }
            })
            .catch((error) => {
              Notify({
                alert: true,
                type: 'error',
                title: 'Something went wrong',
              });
            })
            .finally(() => {
              setIsExportInprogress(false);
            });
        } catch (error) {
          setIsExportInprogress(false);
        }
      }
    };

    return (
      <>
        <Container fluid>
          <Row>
            <Col>
              <div className="add-entitlements left-0 mt-2"></div>
            </Col>
          </Row>
          <Row>
            <Col>
              <UsersDropdown
                contracts={contracts}
                callBackUsers={callBackUsers}
              />
            </Col>
            <Col>
              <LocationsDropdown
                contracts={contracts}
                callBackLocations={callBackLocations}
              />
            </Col>
            <Col>
              <PackagesDropdown
                DataSet={filteredPackages}
                callBackPackages={callBackPackages}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <DistributorsDropdown
                DataSet={filteredDistributors}
                Type="ExportEntitlements"
                callBackDistributors={callBackDistributors}
              />
            </Col>
            <Col>
              <StatusDropdown
                reset={resetStatus}
                callBackStatus={callBackStatus}
              />
            </Col>
            <Col className="d-flex justify-content-end">
              <button
                className="btn btn-dark btn-opacity me-2"
                disabled={!enablePreview}
                onClick={clickPreview}
              >
                Preview
              </button>
              <button
                className="btn btn-dark btn-opacity"
                disabled={!enableExport}
                onClick={clickExport}
              >
                Export
              </button>
            </Col>
          </Row>
          <Row className="mt-2 mb-n3">
            <div className="mb-2">
              Number of records for Export: {exportCount}
            </div>
          </Row>
          <Row>
            <Col>
              <div className="ag-grid-export-entitlement-table">
                <div
                  className={
                    isGridDataAvailable ? 'd-none' : 'd-block text-center pt-5'
                  }
                >
                  <p>Please select criteria above to preview and export.</p>
                  <p>
                    Maximum of {maxExportSizeLimit} records can be downloaded at
                    once. Please update your criteria to ensure Number of
                    records selected for export is less than the limit.
                  </p>
                </div>
                <div
                  className={
                    isGridDataAvailable
                      ? 'd-block ag-grid-table with-multi-tabs'
                      : 'd-none'
                  }
                >
                  <AgGridComponent
                    config={defaultConfig}
                    defaultColumnDef={defaultColdef}
                    columns={columns}
                    onGridReady={onGridReadyHandler}
                    getRowId={getRowId}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <div className="notes-text-label mt-1" id="notes-label">
          <span className="notes-asterisk-label">*</span>Maximum of{' '}
          {maxExportSizeLimit} records can be downloaded at once. Please update
          your criteria to ensure Number of records selected for export is less
          than the limit.
        </div>
      </>
    );
  },
  (op, np) => op?.contracts === np?.contracts,
);

export default ExportEntitlements;
