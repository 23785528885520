import { ReduxAction } from '../../constants';
const initialState = {};

const userPreferencesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ReduxAction.userPreferencesSet: {
      return {
        ...state,
        userPreferences: action.payload,
      };
    }
    default:
      return state;
  }
};
export default userPreferencesReducer;
