export const Endpoints = {
  sendEmailApi: 'v1/email/emailnotification',
  saveEndUserApi: 'v1/email/useremailinvitees',
  accountApi: 'v1/account',
  userRegistrationAccountApi: 'v1/user-registration/account',
  userRegistrationLocationApi: 'v1/user-registration/location',
  emailDomainApi: 'v1/account/email-domain',
  welcomeEmailApi: 'v1/account/welcome-email',
  userApi: 'v1/account/user',
  userReportApi: 'v1/account/user/report',
  userExportApi: 'v1/account/user/export',
  addedCriteriaDdDetailsApi:
    'v1/account/user/entitlement/addedCriteriaDdDetails',
  userRegistrationUserPostApi: 'v1/user-registration',
  bulkUserEntitlementApi: 'v1/account/user/bulk-user-entitlement',
  userGroupApi: 'v1/account/user-group',
  configurationApi: 'v1/configuration',
  userRegistrationConfigurationApi: 'v1/user-registration/configuration',
  packagesApi: 'v1/package',
  userentitlementsApi: 'v1/account/user/entitlement',
  userEntitlementsAuditApi: 'v1/account/user/entitlement-audit',
  userEntitlementsAuditExportApi: 'v1/account/user/entitlement-audit/export',
  packageDataset: 'v1/package/dataset',
  locationApi: 'v1/account/location',
  addressValidatorApi: 'v1/account/location/address-validate',
  duplicateAddressValidatorApi:
    'v1/account/location/duplicate-address-validate',
  contractApi: 'v1/account/contract',
  contractPackagesApi: 'v1/account/contract/package',
  contractProductsApi: 'v1/account/contract/product',
  bulkUploadTemplateApi: 'v1/account/bulk-upload-template',
  userEntitlementsCloneApi: 'v1/account/user/entitlement/clone',
  distributorUserApi: 'v1/account/distributor/user',
  distributorApi: 'v1/package/distributor',
  userGroupEntitlementsApi: 'v1/account/user-group/entitlement',
  userGroupContactApi: 'v1/account/user-group/contact',
  userPreferenceApi: 'v1/account/user/user-preference',

  userRegistrationUserGroupContactApi:
    'v1/user-registration/user-group/contact',
  userRegistrationDuplicateAddressValidatorApi:
    'v1/user-registration/duplicate-address-validate',
  entitlementExportApi: 'v1/account/user/entitlement/export',
  entitlementReportApi: 'v1/account/user/entitlement/report',
};

export const packageDatasetProps = [
  'marketplaceLongDescription',
  'industryCoverageData',
  'marketplaceShortDescription',
  'marketplaceDatasetName',
  'keyFeatures',
  'keyServiceHighlights',
  'keyMarkets',
  'keyMarketInsightHighlights',
  'keyMarketInsights',
  'keyHighlights',
  'keyPersonasUseCases',
  'keyAssessments',
  'keyPriceAssessments',
  'keyBenefits',
  'marketsCovered',
];

export const distributorDatasetProps = [
  'tile_main_heading',
  'tile_description',
  'distributorDescription',
  'distributorDisplayName',
  'distributorPlatformDisplayName',
  'keyUseCases',
  'keyFeatures',
  'keyWorkflows',
  'keyBenefits',
  'keyPersonas',
  'keyCapabilities',
  'features',
  'accessibleData',
  'delivery',
  'coverage',
  'platformDescription',
  'distributorPlatformLink',
  'platformUserPersona',
  'commodityCoverage',
  'csmMarketDataPackageCoverage',
  'csmMarketInsightsPackageCoverage',
  'csmRiskInsightsPackageCoverage',
];

export const setUserId = (newUserId) => {
  UserId = newUserId;
};

export let UserId = '';
export const contractType = ['CSM1.0', 'CSM+', 'Market Basics'];
export const contractTypeCSMPlus = 'CSM+';
export const contractTypePrimary = 'Primary';
export const contractDisplayOptionKey = 'DISPLAY_CONTRACT';
export const columnDisplayOptionKey = 'COLUMN_OPTION_PREFERENCES';
export const contractTypeMarketBasics = 'Market Basics';

export const ProductDeliveryPlatforms = ['AlphaMail'];
export const DistributorUserIDRequiredList = ['Bloomberg'];
export const AutoSelectPkgDist = {
  Market_Basics: {
    pkgName: 'Market Basics',
    distName: 'SPGCI:Platts Connect:Web',
    distId: '46',
    distPlatform: 'Platts Connect',
  },
};

export const userPreferencesKey = { displayContract: 'DISPLAY_CONTRACT' };

export const GenericName = {
  channelPartners: 'Channel Partners',
  commodityInsights: 'Commodity Insights',
};

export const UserGroupTab = 'user-group-tab';
export const entitlementTabName = 'entitlements-tab';
export const productEntitlementTabName = 'product-entitlements-tab';
export const manageUsersTabName = 'manage-user-tab';

export const deliveryType = ['Channel'];
export const RequiredPlatformCategory = [GenericName.channelPartners];

export const setMaxExportSizeLimit = (limit) => {
  maxExportSizeLimit = limit;
  Verbiage.entitlementExportExceedMsg = `Number of records selected for export is greater than allowed limit of ${limit}. Reduce the number of records by updating the criteria.`;
};

export const maxPageSize = 50000;
export let maxExportSizeLimit = null;

// Entitlement Status
export const PendingActive = 'Pending Active';
export const Active = 'Active';
export const PendingApproval = 'Pending Approval';
export const PendingInactive = 'Pending Inactive';
export const Cancelled = 'Cancelled';
export const Rejected = 'Rejected';
export const Blocked = 'Blocked';
export const positiveStatuses = [
  'Active',
  'Pending Active',
  'Pending Approval',
];
export const negativeStatuses = [
  'Cancelled',
  'Rejected',
  'Pending Inactive',
  'Blocked',
];

// Bulk Upload Template Type
export const BulkUploadUsersTemplateType = 'BulkUploadUsers';
export const BulkUploadUserEntitlementsTemplateType =
  'BulkUploadUserEntitlements';

export const UrlPath = {
  users: '/users',
  packages: '/packages',
  entitlements: '/entitlements',
  contact: 'https://www.spglobal.com/commodity-insights/en/contact',
  termsOfUse: 'https://www.spglobal.com/en/terms-of-use',
  privacy: 'https://www.spglobal.com/en/privacy',
  cookieNotice:
    'https://www.spglobal.com/corporate-privacy-policy/corporate-privacy-and-cookie-notice',
  entitlementsServerside: '/entitlements-serverside', // Test URL
  addEntitlements: '/add-entitlements',
  reports: '/reports',
  exportUsers: '/export-users',
  exportEntitlements: '/export-entitlements',
  productEntitlements: '/product-entitlements',
  userLocation: '/user-location',
  userDashboard: '/user-dashboard',
  details_Id: '/details/:id',
  changeHistory: '/change-history',
  manageEmailDomains: '/manage-email-domains',
  userGroup: '/user-group',
  adminDashboard: '/admin-dashboard',
  logout: '/logout',
  endUserRegistration: '/user-registration/:id',
  endUserRegistrationSuccess: '/user-registration-success',
  invalidRegistrationLink: '/invalid-registration-link',
  mySubscription: '/my-subscription',
  manageUserIDs: '/manage-user-ids',
};

export const Verbiage = {
  noData: 'No Data Available',
  locations: 'No locations in this contract',
  users: 'No users in this contract',
  userGroups: 'No user groups in this contract',
  entitlements: 'No entitlements in this contract',
  entitlementsAdded: 'Entitlements Added',
  retriggerSuccess: 'Email sent',
  singleUser: ' user',
  multipleUser: ' users',
  singleUserEntitlement: ' user entitlement',
  multipleUserEntitlement: ' user entitlements',
  noValidDataForUserEntitlementUpload: 'No valid records to be uploaded',
  tileDisabledTooltip:
    'Currently not subscribed, please contact CI support for further assistance',
  commonEntitlementsSave: 'Your entitlements have been saved',
  restrictedEntitlementsAdded:
    'Your entitlements are pending approval with the Contract Admin',
  unrestrictedEntitlementsAdded: 'Your Entitlements will be activated soon',
  mixedEntitlementsAdded:
    'Some of your entitlements will be activated soon, and some are pending approval from the Contract Admin',
  endUserDetailsEdited: 'Your details have been updated',
  disallowedEmailDomain:
    'The user cannot be added as the email domain is invalid for this account',
  emailSent: 'Email invites successfully sent out to the specified Users',
  reachOutToContractAdminToUpdateEntitlements:
    'Please reach out to the contract admin to update your entitlements.',
  contractNotSelected: 'Please select valid contract to download the template.',
  duplicateAddressFound:
    'We have detected existing locations (listed below) that may be duplicate of the new location being added. Please review these locations and ensure that you are not adding a duplicate one. Click on Save if the new location is not a duplicate else click Cancel',
  groupEntitlementsAttemptedUpdate:
    'You have selected users that have been added to a group. Entitlements for such users can not be managed separately and must be managed at group level.',
  displayOptionContractDescriptionFailed:
    'You can set Display option as Contract Description only if description is specified for all contracts. Click on Contract ID to add description.',
  displayOptionContractIDAndDescription:
    'There are at least one or more contracts without a description and will therefore be displayed with Contract ID only. Click on Contract ID below to add a description.',
  resetDisplayOption:
    'Removing description for any contract will update your Display options to "Contract ID" only. Click Ok if you want to proceed deleting the contract description and change display option to "Contract ID". Click Cancel to retain the description and Display Option as "Contract Description"',
  filterCriteriaTooltip:
    'Select criteria below and click on Apply to preview and export. Please note that Active User list for Alphamail and other platforms can not be included in the same report',
};

export const ReduxAction = {
  userInfoSet: 'STORE_USERINFO',
  userContractsSet: 'STORE_USERCONTRACTS',
  selectedEntitlementUser: 'ENTITLEMENT_USERS',
  tabType: 'TAB_TYPE',
  userPreferencesSet: 'STORE_USERPREFERENCES',
  updateEntitlement: 'UPDATE_ENTITLEMENT',
  updateProductEntitlement: 'UPDATE_PRODUCT_ENTITLEMENT',
  updateUGEntitlement: 'UPDATE_USER_GROUP_ENTITLEMENT',
  updateUGProductEntitlement: 'UPDATE_USER_GROUP_PRODUCT_ENTITLEMENT',
  updateUGManageUser: 'UPDATE_USER_GROUP_MANAGE_USERS',
  updateUG_API_Flag: 'UPDATE_USER_GROUP_API_SUCCESS_FLAG',
  distributorsDetail: 'SET_DISTRIBUTORS',
  datasets: 'SET_DATASETS',
  distributors: 'SET_USER_DISTRIBUTORS',
  jobFuncDesignation: 'SET_JOB_DESIGNATION',
  entitlementStatus: 'SET_ENTITLEMENT_STATUS',
  // Location Tab
  countryStateList: 'COUNTRY_STATE_LIST',
  // DB Config
  dbConfig: 'DB_CONFIG',
};

export const PersistReduxAction = {
  csaActionData: 'CSA_ACTION_INFO',
};

export const ContractDisplayOptions = [
  {
    contractDisplayOptionLabel: 'Contract ID',
    contractDisplayOption: 'ContractNumber',
  },
  {
    contractDisplayOptionLabel: 'Contract Description',
    contractDisplayOption: 'ContractDescription',
  },
  {
    contractDisplayOptionLabel: 'Contract ID and Description',
    contractDisplayOption: 'ContractNumberDescription',
  },
];

export const JobTitleCollection = [
  'Acct/Back Office/Settlement',
  'Academic',
  'Administration',
  'Analyst',
  'Broker',
  'Compliance',
  'Consultant',
  'Economist',
  'Engineer',
  'Finance',
  'Executive',
  'Forecasting',
  'Government',
  'IT',
  'Journalist',
  'Legal',
  'Librarian',
  'Marketer',
  'Market Research',
  'Operations Management',
  'Project Management',
  'Publishing',
  'Procurement/Purchasing',
  'Research and Development',
  'Sales/Business Development',
  'Strategic Planning',
  'Student',
  'Trader',
];

export const GenericError = {
  somethingWentWrong: 'Something went wrong. Try again',
};
export const GenericSuccess = {
  thankYou: 'Thank You for Submitting your feedback!',
};

export const mail = {
  mail_to_address: 'ci.support@spglobal.com',
  mail_from_address: 'donotreply@spglobal.com',
};

export const loaderStyle = {
  width: '100%',
  height: '100%',
  background: '#cdcecf6e',
  display: 'flex',
  position: 'absolute',
  zIndex: '1100',
  justifyContent: 'center',
  alignItems: 'center',
};

export const addressValidation = {
  status: {
    valid: 'Valid',
    inValid: 'InValid',
    partial: 'Partial',
  },
  msg: {
    valid: 'Location Validated Successfully',
    inValid: 'Validation failed',
    partial: 'Validation partially failed',
  },
};

export const queryParamFields = {
  contractPackagesAPIFields:
    'packageId,packageName,distributor,distributorId,distributorPlatform,distributorDisplayName,distributorPlatformDisplayName,deliveryType,productComponentId,platformCategories,userIdFormat',
};
