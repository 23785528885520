import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { debounce } from '../../../helpers/debounce';
import AgGridComponent from '../../../sharedcomponents/ag-grid/AgGrid';
import {
  Active,
  AutoSelectPkgDist,
  Cancelled,
  Endpoints,
  GenericError,
  PendingActive,
  PendingApproval,
  PendingInactive,
  ProductDeliveryPlatforms,
  ReduxAction,
  Rejected,
  RequiredPlatformCategory,
  UserId,
} from './../../../constants';
import store from './../../../redux/store';
import httpService from './../../../services/http-service';
import { Notify } from './../../../sharedcomponents/Alert/Notify';
import ProductsDropdown from './../../Entitlements/ProductsDropdown';

const UserProductEntitlements = ({
  contractNumber,
  editMode,
  distributorDataSet,
  userEntitlementData,
  packageMappingData,
  editData,
  onAddEditModalClose,
  getUsers,
  setLoading,
  setActiveTab,
  setIsUserIdEnabled,
  isViewerUser,
}) => {
  //state variable declarations starts
  const [productRowData, setProductRowData] = useState();
  const [productDataset, setProductDataset] = useState();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [availableMappings, setAvailableMappings] = useState([]);
  const [initialEntitlements, setInitialEntitlements] = useState([]);
  const [deselectRowsData, setDeselectRowsData] = useState([]);
  const [isEntitlementsChanged, setIsEntitlementsChanged] = useState(false);
  const [isFirstDataSelection, setIsFirstDataSelection] = useState(true);
  const [isRowDataLoaded, setIsRowDataLoaded] = useState(false);
  const productEntitlementgridApi = useRef(null);
  const dispatch = useDispatch();
  //state variable declarations ends

  const onProductEntitlementGridReadyHandler = (params) => {
    productEntitlementgridApi.current = params;
    productEntitlementgridApi.current?.api.showLoadingOverlay();
  };

  const isFirstColumn = (params) => {
    let displayedColumns = params?.api?.getAllDisplayedColumns();
    let thisIsFirstColumn = displayedColumns[0] === params?.column;
    if (thisIsFirstColumn) {
      return thisIsFirstColumn;
    }
  };
  const defaultConfig = {
    rowModelType: 'clientSide',
    isExportCSV: false,
    isExportExcel: false,
    suppressDragLeaveHidesColumns: true,
    pivotPanelShow: '',
    pagination: false,
    isAutoSizeColumns: true,
    enableCharts: false,
    rowGroupPanelShow: false,
    sideBar: false,
    overlayNoRowsTemplate: 'This user has no entitlements',
  };

  const defaultColdef = {
    sortable: true,
    resizable: true,
    headerCheckboxSelection:
      editData?.groupId || isViewerUser ? false : isFirstColumn,
    checkboxSelection:
      editData?.groupId || isViewerUser ? false : isFirstColumn,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };

  const objectsEqual = (o1, o2) =>
    Object.keys(o1).length === Object.keys(o2).length &&
    Object.keys(o1).every((p) => o1[p] === o2[p]);

  const validateEntitlementChange = () => {
    let currentlySelectedEntitlements =
      productEntitlementgridApi?.current?.api?.getSelectedRows();
    let initialEntitlementsToValidateChanges = initialEntitlements?.filter(
      (x) =>
        x.status != Cancelled &&
        x.status != PendingInactive &&
        x.status != Rejected,
    );
    if (currentlySelectedEntitlements && initialEntitlementsToValidateChanges) {
      if (
        objectsEqual(
          currentlySelectedEntitlements,
          initialEntitlementsToValidateChanges,
        )
      ) {
        setIsEntitlementsChanged(false);
      } else {
        setIsEntitlementsChanged(true);
      }
    }
  };

  const setSelectionInGrid = () => {
    if (availableMappings.length > 0) {
      productEntitlementgridApi.current.api.selectAll();
      const nodesToDeselect = [];
      if (isFirstDataSelection) {
        setIsFirstDataSelection(false);
        productEntitlementgridApi.current.api.forEachNode((node) => {
          // Deselect Cancelled and Pending Inactive entitlements
          if (
            [Cancelled, PendingInactive, Rejected].includes(node?.data?.status)
          )
            nodesToDeselect.push(node);
        });
        productEntitlementgridApi.current?.api.hideOverlay();
      }
      if (isRowDataLoaded && deselectRowsData?.length > 0) {
        productEntitlementgridApi?.current?.api?.forEachNode((node) => {
          if (
            deselectRowsData.some(
              (nd) => nd.productComponentId === node.data.productComponentId,
            )
          )
            nodesToDeselect.push(node);
        });
      }
      productEntitlementgridApi.current.api.setNodesSelected({
        nodes: nodesToDeselect,
        newValue: false,
      });
      setDeselectRowsData([]);
      setIsRowDataLoaded(true);
      validateEntitlementChange();
    }
  };

  const getEntitlementChanges = () => {
    let contactId = editData.contactId !== '' ? editData.contactId : -1;
    let entitlementChanges = [];
    let newEntitlementChanges = [];

    productEntitlementgridApi?.current?.api?.forEachNode((node) => {
      // remove entitlements that have a status and aren't selected
      if (
        node.data.status &&
        [Active, PendingActive, PendingApproval].includes(node.data.status) &&
        !node.selected
      ) {
        entitlementChanges.push({
          contactid: contactId,
          productcomponentid: node.data.productComponentId,
          status: PendingInactive,
          contractNumber: node.data.contractNumber,
          userEntitlementId: node.data.userEntitlementId,
          packageName: node.data.packageName,
        });
      }
      // add entitlements that don't have a status and are selected
      if (
        (!node.data.status ||
          [PendingInactive, Cancelled, Rejected].includes(node.data.status)) &&
        node.selected
      ) {
        const newEntitlement = {
          contactid: contactId,
          productcomponentid: node.data.productComponentId,
          status: PendingActive,
          contractNumber: node.data.contractNumber,
          packageName: node.data.packageName,
        };
        entitlementChanges.push(newEntitlement);
        // Add into this array if product's parent package is Market Basics
        if (
          node.data.packageName.includes(
            AutoSelectPkgDist.Market_Basics.pkgName,
          )
        ) {
          newEntitlementChanges.push(newEntitlement);
        }
      }
    });

    // Add Market Basics entitlements data if Product has same Market Basics Packages
    if (newEntitlementChanges.length > 0) {
      const filteredUserEntitlementsData = packageMappingData.filter(
        (uedata) =>
          !ProductDeliveryPlatforms.includes(uedata.distributor) &&
          uedata.packageName.includes(
            AutoSelectPkgDist.Market_Basics.pkgName,
          ) &&
          uedata.distributorId == AutoSelectPkgDist.Market_Basics.distId &&
          newEntitlementChanges.some(
            (ec) => ec.packageName == uedata.packageName,
          ),
      );

      filteredUserEntitlementsData.forEach((uedata) => {
        const existingEntitlement = productRowData.find(
          (ue) => ue.productComponentId === uedata.productComponentId,
        );

        if (
          (existingEntitlement &&
            [PendingInactive, Cancelled, Rejected].includes(
              existingEntitlement.status,
            )) ||
          !existingEntitlement
        ) {
          entitlementChanges.push({
            contactId: contactId,
            productComponentId: uedata.productComponentId,
            status: PendingActive,
            contractNumber: uedata.contractNumber,
          });
        }
      });
    }

    return entitlementChanges;
  };

  //useEffects start
  useEffect(() => {
    // Controller to abort previous API calls if any ongoing flight
    const controller = new AbortController();
    const signal = controller.signal;
    if (editMode) {
      getContractProducts(signal);
    }
    return () => {
      controller?.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (editData && userEntitlementData) {
        let currentUserEntitlements = userEntitlementData.filter(
          (ue) =>
            ue.contactId === editData.contactId &&
            ProductDeliveryPlatforms.includes(ue.distributor) &&
            ue.contractNumber === editData.contractNumber,
        );
        setInitialEntitlements(currentUserEntitlements);
        setProductRowData(currentUserEntitlements);
        dispatch({
          type: ReduxAction.updateProductEntitlement,
          payload: {},
        });
        if (currentUserEntitlements?.length === 0) {
          setIsRowDataLoaded(true);
          productEntitlementgridApi.current?.api.showNoRowsOverlay();
        }
      }
    }
    return () => {
      isMounted = false;
    };
  }, [userEntitlementData, editData]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (selectedProducts && availableMappings.length > 0) {
        let matchedMappings = [];
        selectedProducts?.map((product) => {
          let matchedRows = availableMappings.filter(
            (map) =>
              map.productId === product &&
              ProductDeliveryPlatforms.includes(map.distributor),
          );
          if (matchedRows?.length > 0) {
            matchedMappings = matchedMappings.concat(matchedRows);
          }
        });
        if (matchedMappings?.length > 0) {
          // filter out duplicate rows
          initialEntitlements.forEach((row) => {
            let nodeIndex = matchedMappings.findIndex(
              (nid) => nid.productComponentId === row.productComponentId,
            );
            if (nodeIndex !== -1) {
              matchedMappings.splice(nodeIndex, 1);
            }
          });
        }
        let updatedData = matchedMappings.concat(initialEntitlements);
        //set deselected rows from previous state
        let selectedRows =
          productEntitlementgridApi?.current?.api?.getSelectedRows();
        if (productRowData?.length > 0) {
          let deselectedRows =
            selectedRows?.length > 0
              ? productRowData?.filter(
                  (x) =>
                    !selectedRows.some(
                      (s) => s.productComponentId === x.productComponentId,
                    ),
                )
              : productRowData;
          setDeselectRowsData(deselectedRows);
        } else {
          setDeselectRowsData([]);
        }
        if (productRowData) {
          setProductRowData(updatedData);
          setTimeout(() => {
            dispatch({
              type: ReduxAction.updateProductEntitlement,
              payload: {
                updatedProductEntitlements: getEntitlementChanges(),
              },
            });
          }, 300);
        }
      }
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProducts, availableMappings]);

  useEffect(() => {
    let isMounted = true;
    const reduceCallback = setTimeout(() => {
      if (productRowData && isMounted) {
        if (productRowData.length > 0) {
          setSelectionInGrid();
        } else {
          if (
            productRowData.length === 0 &&
            initialEntitlements?.length === 0
          ) {
            setIsEntitlementsChanged(false);
          }
        }
      }
    }, 100);
    return () => {
      clearTimeout(reduceCallback);
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productRowData, availableMappings]);
  //useeffect ends

  const getColumnClass = (params) => {
    if (
      (params?.node?.data?.status === Cancelled ||
        params?.node?.data?.status === PendingInactive ||
        params?.node?.data?.status === Rejected) &&
      !isRowDataLoaded
    ) {
      return 'unselected-entitlement-row';
    }
    if (
      (!params?.node?.data?.status ||
        params?.node?.data?.status === Cancelled ||
        params?.node?.data?.status === PendingInactive ||
        params?.node?.data?.status === Rejected) &&
      params?.node?.selected &&
      isRowDataLoaded
    ) {
      return 'new-selected-entitlement-row';
    }
    if (
      params?.node?.data?.status &&
      !params?.node?.selected &&
      isRowDataLoaded
    ) {
      return 'unselected-entitlement-row';
    } else {
      return '';
    }
  };

  const productEntitlementsColumns = [
    {
      headerName: '',
      field: 'id',
      resizable: true,
      filter: 'agTextColumnFilter',
      suppressHeaderMenuButton: true,
      // suppressCellSelection: true,
      sortable: false,
      hide: true,
    },
    {
      headerName: 'Package',
      headerTooltip: 'Package',
      field: 'packageName',
      tooltipField: 'packageName',
      initialFlex: 3,
      cellClass: getColumnClass,
    },
    {
      headerName: 'Product',
      headerTooltip: 'Product',
      field: 'productName',
      tooltipField: 'productName',
      initialFlex: 2,
      cellClass: getColumnClass,
    },
    {
      headerName: 'Status',
      headerTooltip: 'Status',
      field: 'status',
      tooltipField: 'status',
      initialFlex: 2,
      cellClass: getColumnClass,
    },
  ];

  const productCompare = (current, next) => {
    if (current.productName < next.productName) {
      return -1;
    }
    if (current.productName > next.productName) {
      return 1;
    }
    return 0;
  };

  const getContractProducts = (signal) => {
    if (contractNumber) {
      let productDistributors = ProductDeliveryPlatforms?.map(
        (t) => '"' + t + '"',
      ).join(',');

      let queryParams = {
        pageSize: 5000,
        id: new Date().getTime(),
        filter: `contractNumber:"${contractNumber}" ${
          productDistributors
            ? 'AND distributor in (' + productDistributors + ')'
            : ''
        }`,
        Field:
          'productId,productName,packageName,productComponentId,distributorId,distributor,distributorPlatform,deliveryType,contractNumber',
      };
      httpService
        .get(Endpoints.contractProductsApi, queryParams, { signal })
        .then((res) => {
          if (res) {
            let productData = [];
            // productData.metadata = res?.data?.metadata;
            setAvailableMappings(res?.data?.results);
            const allProducts = res?.data?.results?.map((prd) => {
              return { productId: prd.productId, productName: prd.productName };
            });
            let uniqueProducts = allProducts.filter((value, index, self) => {
              return (
                self.findIndex((x) => x.productId === value.productId) === index
              );
            });
            uniqueProducts?.sort(productCompare);
            productData.results = uniqueProducts;
            setProductDataset(productData);
          }
        })
        .catch((err) => {
          if (err.code !== 'ERR_CANCELED') setProductDataset([]);
        });
    }
  };

  const callBackProducts = (params) => {
    let dsts = [];
    params?.forEach((dst) => {
      dsts.push(dst?.productId);
    });
    setSelectedProducts(dsts);
  };

  const onSelectionChanged = debounce(() => {
    productEntitlementgridApi.current?.api.refreshCells({
      force: true,
    });

    validateEntitlementChange();
    setTimeout(() => {
      dispatch({
        type: ReduxAction.updateProductEntitlement,
        payload: {
          updatedProductEntitlements: getEntitlementChanges(),
        },
      });
    }, 300);
  }, 100);

  const validateData = (entitlementPostData, intialEntitlementData) => {
    let postDetailEntitlements =
      entitlementPostData?.userEntitlements?.length > 0
        ? entitlementPostData?.userEntitlements
        : [];
    let managedUsers = distributorDataSet?.results?.filter((x) =>
      RequiredPlatformCategory.includes(x.platformCategories),
    );
    let requiredEntitlements = postDetailEntitlements.filter(
      (en) =>
        managedUsers?.some((md) => md.distributorId === en.distributorId) &&
        (en.status === Active || en.status === PendingActive),
    );
    return requiredEntitlements;
  };

  const updateProductEntitlements = (postData) => {
    setLoading(true);

    httpService
      .post(Endpoints.userentitlementsApi, postData)
      .then(() => {
        getUsers();
        onAddEditModalClose();
        Notify({
          alert: true,
          type: 'success',
          title: 'User Entitlements have been updated',
        });
      })
      .catch(() => {
        Notify({
          alert: true,
          type: 'error',
          title: GenericError.somethingWentWrong,
        });
      })
      .finally(() => setLoading(false));
  };

  const updateEntitlements = () => {
    try {
      let entitlementChanges = getEntitlementChanges();
      let entitlementDetails = store.getState()?.entitlementDetails;
      let postPackageEntData = entitlementDetails?.entitlementsToUpdate;
      let initialEntitlementData = entitlementDetails?.initialEntitlements;
      if (postPackageEntData || initialEntitlementData) {
        let requiredEntitlements = validateData(
          postPackageEntData,
          initialEntitlementData,
        );
        if (requiredEntitlements && requiredEntitlements.length > 0) {
          dispatch({
            type: ReduxAction.updateProductEntitlement,
            payload: {
              updatedProductEntitlements: entitlementChanges,
            },
          });
          setIsUserIdEnabled(true);
          setActiveTab('manage-user-ids-tab');
        } else {
          postPackageEntData?.userEntitlements.forEach((entData) => {
            entitlementChanges.push(entData);
          });
          let postData = {
            userEntitlements: entitlementChanges,
            userId: UserId,
          };
          updateProductEntitlements(postData);
        }
      } else {
        let postData = {
          userEntitlements: entitlementChanges,
          userId: UserId,
        };
        updateProductEntitlements(postData);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const onFirstDataRenderedHandler = useCallback((params) => {
    // setSelectionInGrid();
  }, []);
  return (
    <>
      <Container className={editData?.groupId ? 'pt-3' : 'pt-2-5'}>
        <Row>
          <Col className="mb-2">
            {!editData?.groupId && (
              <span className="email-preference-note-label">
                Please select CI Market Reports & Insights the user shall
                receive via Email
              </span>
            )}
            {editData?.groupId && (
              <span className="email-preference-note-label">
                This user belongs to a group and cannot be edited
              </span>
            )}
          </Col>
        </Row>
        {!editData?.groupId && (
          <Row>
            <Col md={6}>
              <div>
                <ProductsDropdown
                  DataSet={productDataset}
                  callBackProducts={callBackProducts}
                  disabled={editData?.groupId}
                />
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <div className="ag-grid-modal-content">
            <AgGridComponent
              config={defaultConfig}
              defaultColumnDef={defaultColdef}
              data={productRowData}
              columns={productEntitlementsColumns}
              onSelectionChanged={onSelectionChanged}
              onGridReady={onProductEntitlementGridReadyHandler}
              onFirstDataRendered={onFirstDataRenderedHandler}
            />
          </div>
        </Row>
        <Row
          className={
            editData?.groupId
              ? 'modal-row group-mapped-entitlement-fix'
              : 'modal-row'
          }
        >
          <div className="col-10 mt-1">
            <span className="email-preference-note-label">
              <span className="notes-asterisk-label">*</span>
              Please note that this user can self-manage and customise Email
              alerts on Platts Connect
            </span>
          </div>
          <Form className="col-2">
            <Form.Group className="user-save-btn-body">
              <button
                type="button"
                disabled={
                  !isEntitlementsChanged || editData?.groupId || isViewerUser
                }
                className="user-save-btn btn btn-dark float-end btn-opacity"
                onClick={updateEntitlements}
              >
                {editMode ? 'Update' : 'Save'}
              </button>
            </Form.Group>
          </Form>
        </Row>
      </Container>
    </>
  );
};

export default UserProductEntitlements;
