import React from 'react';
import { Navigate } from 'react-router-dom';
// All pages that rely on 3rd party components (other than Bootstrap) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
// import AuthLayout from './layouts/Auth';
import DashboardLayout from './layouts/Dashboard';
import LandingLayout from './layouts/Landing';
import BasicLayout from './layouts/BasicLayout';

// Guards
import AuthGuard from './components/guards/AuthGuard';

// import { Info } from 'react-feather';
// import { Dashboard } from './pages/admin/Dashboard';

// CCA Pages
// import AddEntitlements from './pages/Entitlements/AddEntitlements';
// import ProductEntitlements from './pages/Entitlements/ProductEntitlements';
import UserDetails from './pages/user-details/user-details';
import EntitlementsContainer from './pages/Entitlements/entitlements-container';
import Packages from './pages/packages/packages';
import PackageDetails from './pages/packages/packages-details';
import DistributorDetails from './pages/packages/distributor-details';
// import Resources from './pages/Resources';
// import SampleApi from './pages/sample-api/SampleApi';
import UserDashboard from './pages/user-dashboard/user-dashboard';
import UserInfo from './pages/user-dashboard/user-info';
import UserGroup from './pages/user-group/user-group';
import UserLocationContainer from './pages/user-details/UserLocationContainer';
import Reports from './pages/reports/Reports';
// import User from './pages/user/User';
import ChangeHistory from './pages/change-history/ChangeHistory';
import ManageEmailDomains from './pages/manage-email-domains/manage-email-domains';

// CSA Pages
import AdminDashboard from './pages/csa/admin-dashboard/AdminDashboard';

// CCS Pages
import OnboardContracts from './pages/ccs/onboard-contracts';

// Individual Page
import MySubscription from './pages/end-user/my-susbscription/MySubscription';
import EndUserManageUserIds from './pages/end-user/end-user-manage-user-ids/end-user-manage-user-ids';

// End User Registration Page
import EndUserRegistration from './pages/end-user/end-user-registration/end-user-registration';
import EndUserRegistrationSuccess from './pages/end-user/end-user-registration/end-user-registration-success';

// Login
import Logout from './pages/auth/Logout';
import { LoginCallback } from '@okta/okta-react';

import { SecureRoute } from './components/auth/SecureRoute';
import { UrlPath } from './constants';

// Invalid Registration Link
import InvalidRegistrationLink from './sharedcomponents/enduser-invalid-registration-link/InvalidRegistrationLink';

const routes = [
  {
    path: '/',
    element: <SecureRoute />,
  },
  {
    path: 'login/callback',
    element: <LoginCallback />,
  },
  {
    path: UrlPath.logout,
    element: (
      <DashboardLayout>
        <Logout />
      </DashboardLayout>
    ),
  },
  {
    path: UrlPath.endUserRegistration,
    element: (
      <DashboardLayout>
        <EndUserRegistration />
      </DashboardLayout>
    ),
  },
  {
    path: UrlPath.endUserRegistrationSuccess,
    element: (
      <DashboardLayout>
        <EndUserRegistrationSuccess />
      </DashboardLayout>
    ),
  },
  {
    path: UrlPath.invalidRegistrationLink,
    element: (
      <DashboardLayout>
        <InvalidRegistrationLink />
      </DashboardLayout>
    ),
  },
  {
    path: '',
    element: (
      <AuthGuard
        roles={['SFS_CCA', 'GRP_SFS_CSA', 'GRP_SFS_VIEWER']}
        layout={DashboardLayout}
      />
    ),
    children: [
      { path: 'user-location', element: <UserLocationContainer /> },
      { path: 'user-group', element: <UserGroup /> },
      { path: 'reports', element: <Reports /> },
      { path: 'export-users', element: <UserLocationContainer /> },
      { path: 'entitlements', element: <EntitlementsContainer /> },
      { path: 'entitlements-serverside', element: <EntitlementsContainer /> },
      { path: 'add-entitlements', element: <EntitlementsContainer /> },
      { path: 'export-entitlements', element: <EntitlementsContainer /> },
      { path: 'product-entitlements', element: <EntitlementsContainer /> },
      { path: 'change-history', element: <ChangeHistory /> },
    ],
  },
  {
    path: '',
    element: <AuthGuard roles={['SFS_ENDUSER']} layout={DashboardLayout} />,
    children: [
      { path: 'my-subscription', element: <MySubscription /> },
      { path: 'manage-user-ids', element: <EndUserManageUserIds /> },
    ],
  },

  {
    path: '',
    element: (
      <AuthGuard
        roles={['SFS_CCA', 'GRP_SFS_CSA', 'GRP_SFS_VIEWER', 'SFS_ENDUSER']}
        layout={DashboardLayout}
      />
    ),
    children: [{ path: 'packages', element: <Packages /> }],
  },
  {
    path: '',
    element: (
      <AuthGuard
        roles={['GRP_SFS_CSA', 'GRP_SFS_VIEWER']}
        layout={DashboardLayout}
      />
    ),
    children: [
      { path: 'admin-dashboard', element: <AdminDashboard /> },
      { path: 'manage-email-domains', element: <ManageEmailDomains /> },
      { path: 'onboard-contracts', element: <OnboardContracts /> },
    ],
  },

  // // Using Basic Layout which excludes content & container css
  {
    path: '',
    element: (
      <AuthGuard
        roles={['SFS_CCA', 'GRP_SFS_CSA', 'GRP_SFS_VIEWER']}
        layout={BasicLayout}
      />
    ),
    children: [{ path: 'user-dashboard', element: <UserDashboard /> }],
  },
  {
    path: '',
    element: <AuthGuard roles={['SFS_ENDUSER']} layout={DashboardLayout} />,
    children: [{ path: 'user-info', element: <UserInfo /> }],
  },
  {
    path: 'packages',
    element: (
      <AuthGuard
        roles={['SFS_CCA', 'GRP_SFS_CSA', 'GRP_SFS_VIEWER', 'SFS_ENDUSER']}
        layout={DashboardLayout}
      />
    ),
    children: [
      { path: 'package-details/:id', element: <PackageDetails /> },

      {
        path: 'delivery-platform-details/:id',
        element: <DistributorDetails />,
      },
    ],
  },
];

export default routes;
