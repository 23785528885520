import React, { memo, useEffect, useState } from 'react';
import MultiSelectDropdown from '../../sharedcomponents/multi-select-dropdown/multi-select-dropdown';
import { Endpoints, maxPageSize } from '../../constants';
import httpService from '../../services/http-service';
import { ReactComponent as CrossIcon } from '../../assets/icons/common/cross-icon.svg';
import { Col, Row } from 'react-bootstrap';

const ReportFilterDropdown = memo(
  ({
    dropdownType,
    filterValue,
    initialCriteriaList,
    entityKey,
    handleSetDropdownOptions,
    handleRemoveCriteria,
    contractsResult,
    dropDownDisabled = false,
    dropdownRef,
    handleAfterDropdownChange,
  }) => {
    const [dropDownLoader, setDropDownLoader] = useState(true);
    const [dropdownOptions, setDropdownOptions] = useState();
    const [dropdownProps, setDropdownProps] = useState({});
    const selectedOptionCount = dropdownOptions?.filter(
      (item) => item.checked,
    ).length;
    //use effect starts
    useEffect(() => {
      selectedCriteriaDropdown();
    }, [dropdownType]);
    useEffect(() => {
      if (filterValue) {
        setDropdownOptions();
        getAddedCriteriaDDValue();
      } else {
        setDropdownOptions([]);
      }
    }, [filterValue]);

    useEffect(() => {
      // Run after any update in criteriaDropdown in parent component
      if (dropdownRef && dropdownRef[entityKey]) {
        if (
          entityKey === 0 &&
          dropdownRef[entityKey].values?.length === 0 &&
          dropdownOptions?.some((k) => k.checked)
        ) {
          setDropdownOptions(
            dropdownOptions.map((el) => {
              return { ...el, checked: false };
            }),
          );
        } else {
          if (dropdownRef[entityKey].isDropdownDisabled) {
            setDropdownOptions([]);
          }
        }
      }
    }, [dropdownRef]);
    //use effect ends

    const selectedCriteriaDropdown = () => {
      const onClickHandler = () => {
        setTimeout(() => {
          if (entityKey >= 0) {
            const containerBlockDiv =
              document.getElementById('container-block');
            if (containerBlockDiv) {
              containerBlockDiv.scrollTo({
                top: containerBlockDiv.scrollHeight,
                behavior: 'smooth',
              });
            }
          }
        }, 10);
      };
      switch (dropdownType) {
        case initialCriteriaList[0].criteriaName:
          setDropdownProps({
            searchKeys: ['account'],
            ddOptionsKey: 'id',
            ddName: 'accounts',
            isSelectAllCheckbox: true,
            ddPlaceHolder: `Accounts (${selectedOptionCount ?? 0} of ${
              dropdownOptions?.length ?? 0
            } selected)`,
            onClick: onClickHandler,
          });
          break;
        case initialCriteriaList[1].criteriaName:
          setDropdownProps({
            searchKeys: ['officeLocation'],
            ddOptionsKey: 'id',
            ddName: 'locations',
            isSelectAllCheckbox: true,
            ddPlaceHolder: `Locations (${selectedOptionCount ?? 0} of ${
              dropdownOptions?.length ?? 0
            } selected)`,
            onClick: onClickHandler,
          });
          break;
        case initialCriteriaList[2].criteriaName:
          setDropdownProps({
            searchKeys: ['groupName'],
            ddOptionsKey: 'id',
            ddName: 'groups',
            isSelectAllCheckbox: true,
            ddPlaceHolder: `Groups (${selectedOptionCount ?? 0} of ${
              dropdownOptions?.length ?? 0
            } selected)`,
            onClick: onClickHandler,
          });
          break;
        case initialCriteriaList[3].criteriaName:
          setDropdownProps({
            searchKeys: ['userLabel'],
            ddOptionsKey: 'id',
            ddName: 'contacts',
            isSelectAllCheckbox: false,
            ddPlaceHolder: `Users (${selectedOptionCount ?? 0} of ${
              dropdownOptions?.length ?? 0
            } selected)`,
            onClick: onClickHandler,
          });
          break;
        case initialCriteriaList[4].criteriaName:
          setDropdownProps({
            searchKeys: ['distributorLabel'],
            ddOptionsKey: 'id',
            ddName: 'distributors',
            isSelectAllCheckbox: true,
            ddPlaceHolder: `Delivery Platforms (${
              selectedOptionCount ?? 0
            } of ${dropdownOptions?.length ?? 0} selected)`,
            onClick: onClickHandler,
          });
          break;
        case initialCriteriaList[5].criteriaName:
          setDropdownProps({
            searchKeys: ['productName'],
            ddOptionsKey: 'id',
            ddName: 'Alphamail Reports',
            isSelectAllCheckbox: true,
            ddPlaceHolder: `Alphamail Reports (${selectedOptionCount ?? 0} of ${
              dropdownOptions?.length ?? 0
            } selected)`,
            onClick: onClickHandler,
          });
          break;
        case initialCriteriaList[6].criteriaName:
          setDropdownProps({
            searchKeys: ['packageName'],
            ddOptionsKey: 'id',
            ddName: 'packages',
            isSelectAllCheckbox: true,
            ddPlaceHolder: `Packages (${selectedOptionCount ?? 0} of ${
              dropdownOptions?.length ?? 0
            } selected)`,
            onClick: onClickHandler,
          });
          break;
      }
    };
    const getDropdownPlaceholder = () => {
      switch (dropdownType) {
        case initialCriteriaList[0].criteriaName:
          return `Accounts (${selectedOptionCount ?? 0} of ${
            dropdownOptions?.length ?? 0
          } selected)`;
        case initialCriteriaList[1].criteriaName:
          return `Locations (${selectedOptionCount ?? 0} of ${
            dropdownOptions?.length ?? 0
          } selected)`;
        case initialCriteriaList[2].criteriaName:
          return `Groups (${selectedOptionCount ?? 0} of ${
            dropdownOptions?.length ?? 0
          } selected)`;
        case initialCriteriaList[3].criteriaName:
          return `Users (${selectedOptionCount ?? 0} of ${
            dropdownOptions?.length ?? 0
          } selected)`;
        case initialCriteriaList[4].criteriaName:
          return `Delivery Platforms (${selectedOptionCount ?? 0} of ${
            dropdownOptions?.length ?? 0
          } selected)`;
        case initialCriteriaList[5].criteriaName:
          return `Alphamail Reports (${selectedOptionCount ?? 0} of ${
            dropdownOptions?.length ?? 0
          } selected)`;
        case initialCriteriaList[6].criteriaName:
          return `Packages (${selectedOptionCount ?? 0} of ${
            dropdownOptions?.length ?? 0
          } selected)`;
      }
    };

    const updateDropdownResponse = (res, labelField) => {
      if (res && res?.data?.results?.length > 0) {
        switch (dropdownType) {
          case initialCriteriaList[3].criteriaName:
            res.data.results = res.data.results.map((el, i) => {
              return {
                ...el,
                userLabel: `${el.firstName} ${el.lastName} (${el.emailId})`,
              };
            });
            return res;
          case initialCriteriaList[4].criteriaName:
            res.data.results = res.data.results.map((el, i) => {
              return {
                ...el,
                distributorLabel: `${el.distributor} : ${el.distributorPlatform} (${el.deliveryType})`,
              };
            });
            return res;
        }
      }
      return res;
    };

    const getAddedCriteriaDDValue = async () => {
      let fieldOption = '';
      let labelField = '';
      switch (dropdownType) {
        case initialCriteriaList[0].criteriaName:
          // Accounts
          fieldOption = 'accountid,Account';
          labelField = 'account';
          break;
        case initialCriteriaList[1].criteriaName:
          // Locations
          fieldOption = 'officelocationid,officelocation';
          labelField = 'officeLocation';
          break;
        case initialCriteriaList[2].criteriaName:
          // Groups
          fieldOption = 'groupid,groupname';
          labelField = 'groupName';
          break;
        case initialCriteriaList[3].criteriaName:
          // Users
          fieldOption = 'contactId,emailId,firstname,lastname';
          labelField = 'userLabel';
          break;
        case initialCriteriaList[4].criteriaName:
          // Delivery Platforms
          fieldOption =
            'distributorId,distributor,distributorplatform,deliveryType';
          // labelField = 'distributorPlatform';
          labelField = 'distributorLabel';
          break;
        case initialCriteriaList[5].criteriaName:
          // Alphamail Reports
          fieldOption = 'productid,productname,distributorPlatformDisplayName';
          labelField = 'productName';
          break;
        case initialCriteriaList[6].criteriaName:
          // Packages
          fieldOption = 'packageid,packagename';
          labelField = 'packageName';
          break;
        default:
          fieldOption =
            'accountid,Account,officelocationid,groupid,groupname,contactId,firstname,lastname,distributorid,distributor,distributorplatform,productid,productname,packageid,packagename';
          labelField = 'account';
      }

      let queryParams = {
        Filter:
          filterValue +
          ` AND status in("Active","Pending Active")` +
          (entityKey > 0
            ? ` AND contractNumber in (${contractsResult
                .filter((x) => x.checked)
                .map((t) => (t.id ? '"' + t.id + '"' : ''))
                .join(',')})`
            : ''),
        Field: fieldOption,
        PageSize: 5000,
      };
      await httpService
        .post(Endpoints.addedCriteriaDdDetailsApi, queryParams)
        .then((res) => {
          if (res && res.data) {
            updateDropdownResponse(res, labelField);
            let dropdownData = res.data?.results
              ?.filter((x) => x[labelField])
              .filter((el) => {
                if (labelField === 'distributorLabel') {
                  return !(
                    (el.distributor && el.distributor.includes('AlphaMail')) ||
                    (el.distributorPlatform &&
                      el.distributorPlatform.includes('AlphaMail'))
                  );
                }
                if (labelField === 'productName') {
                  return (
                    el.distributorPlatformDisplayName?.includes('AlphaMail') ||
                    false
                  );
                }
                return true;
              })
              .map((el, i) => {
                return {
                  id: i,
                  label: el[labelField],
                  checked: false,
                  ...el,
                };
              });
            dropdownData.sort((a, b) => a.label.localeCompare(b.label));
            handleAfterDropdownChange(entityKey, true);
            setDropdownOptions(dropdownData);
          } else {
            handleAfterDropdownChange(entityKey, false);
            setDropdownOptions([]);
          }
        });
    };

    const onDropdownChange = (e) => {
      setDropdownOptions(e);
      handleSetDropdownOptions(e, entityKey);
    };

    const removeCriteriaDropdown = () => {
      handleRemoveCriteria(entityKey, dropdownType);
    };
    return (
      <>
        <Row className="align-items-center">
          <Col className="col-lg-11 col-md-11 filter-dropdown-pr1">
            <MultiSelectDropdown
              enableSelectAll={dropdownProps.isSelectAllCheckbox}
              searchKeys={dropdownProps.searchKeys}
              enableSearch={true}
              labelKeyName="label"
              ddOptionsKey="id"
              dropDownLoader={dropDownLoader}
              ddOptions={dropdownOptions}
              setddOptions={onDropdownChange}
              ddName={dropdownProps.ddName}
              ddPlaceHolder={getDropdownPlaceholder()}
              dropDownDisabled={dropDownDisabled}
              handleToggle={dropdownProps.onClick}
            />
          </Col>
          <Col className="col-lg-1 col-md-1 ps-0 cross-icon">
            <CrossIcon
              onClick={removeCriteriaDropdown}
              className="cursor-pointer"
              alt="Close"
              title="Close"
            ></CrossIcon>
          </Col>
        </Row>
      </>
    );
  },
);

export default ReportFilterDropdown;
