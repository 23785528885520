import React, { useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { ReactComponent as DownArrow } from './../../assets/icons/down-arrow-icon.svg';
import cn from './../../helpers/cn';
import Loader from '../../components/Loader';
import './DropdownInput.scss';

const DropdownInput = ({
  formLabel,
  formik,
  addAsterisk,
  customDropdownMessage,
  ddName,
  enableSearch = false,
  inputClass,
  name,
  disabled = false,
  searchKeys = [],
  labelKeyName,
  dropdownOptions,
  ddPlaceHolder,
  onChangeHandler,
  containerClass,
}) => {
  const [searchText, setSearchText] = useState('');
  const [show, setShow] = useState(false);

  const ResetSearchText = (isOpen) => {
    enableSearch && setSearchText('');
    setShow(isOpen);
  };
  const Asterisk = () =>
    addAsterisk ? <span className="asterik">*</span> : null;

  const formikError = () =>
    formik?.touched[name] && formik?.errors[name] ? (
      <div className="form-input-error">{formik.errors[name]}</div>
    ) : null;

  const DropDownOptions = () => {
    try {
      let filteredResult =
        enableSearch && searchText?.length >= 2 && searchKeys?.length >= 0
          ? dropdownOptions?.filter((dataSet) => {
              if (searchKeys?.length > 0) {
                return searchKeys.some((prop) =>
                  dataSet[prop]
                    .toString()
                    .toLowerCase()
                    .includes(searchText?.toLowerCase()),
                );
              } else {
                return dataSet
                  .toString()
                  .toLowerCase()
                  .includes(searchText?.toLowerCase());
              }
            })
          : dropdownOptions;

      return filteredResult?.length > 0 ? (
        filteredResult?.map((res, i) => {
          const isOptionDisabled = res.disabled;
          return (
            <div
              className={cn('add-entitlements-block', {
                'disabled-option': isOptionDisabled,
              })}
              key={`${ddName}_` + i}
              onClick={() => {
                if (!isOptionDisabled) {
                  onSelectHandler(res);
                }
              }}
            >
              <div className="account-dropdown">
                <label className="account-label" htmlFor={`${ddName}_` + i}>
                  {res[labelKeyName] ?? res}
                </label>
              </div>
            </div>
          );
        })
      ) : (
        <h6 className="text-center pt-2">
          {!dropdownOptions ? (
            <Loader type="scaleLoaderDropdown" />
          ) : customDropdownMessage && filteredResult?.length > 0 ? (
            customDropdownMessage
          ) : (
            'No results found'
          )}
        </h6>
      );
    } catch (err) {}
  };

  const onSelectHandler = (val) => {
    if (!val.disabled) {
      onChangeHandler(val);
      setShow(!show);
    }
  };

  return (
    <Form.Group
      className={
        'col-lg-6 col-md-12 mb-2 custom-dropdown ' +
        (containerClass ? containerClass : '')
      }
    >
      <Form.Label className="user-form-label">{formLabel}</Form.Label>
      <Asterisk />

      <Dropdown
        name={name}
        show={!disabled && show}
        onToggle={ResetSearchText}
        autoClose={'outside'}
        className={cn({ 'dropdown-disabled': disabled })}
      >
        <Dropdown.Toggle variant="light">
          <span className="label-truncate">{ddPlaceHolder}</span>
          <DownArrow className="user-down-arrow" />
        </Dropdown.Toggle>
        <Dropdown.Menu className={cn('user-form-input', inputClass)}>
          {enableSearch && (
            <div className="add-entitlements-block" key={'-1'}>
              <div className="account-dropdown">
                <input
                  className="w-100 px-2 py-1 search-box"
                  type="text"
                  id="dd-search-input"
                  placeholder="Search"
                  value={searchText}
                  onChange={({ target }) => setSearchText(target.value)}
                />
              </div>
            </div>
          )}
          <DropDownOptions />
        </Dropdown.Menu>
      </Dropdown>
      {formikError(name)}
    </Form.Group>
  );
};

export default React.memo(DropdownInput);
