import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import Loader from '../../components/Loader';
import {
  Active,
  Endpoints,
  GenericError,
  PendingActive,
  PendingApproval,
  PendingInactive,
  Blocked,
  Rejected,
  UrlPath,
  UserId,
  Verbiage,
  loaderStyle,
} from '../../constants';
import { debounce } from '../../helpers/debounce';
import httpService from '../../services/http-service';
import { Notify } from '../../sharedcomponents/Alert/Notify';
import AgGridComponent from '../../sharedcomponents/ag-grid/AgGrid';
import {
  getFilterColumns,
  getGroupByColumns,
  getSortColumns,
  isGroupBy,
} from '../../sharedcomponents/ag-grid/ag-grid-helper';
import { ReactComponent as DeleteIcon } from './../../assets/icons/common/delete.svg';
import { ReactComponent as ApproveIcon } from './../../assets/icons/common/entitlement-approval.svg';
import { ReactComponent as RejectIcon } from './../../assets/icons/common/entitlement-rejected.svg';
import { ReactComponent as ExportIcon } from './../../assets/icons/common/export.svg';
import { ReactComponent as UserGroupIcon } from './../../assets/icons/common/user-group.svg';
import { ReactComponent as CloudIcon } from './../../assets/icons/common/cloud.svg';
import addEntitlementIcon from './../../assets/icons/entitlements/add-entitlement.svg';
import mailing from './../../assets/icons/entitlements/mailing.svg';
import bell_icon from './../../assets/icons/avatars/bell_icon.svg';
import { persistDataStore } from './../../redux/persistStore';
import store from './../../redux/store';
import BulkUploadUserEntitlements from '../user-details/bulk-upload-user-entitlements/bulk-upload-user-entitlements';
import ExportServerSide from '../../sharedcomponents/Export-Server-Side/ExportServerSide';
import InfoIcon from './../../assets/icons/common/info.png';
import StickyTooltip from './../../sharedcomponents/StickyTooltip/StickyTooltip';

// Integrated with new version of aggrid(v31.0.2)
const Entitlements = ({ contracts, isViewerUser }) => {
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [pendingApprovalCount, setPendingApprovalCount] = useState();
  const [fetchedPendingApproval, setFetchedPendingApproval] = useState(false);
  const [contractFilter, setContractFilter] = useState('');
  const [bulkUserEntitlementEnable, setBulkUserEntitlementEnable] = useState();
  const [showBulkUserEntitlements, setShowBulkUserEntitlements] =
    useState(false);
  const [buttonIcons, setButtonIcons] = useState({
    DeleteIcon: false,
    ExportIcon: false,
    ExportInProgress: false,
    ApproveIcon: false,
    RejectIcon: false,
  });
  const [tooltip, setTooltip] = useState({});
  const tooltipRef = useRef(null);
  const location = useLocation();
  const gridRef = useRef();
  const gridContainerRef = useRef(null);

  const defaultConfig = {
    editable: true,
    isExportCSV: false,
    isExportExcel: false,
    pivotPanelShow: '',
    pagination: false,
    isAutoSizeColums: true,
    enableCharts: false,
    sideBar: true,
    overlayNoRowsTemplate:
      contracts && contracts.some((x) => x.checked)
        ? Verbiage.entitlements
        : Verbiage.noData,
  };

  const isFirstColumn = (params) => {
    let displayedColumns = params?.api?.getAllDisplayedColumns();
    let firstColumn = displayedColumns[0] === params?.column;
    if (firstColumn) {
      return firstColumn;
    }
  };

  const isSameOrFirstColumn = (params) => {
    // Show checkbox on each row of first column and on grouped, show it only on grouped columns
    let displayedColumns = params?.api?.getAllDisplayedColumns();
    let rowGroupCheckBox = !params?.node?.group;
    let showCheckBox =
      // rowGroupCheckBox &&
      displayedColumns[params?.node?.level] === params?.column;
    return showCheckBox;
  };

  const columnFilterParams = {
    // common column definition
    filter: 'agTextColumnFilter',
    // filterParams: {
    //   buttons: ['reset', 'apply'],
    //   maxNumConditions: 1,
    // },
    enableRowGroup: true,
    enablePivot: false,
    menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
    enableValue: false, // disable value aggregation in column header
  };

  const defaultColdef = {
    ...columnFilterParams,
    sortable: true,
    resizable: true,
    checkboxSelection: !isViewerUser && isSameOrFirstColumn,
    headerCheckboxSelection: !isViewerUser && isFirstColumn,
    cellRenderer: (params) => {
      let newValue = params.value;
      if (params.column.colId === 'emailId')
        if (!params.node?.group && params.data?.groupName) {
          newValue = groupIcon(params);
        }

      return newValue ?? null;
    },
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };

  const isRowSelectable = useCallback((params) => {
    return true;
  }, []);

  const contractChangeHandler = () => {
    if (gridRef.current && contracts && contracts.length > 0) {
      let filteredContracts = contracts.filter((x) => x.checked);
      let commonFilterType = {
        filterType: 'text',
        type: 'inRange',
      };
      let filterValue = {
        filter:
          filteredContracts.length !== 0
            ? filteredContracts
                .map((t) =>
                  t.contractNumber ? '"' + t.contractNumber + '"' : '',
                )
                .join(',')
            : 'NoContractSelected',
        ...commonFilterType,
      };
      // add status filter after been redirected from user dashboard
      let newFilterModel = getAllFilterModel('contractNumber', filterValue);
      setContractFilter('contractNumber IN(' + filterValue.filter + ')');

      if (location?.state && filteredContracts.length !== 0) {
        let statusFilterValue = {
          filter: location?.state
            ?.map((statusEnt) => (statusEnt ? '"' + statusEnt + '"' : ''))
            .join(','),
          ...commonFilterType,
        };
        let statusFilter = getAllFilterModel('status', statusFilterValue);
        newFilterModel.status = { ...statusFilter.status };
      }
      gridRef?.current?.api?.setFilterModel(newFilterModel);

      // clear location state on refresh
      window.history.replaceState({}, document.title);
    }
  };

  useEffect(() => {
    setTooltip({});
    let filteredContracts = contracts?.filter((x) => x.checked);
    let storeData = store.getState().userInfo?.userInfo;
    setBulkUserEntitlementEnable(
      storeData?.SPGGroups?.includes('SFS_CCA') &&
        filteredContracts?.some((x) => x.bulkEntitlementEnable),
    );

    // Reduce callback onchange of contract selection
    const reduceCallback = setTimeout(() => {
      contractChangeHandler();
    }, 1000);
    return () => clearTimeout(reduceCallback);
  }, [contracts]);

  const groupIcon = (params) => (
    <>
      <svg
        className="icon-active"
        width="15"
        style={{ marginBottom: '8px' }}
        height="17"
        viewBox="0 0 20 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>{params.data.groupName}</title>
        <path
          d="M9.96875 10.0485C11.7812 10.0485 13.2188 8.61102 13.2188 6.79852C13.2188 5.01727 11.75 3.54852 9.96875 3.54852C8.1875 3.54852 6.75 5.01727 6.75 6.79852C6.71875 8.61102 8.1875 10.0485 9.96875 10.0485ZM9.96875 5.04852C10.9375 5.04852 11.7188 5.86102 11.7188 6.79852C11.7188 7.76727 10.9375 8.54852 9.96875 8.54852C9.03125 8.54852 8.21875 7.76727 8.21875 6.79852C8.21875 5.86102 9.03125 5.04852 9.96875 5.04852ZM16 5.04852C17.375 5.04852 18.5 3.95477 18.5 2.54852C18.5 1.17352 17.375 0.0485229 16 0.0485229C14.5938 0.0485229 13.5 1.17352 13.5 2.54852C13.5 3.95477 14.5938 5.04852 16 5.04852ZM11.5312 11.0485H8.4375C5.96875 11.0485 4 12.9235 4 15.236C4 15.7048 4.375 16.0485 4.875 16.0485H15.0938C15.5938 16.0485 16 15.7048 16 15.236C16 12.9235 14 11.0485 11.5312 11.0485ZM5.5625 14.5485C5.875 13.4235 7.03125 12.5485 8.40625 12.5485H11.5312C12.9062 12.5485 14.0625 13.4235 14.4062 14.5485H5.5625ZM17.2188 6.04852H15.3125C14.9062 6.04852 14.5312 6.17352 14.1875 6.32977C14.1875 6.48602 14.2188 6.64227 14.2188 6.79852C14.2188 7.86102 13.8438 8.82977 13.1875 9.54852H19.4375C19.75 9.54852 20 9.29852 20 8.98602C20 7.36102 18.75 6.04852 17.2188 6.04852ZM5.71875 6.79852C5.71875 6.64227 5.75 6.48602 5.78125 6.32977C5.4375 6.14227 5.0625 6.04852 4.65625 6.04852H2.75C1.21875 6.04852 0 7.36102 0 8.98602C0 9.29852 0.21875 9.54852 0.53125 9.54852H6.78125C6.125 8.82977 5.71875 7.86102 5.71875 6.79852ZM4 5.04852C5.375 5.04852 6.5 3.95477 6.5 2.54852C6.5 1.17352 5.375 0.0485229 4 0.0485229C2.59375 0.0485229 1.5 1.17352 1.5 2.54852C1.5 3.95477 2.59375 5.04852 4 5.04852Z"
          fill=""
        />
      </svg>
      {' ' + params.value}
    </>
  );

  const customValueGetter = (params, columnId) => {
    // returns single string joining multiple keys
    let newValue = '';
    if (params) {
      let colId = columnId ?? params.column?.colId;
      switch (colId) {
        case 'contactName':
          if (
            params.data?.contactName ||
            (params.data?.firstName && params.data?.lastName)
          )
            newValue =
              params.data?.contactName ??
              params.data?.firstName + ' ' + params.data?.lastName;
          break;
        case 'deliveryPlatform':
          if (
            params.data?.distributor &&
            params.data?.distributorPlatform &&
            params.data?.deliveryType
          )
            newValue =
              params.data?.distributor +
              ' : ' +
              params.data?.distributorPlatform +
              ' (' +
              params?.data?.deliveryType +
              ')';
          break;
        default:
          newValue = params.data[colId];
      }
    }
    return newValue;
  };

  const getMergedColName = (colId) => {
    // Create query string for combined columns
    let newValue = '';
    if (colId) {
      switch (colId) {
        case 'contactName':
          newValue = '(firstname + " " + lastname)';
          break;

        case 'deliveryPlatform':
          newValue =
            '(distributor + " : " + distributorPlatform + " (" + deliveryType + ")")';
          break;

        default:
          newValue = null;
      }
    }

    return newValue;
  };

  const sideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressPivotMode: true,
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
    ],
  };

  const autoGroupColumnDef = {
    // column definition for grouped column
    ...columnFilterParams,
    enableRowGroup: false,
    width: 430,
    lockPosition: 'left',
    sortable: false,
    cellRenderer: 'agGroupCellRenderer',
  };

  const columns = [
    {
      headerName: '',
      field: 'contractNumber',
      resizable: true,
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: [
          'inRange',
          {
            displayKey: 'inRange',
            displayName: 'Range Between',
            predicate: ([filterValue], cellValue) => {
              return cellValue == null || cellValue < filterValue;
            },
          },
        ],
      },
      suppressHeaderMenuButton: true,
      sortable: false,
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: 'Email ID',
      headerTooltip: 'Email ID',
      field: 'emailId',
      tooltipField: 'emailId',
    },
    {
      headerName: 'Name',
      headerTooltip: 'Name',
      field: 'contactName',
      valueGetter: customValueGetter,
      tooltipValueGetter: customValueGetter,
      tooltipField: 'contactName',
    },
    {
      headerName: 'Location',
      headerTooltip: 'Location',
      field: 'officeLocation',
      tooltipField: 'officeLocation',
    },
    {
      headerName: 'Package',
      headerTooltip: 'Package',
      field: 'packageName',
      tooltipField: 'packageName',
    },
    {
      headerName: 'Product',
      headerTooltip: 'Product',
      field: 'productName',
      tooltipField: 'productName',
    },
    {
      headerName: 'Delivery Platform (Type)',
      headerTooltip: 'Delivery Platform (Type)',
      field: 'deliveryPlatform',
      valueGetter: customValueGetter,
      tooltipValueGetter: customValueGetter,
    },
    {
      headerName: 'Status',
      headerTooltip: 'Status',
      field: 'status',
      tooltipField: 'status',
      cellRenderer: (params) => {
        const isTooltipVisible =
          tooltip.showTooltip && tooltip.rowId === params.rowIndex;

        return (
          <div style={{ position: 'relative' }} data-row-id={params.rowIndex}>
            <span>
              {params.value}
              {params.value === 'Blocked' && (
                <img
                  src={InfoIcon}
                  className="info-icon"
                  style={{
                    cursor: 'pointer',
                    marginLeft: '5px',
                    width: '20px',
                  }}
                  title={params.data.blockedReason}
                  onClick={(event) => {
                    handleTooltipClick(
                      event,
                      params.rowIndex,
                      params.data.blockedReason,
                    );
                  }}
                />
              )}
              {isTooltipVisible && (
                <StickyTooltip
                  ref={tooltipRef}
                  text={tooltip.text}
                  onClose={() => setTooltip({})}
                />
              )}
            </span>
          </div>
        );
      },
    },
  ];

  const handleTooltipClick = (event, rowId, blockedReason) => {
    event.stopPropagation();
    if (tooltip.rowId === rowId) {
      setTooltip({});
    } else {
      setTooltip({
        showTooltip: true,
        rowId: rowId,
        text: blockedReason,
      });
    }
  };

  useEffect(() => {
    if (tooltip.showTooltip && gridContainerRef.current) {
      const scrollContainer =
        gridContainerRef.current.querySelector('.ag-body-viewport');
      const handleScroll = () => {
        const rowElement = document.querySelector(
          `[data-row-id="${tooltip.rowId}"]`,
        );
        if (rowElement) {
          const gridRect = scrollContainer.getBoundingClientRect();
          const rowRect = rowElement.getBoundingClientRect();
          if (rowRect.bottom < gridRect.top || rowRect.top > gridRect.bottom) {
            setTooltip({});
          }
        } else {
          setTooltip({});
        }
      };
      if (scrollContainer) {
        scrollContainer.addEventListener('scroll', handleScroll);
      }
      return () => {
        if (scrollContainer) {
          scrollContainer.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [tooltip.showTooltip, tooltip.rowId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target) &&
        !event.target.classList.contains('info-icon')
      ) {
        setTooltip({});
      }
    };

    if (tooltip.showTooltip) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [tooltip.showTooltip]);

  // set input to CSA selected user
  useEffect(() => {
    let csaActionData =
      persistDataStore.getState()?.userActionData?.csaUserActionInfo;
    if (
      csaActionData?.type &&
      csaActionData?.value &&
      csaActionData?.type === 'user'
    ) {
      setInputValue(csaActionData?.value);
    }
  }, []);

  const onFilterTextBoxChanged = useCallback(({ target }) => {
    let searchText = target.value;
    let moreThan2 = searchText && searchText.length > 2;
    // Apply custom search filter
    if (!searchText || moreThan2) {
      gridRef?.current?.api?.onFilterChanged();
    }

    let isRowExist = gridRef?.current?.api?.getRenderedNodes()?.length > 0;

    // Check if any row is rendered if not then show overlay
    if (moreThan2 && !isRowExist) {
      gridRef?.current?.api?.showNoRowsOverlay();
    } else {
      gridRef?.current?.api?.hideOverlay();
    }
  }, []);

  const onFilterTextBoxChangedDebounce = debounce(onFilterTextBoxChanged, 1000);

  const onGridReadyHandler = (params) => {
    gridRef.current = params;

    params.api.setGridOption('serverSideDatasource', getServerSideDatasource());

    const allColumnIds = [];
    params.api.getAllGridColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });

    // autosize columns, get and set col widths directly
    params.api.sizeColumnsToFit(allColumnIds);
    columns.forEach((col) => {
      const columnState = params.api
        .getColumnState()
        .find((c) => c.colId === col.field);
      if (columnState) {
        col.width = columnState.width - 3;
      }
    });
    params.api.setGridOption('columnDefs', columns);
  };

  const filterPendingApprovalETS = (statusFilter) => {
    const newFilterModel = getAllFilterModel('status', {
      filterType: 'text',
      type: 'equals',
      filter: statusFilter,
    });

    gridRef?.current?.api?.setFilterModel(newFilterModel);
  };

  const getServerSideDatasource = (statusFilter) => {
    return {
      getRows: (params) => {
        try {
          let selectedRows = params.api?.getSelectedRows();
          if (Array.isArray(selectedRows) && selectedRows.length === 0) {
            setButtonIcons({ ...buttonIcons, DeleteIcon: false });
          }
          let AgParams = params?.request;
          let groupedColumn = getGroupByColumns(AgParams);
          let contractFilterModel =
            AgParams?.filterModel['contractNumber']?.filter;

          if (
            contractFilterModel &&
            Object.keys(contractFilterModel)?.length > 0 &&
            contractFilterModel !== 'NoContractSelected'
          ) {
            let FilterColumns =
              'emailId,contactName,deliveryPlatform,officeLocation,packageName,productName,status';
            let searchText = document.getElementById(
              'entitlement-search-filter-input',
            ).value;
            let queryParams = {
              Filter: getFilterColumns(AgParams),
              FilteredColumns: JSON.stringify(
                gridRef.current?.api.getFilterModel(),
              ),
              Field: isGroupBy(AgParams)
                ? `count(${groupedColumn})`
                : 'contactId,contractNumber,blockedReason,userEntitlementId,deliveryPlatform,emailId,productComponentId,packageName,productName,officeLocation,groupId,groupName,status,contactName,modifiedDate',
              Sort: getSortColumns(
                AgParams,
                'modifiedDate:desc,userEntitlementId:desc',
              ),
              PageSize: AgParams.endRow - AgParams.startRow,
              Page:
                AgParams.startRow / (AgParams.endRow - AgParams.startRow) + 1,
              groupBy: groupedColumn,
              querySearch: searchText,
              searchColumns: searchText ? FilterColumns : '',
              id: String(Date.now()),
            };
            const fromUserDashboard = location?.state;
            gridRef?.current?.api?.showLoadingOverlay();
            httpService
              .get(Endpoints.userentitlementsApi, queryParams)
              .then(({ data }) => {
                // clear tooltip on data change
                setTooltip({});
                if (data) {
                  let rowData = data?.results ?? data?.aggResultValue;
                  if (
                    data?.aggResultValue &&
                    data?.aggResultValue?.length > 0
                  ) {
                    // On groupby - create unique key to avoid duplicate rows
                    let groupKeys = AgParams.groupKeys.join(',');
                    rowData = rowData.map((el) => {
                      return { ...el, groupKeys: groupKeys };
                    });
                  }

                  params.success({
                    rowData: rowData,
                    rowCount: data?.metadata.count,
                  });

                  if (rowData?.length === 0)
                    gridRef?.current?.api?.showNoRowsOverlay();
                  else {
                    if (!fromUserDashboard) {
                      gridRef?.current?.api?.hideOverlay();
                    }
                  }

                  if (fromUserDashboard) {
                    gridRef?.current?.api?.hideOverlay();
                    if (statusFilter) {
                      // apply status of pending active and inactive to status column
                      setTimeout(
                        () =>
                          gridRef?.current?.api?.setFilterModel(statusFilter),
                        50,
                      );
                    }
                  }
                }
              })
              .catch((err) => {
                params.fail();
                gridRef?.current?.api?.hideOverlay();
              });

            if (!fetchedPendingApproval) {
              setFetchedPendingApproval(true);
              let fullQueryParams = {
                ...queryParams,
                PageSize: 50000,
                Page: 1,
              };

              httpService
                .get(Endpoints.userentitlementsApi, fullQueryParams)
                .then(({ data }) => {
                  setPendingApprovalCount(
                    data?.results?.filter(
                      (obj) => obj.status === 'Pending Approval',
                    ).length,
                  );
                })
                .catch((err) => {
                  console.error('Error fetching entire data:', err);
                });
            }
          } else {
            params.success({
              rowData: [],
              rowCount: 0,
            });

            if (contractFilterModel === 'NoContractSelected') {
              gridRef?.current?.api?.showNoRowsOverlay();
            }
          }
        } catch (err) {
          params.fail();
        }
      },
    };
  };

  useEffect(() => {
    if (fetchedPendingApproval) {
      let filter = contractFilter + ' AND status:"Pending Approval"';
      let pendingApprovalQueryParams = {
        Filter: filter,
        FilteredColumns: JSON.stringify(gridRef.current?.api.getFilterModel()),
        PageSize: 1,
        Page: 1,
        id: String(Date.now()),
      };

      httpService
        .get(Endpoints.userentitlementsApi, pendingApprovalQueryParams)
        .then(({ data }) => {
          setPendingApprovalCount(data?.metadata.count > 0);
        })
        .catch((err) => {});
    }
  }, [fetchedPendingApproval]);

  const getAllFilterModel = (colId, newModel) => {
    let currentModel = gridRef?.current?.api?.getFilterModel();
    currentModel[colId] = newModel;
    return currentModel;
  };

  const getChildCount = useCallback((data) => {
    // Server side grouped row child count
    return data?.count ?? 100;
  }, []);

  const onSelectionChanged = (elements) => {
    let selectedRow = elements?.api?.getSelectedRows();
    let nonGroupSelectedRows = selectedRow?.filter(
      (el) => el?.groupKeys !== '',
    );
    let rowLength = nonGroupSelectedRows.length;
    let isApprovalEts = nonGroupSelectedRows.some(
      (el) => el?.status === PendingApproval,
    );

    setButtonIcons({
      ...buttonIcons,
      DeleteIcon: rowLength > 0,
      ExportIcon: selectedRow.length > 0,
      ApproveIcon: isApprovalEts,
      RejectIcon: isApprovalEts,
    });
  };

  const onSelectionChangedDebounce = debounce(onSelectionChanged, 100);

  const notifyDeleteEntitlements = () => {
    // Alert user to confirm delete entitlements
    if (buttonIcons.DeleteIcon) {
      let selectedEntitlements = gridRef.current?.api?.getSelectedRows();
      let statusToDelete = [Active, PendingActive, Blocked];
      let filteredSelectionID = [];
      let groupEntitlementFound = false;
      selectedEntitlements.forEach((el) => {
        // check if any group entitlements are selected
        if (el?.groupId && !groupEntitlementFound) {
          groupEntitlementFound = true;
        } else {
          if (statusToDelete.includes(el.status)) filteredSelectionID.push(el);
        }
      });

      if (groupEntitlementFound) {
        Notify({
          alert: true,
          type: 'error',
          title: Verbiage.groupEntitlementsAttemptedUpdate,
          confirmBtnText: 'OK',
          dialogClassName: 'groupEntitlementsAttemptedUpdate-alert',
        });
      } else {
        let title =
          (filteredSelectionID.length > 0) &
          (selectedEntitlements.length === filteredSelectionID.length)
            ? 'Delete selected entitlements?'
            : `Only '${Active}' & '${PendingActive}' entitlements will be deleted`;

        Notify({
          alert: true,
          type: 'warning',
          title: title,
          confirmBtnText: 'YES DELETE',
          cancelBtnText: 'NO, KEEP IT',
          onConfirm: () => deleteEntitlements(filteredSelectionID),
        });
      }
    }
  };

  const deleteEntitlements = (filteredSelectionID) => {
    // delete user after confirmation
    if (filteredSelectionID.length > 0) {
      let deleteSuccessTitle =
        filteredSelectionID.length > 1
          ? 'Entitlements Deleted'
          : 'Entitlement Deleted';
      let entitlementID = filteredSelectionID?.map(
        (el) => el.userEntitlementId,
      );
      setLoading(true);
      httpService
        .delete(Endpoints.userentitlementsApi, entitlementID)
        .then((res) => {
          if (res.status === 200) {
            getEntitlements();
            Notify({
              alert: true,
              type: 'success',
              title: deleteSuccessTitle,
            });
          }
        })
        .catch(() => {})
        .finally(() => setLoading(false));
    }
    gridRef.current?.api?.deselectAll();
  };

  const notifyPendingApprovalEts = (approvalRqdStatus) => {
    // Alert user to confirm approval status of entitlements
    if (buttonIcons.ApproveIcon || buttonIcons.RejectIcon) {
      let selectedEntitlements = gridRef.current?.api?.getSelectedRows();
      let statusToApprove = [PendingApproval];
      let filteredSelectionID = [];
      selectedEntitlements.forEach((el) => {
        if (statusToApprove.includes(el.status)) filteredSelectionID.push(el);
      });

      let title =
        (filteredSelectionID.length > 0) &
        (selectedEntitlements.length === filteredSelectionID.length)
          ? `${approvalRqdStatus} selected entitlements?`
          : `Only ${PendingApproval} entitlements will be ${
              approvalRqdStatus === 'Approve' ? 'approved' : 'rejected'
            }`;

      let approvalStatus =
        approvalRqdStatus === 'Approve'
          ? PendingActive
          : approvalRqdStatus === 'Reject'
          ? Rejected
          : null;

      Notify({
        alert: true,
        type: 'warning',
        title: title,
        confirmBtnText: `YES ${approvalRqdStatus}`,
        cancelBtnText: 'NO, KEEP IT',
        onConfirm: () =>
          saveStatusOfPendingApprovalEts(filteredSelectionID, approvalStatus),
      });
    }
  };

  const saveStatusOfPendingApprovalEts = (
    filteredPendingApprovalETS,
    approvalStatus,
  ) => {
    // Save entitlement status after confirmation
    if (filteredPendingApprovalETS.length > 0 && approvalStatus) {
      let updatedPAEts = [];
      let postData = filteredPendingApprovalETS.map((obj) => {
        updatedPAEts.push({ ...obj, status: approvalStatus });
        return {
          userEntitlementId: obj.userEntitlementId,
          contactId: obj.contactId,
          productComponentId: obj.productComponentId,
          contractNumber: obj.contractNumber,
          status: approvalStatus,
        };
      });
      let entitlementModel = {
        userEntitlements: postData,
        userId: UserId,
      };
      let approvalSuccessTitle =
        filteredPendingApprovalETS.length > 0 &&
        approvalStatus === PendingActive
          ? 'Entitlements Approved'
          : 'Entitlements Rejected';

      setLoading(true);
      httpService
        .post(Endpoints.userentitlementsApi, entitlementModel)
        .then((res) => {
          if (res.status === 201) {
            getEntitlements();
            Notify({
              alert: true,
              type: 'success',
              title: approvalSuccessTitle,
            });
          }
        })
        .catch(() => {})
        .finally(() => setLoading(false));

      sendEmailToEU(filteredPendingApprovalETS, approvalStatus);
    }
    gridRef.current?.api?.deselectAll();
  };

  const sendEmailToEU = (filteredPendingApprovalETS, approvalStatus) => {
    try {
      let storeData = store.getState();
      let CCAEmail = storeData?.userInfo?.userInfo?.email;

      let status = approvalStatus === PendingActive ? 'Approved' : 'Rejected';
      const emailBodies = createEmailBodies(filteredPendingApprovalETS, status);

      Object.entries(emailBodies).forEach(([emailId, mailBody]) => {
        let emailData = [];
        let mail_to_address = emailId;
        let mail_cc_address = CCAEmail;
        let mail_from_address = 'donotreply@spglobal.com';
        let mail_subject =
          'CI Subscription Manager System Notification: Update on your New Entitlement Request';

        emailData.push({
          MAIL_FROM_ADDRESS: mail_from_address,
          MAIL_TO_ADDRESS: mail_to_address,
          MAIL_CC_ADDRESS: mail_cc_address,
          MAIL_BODY: mailBody,
          MAIL_SUBJECT: mail_subject,
        });

        httpService
          .post(Endpoints.sendEmailApi, emailData[0])
          .then((response) => {
            if (response.status === 200) {
              setTimeout(() => {}, 200);
            }
          })
          .catch((err) => {
            Notify({
              alert: true,
              type: 'error',
              title: GenericError.somethingWentWrong,
            });
          });
      });
    } catch (err) {}
  };

  const createEmailBodies = (pendingETS, status) => {
    const emailsGroup = pendingETS.reduce((acc, item) => {
      const {
        emailId,
        packageName,
        distributor,
        distributorPlatform,
        contactName,
      } = item;
      if (!acc[emailId]) {
        acc[emailId] = {
          packages: [],
          contactName,
        };
      }

      acc[emailId].packages.push({
        packageName,
        distributor,
        distributorPlatform,
      });
      return acc;
    }, {});

    const emailBodies = Object.entries(emailsGroup).reduce(
      (acc, [emailId, { packages, contactName }]) => {
        const tableRows = packages
          .map(
            ({ packageName, distributor, distributorPlatform }) =>
              `<tr>
                <td style='width: 50%;'>${packageName}</td>
                <td style='width: 30%;'>${distributor}:${distributorPlatform}</td>
                <td style='width: 20%;'>${status}</td>
              </tr>`,
          )
          .join('');

        const currentYear = new Date().getFullYear();

        const mailBody = `<div style='background-color: #F2F2F2; padding: 20px; font-family: Arial, sans-serif; color: #000000; margin: 0;'>
                            <table width='100%' cellpadding='0' cellspacing='0' style='margin: 0; padding: 0; border-collapse: collapse; font-family: Arial, sans-serif; color: #333333;'>
                              <tr>
                                <td align='center'>
                                  <table class='container' width='600' cellpadding='0' cellspacing='0' style='background-color: #FFFFFF; padding: 20px; border-radius: 5px; border-collapse: collapse; font-family: Arial, sans-serif; color: #333333;'>
                                    <tr>
                                      <td align='left' style='padding-bottom: 20px;'>
                                        <img src='https://www.spglobal.com/commodityinsights/images/spglobal-commodity-insights-logo.png' alt='S&P Global' width='230' height='80' style='margin-top: 16px; float: left; display: block; border: 0;'>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style='padding: 0 20px;'>
                                        <p style='font-size: 14px; color: #000000; margin: 0;'>
                                          Dear ${contactName},<br><br>
                                          We wanted to inform you about the status of your entitlement approval request submitted through the CI Subscription Manager. Please see the details below:
                                        </p>

                                        <p style='font-size: 14px; color: #000000; font-weight: bold; margin: 20px 0 0 0;'>
                                          Entitlement Request Status:
                                        </p>

                                        <table width='100%' border='1' cellpadding='8' cellspacing='0' style='border: 1px solid #dddddd; font-size: 14px; border-collapse: collapse; font-family: Arial, sans-serif; color: #333333; margin: 10px 0;'>
                                          <thead>
                                            <tr style='background-color: #f9f9f9;'>
                                              <th style='width: 50%; font-weight: bold; text-align: left;'>Package</th>
                                              <th style='width: 30%; font-weight: bold; text-align: left;'>Delivery Platform</th>
                                              <th style='width: 20%; font-weight: bold; text-align: left;'>Status</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            ${tableRows}
                                          </tbody>
                                        </table>

                                        <p style='font-size: 14px; color: #000000; margin: 20px 0 0 0;'>
                                          To view your entitlements and manage your subscriptions, please click the link below to log in to the S&P Global CI Subscription Manager:
                                        </p>

                                        <p style='text-align: left; margin: 20px 0;'>
                                          <a href='${window.location.origin}${UrlPath.mySubscription}' target="_blank" rel="noopener noreferrer" style='color: #0563C1; text-decoration: underline;'>Log in to CI Subscription Manager</a>
                                        </p>

                                        <p style='font-size: 14px; color: #000000; margin: 0;'>
                                          If you have any questions or need further assistance, feel free to reach out.<br><br>
                                          Kind regards,<br>
                                          S&P Global Commodity Insights
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style='padding-top: 10px; text-align: left;'>
                                        <hr width='93%' style='border: none; border-top: 1px solid #dddddd; margin: 0;'>
                                        <p style='font-size: 12px; padding-left: 4%; padding-bottom:10px; color: #999999; margin: 10px 0 0 0;'>
                                          © ${currentYear} S&P Global. All rights reserved.<br><br>
                                          <a href='${UrlPath.contact}' target="_blank" rel="noopener noreferrer" style='color: #0563C1; text-decoration: underline;'>Support</a> | 
                                          <a href='${UrlPath.termsOfUse}' target="_blank" rel="noopener noreferrer" style='color: #0563C1; text-decoration: underline;'>Terms of Use</a> | 
                                          <a href='${UrlPath.privacy}' target="_blank" rel="noopener noreferrer" style='color: #0563C1; text-decoration: underline;'>Privacy Policy</a> | 
                                          <a href='${UrlPath.cookieNotice}' target="_blank" rel="noopener noreferrer" style='color: #0563C1; text-decoration: underline;'>Cookie Notice</a>
                                        </p>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </div>
                          `;

        acc[emailId] = mailBody;
        return acc;
      },
      {},
    );

    return emailBodies;
  };

  const getRowId = (params) => {
    if (params?.data?.userEntitlementId != null) {
      return 'entitlement-' + params.data.userEntitlementId;
    } else {
      // assign a unique ID to each data item
      return Object.entries(params?.data).join(',');
    }
  };

  const getEntitlements = () => {
    gridRef?.current?.api?.onFilterChanged();
    gridRef?.current?.api?.ensureIndexVisible(0, 'top');
  };

  const getGroupCSVData = (params) => {
    // Export CSV on group including grouped column data
    if (!params.value) {
      let displayedColumns = params?.api?.getAllDisplayedColumns();
      let currentColumn = displayedColumns[params?.accumulatedRowIndex];
      let columnId = currentColumn?.colId?.split('ag-Grid-AutoColumn-')[1];
      return customValueGetter(params?.node, columnId);
    } else {
      return params?.value;
    }
  };

  const getEntitlementsQueryParams = async () => {
    try {
      if (gridRef) {
        const AgParams = gridRef.current?.api;
        const gridRequestStructure = {
          rowGroupCols: [
            ...AgParams.columnModel.rowGroupColumns.map((el) => ({
              ...el,
              id: el.colId,
            })),
          ],
          groupKeys: [],
        };
        const groupedColumn = await getGroupByColumns(gridRequestStructure);

        let FilterColumns =
          'emailId,contactName,deliveryPlatform,officeLocation,packageName,productName,status';
        let searchText = document.getElementById(
          'entitlement-search-filter-input',
        ).value;

        const currentFilterModel = { filterModel: AgParams?.getFilterModel() };
        let queryParams = {
          Filter: await getFilterColumns(currentFilterModel),
          FilteredColumns: await JSON.stringify(currentFilterModel.filterModel),
          Field:
            'contactId,contractNumber,userEntitlementId,deliveryPlatform,emailId,productComponentId,packageName,productName,officeLocation,groupId,groupName,status,contactName,modifiedDate',
          Sort: await getSortColumns(
            AgParams,
            'modifiedDate:desc,userEntitlementId:desc',
          ),
          querySearch: searchText,
          searchColumns: searchText ? FilterColumns : '',
          id: String(Date.now()),
        };

        return queryParams;
      }
    } catch (error) {}
  };

  const getDate = () => {
    let currentDate = new Date();
    return (
      currentDate.getDate() +
      '-' +
      (currentDate.getMonth() + 1) +
      '-' +
      currentDate.getFullYear()
    );
  };

  const exportEntitlementCSV = async () => {
    if (buttonIcons.ExportIcon && !buttonIcons.ExportInProgress) {
      setButtonIcons({ ...buttonIcons, ExportInProgress: true });

      const requestURL = Endpoints.entitlementExportApi;
      const queryParams = await getEntitlementsQueryParams();
      const fileName = 'Entitlements_' + getDate();
      await ExportServerSide(
        gridRef,
        requestURL,
        queryParams,
        fileName,
        customValueGetter,
        getMergedColName,
      );

      setTimeout(
        () => setButtonIcons({ ...buttonIcons, ExportInProgress: false }),
        300,
      );
    }
  };

  const exportEntitlementCSVDebounce = debounce(exportEntitlementCSV, 500);

  const handleCloseBulkUerEntitlements = () =>
    setShowBulkUserEntitlements(false);
  const handleShowBulkUserEntitlements = () =>
    setShowBulkUserEntitlements(true);

  return (
    <>
      {loading && <Loader type="scaleLoader" cssClass={loaderStyle} />}
      {showBulkUserEntitlements && (
        <BulkUploadUserEntitlements
          showBulkUserEntitlements={showBulkUserEntitlements}
          handleCloseBulkUerEntitlements={handleCloseBulkUerEntitlements}
          contracts={contracts}
          getUpdatedUserEntitlements={getEntitlements}
        />
      )}
      <>
        <div className="row mt-2">
          <div className="col">
            <input
              type="text"
              id="entitlement-search-filter-input"
              disabled={buttonIcons.ExportInProgress}
              className="form-control search-box"
              defaultValue={inputValue}
              placeholder="Search by users, packages, products, delivery platform or status"
              onInput={onFilterTextBoxChangedDebounce}
            ></input>
          </div>
          <div className="col action-item-bar">
            {isViewerUser ? (
              <span className="user-grid-icons icon-divider">
                <Link to="/reports">
                  <ExportIcon
                    className="icon-active"
                    alt="Export Active Users Report"
                    width="18"
                    title="Export Active Users Report"
                  />
                </Link>
              </span>
            ) : (
              <>
                <span className="user-grid-icons icon-divider">
                  <ApproveIcon
                    className={`approve-icon ${
                      buttonIcons.ApproveIcon ? 'icon-active' : 'cursor-default'
                    }`}
                    alt="Approve Entitlements"
                    width="18"
                    title="Approve entitlements"
                    onClick={() => notifyPendingApprovalEts('Approve')}
                  />
                </span>
                <span className="user-grid-icons icon-divider">
                  <RejectIcon
                    className={`reject-icon ${
                      buttonIcons.RejectIcon ? 'icon-active' : 'cursor-default'
                    }`}
                    alt="Reject Entitlements"
                    width="18"
                    title="Reject entitlements"
                    onClick={() => notifyPendingApprovalEts('Reject')}
                  />
                </span>
                <span className="user-grid-icons icon-divider">
                  <DeleteIcon
                    className={
                      buttonIcons.DeleteIcon ? 'icon-active' : 'cursor-default'
                    }
                    alt="Delete Entitlements"
                    width="18"
                    title="Delete entitlements"
                    onClick={notifyDeleteEntitlements}
                  />
                </span>
                <span className="user-grid-icons icon-divider">
                  <Link to="/reports">
                    <ExportIcon
                      className="icon-active"
                      alt="Export Active Users Report"
                      width="18"
                      title="Export Active Users Report"
                    />
                  </Link>
                </span>
                <span className="user-grid-icons icon-divider">
                  <Link to="/add-entitlements" title="Create New entitlements">
                    <img
                      src={addEntitlementIcon}
                      className="logo-img"
                      alt="ADD ENTITLEMENTS"
                      width="19"
                    />
                  </Link>
                </span>
                <span className="user-grid-icons icon-divider">
                  <Link
                    to="/product-entitlements"
                    title="Manage email preferences"
                  >
                    <img
                      src={mailing}
                      className="logo-img"
                      alt="Manage email preferences"
                      width="19"
                    />
                  </Link>
                </span>
                {bulkUserEntitlementEnable && (
                  <span className="user-grid-icons icon-divider">
                    <CloudIcon
                      alt="Import User Entitlements"
                      width="20"
                      title="Import User Entitlements"
                      onClick={handleShowBulkUserEntitlements}
                    ></CloudIcon>
                  </span>
                )}
              </>
            )}
          </div>
        </div>
        {isViewerUser ? (
          ''
        ) : (
          <div className="row mt-2 mb-n1">
            {pendingApprovalCount > 0 && (
              <div className="col">
                <div className="d-flex">
                  <img
                    src={bell_icon}
                    className="user-bell-icon img-fluid rounded-circle"
                    alt="Admin"
                  />

                  <div className="user-history-wrapper">
                    <span className="user-history-title">
                      There are new entitlement requests pending approval.{' '}
                      <span
                        className="spg-red cursor-pointer"
                        onClick={() =>
                          filterPendingApprovalETS('Pending Approval')
                        }
                      >
                        Click here
                      </span>{' '}
                      to view and approve
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        <div className="ag-grid-table mb-2" ref={gridContainerRef}>
          <AgGridComponent
            autoGroupColumnDef={autoGroupColumnDef}
            config={defaultConfig}
            defaultColumnDef={defaultColdef}
            columns={columns}
            getChildCount={getChildCount}
            onGridReady={onGridReadyHandler}
            onSelectionChanged={onSelectionChangedDebounce}
            groupDisplayType="multipleColumns"
            getRowId={getRowId}
            isRowSelectable={isRowSelectable}
          />
          <div className="notes-text-label mt-1" id="notes-label">
            <span className="notes-asterisk-label">
              <UserGroupIcon
                className="icon-active"
                alt="User Group"
                width="18"
              ></UserGroupIcon>
            </span>
            User is part of a group and can not be entitled separately.
            Entitlements must be updated at group level.
          </div>
        </div>
      </>
    </>
  );
};

export default Entitlements;
